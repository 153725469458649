export const MOBILE_BREAKPOINT = 647
export const TABLET_BREAKPOINT = 767
export const DESKTOP_BREAKPOINT = 1024
export const DESKTOP_LARGE_BREAKPOINT = 1280
export const COMPANY_NAME = 'recteq'
export const HEADER_THRESHOLD = 100
export const HITS_PER_PAGE = 250

export const TAGS = {
  cart: 'cart',
  products: 'products',
  customer: 'customer',
  subscription: 'subscription'
}
export const DEFAULT_OPTION = 'Default Title'
export const FALLBACK_IMAGE = '/images/fallback-image.png'
export const FALLBACK_OG_IMAGE = '/images/recteq-MetaImage_1200x630.webp'
export const FALLBACK_PROFILE_IMAGE = '/images/fallback-profile-image.jpeg'
export const DEFAULT_LOGO = '/images/recteq-OFFICIAL-LOGO.png'
export const ALGOLIA_OPERATORS = {
  equals: ':',
  greater_than: '>',
  less_than: '<',
  less_or_equal_than: '<=',
  greater_or_equal_than: '>=',
  is_not: ':'
}

export const SHOPIFY_QUALIFIERS_HANDLE = ['bundle-qualifier', 'order-adjustment']
export const INACTIVE_PRODUCT_TAGS = ['Hide From Search', 'no-search']
export const BUNDLE_PRODUCT_TYPE = 'Grill_Bundle'
export const CALL_TO_ORDER_TAG = 'call-to-order'

let url_site = `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  url_site = `https://${process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL}`
} else if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'release') {
  url_site = `https://recteq-dtc-env-release-recteq.vercel.app`
} else if (process.env.NODE_ENV === 'development') {
  url_site = `http://localhost:3000`
}

export const SITE_URL = url_site
export const RAYSCLUB_TAG = 'Rays-Club'
export const PELLET_TWO_PACK = 'Pellet-Two-Pack'
