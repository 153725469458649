import { Select } from '@/components'
import { useAlgoliaContext } from '@/context'

const SortInput = ({
  options,
  onChange
}: {
  options: { label: string; value: string }[]
  onChange?: (props?: any) => void
}) => {
  const { setSort } = useAlgoliaContext()

  return (
    <section className="flex w-full lg:max-w-[300px]">
      <div className="w-full">
        <Select
          placeholder="Sort By"
          onChange={({ value }) => {
            setSort && setSort(value)
            onChange && onChange(value)
          }}
          options={options}
          inputStyles="text-base"
        />
      </div>
    </section>
  )
}

export default SortInput
