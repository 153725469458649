'use client'
import { Accordion, ProductFeatures, ProductSpecifications } from '@/components'
import Image from 'next/image'
import { Key, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { getProductMetafields } from '@/app/(store)/lib'

type productSpecsType = {
  image: {
    url: string
    altText: string
  }
}

interface IconType {
  name: string
  provider: string
  type: string
}

interface AccordionItemProps {
  title: string
  content: string
}

type productDetailType = {
  _key: string
  title: string
  items: Partial<AccordionItemProps>[]
  collapseIcon?: IconType
  expandIcon?: IconType
}

type Props = {
  productSpecs: productSpecsType[]
  productDetail: productDetailType
  handle: string
}

const Carousel = dynamic(() => import('@/components').then((mod) => mod.Carousel), { ssr: false })

const ProductSpecs = ({ productSpecs, productDetail, handle }: Props) => {
  const [metafields, setMetafields] = useState<Metafield[]>([])
  const [hasSpecs, setHasSpecs] = useState(true)
  useEffect(() => {
    const getData = async () => {
      const data = await getProductMetafields(handle, [
        { namespace: 'custom', key: 'wifi_enabled' },
        { namespace: 'custom', key: 'power_source' },
        { namespace: 'custom', key: 'fuel_source' },
        { namespace: 'custom', key: 'warranty' },
        { namespace: 'custom', key: 'assembly_time' },
        { namespace: 'custom', key: 'direct_flame' },
        { namespace: 'custom', key: 'temperature_probes' },
        { namespace: 'custom', key: 'hopper_capacity' },
        { namespace: 'custom', key: 'stainless_steel_barrel' },
        { namespace: 'custom', key: 'interior_lighting' },
        { namespace: 'custom', key: 'grill_grate_material' },
        { namespace: 'custom', key: 'min_temp' },
        { namespace: 'custom', key: 'max_temp' },
        { namespace: 'custom', key: 'features_list' },
        { namespace: 'custom', key: 'total_cooking_space' },
        { namespace: 'custom', key: 'total_space_with_second_shelf' },
        { namespace: 'custom', key: 'riot_mode' },
        { namespace: 'custom', key: 'stainless_steel_ffs' },
        { namespace: 'custom', key: 'cast_iron_deflector' },
        { namespace: 'custom', key: 'grill_grates' },
        { namespace: 'custom', key: 'ingredients' }
      ])
      data?.metafields && setMetafields(data?.metafields)
    }
    getData()
  }, [handle])

  const specsImages =
    productSpecs?.map(
      (item: { image: { url: string; altText: string } }, index: Key | null | undefined) => (
        <div key={index} className="h-full">
          <Image
            fill
            src={item?.image?.url}
            alt={item?.image?.altText || ''}
            sizes="(max-width: 768px) 80vw, 50vw"
            className={`object-contain`}
            key={index}
          />
        </div>
      )
    ) || []

  const featuresMetadata = metafields.reduce((acc, curr) => {
    if (curr) {
      return [...acc, curr]
    }
    return acc
  }, [] as Metafield[])

  const specificationsMetadata = metafields.reduce((acc, curr) => {
    if (curr) {
      return [...acc, curr]
    }
    return acc
  }, [] as Metafield[])

  const hasCarouselItems = specsImages.length > 0
  useEffect(() => {
    if (
      (specificationsMetadata?.length < 1 && specificationsMetadata[0]?.key === 'features_list') ||
      (specificationsMetadata?.length > 1 && specificationsMetadata[1]?.key === 'ingredients') ||
      specificationsMetadata?.length === 1
    ) {
      setHasSpecs(false)
    }
  }, [specificationsMetadata])

  return (
    <>
      {(productDetail?.items?.length > 0 || specificationsMetadata?.length > 0) && (
        <>
          {productDetail?.title && <h2 className="h2 module-spacing">{productDetail?.title}</h2>}
          <div className="flex flex-wrap">
            {hasCarouselItems && (
              <div
                className="h-[360px] w-full lg:w-1/3 lg:px-8"
                data-component="product-specs-slider"
              >
                <Carousel
                  loop={false}
                  delay={false}
                  embedded={true}
                  slidesPerView={1}
                  mobileSlidesPerView={1}
                  showNavigation={true}
                  showPagination={true}
                  paginationStyles="light"
                  items={[...specsImages]}
                  wrapperStyles="h-full"
                  pagination={{
                    el: `.swiper-custom-pagination-${productDetail._key}`,
                    clickable: true,
                    type: 'progressbar'
                  }}
                />
              </div>
            )}
            <div className={`mt-10 w-full md:mt-0 ${hasCarouselItems ? 'lg:w-2/3' : 'lg:w-full'}`}>
              <Accordion
                items={[
                  featuresMetadata.length > 0
                    ? {
                        title: 'Features',
                        content: <ProductFeatures metafields={featuresMetadata} handle={handle} />
                      }
                    : undefined,
                  hasSpecs
                    ? {
                        title: 'Specifications',
                        content: (
                          <ProductSpecifications
                            metafields={specificationsMetadata}
                            handle={handle}
                          />
                        )
                      }
                    : undefined,
                  ...(productDetail?.items ? productDetail?.items : [])
                ]}
                collapseIcon={productDetail?.collapseIcon}
                expandIcon={productDetail?.expandIcon}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ProductSpecs
