export function minutesToISO8601Duration(minutes: number) {
  // Ensure minutes is a positive number
  if (isNaN(minutes) || minutes < 0) {
    console.error('Invalid number of minutes')
    return ''
  }

  // Calculate hours and remaining minutes
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  // Construct the ISO 8601 duration string
  let duration = 'PT'
  if (hours > 0) {
    duration += `${hours}H`
  }
  if (remainingMinutes > 0) {
    duration += `${remainingMinutes}M`
  }

  // Handle case when both hours and minutes are zero
  return duration || 'PT0M'
}

export function minutesToHours(minutes: number) {
  if (isNaN(minutes) || minutes < 0) {
    console.error('Invalid number of minutes')
    return ''
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  let duration = ''
  if (hours > 0) {
    duration += `${hours} hrs${remainingMinutes ? ', ' : ''}`
  }
  if (remainingMinutes > 0) {
    duration += `${remainingMinutes} min`
  }

  return duration || ''
}
