'use client'
export type GtmEventKey =
  | 'addToCartFromPDP'
  | 'addToCartFromProductCard'
  | 'addToCartFromCategoryCard'
  | 'quantityIncreased'
  | 'checkoutStarted'
  | 'compareProductAdded'
  | 'compareProductRemoved'
  | 'compareDrawerExpanded'
  | 'compareDrawerCollapsed'
  | 'carouselRight'
  | 'carouselLeft'
  | 'dropdownExpanded'
  | 'dropdownCollapsed'
  | 'callToActionClick'
  | 'facetSelected'
  | 'facetDragStarted'
  | 'facetDragEnded'
  | 'facetInput'
  | 'facetsCleared'
  | 'facetCleared'
  | 'login'
  | 'menuExpanded'
  | 'subMenuExpanded'
  | 'menuCollapsed'
  | 'subMenuCollapsed'
  | 'miniCartOpened'
  | 'miniCartClosed'
  | 'mobileMenuOpened'
  | 'mobileMenuClosed'
  | 'removeFromCart'
  | 'quantityDecreased'
  | 'searchOpened'
  | 'searchInput'
  | 'searchPerformed'
  | 'searchClosed'
  | 'signUp'
  | 'userData'
  | 'viewCart'
  | 'viewItem'
  | 'viewItemListCategory'
  | 'viewItemListProduct'
  | 'searchProductClicked'
  | 'searchContentClicked'

/**
 * Adds product(s) to cart via localStorage update + pushes `dl_add_to_cart`.
 * Also handles optional 'bundle' logic & calculates total value.
 */
import { getCart } from '@/lib/shopify'
import { getCookie } from '../utils'

const klaviyoAddToCart = async (data: any) => {
  let klaviyo = []
  const cartId = await getCookie('cartId')
  const klaviyoAccount_ssid = await getCookie('__ssid')
  const klaviyoAccount_kla = await getCookie('__kla_id')
  const klaviyoAccount = klaviyoAccount_ssid || klaviyoAccount_kla
  let customerInfo: any = {}

  let cart: any = []

  if (cartId) {
    cart = await getCart(cartId)
  }

  if (typeof window !== 'undefined') {
    klaviyo = window?.klaviyo
  }
  const myCallback = () => {
    let cartLines = []
    if (cart?.lines) {
      cartLines = cart.lines.map((item: any) => ({
        ProductID: `${extractNumericId(item?.merchandise?.id)}`,
        SKU: `${item.merchandise?.sku}`,
        ProductName: `${item?.merchandise?.product?.title}${item?.merchandise?.product?.options[0]?.values?.length > 1 ? ` - ${item?.merchandise?.title}` : ''}`,
        Quantity: item?.quantity,
        ItemPrice: item.merchandise?.price?.amount,
        RowTotal: item.cost?.totalAmount?.amount,
        ProductURL: `https://www.recteq.com/products/${item.merchandise?.product?.handle}${item.merchandise?.requiresComponents ? `?variantId=${btoa(item.product?.id)}&?bundleId=${btoa(item.merchandise?.id)}` : `?variantId=${btoa(item.merchandise?.id)}`}`,
        ImageURL: `${item.merchandise?.image?.url}`,
        ProductCategories: [`${item?.merchandise?.product?.productType}`]
      }))
    }
    const klaviyoProduct = {
      $value: data.selectedVariant?.price?.amount,
      AddedItemProductName: `${data.selectedVariant?.title != 'Default Title' ? data.selectedVariant?.title : data.selectedVariant?.product?.title}`,
      AddedItemProductID: `${extractNumericId(data.selectedVariant?.id)}`,
      AddedItemSKU: `${data.selectedVariant?.sku}`,
      AddedItemCategories: `${data.selectedVariant?.product?.productType}`,
      AddedItemImageURL: `${data.selectedVariant?.image?.url}`,
      AddedItemURL: `https://www.recteq.com/products/${data.selectedVariant?.product?.handle}?variantId=${btoa(data.selectedVariant?.id)}${data.selectedVariant?.bundle?.id ? `&bundleId=${btoa(data.selectedVariant?.bundle?.id)}` : ''}`,
      AddedItemPrice: data.selectedVariant?.price?.amount,
      AddedItemQuantity: data.selectedVariant?.quantity || 1,
      CheckoutURL: `${cart?.checkoutUrl}`,
      Items: [...cartLines]
    }
    klaviyo.account(klaviyoAccount)
    klaviyo.track('Added to Cart', klaviyoProduct)
  }

  try {
    const storedCustomer = localStorage.getItem('customer')
    if (storedCustomer) {
      customerInfo = JSON.parse(storedCustomer)
    }
  } catch (error) {
    console.error('Error parsing customer data in viewItem:', error)
  }
  const identity = {
    email: `${customerInfo?.email}`,
    first_name: `${customerInfo?.firstName}`,
    last_name: `${customerInfo?.lastName}`
  }
  if (klaviyo) {
    klaviyo?.identify(customerInfo?.email && customerInfo?.firstName ? identity : {}, myCallback)
  }
}
const addToCartFromPDP = (data: any) => {
  try {
    const { selectedVariant, quantity, bundle } = data
    const variantId = extractNumericId(selectedVariant.id)
    const productId = extractNumericId(selectedVariant.product.id)
    const { effectivePrice, discountAmount, currency } = getEffectivePrice(
      selectedVariant.price,
      selectedVariant.compareAtPrice
    )
    const products = [
      {
        id: selectedVariant.sku,
        variant_id: variantId,
        product_id: productId,
        name: selectedVariant.product.title,
        brand: 'recteq',
        quantity: quantity || 1,
        price: effectivePrice,
        discount: discountAmount
      }
    ]
    let totalValue = parseFloat(effectivePrice) * (quantity || 1)
    if (bundle) {
      const bundleId = extractNumericId(bundle.id)
      const { effectivePrice: bundlePrice, discountAmount: bundleDiscount } = getEffectivePrice(
        bundle.variants[0].price,
        bundle.variants[0].compareAtPrice
      )

      products.push({
        id: bundle.title,
        variant_id: bundleId,
        product_id: productId,
        name: bundle.title,
        brand: 'recteq',
        quantity: 1,
        price: bundlePrice,
        discount: bundleDiscount
      })
      totalValue += parseFloat(bundlePrice)
    }

    // Update the localStorage cart
    const cartItems = updateCartInLocalStorage(products)

    const eventObject = {
      event: 'dl_add_to_cart',
      ecommerce: {
        currencyCode: currency,
        value: totalValue.toFixed(2),
        cart_contents: {
          products: cartItems
        },
        add: {
          products
        }
      }
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    klaviyoAddToCart(data)
    return
  } catch (error) {
    console.warn('Error formatting AddToCartFromPDP event:', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: 'dl_add_to_cart', ecommerce: {} })
    } else {
      console.warn('DataLayer is not initialized.')
    }

    return
  }
}
/**
 * Adds product(s) to cart via localStorage + pushes `dl_add_to_cart`
 * for a product card scenario.
 */
const addToCartFromProductCard = (data: any) => {
  try {
    const { selectedVariant, quantity } = data

    const variantId = extractNumericId(selectedVariant.firstVariantId)
    const productId = extractProductId(data.selectedVariant._tags)
    const { effectivePrice, discountAmount, currency } = getEffectivePrice(
      selectedVariant.price,
      selectedVariant.compareAtPrice
    )
    const products = [
      {
        id: selectedVariant.sku,
        variant_id: variantId,
        product_id: productId,
        name: selectedVariant.title,
        brand: 'recteq',
        quantity: quantity || 1,
        price: effectivePrice,
        discount: discountAmount
      }
    ]
    let totalValue = parseFloat(effectivePrice) * (quantity || 1)

    // Update the localStorage cart
    const cartItems = updateCartInLocalStorage(products)

    const eventObject = {
      event: 'dl_add_to_cart',
      ecommerce: {
        currencyCode: currency,
        value: totalValue.toFixed(2),
        cart_contents: {
          products: cartItems
        },
        add: {
          products
        }
      }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    klaviyoAddToCart(data)
    return
  } catch (error) {
    console.warn('Error formatting AddToCartFromProductCard event:', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: 'dl_add_to_cart', ecommerce: {} })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/**
 * Similar to above but uses data from a category card. Also
 * updates localStorage and pushes `dl_add_to_cart`
 */
const addToCartFromCategoryCard = (data: any) => {
  try {
    const { selectedVariant, quantity } = data

    const variantId = extractNumericId(selectedVariant.variants[0].id)
    const productId = extractNumericId(selectedVariant.id)
    const { effectivePrice, discountAmount, currency } = getEffectivePrice(
      selectedVariant.variants[0].price,
      selectedVariant.variants[0].compareAtPrice
    )
    const products = [
      {
        id: selectedVariant.variants[0].sku,
        variant_id: variantId,
        product_id: productId,
        name: selectedVariant.title,
        brand: 'recteq',
        quantity: quantity || 1,
        price: effectivePrice,
        discount: discountAmount
      }
    ]
    let totalValue = parseFloat(effectivePrice) * (quantity || 1)

    // Update the localStorage cart
    const cartItems = updateCartInLocalStorage(products)

    const eventObject = {
      event: 'dl_add_to_cart',
      ecommerce: {
        currencyCode: currency,
        value: totalValue.toFixed(2),
        cart_contents: {
          products: cartItems
        },
        add: {
          products
        }
      }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    klaviyoAddToCart(data)
    return
  } catch (error) {
    console.warn('Error formatting AddToCartFromCategoryCard event:', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: 'dl_add_to_cart', ecommerce: {} })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/** Increments quantity on a cart item (just pushes `dl_quantity_increased`) */
const quantityIncreased = (item: CartItem) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_quantity_increased',
        actionField: { item: item.merchandise.product.title }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error formatting AddToCartFromPDP event:', error)
      window.dataLayer.push({ event: 'dl_quanitity_increased' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes a simple checkoutStarted event */
const checkoutStarted = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_checkout_started'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes compare product added with a product title */
const compareProductAdded = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_compare_product_added',
        actionField: { product: data.title }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in compareProductAdded:', error)
      window.dataLayer.push({ event: 'dl_compare_product_added' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes compare product removed with a product title */
const compareProductRemoved = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_compare_product_removed',
        actionField: { product: data.title }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in compareProductRemoved:', error)
      window.dataLayer.push({ event: 'dl_compare_product_removed' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes compare drawer expanded */
const compareDrawerExpanded = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_compare_drawer_expanded'
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in compareDrawerExpanded:', error)
      window.dataLayer.push({ event: 'dl_compare_drawer_expanded' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes compare drawer collapsed */
const compareDrawerCollapsed = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_compare_drawer_collapsed'
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in compareDrawerCollapsed:', error)
      window.dataLayer.push({ event: 'dl_compare_drawer_collapsed' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes carousel right with optional data */
const carouselRight = (data?: string) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_carousel_right',
        actionField: { carousel: data }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in carouselRight:', error)
      window.dataLayer.push({ event: 'dl_carousel_right' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes carousel left with optional data */
const carouselLeft = (data?: string) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_carousel_left',
        actionField: { carousel: data }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in carouselLeft:', error)
      window.dataLayer.push({ event: 'dl_carousel_left' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes dropdown expanded with a dropdown name */
const dropdownExpanded = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_dropdown_expanded',
        actionField: { dropdown: data }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in dropdownExpanded:', error)
      window.dataLayer.push({ event: 'dl_dropdown_expanded' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes dropdown collapsed with a dropdown name */
const dropdownCollapsed = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_dropdown_collapsed',
        actionField: { dropdown: data }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in dropdownCollapsed:', error)
      window.dataLayer.push({ event: 'dl_dropdown_collapsed' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes a CTA click with label & component details */
const callToActionClick = ({ label, component }: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_call_to_action_click',
        actionField: { label: label, component: component }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in dropdownCollapsed:', error)
      window.dataLayer.push({ event: 'dl_call_to_action_click' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes facet selected with group & facet details */
const facetSelected = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_facet_selected',
        actionField: { group: data?.facetList, facet: data?.facetItem }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in facetSelected:', error)
      window.dataLayer.push({ event: 'dl_facet_selected' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes facet drag started with facet + value info */
const facetDragStarted = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_facet_drag_started',
        actionField: { facet: data?.title, value: data?.value?.value }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in facetDragStarted:', error)
      window.dataLayer.push({ event: 'dl_facet_drag_started' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes facet drag ended with facet + value info */
const facetDragEnded = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_facet_drag_ended',
        actionField: { facet: data?.title, value: data?.value?.value }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in facetDragEnded:', error)
      window.dataLayer.push({ event: 'dl_facet_drag_ended' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes facet input with the facet data */
const facetInput = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_facet_input',
        actionField: { facet: data.title, value: data.value }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('Error in facetInput:', error)
      window.dataLayer.push({ event: 'dl_facet_input' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/**  pushes event for facets cleared */
const facetsCleared = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_facets_cleared'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/**  pushes event for facets cleared */
const facetCleared = (name: string) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_facet_cleared',
      actionField: { facet: name }
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/** Pushes a login event with "visitor_type" property */
const login = (customer: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventObject = {
      event: 'dl_login',
      user_properties: {
        visitor_type: 'logged_in'
      }
    }
    window.dataLayer.push(eventObject)
    localStorage.setItem('customer', JSON.stringify(customer))
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes menu expanded
const menuExpanded = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_menu_expanded'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes sub menu expanded
const subMenuExpanded = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_sub_menu_expanded'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes menu collapsed
const menuCollapsed = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_menu_collapsed'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes submenu collapsed
const subMenuCollapsed = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_sub_menu_collapsed'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes mini cart opened
const miniCartOpened = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_mini_cart_opened'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes mini cart closed
const miniCartClosed = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_mini_cart_closed'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes mobile menu opened
const mobileMenuOpened = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_mobile_menu_opened'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes mobile menu closed
const mobileMenuClosed = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_mobile_menu_closed'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes data to remove from cart and removes item from local storage
const removeFromCart = (data: any) => {
  try {
    const productId = extractNumericId(data)
    const productData = removeItemFromCartInLocalStorage(productId)

    const eventObject = {
      event: 'dl_remove_from_cart',
      ecommerce: {
        remove: {
          products: [productData]
        }
      }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  } catch (error) {
    console.warn('error in formatRemoveFromCart', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: 'dl_remove_from_cart' })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
// pushes quantity decreased
const quantityDecreased = (item: CartItem) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_quanitity_decreased',
        actionField: { item: item.merchandise.product.title }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('error in quantityDecreased', error)
      window.dataLayer.push({ event: 'dl_quanitity_decreased' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes search opened
const searchOpened = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_search_opened'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes search input
const searchInput = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_search_input',
      actionField: { list: data.query }
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes search performed
const searchPerformed = (data: any) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      const eventData = {
        event: 'dl_search_performed',
        actionField: { list: data.query }
      }
      window.dataLayer.push(eventData)
    } catch (error) {
      console.warn('error in searchPerformed', error)
      window.dataLayer.push({ event: 'dl_search_performed' })
    }
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes search closed
const searchClosed = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_search_closed'
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes search produxt clicked with the product name
const searchProductClicked = (title: string) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_search_product_clicked',
      actionField: { product: title }
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
// pushes search content clicked with the article name
const searchContentClicked = (title: string) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'dl_search_content_clicked',
      actionField: { content: title }
    }
    window.dataLayer.push(eventData)
  } else {
    console.warn('DataLayer is not initialized.')
  }
  return
}
/**
 * Submits user sign-up info and pushes `dl_sign_up`.
 * Also sets user_properties with email & consent info.
 */
const signUp = (data: any) => {
  try {
    const eventObject = {
      event: 'dl_sign_up',
      user_properties: {
        visitor_type: 'logged_in',
        customer_email: data.customer.email,
        user_consent: 'yes'
      }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  } catch (error) {
    console.warn('error in formatSignUp', error)
    const eventObject = {
      event: 'dl_sign_up',
      user_properties: {
        visitor_type: 'logged_in',
        user_consent: 'yes'
      }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/**
 * Gathers user/cart data from localStorage & cookies,
 * sets user properties, then pushes `dl_user_data`.
 */
const userData = (data: any) => {
  try {
    const userId = getUserId()
    const cartTotal = data.cookieCart?.cost.subtotalAmount.amount || 0
    const eventId = generateUUID()
    const cart_contents = formatCartContents(data.cookieCart)
    const cartItems = initCart(cart_contents)
    const GTMCookieKey = process.env.NEXT_PUBLIC_GA_COOKIE_ID || '_ga_GA4ID'
    //prepare marketing cookies
    const marketing = {
      _ga: data._gaCookie,
      [GTMCookieKey]: data._gtmCookie
    }
    // Prepare user properties
    const userProperties = {
      user_id: userId,
      visitor_type: userId ? 'guest' : 'registered'
    }

    // Prepare ecommerce data
    const ecommerceData = {
      cart_contents: {
        products: cartItems
      }
    }

    const dlUserDataEvent = {
      event: 'dl_user_data',
      event_id: eventId,
      restricted_data_processing: false,
      cart_total: cartTotal,
      user_properties: userProperties,
      ecommerce: ecommerceData,
      marketing: marketing
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(dlUserDataEvent)
    } else {
      console.warn('DataLayer is not initialized.')
    }
  } catch (error) {
    console.warn('error in userData', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: 'dl_user_data' })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/**
 * Retrieves cart from localStorage & pushes `dl_view_cart`.
 * Also includes impressions of items in the cart.
 */
const viewCart = () => {
  try {
    const cartKey = '_recteq_gtm_suite--cart'
    let cartData = localStorage.getItem(cartKey)
    let cart = []
    // Retrieve existing cart from localStorage
    if (cartData) {
      try {
        cart = JSON.parse(cartData)
        if (!Array.isArray(cart)) {
          throw new Error('Cart data is not an array')
        }
      } catch (error) {
        console.warn('Invalid cart data in localStorage, resetting to empty array')
        cart = []
      }
    }

    const eventObject = {
      event: 'dl_view_cart',
      ecommerce: {
        impressions: cart
      }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  } catch (error) {
    console.warn('error in formatViewCart', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'dl_view_cart',
        ecommerce: {
          impressions: []
        }
      })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/** Builds a single product object from PDP data, then pushes event */

const viewItem = async (payload: any) => {
  const data = payload[0]
  const currentVariant = payload[1]
  let customerInfo: any = {}

  try {
    // Safely access properties
    const variants = data?.variants
    if (!Array.isArray(variants) || variants.length === 0) {
      console.warn('formatViewItem: data.variants is empty or not an array')
      return { event: 'dl_view_item', ecommerce: {} }
    }

    const firstVariant = variants[0]
    const variantId = extractNumericId(firstVariant?.id)
    const productId = extractNumericId(data?.id)

    const { effectivePrice, discountAmount } = getEffectivePrice(
      firstVariant?.price,
      firstVariant?.compareAtPrice
    )

    const product = {
      id: firstVariant?.sku || 'unknown_sku',
      variant_id: variantId || 'unknown_variant_id',
      product_id: productId || 'unknown_product_id',
      name: data?.title || 'Unnamed Product',
      brand: 'recteq',
      quantity: 1,
      price: effectivePrice,
      discount: discountAmount
    }

    const eventObject = {
      event: 'dl_view_item',
      ecommerce: {
        detail: {
          products: [product]
        }
      }
    }
    let klaviyo = []
    if (typeof window !== 'undefined') {
      klaviyo = window?.klaviyo
    }

    try {
      const storedCustomer = localStorage.getItem('customer')
      if (storedCustomer) {
        customerInfo = JSON.parse(storedCustomer)
      }
    } catch (error) {
      console.error('Error parsing customer data:', error)
      // Continue with empty customer info
    }

    const myCallback = () => {
      const klaviyoProduct = {
        ProductName: data?.title || 'Unnamed Product',
        ProductID: productId || 'unknown_product_id',
        SKU: data.variants[0].sku,
        Categories: data.productType,
        ImageURL: data.featuredImage?.url,
        URL: `https://www.recteq.com/products/${data.handle}?variantId=${btoa(currentVariant?.id)}`,
        Brand: 'recteq',
        Price: effectivePrice,
        CompareAtPrice: data.variants[0]?.compareAtPrice || effectivePrice
      }
      klaviyo.track('Viewed Product', klaviyoProduct)
      klaviyo.trackViewedItem({
        Title: data?.title || 'Unnamed Product',
        ItemId: productId || 'unknown_product_id',
        Categories: data.productType,
        ImageUrl: data.featuredImage?.url,
        Url: `https://www.recteq.com/products/${data.handle}?variantId=${btoa(currentVariant?.id)}`,
        Metadata: {
          Brand: 'recteq',
          Price: effectivePrice,
          CompareAtPrice: data.variants[0]?.compareAtPrice || effectivePrice
        }
      })
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }

    const identity = {
      email: `${customerInfo?.email}`,
      first_name: `${customerInfo?.firstName}`,
      last_name: `${customerInfo?.lastName}`
    }
    if (klaviyo) {
      klaviyo?.identify(customerInfo?.email ? identity : {}, myCallback)
    }
  } catch (error) {
    console.warn('Error in formatViewItem:', error)
    // Return a safe fallback event object if something goes wrong
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'dl_view_item',
        ecommerce: {}
      })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/** Builds an impressions array for a category listing, then pushes event */
const viewItemListProduct = (data: any) => {
  try {
    const impressions = Array.isArray(data?.impressions) ? data.impressions.slice(0, 9) : []
    const listValue = (typeof window !== 'undefined' && window?.location?.pathname) || ''

    const products = impressions.map((impression: any) => {
      const variantId = extractNumericId(impression?.firstVariantId)
      const productId = extractProductId(impression?._tags)

      const { effectivePrice, discountAmount } = getEffectivePrice(
        impression?.price,
        impression?.compareAtPrice
      )

      return {
        id: impression?.sku || 'unknown_sku',
        variant_id: variantId || 'unknown_variant_id',
        product_id: productId || 'unknown_product_id',
        name: impression?.title || 'Unnamed Product',
        brand: 'recteq',
        list: listValue,
        price: effectivePrice,
        discount: discountAmount
      }
    })

    const eventObject = {
      event: 'dl_view_item_list',
      actionField: { list: listValue },
      ecommerce: { impressions: products }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  } catch (error) {
    console.warn('Error in formatViewItemListCategory:', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'dl_view_item_list',
        ecommerce: {}
      })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}
/** Builds an impressions array for a product listing, then pushes event viewItemListCategory */
const viewItemListCategory = (data: any) => {
  try {
    const impressions = Array.isArray(data?.impressions) ? data.impressions.slice(0, 9) : []
    const listValue = (typeof window !== 'undefined' && window?.location?.pathname) || ''

    const products = impressions.map((impression: any) => {
      const variants = impression?.variants
      if (!Array.isArray(variants) || variants.length === 0) {
        console.warn('formatViewItemListProduct: impression.variants is missing or not an array')
        return {
          id: 'unknown_sku',
          variant_id: 'unknown_variant_id',
          product_id: 'unknown_product_id',
          name: impression?.title || 'Unnamed Product',
          brand: 'recteq',
          list: listValue,
          price: '0.00',
          discount: '0.00'
        }
      }

      const firstVariant = variants[0]
      const variantId = extractNumericId(firstVariant?.id)
      const productId = extractNumericId(impression?.id)

      const { effectivePrice, discountAmount } = getEffectivePrice(
        firstVariant?.price,
        firstVariant?.compareAtPrice
      )

      return {
        id: firstVariant?.sku || 'unknown_sku',
        variant_id: variantId || 'unknown_variant_id',
        product_id: productId || 'unknown_product_id',
        name: impression?.title || 'Unnamed Product',
        brand: 'recteq',
        list: listValue,
        price: effectivePrice,
        discount: discountAmount
      }
    })

    const eventObject = {
      event: 'dl_view_item_list',
      actionField: { list: listValue },
      ecommerce: { impressions: products }
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventObject)
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  } catch (error) {
    console.warn('Error in formatViewItemListProduct:', error)
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'dl_view_item_list',
        ecommerce: {}
      })
    } else {
      console.warn('DataLayer is not initialized.')
    }
    return
  }
}

// Helper function to extract the product ID from _tags
const extractProductId = (tags: string[]) => {
  try {
    if (!Array.isArray(tags)) {
      console.warn('extractProductId: tags is not an array, its a ', typeof tags)
      return null
    }
    const idTag = tags.find((tag) => typeof tag === 'string' && tag.startsWith('id:'))
    return idTag ? idTag.split('id:')[1] : null
  } catch (error) {
    console.warn('Error in extractProductId:', error)
    return null
  }
}
// helper function to build price
const getEffectivePrice = (price: any, compareAtPrice: any) => {
  try {
    const priceAmount = Math.round(parseFloat(price?.amount || '0') * 100)
    const compareAtPriceAmount = Math.round(parseFloat(compareAtPrice?.amount || '0') * 100)

    let effectivePrice = priceAmount
    let discountAmount = 0
    if (compareAtPriceAmount) {
      effectivePrice = Math.min(priceAmount, compareAtPriceAmount)
      discountAmount = Math.abs(priceAmount - compareAtPriceAmount)
    }

    return {
      effectivePrice: (effectivePrice / 100).toFixed(2),
      discountAmount: (discountAmount / 100).toFixed(2),
      currency: price?.currencyCode || 'USD'
    }
  } catch (error) {
    console.warn('Error in getEffectivePrice:', error)
    return {
      effectivePrice: '0.00',
      discountAmount: '0.00',
      currency: 'USD'
    }
  }
}

// Helper function to generate a UUID
const generateUUID = () => {
  try {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  } catch (error) {
    console.warn('Error generating UUID:', error)
    // Return a fallback UUID if something goes wrong
    return '00000000-0000-0000-0000-000000000000'
  }
}

// helper Function to get or initialize userId
const getUserId = () => {
  const userIdKey = '_recteq_gtm_suite--userId'
  if (typeof localStorage === 'undefined') {
    console.warn('localStorage not available in getUserId')
    return null
  }

  try {
    let userId = localStorage.getItem(userIdKey)

    if (!userId) {
      userId = generateUUID()
      localStorage.setItem(userIdKey, userId)
    }

    return userId
  } catch (error) {
    console.warn('Error in getUserId:', error)
    return null
  }
}
// helper function to update local storage cart
const updateCartInLocalStorage = (newProducts: any[]) => {
  if (typeof localStorage === 'undefined') {
    console.warn('localStorage is not available.')
    return
  }

  const cartKey = '_recteq_gtm_suite--cart'
  let cartData = localStorage.getItem(cartKey)
  let cart: any[] = []

  // Retrieve existing cart from localStorage
  if (cartData) {
    try {
      cart = JSON.parse(cartData)
      if (!Array.isArray(cart)) {
        throw new Error('Cart data is not an array')
      }
    } catch (error) {
      console.warn('Invalid cart data in localStorage, resetting to empty array')
      cart = []
    }
  }

  // Merge new products with existing cart
  newProducts.forEach((newProduct) => {
    // Ensure quantities are numbers
    newProduct.quantity = parseInt(newProduct.quantity, 10) || 1

    // Find if the product already exists in the cart
    const existingProductIndex = cart.findIndex((cartItem) =>
      areProductsEqual(cartItem, newProduct)
    )

    if (existingProductIndex !== -1) {
      // Product exists, increment the quantity
      cart[existingProductIndex].quantity += newProduct.quantity
    } else {
      // Product does not exist, add to cart
      cart.push(newProduct)
    }
  })

  // Save updated cart back to localStorage
  localStorage.setItem(cartKey, JSON.stringify(cart))
  return cart
}
// helper function to remove product from local storage cart
const removeItemFromCartInLocalStorage = (productId: any) => {
  if (typeof localStorage === 'undefined') {
    console.warn('localStorage is not available in removeItemFromCartInLocalStorage.')
    return null
  }

  try {
    const cartKey = '_recteq_gtm_suite--cart'
    let cartData = localStorage.getItem(cartKey)
    let cart: any[] = []

    if (cartData) {
      try {
        const parsed = JSON.parse(cartData)
        if (Array.isArray(parsed)) {
          cart = parsed
        } else {
          throw new Error('Cart data is not an array')
        }
      } catch (error) {
        console.warn('Invalid cart data in localStorage, resetting to empty array')
        cart = []
      }
    }

    const index = cart.findIndex((cartItem) => cartItem?.product_id === productId)
    if (index === -1) {
      console.warn(`Product with id ${productId} not found in cart`)
      return null
    }

    const [removedItem] = cart.splice(index, 1)
    localStorage.setItem(cartKey, JSON.stringify(cart))
    return removedItem
  } catch (error) {
    console.warn('Error in removeItemFromCartInLocalStorage:', error)
    return null
  }
}

// Helper function to compare products
const areProductsEqual = (productA: any, productB: any): boolean => {
  try {
    return (
      productA?.id === productB?.id &&
      productA?.variant_id === productB?.variant_id &&
      productA?.product_id === productB?.product_id &&
      productA?.name === productB?.name &&
      productA?.brand === productB?.brand &&
      productA?.price === productB?.price &&
      productA?.discount === productB?.discount
    )
  } catch (error) {
    console.warn('Error in areProductsEqual:', error)
    return false
  }
}

// Function to get or initialize cart
const initCart = (cart_contents?: any[]) => {
  const cartKey = '_recteq_gtm_suite--cart'

  if (Array.isArray(cart_contents)) {
    // Set the provided cart_contents to local storage
    localStorage.setItem(cartKey, JSON.stringify(cart_contents))
    return cart_contents
  } else {
    // If cart_contents not provided, fetch from local storage or initialize
    let cartData = localStorage.getItem(cartKey)

    if (!cartData) {
      cartData = JSON.stringify([])
      localStorage.setItem(cartKey, cartData)
    }

    // Parse the cart data to JSON
    try {
      cartData = JSON.parse(cartData)
      if (!Array.isArray(cartData)) {
        throw new Error('Cart data is not an array')
      }
    } catch (error) {
      console.warn('Invalid cart data, resetting to empty array')
      localStorage.setItem(cartKey, JSON.stringify(''))
    }

    return cartData
  }
}
// helper function to format cart contents from cookies
const formatCartContents = (cookieCart: any) => {
  try {
    if (!cookieCart?.lines || !Array.isArray(cookieCart.lines)) {
      return []
    }

    const cart_contents: any[] = []
    for (let i = 0; i < cookieCart.lines.length; ++i) {
      const line = cookieCart.lines[i]
      const merchandise = line?.merchandise
      const productNode = merchandise?.product
      const variantNode = productNode?.variants?.edges?.[0]?.node

      if (!variantNode || !productNode) {
        console.warn('Missing product or variant information in formatCartContents line:', i)
        continue
      }

      const { effectivePrice, discountAmount } = getEffectivePrice(
        merchandise?.price,
        merchandise?.compareAtPrice
      )

      const product_id = extractNumericId(productNode.id)
      const variant_id = extractNumericId(variantNode.id)

      cart_contents.push({
        id: variantNode.sku,
        variant_id: variant_id,
        product_id: product_id,
        name: productNode.title,
        brand: 'recteq',
        quantity: line?.quantity || 1,
        price: effectivePrice,
        discount: discountAmount
      })
    }

    return cart_contents
  } catch (error) {
    console.warn('Error in formatCartContents:', error)
    return []
  }
}
// Helper function to extract the numeric ID from GID
const extractNumericId = (gid: string) => {
  try {
    if (typeof gid !== 'string') {
      console.warn('extractNumericId: gid is not a string, its a ', typeof gid)
      return null
    }
    const parts = gid?.split('/')
    return parts.pop() || null
  } catch (error) {
    console.warn('Error in extractNumericId:', error)
    return null
  }
}
// ! formats some data on product-upsells.tsx for addToCartPDP formatter
export const formatUpsellAddToCart = (upsellProduct: any, discount: string): any => {
  try {
    const amount =
      typeof upsellProduct?.price?.amount === 'number'
        ? upsellProduct.price.amount
        : parseFloat(upsellProduct?.price?.amount || '0')

    const discountValue = parseFloat((discount || '').replace('$', '')) || 0

    const compareAtPrice = {
      amount: parseFloat((amount - discountValue).toFixed(2)),
      currency: upsellProduct?.price?.currencyCode || 'USD'
    }

    return compareAtPrice
  } catch (error) {
    console.warn('Error in formatUpsellAddToCart:', error)
    return { amount: 0, currency: 'USD' }
  }
}

// Create a dictionary that references each pusher function
export const eventHandlers: Record<GtmEventKey, (data?: any) => void> = {
  addToCartFromPDP,
  addToCartFromProductCard,
  addToCartFromCategoryCard,
  quantityIncreased,
  checkoutStarted,
  compareProductAdded,
  compareProductRemoved,
  compareDrawerExpanded,
  compareDrawerCollapsed,
  carouselRight,
  carouselLeft,
  dropdownExpanded,
  dropdownCollapsed,
  callToActionClick,
  facetSelected,
  facetDragStarted,
  facetDragEnded,
  facetInput,
  facetsCleared,
  facetCleared,
  login,
  menuExpanded,
  subMenuExpanded,
  menuCollapsed,
  subMenuCollapsed,
  miniCartOpened,
  miniCartClosed,
  mobileMenuOpened,
  mobileMenuClosed,
  removeFromCart,
  quantityDecreased,
  searchOpened,
  searchInput,
  searchPerformed,
  searchClosed,
  signUp,
  userData,
  viewCart,
  viewItem,
  viewItemListCategory,
  viewItemListProduct,
  searchProductClicked,
  searchContentClicked
}
