const ColWith = {
  1: 'md:col-span-1',
  2: 'md:col-span-2',
  3: 'md:col-span-3',
  4: 'md:col-span-4',
  6: 'md:col-span-6'
}

interface ItemProps extends React.ComponentPropsWithRef<'div'> {
  children: React.ReactNode
  colWidth: keyof typeof ColWith
}

export const Item = (props: ItemProps) => {
  const { children, className, colWidth, ...rest } = props
  return (
    <div {...rest} className={`${className} ${ColWith[colWidth]}`}>
      {children}
    </div>
  )
}
