'use client'
import Image from 'next/image'
import React, { useState } from 'react'
import { PortableTextBlock } from '@portabletext/react'
import { CTA, RichText, CTALayout } from '@/components'

enum Background {
  none = 'bg-white',
  primary = 'bg-primary text-white',
  secondary = 'bg-secondary text-white',
  tertiary = 'bg-tertiary text-white',
  'gray' = 'bg-gray text-secondary',
  'gray-2' = 'bg-gray-secondary text-secondary'
}

interface Props {
  backgroundColor?: keyof typeof Background
  media: Sanity.Media
  rolloverImage?: Sanity.Media | null
  rollover?: boolean
  title: string
  subtitle?: string
  body: PortableTextBlock[]
  cta?: {
    link: Sanity.Link
  } & CTALayout
}

export const BioCard = (props: Props) => {
  const {
    backgroundColor = 'gray',
    rollover = false,
    rolloverImage = null,
    title,
    subtitle,
    body,
    cta,
    media
  } = props
  const [animateLeave, setAnimateLeave] = useState(false)
  const [activeImage, setActiveImage] = useState(media)
  const [showHoverContent, setShowHoverContent] = useState(false)
  return (
    <article
      data-test="biocard-container"
      className={`group relative h-full min-h-[600px] w-full overflow-hidden ${Background[backgroundColor]} ${((rollover && rolloverImage) || !rollover) && ''}`}
      onMouseLeave={() => {
        if (rollover) {
          setActiveImage(media)

          setTimeout(() => {
            setAnimateLeave(false)
          }, 300)

          setAnimateLeave(true)
          setShowHoverContent(false)
        }
      }}
      onMouseEnter={() => {
        if (!rollover) {
          setActiveImage(media)
        }

        if (rollover && rolloverImage) {
          setActiveImage(rolloverImage)
        } else if (rollover && !rolloverImage) {
          setShowHoverContent(true)
        }
      }}
    >
      <span className="relative block h-[350px] w-full">
        {media?.asset?.url && (
          <Image
            fill
            src={media?.asset?.url}
            alt={media?.asset?.assetId || ''}
            sizes="(max-width: 768px) 100vw, 50vw"
            className={`animate-flash absolute left-0 top-0 object-fill
            ${media.asset.assetId === activeImage.asset.assetId ? 'animate-' : 'animate-'}
          `}
          />
        )}
        {rolloverImage && (
          <Image
            fill
            src={rolloverImage?.asset?.url}
            alt={rolloverImage?.asset?.assetId || ''}
            sizes="(max-width: 768px) 100vw, 50vw"
            className={`animate-flash absolute left-0 top-0 object-fill
              ${rolloverImage.asset?.assetId === activeImage.asset?.assetId ? 'animate-appear' : 'opacity-0'}
              ${rolloverImage.asset?.assetId !== activeImage.asset?.assetId && animateLeave ? 'animate-disappear' : 'opacity-0'}
            `}
          />
        )}
      </span>
      <div className="h-[calc(100%-350px)]">
        <div
          className={`flex h-auto h-full min-h-[350px] w-full flex-col items-center justify-center gap-5 px-4 pb-4 pt-16 text-center
          ${Background[backgroundColor]}
          ${(backgroundColor === 'primary' || backgroundColor === 'secondary' || backgroundColor === 'tertiary') && 'ellipseOutline'}
        `}
        >
          <div className="z-0 flex h-full flex-col items-center justify-start gap-8">
            <div>
              {title && (
                <h4 data-test="biocard-title" className="h4">
                  {title}
                </h4>
              )}
              {subtitle && (
                <h5 data-test="biocard-subtitle" className="p-alt text-xl ">
                  {subtitle}
                </h5>
              )}
            </div>
            {body && ((rollover && rolloverImage) || !rollover) && (
              <div
                data-test="biocard-body"
                className={`p text-xl font-medium text-inherit ${Background[backgroundColor]}`}
              >
                <RichText content={body} />
              </div>
            )}
          </div>
          {cta && cta?.link && cta?.link?.text && (
            <div data-test="biocard-cta-container">
              <CTA
                as="button-link"
                link={cta.link}
                layout={{
                  color: cta.color,
                  size: cta.size
                }}
                component="bio-card"
              />
            </div>
          )}
        </div>
      </div>
      {/* Rollover Content */}
      <>
        <div
          className={`absolute transition-all duration-300 ease-in-out ${showHoverContent ? 'top-0' : 'top-full'} left-0 z-10 flex h-full w-full flex-col items-center justify-between gap-4 ${Background[backgroundColor]} py-8`}
        >
          <div className="container text-center">
            {title && (
              <h4 data-test="biocard-title" className="h4">
                {title}
              </h4>
            )}
            {subtitle && (
              <h5 data-test="biocard-subtitle" className="p-alt text-xl ">
                {subtitle}
              </h5>
            )}
          </div>
          <div data-test="biocard-body" className={`text-center text-xl font-medium`}>
            <RichText content={body} />
          </div>
          {cta && cta?.link && cta?.link?.text && (
            <div className="container flex justify-center" data-test="biocard-cta-container">
              <CTA
                as="button-link"
                link={cta.link}
                layout={{
                  color: cta.color,
                  size: cta.size
                }}
                component="bio-card"
              />
            </div>
          )}
        </div>
      </>
    </article>
  )
}

export default BioCard
