import Image from 'next/image'
import { Overlay, CTA, CTALayout } from '@/components'
interface HeroCardSubItemProps {
  main: string
  secondary?: string
  align: keyof typeof SubtitleAlignClass
}
enum SubtitleAlignClass {
  left = 'self-start',
  right = 'self-end',
  center = 'self-center'
}
enum AlignClass {
  left = 'items-start text-left',
  right = 'items-end text-right',
  center = 'items-center text-center'
}

enum PositionClass {
  top = 'top-0 transform',
  bottom = 'bottom-0 transform translate-x-0',
  center = 'top-1/2 transform -translate-y-1/2'
}

const HeroCardSubItem = ({ main, align }: HeroCardSubItemProps) => {
  return (
    <span className={`p-alt block uppercase leading-[0.2] text-white ${AlignClass[align]}`}>
      {main}
    </span>
  )
}
const FeaturedHeroCardSubItem = ({ main, secondary, align }: HeroCardSubItemProps) => {
  return (
    <p className={`p-alt text-2xl font-black leading-[0.2] text-white ${AlignClass[align]}`}>
      {main} <span className="text-xl font-normal text-gray">{secondary}</span>
    </p>
  )
}
interface HeroCardProps {
  contentAlign: 'left' | 'right' | 'center'
  contentPosition: 'top' | 'bottom' | 'center'
  media: Sanity.Media
  title: string
  subtitles?: HeroCardSubItemProps
  text?: string
  cta?: {
    link: Sanity.Link
  } & CTALayout
  asFeatured: boolean
}

export const HeroCard = ({
  asFeatured,
  contentAlign,
  contentPosition,
  media,
  title,
  subtitles,
  text,
  cta
}: HeroCardProps) => {
  const heightClass = asFeatured ? 'min-h-[550px]' : 'min-h-[90svw] md:min-h-[425px]'
  const boxShadow = asFeatured ? 'shadow-blur' : ''

  return (
    <article
      className={`relative h-full ${heightClass} group mb-4 w-full ${boxShadow} max-w-[98vw]`}
    >
      <section className={`invisible flex w-full flex-col p-8`}>
        {subtitles && asFeatured && (
          <FeaturedHeroCardSubItem
            main={subtitles.main}
            secondary={subtitles.secondary}
            align={contentAlign}
          />
        )}
        {subtitles && !asFeatured && <HeroCardSubItem main={subtitles.main} align={contentAlign} />}
        {title && (
          <h3
            data-test="herocard-title"
            className={`h2 my-2 text-white ${AlignClass[contentAlign]}`}
          >
            {title}
          </h3>
        )}
        {text && (
          <p
            data-test="herocard-text"
            className={`p mb-2 font-medium text-white ${AlignClass[contentAlign]}`}
          >
            {text}
          </p>
        )}
        {cta?.link?.text && (
          <div>
            <CTA
              as="button-link"
              link={cta.link}
              layout={{
                color: cta.color,
                size: cta.size
              }}
              component="hero-card"
            />
          </div>
        )}
      </section>
      {media?.asset?.url && (
        <Image
          src={media.asset.url}
          alt={media.asset?.altText || ''}
          sizes="(max-width: 768px) 100vw, 50vw"
          className="object-cover"
          fill
        />
      )}
      <Overlay gradient="none" color="dark" lighter={true} />
      <section
        className={`absolute z-[1] flex flex-col ${PositionClass[contentPosition]} ${AlignClass[contentAlign]} w-full p-4 lg:p-8`}
      >
        {subtitles && asFeatured && (
          <FeaturedHeroCardSubItem
            main={subtitles.main}
            secondary={subtitles.secondary}
            align={contentAlign}
          />
        )}
        {subtitles && !asFeatured && <HeroCardSubItem main={subtitles.main} align={contentAlign} />}
        {title && (
          <h3
            data-test="herocard-title"
            className={`h2 my-2 leading-9 text-white drop-shadow-md lg:leading-none ${AlignClass[contentAlign]}`}
          >
            {title}
          </h3>
        )}
        {text && (
          <p
            data-test="herocard-text"
            className={`p mb-2 font-medium text-white ${AlignClass[contentAlign]}`}
          >
            {text}
          </p>
        )}
        {cta?.link?.text && (
          <div>
            <CTA
              as="button-link"
              link={cta.link}
              layout={{
                color: cta.color,
                size: cta.size
              }}
              component="hero-card"
            />
          </div>
        )}
      </section>
    </article>
  )
}

export default HeroCard
