'use client'
import Link from 'next/link'
import Image from 'next/image'
import MobileMenu from './mobile-menu'
import DesktopMenu from './desktop-menu'
import Search from './search'
import { Topbar, Background } from '@/components'
import { useWindowPosition, useScrollDirection } from '@/hooks'
import { useEffect, useMemo } from 'react'
import { transformMenu } from '@/utils'
import { useHeaderContext } from '@/context/headerContext'
import { PortableTextBlock } from 'next-sanity'
import { HEADER_THRESHOLD } from '@/constants'
import Klaviyo from '../_scripts/klaviyo'

interface Props {
  isTransparent?: boolean
  transparentByDefault?: boolean
  miniCart: React.ReactNode
  hasAcitveSession: boolean
  menus: {
    logo: string
    menu: Sanity.Header
    secondaryMenu: Sanity.Link[]
    supportMenu: SupportMenuItem[]
  }
  globalMessages: { textBody: PortableTextBlock[] }[]
}

const HeaderContainer = ({ menus, miniCart, hasAcitveSession, globalMessages }: Props) => {
  const { isOpen, setIsOpen, isTransparent, setActiveItem, setActiveChild } = useHeaderContext()
  const { position } = useWindowPosition()
  const { scrollDir } = useScrollDirection()

  const menu = useMemo(() => transformMenu(menus.menu), [menus.menu])
  const secondaryMenu = useMemo(() => transformMenu(menus?.secondaryMenu), [menus?.secondaryMenu])
  const supportMenu = menus.supportMenu.map((item: any) => {
    return {
      ...item,
      icon: item?.text?.name
    }
  })

  useEffect(() => {
    setIsOpen(scrollDir === 'up' || position < HEADER_THRESHOLD)

    return () => {
      setActiveItem(null)
      setActiveChild([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position])

  return (
    <>
      <SkipToNextPage />
      <Klaviyo />
      <header
        className={`header ease top-0 z-[20] w-full transition-all print:hidden ${globalMessages?.length || isTransparent ? 'has-global-messages' : ''}
         ${isOpen || scrollDir === 'up' || position < HEADER_THRESHOLD ? '' : '-translate-y-full'}
         ${isTransparent ? 'isTransparent peer fixed' : 'sticky bg-secondary opacity-100'}  `}
      >
        <section className="relative text-white">
          <Topbar
            advertisements={globalMessages}
            subMenuItems={secondaryMenu}
            hasAcitveSession={hasAcitveSession}
          />
          <Background />

          <div
            className={`ease relative flex h-full min-h-[80px] w-full items-center transition-all lg:min-h-[100px] lg:py-6 ${globalMessages?.length && isTransparent ? (position < HEADER_THRESHOLD ? 'pt-1' : 'py-2 lg:pt-6') : 'lg:pt-6'}`}
          >
            <div className="container flex">
              <div className="flex w-full items-center gap-14">
                {menus?.logo && (
                  <Link href="/">
                    <Image
                      src={menus.logo}
                      width={200}
                      height={75}
                      alt="Recteq Logo"
                      priority
                      className="w-full max-w-32 lg:max-w-40"
                    />
                  </Link>
                )}
                <DesktopMenu menuItems={menu} />
              </div>

              <div className="mb-1 flex items-center gap-6 self-end">
                <div className="flex items-center gap-4">
                  <Search />
                  {miniCart}
                </div>
                <MobileMenu
                  logo={menus?.logo}
                  menuItems={menu}
                  subMenuItems={secondaryMenu}
                  supportMenuItems={supportMenu}
                  hasAcitveSession={hasAcitveSession}
                />
              </div>
            </div>
          </div>
        </section>
      </header>
    </>
  )
}
const SkipToNextPage = () => {
  return (
    <button
      tabIndex={1}
      onClick={(e) => {
        e.preventDefault()
        const main = document.getElementById('main')
        main?.focus()
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          const main = document.getElementById('main')
          main?.focus()
        }
      }}
      className="width-[1px] height-[1px] focus:width-auto focus:height-auto absolute -left-[1000px] top-auto z-[10] overflow-hidden bg-white p-2 text-secondary focus:absolute focus:left-0"
    >
      Skip to main content
    </button>
  )
}

export default HeaderContainer
export * from './background'
export * from './desktop-menu'
export * from './mini-cart'
export * from './mobile-menu'
export * from './search'
