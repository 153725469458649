'use client'
import { parseLocaleDateTime } from '@/utils'

export const PublishDate = (data: { publishDate: string }) => {
  const date = data.publishDate
    ? new Date(data.publishDate.replace(/-/g, '/').replace(/T.+/, ''))
    : new Date()
  return (
    <>
      <span aria-hidden="true" className="material-symbols-outlined">
        calendar_clock
      </span>
      <p className="p">{parseLocaleDateTime(date)} </p>
    </>
  )
}
