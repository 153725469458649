'use client'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'
import { useAlgoliaContent, useAlgoliaFacets, useAlgoliaProducts } from '../hooks'

export interface ContextProduct extends AlgoliaResponse<ExtendedProduct> {}
export interface ContextContent extends AlgoliaResponse<AlgoliaContent> {}

interface AlgoliaContextType {
  searchParams: Record<string, string | string[]>

  products: ContextProduct
  metafields: any[]
  getNextProductPage: () => void

  setSort?: Dispatch<SetStateAction<string>>

  contentMetafields: any[]
  content: ContextContent
  getNextContentPage: () => void

  handleClearFacets: () => void
  handleClearFacet: (key: string) => void
  handleFacetChange: (input: React.ChangeEvent<HTMLInputElement>) => void

  checkedFacets: Map<string, Set<string>>
}

type AlgoliaContextProps = {
  search?: string
  sorting?: string
  searchParams: Record<string, string | string[]>
  type?: string
  children: ReactNode
  openItem?: 'products' | 'content' | 'both'
}

const AlgoliaContext = createContext<AlgoliaContextType | null>(null)

export const AlgoliaContextProvider = ({
  children,
  search,
  searchParams,
  openItem = 'products',
  type
}: AlgoliaContextProps) => {
  const [sort, setSort] = useState<string>('')

  const { handleClearFacets, handleFacetChange, checkedFacets, handleClearFacet } =
    useAlgoliaFacets({ search: search || '' })

  const {
    content,
    getNextContentPage,
    metafields: contentMetafields
  } = useAlgoliaContent({
    search: search || '',
    sorting: sort,
    type,
    preventFetching: openItem === 'products',
    searchParams
  })
  const { products, getNextProductPage, metafields } = useAlgoliaProducts({
    search: search || '',
    sorting: sort,
    preventFetching: openItem === 'content',
    searchParams
  })

  return (
    <AlgoliaContext.Provider
      value={{
        contentMetafields,
        metafields,
        setSort,
        searchParams,
        handleClearFacets,
        handleClearFacet,
        handleFacetChange,
        checkedFacets,
        products,
        getNextProductPage,
        content,
        getNextContentPage
      }}
    >
      {children}
    </AlgoliaContext.Provider>
  )
}

export const useAlgoliaContext = () => {
  const context = useContext(AlgoliaContext)

  if (!context) {
    throw new Error('useAlgoliaContext must be used within a AlgoliaContextProvider')
  }
  return context
}
