'use client'
import { useEffect, useState } from 'react'
import { TABLET_BREAKPOINT, DESKTOP_BREAKPOINT } from '../constants'

interface WindowSize {
  width: number
  height: number
}

export const useIsTablet = (): boolean => {
  const [isTablet, setIsTablet] = useState<boolean>(false)

  const getWindowSize = (): WindowSize => ({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = (): void => {
      const { width } = getWindowSize()
      setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isTablet
}
