import { accessibleErrorToast } from '@/utils'

// Reshaping utilities
export const reshapeCart = (
  cart: ShopifyCart = { cost: { totalTaxAmount: { amount: 0 } } } as ShopifyCart
): Cart => {
  if (!cart?.cost?.totalTaxAmount) {
    cart.cost.totalTaxAmount = {
      amount: '0.0',
      currencyCode: 'USD'
    }
  }

  return {
    ...cart,
    lines: removeEdgesAndNodes(cart.lines)
  }
}
export const removeEdgesAndNodes = (array: Connection<any>) => {
  return array.edges.map((edge) => edge?.node)
}
export const reshapeImages = (images: Connection<Image>, productTitle: string) => {
  if (!images) {
    return
  }
  const flattened = removeEdgesAndNodes(images)

  return flattened.map((image) => {
    const filename = image.url.match(/.*\/(.*)\..*/)[1]
    return {
      ...image,
      altText: image.altText || `${productTitle} - ${filename}`
    }
  })
}
export const reshapeProduct = (product: ShopifyProduct, filterHiddenProducts: boolean = true) => {
  if (!product || (filterHiddenProducts && product.tags.includes('nextjs-frontend-hidden'))) {
    return null
  }

  const { images, variants, ...rest } = product

  return {
    ...rest,
    images: reshapeImages(images, product.title),
    variants: removeEdgesAndNodes(variants)
  } as Product
}
export const reshapeProducts = (products: ShopifyProduct[]) => {
  const reshapedProducts = []

  for (const product of products) {
    if (product) {
      const reshapedProduct = reshapeProduct(product)

      if (reshapedProduct) {
        reshapedProducts.push(reshapedProduct)
      }
    }
  }

  return reshapedProducts
}
export const handleShopifyErrors = (customerUserErrors?: CustomerUserErrors[]) => {
  if (customerUserErrors) {
    customerUserErrors.forEach(({ message }) => {
      accessibleErrorToast(message)
    })
  }
}
