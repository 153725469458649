import Image from 'next/image'
import { Button, ModuleHandler, RichText } from '@/components'
import { PortableTextBlock } from 'next-sanity'

interface ItemProps {
  mediaStyle: 'default' | 'none'
  media: Sanity.Image
  title: string
  body: PortableTextBlock[]
  label?: string
  mediaPosition: string
}

interface Props {
  alignment: 'space-between' | 'space-evenly' | 'centered'
  items: ItemProps[]
  componentGlobalConfig?: Sanity.ComponentConfig
}

const TwoColumnBio = ({ items, componentGlobalConfig }: Props) => {
  return (
    <>
      <ModuleHandler config={componentGlobalConfig}>
        <section
          className="module-spacing container grid grid-cols-1 gap-10 lg:grid-cols-2"
          data-component="two-column-bio"
        >
          {items?.map((item, index) => <TwoColumnBioItem key={index} {...item} />)}
        </section>
      </ModuleHandler>
    </>
  )
}

const TwoColumnBioItem = ({ mediaStyle, media, title, body, label, mediaPosition }: ItemProps) => {
  const featuredImage =
    mediaStyle === 'default' ? `border-[10px] border-white shadow-2xl` : mediaStyle

  return (
    <article
      className={`group relative m-0 mb-4 flex w-full lg:mb-2 ${
        mediaPosition === 'left' ? 'flex-row-reverse' : 'flex-row'
      } flex-wrap-reverse xl:flex-nowrap`}
      data-test="2 Colum Bio Component Item"
    >
      <div className="relative mt-4 flex w-full flex-col items-start p-0 md:items-start lg:mt-0 lg:w-3/5 lg:px-4">
        {title && (
          <h4 data-test="2-col-bio-title" className="h4 mb-2 text-secondary">
            {title}
          </h4>
        )}
        {body && <RichText content={body} />}
      </div>
      <div className="flex w-full min-w-[150px] flex-col justify-start p-0 lg:w-2/5">
        {media?.asset?.url && (
          <Image
            src={media.asset.url}
            alt={media?.alt || ''}
            className={`${featuredImage} h-full max-h-[400px] w-full object-cover`}
            height={400}
            width={300}
            data-media={media.asset.url}
          />
        )}
        {label && (
          <Button
            color={'secondary'}
            size="minimal"
            className="pointer-events-none"
            renderButton={({ size, color }) => {
              return (
                <div
                  className={`relative inline-block ${mediaStyle === 'default' ? '-top-[25px]' : '-top-[20px]'} flex justify-center`}
                >
                  <span aria-label={label} className={`${size} ${color} `}>
                    {label}
                  </span>
                </div>
              )
            }}
          />
        )}
      </div>
    </article>
  )
}

export default TwoColumnBio
