'use client'
import { useAlgoliaContext } from '@/app/(store)/context'
import { ArticleCard, Button, PageTitle, SortInput } from '@/components'
import { useInifiniteScroll } from '@/hooks'
import { algoliaToSanityArticleAdapter } from '@/utils'

interface ArticleProps {
  title: string
}
const ArticlesResults = (props: ArticleProps) => {
  const { content, getNextContentPage } = useAlgoliaContext()
  const { title } = props
  const { observerTarget } = useInifiniteScroll(() => getNextContentPage())
  let contentType

  switch (title) {
    case 'explore':
      contentType = 'page'
      break
    case 'articles':
      contentType = 'article'
      break
    default:
      contentType = title
  }

  return (
    <>
      <PageTitle title={title}>
        <SortInput
          options={[
            {
              label: 'DEFAULT',
              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}`
            },
            {
              label: 'DATE: OLDEST TO RECENT',
              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_publishdate_asc`
            },
            {
              label: 'TITLE: A-Z',
              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_az`
            },
            {
              label: 'TITLE: Z-A',
              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_za`
            }
          ]}
        />
      </PageTitle>
      <div
        className="grid grid-cols-1 gap-14 md:grid-cols-4"
        data-insights-index={`${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}`}
      >
        {content?.hits?.length > 0 &&
          content?.hits?.map((article, idx) => (
            <div key={article._id} className="col-span-1">
              <ArticleCard
                position={idx}
                queryId={content.queryID}
                article={algoliaToSanityArticleAdapter(article as AlgoliaArticle)}
                contentType={contentType}
              />
              {idx === content?.hits?.length - 1 && <div ref={observerTarget} />}
            </div>
          ))}
        {content.loading && (
          <div className="col-span-1 flex min-h-[70vh] items-center justify-center md:col-span-4">
            <span className="material-symbols-outlined block animate-spin">progress_activity</span>
          </div>
        )}
      </div>
      {!content.loading && content.nbHits > 0 && (
        <div>
          <div className="col-span-4 flex flex-col items-center justify-center gap-5 py-10 uppercase">
            <p className="p">
              Showing results <b>{content?.hits?.length}</b> of <b>{content?.nbHits}</b>
            </p>
            <div>
              <Button
                size="normal"
                disabled={content?.loading || content?.hits?.length >= content?.nbHits}
                onClick={() => {
                  getNextContentPage()
                }}
              >
                Load more
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ArticlesResults
