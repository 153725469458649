'use client'
import React, { useState, useEffect, useMemo } from 'react'
import { CTA, CTALayout, ModuleHandler } from '@/components'
interface CountdownTime {
  days: number
  hours: number
  minutes: number
}

interface CountdownTimerProps {
  backgroundColor: 'primary' | 'secondary'
  countDownTheme: 'primary' | 'secondary'
  date: Date
  title: string
  subtitle?: string
  cta?: {
    link: Sanity.Link
  } & CTALayout
  componentGlobalConfig?: Sanity.ComponentConfig
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  backgroundColor,
  countDownTheme,
  date,
  title,
  subtitle,
  cta,
  componentGlobalConfig
}) => {
  const targetDate = useMemo(() => new Date(date), [date])

  const [timeLeft, setTimeLeft] = useState<CountdownTime>(calculateTimeLeft(targetDate))

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate))
    }, 60000) // Update every minute

    return () => clearInterval(timer)
  }, [targetDate])

  function calculateTimeLeft(targetDate: Date): CountdownTime {
    const difference = targetDate.getTime() - new Date().getTime()

    let timeLeft: CountdownTime = {
      days: 0,
      hours: 0,
      minutes: 0
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60)
      }
    }

    return timeLeft
  }

  const bannerBgClass = backgroundColor === 'primary' ? 'bg-primary' : 'bg-secondary'
  const countdownBgClass = countDownTheme === 'primary' ? 'bg-primary' : 'bg-secondary'

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <div data-component="countdown-timer" className={`p-2 text-white ${bannerBgClass}`}>
        <div className="container flex flex-col items-center md:flex-row">
          {/* Left */}
          <div className="mb-4 w-full text-center md:mb-0 md:w-1/3 lg:text-left xl:w-1/2">
            <h2 className="text-2xl font-bold lg:text-4xl">{title}</h2>
            {subtitle && <p className="mt-2">{subtitle}</p>}
          </div>

          {/* Center */}
          <div className="mx-auto flex w-full max-w-md justify-center gap-2 md:w-1/3 xl:w-1/4">
            {/* Days */}
            <div
              className={`flex h-16 w-16 min-w-[64px] flex-shrink flex-col items-center justify-center p-2 text-center lg:h-24 lg:w-24 lg:min-w-[96px] lg:p-4 ${countdownBgClass}`}
            >
              <p className="text-2xl font-bold lg:text-5xl">{timeLeft.days}</p>
              <p className="text-sm">Days</p>
            </div>
            {/* Hours */}
            <div
              className={`flex h-16 w-16 min-w-[64px] flex-shrink flex-col items-center justify-center p-2 text-center lg:h-24 lg:w-24 lg:min-w-[96px] lg:p-4 ${countdownBgClass}`}
            >
              <p className="text-2xl font-bold lg:text-5xl">{timeLeft.hours}</p>
              <p className="text-sm">Hours</p>
            </div>
            {/* Minutes */}
            <div
              className={`flex h-16 w-16 min-w-[64px] flex-shrink flex-col items-center justify-center p-2 text-center lg:h-24 lg:w-24 lg:min-w-[96px] lg:p-4 ${countdownBgClass}`}
            >
              <p className="text-2xl font-bold lg:text-5xl">{timeLeft.minutes}</p>
              <p className="text-sm">Minutes</p>
            </div>
          </div>

          {/* Right */}
          {cta?.link?.text && (
            <div className="mt-4 w-full text-center md:mt-0 md:w-1/3 xl:w-1/4">
              <CTA
                as="button-link"
                link={cta.link}
                layout={{
                  size: cta.size,
                  color: cta.color
                }}
                component="countdown-timer"
              />
            </div>
          )}
        </div>
      </div>
    </ModuleHandler>
  )
}

export default CountdownTimer
