'use client'
import Image from 'next/image'
import { CTA, CTALayout, Overlay, Select } from '@/components'
import { useRouter } from 'next/navigation'

enum SubTitleColor {
  white = 'text-white',
  primary = 'text-primary',
  secondary = 'text-secondary'
}

interface Props {
  title: string
  subtitle?: {
    text: string
    style: keyof typeof SubTitleColor
  }
  media: {
    desktop: Sanity.Image
    mobile: Sanity.Image
  }
  body?: string
  locations?: { label: string; value: string }[]
  cta?: {
    link: Sanity.Link
  } & CTALayout
}

const FeaturedHero = ({ title, subtitle, media, body, locations, cta }: Props) => {
  const router = useRouter()

  return (
    <div className="relative mt-[-135px] flex min-h-[75svh] items-center bg-black peer-[.isTransparent]:group-[.main]:mt-0 md:h-[75svh] lg:min-h-0 xl:max-h-[800px]">
      <div className="relative mx-auto h-full w-full max-w-[2200px] px-0">
        <div
          id="top-gradient"
          className="absolute -top-[20px] left-0 z-10 block h-[60px] w-full bg-gradient-to-b from-black to-transparent md:h-[100px] lg:mt-0 lg:hidden lg:h-[150px]"
        />
        <div
          id="left-gradient"
          className="absolute -left-[10px] bottom-0 z-10 hidden h-full bg-gradient-to-l from-transparent to-black lg:w-[120px] 3xl:block"
        />
        <div className="relative md:absolute md:left-0 md:top-0 md:h-full md:w-full">
          <Overlay gradient="none" transparent color="dark" lighter />
          <div className="aspect-h-3 aspect-w-3 relative z-[0] md:aspect-none md:absolute md:h-full md:w-full">
            <picture>
              <source srcSet={media?.mobile?.asset.url} media="(max-width:925px)" />
              <Image
                priority
                sizes="(min-width: 925px) 100vw, 100vw"
                src={media?.desktop?.asset.url}
                alt=""
                fill
                draggable={false}
                className="object-cover object-center"
              />
            </picture>
          </div>
          <div
            id="mobile-image-gradient"
            className="absolute bottom-0 left-0 z-10 h-[100px] w-full bg-gradient-to-t from-black to-transparent lg:hidden"
          />
        </div>

        <div className="container relative z-[5] flex h-full w-full flex-col justify-between px-4 pb-10 md:flex-row md:items-end lg:items-center lg:pb-0 lg:pt-[100px]">
          <div className="flex flex-col gap-6 sm:pb-10 lg:max-w-[70%] lg:gap-12 lg:pb-0">
            <div className="text-white">
              {subtitle && (
                <span
                  className={`font-oswald text-2xl font-normal uppercase leading-none lg:text-3xl ${SubTitleColor[subtitle.style]}`}
                >
                  {subtitle.text}
                </span>
              )}
              <h1 className="jumbo mb-6 leading-none lg:mb-4">{title}</h1>
              <p className="p font-normal text-white lg:text-xl">{body}</p>
            </div>

            {cta?.link.text && (
              <div data-test="featuredHero-cta-container">
                <CTA
                  as="button-link"
                  link={cta.link}
                  layout={{
                    size: cta.size,
                    color: cta.color
                  }}
                  component="featured-hero"
                />
              </div>
            )}
          </div>
          {/* Academy Location Dropdown */}
          {locations && locations.length > 1 && (
            <div className="right-4 z-[12] w-full self-start pt-4 font-lato capitalize md:absolute md:top-48 md:w-auto lg:top-24">
              <Select
                placeholder="Select a location"
                onChange={({ value }) => router.push(`/${value}`)}
                options={locations}
                aria-label="Select an Academy location"
              />
            </div>
          )}
        </div>
        <div
          id="bottom-gradient"
          className="absolute bottom-0 z-10 hidden h-[170px] w-full bg-gradient-to-t from-black to-transparent lg:h-[150px] 3xl:block"
        />
        <div
          id="right-gradient"
          className="absolute -right-[0] bottom-0 z-10 hidden h-full bg-gradient-to-r from-transparent to-black lg:w-[150px] 3xl:block"
        />
      </div>
    </div>
  )
}

export default FeaturedHero
