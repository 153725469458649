'use client'

import { parseMoneyFormat } from '@/utils'
import { Carousel, ProductPrice, ProductTitle, Select } from '@/components'
import { useEtchATeqContext } from '@/context'
import PersonalizationBadges from './personalization-badges'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { DEFAULT_OPTION } from '@/app/(store)/constants'
import { useEffect } from 'react'

const SelectionVariant = () => {
  const router = useRouter()

  const { selectedFee, selectedProduct, setSelectedVariant, selectedVariant } = useEtchATeqContext()

  useEffect(() => {
    if (selectedProduct) {
      const element = document.getElementById('start-customize')
      element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [selectedProduct])

  return (
    <section id="start-customize" className="module-spacing--bottom bg-gray py-12 text-secondary">
      <div className="container flex flex-col justify-between gap-10 lg:flex-row">
        {selectedProduct && selectedProduct?.variants?.length > 1 && (
          <div>
            <h2 className="h2 mb-8">let&apos;s customize your:</h2>
            <div className="p flex flex-col gap-2">
              <div>
                <ProductTitle product={selectedProduct} size="h5 mb-2" />
                {selectedProduct?.variants.length > 1 && !selectedVariant && (
                  <ProductPrice
                    product={selectedProduct}
                    currentVariant={selectedProduct.variants[0]}
                    showRange={true}
                    size="p lg:p-large lg:text-center"
                  />
                )}
                {selectedProduct?.variants.length <= 1 ||
                  (selectedVariant && (
                    <ProductPrice
                      currentVariant={selectedVariant || selectedProduct?.variants[0]}
                      size="p-large"
                    />
                  ))}

                {selectedFee && (
                  <p>
                    + tax and {parseMoneyFormat(selectedFee?.variants[0].price.amount as string)}{' '}
                    customization fee.
                  </p>
                )}
              </div>
              <div>
                <Select
                  placeholder={
                    selectedVariant?.title !== DEFAULT_OPTION
                      ? selectedVariant?.title
                      : 'Select an option'
                  }
                  onChange={(option) => {
                    const variant = JSON.parse(option.value)
                    setSelectedVariant(variant)
                    router.push(`/etch-a-teq?variant=${btoa(variant.id)}`, { scroll: false })
                  }}
                  options={selectedProduct.variants.map((product) => ({
                    label: product.title,
                    value: JSON.stringify(product)
                  }))}
                />
              </div>
            </div>
          </div>
        )}
        {selectedProduct && selectedProduct.variants.length === 1 && (
          <div>
            <h2 className="h2 mb-4 lg:mb-10">let&apos;s customize your:</h2>
            <div className="p flex flex-col gap-2">
              <PersonalizationBadges
                personalizationOptions={selectedProduct.personalization.personalizationOptions}
              />
              <div className="flex flex-col gap-1">
                <ProductTitle product={selectedProduct} size="h5" />
                <ProductPrice currentVariant={selectedProduct?.variants[0]} size="p-large" />
                {selectedFee && (
                  <p>
                    + tax and {parseMoneyFormat(selectedFee?.variants[0].price.amount as string)}{' '}
                    customization fee.
                  </p>
                )}
                <h3 className="h5 mt-4">customized area:</h3>
                <p>{selectedProduct?.personalization?.personalizationAreaLabel}</p>
              </div>
            </div>
          </div>
        )}
        <div className="-mt-4 lg:-mt-8 lg:w-1/3">
          <Carousel
            loop={false}
            delay={false}
            slidesPerView={1}
            mobileSlidesPerView={1}
            showNavigation={false}
            showPagination={true}
            controlsContained={false}
            items={
              selectedProduct?.personalization?.personalizationArea?.personalizationImages?.map(
                (item, idx) => (
                  <div
                    key={`personalizationArea-${idx}`}
                    className="relative h-[95svw] w-full lg:h-[450px] lg:w-[450px]"
                  >
                    <Image src={item?.image?.url} alt="" fill className=" object-contain" />
                  </div>
                )
              ) || []
            }
          />
        </div>
        {!selectedProduct && <h4 className="h4">Select a product from the above list</h4>}
      </div>
    </section>
  )
}

export default SelectionVariant
