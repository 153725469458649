import { InputHTMLAttributes } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.RefObject<HTMLInputElement>
  icon?: React.ReactNode
  errorIcon?: React.ReactNode
  dataTest?: string
}

enum TypeStyle {
  email = 'input',
  text = 'input',
  range = 'input',
  reset = 'input',
  number = 'input',
  password = 'input',
  tel = 'input',
  search = 'input',
  url = 'input',

  checkbox = 'checkbox',
  radio = 'radio',
  button = '',
  color = '',
  date = '',
  datetime = '',
  file = '',
  image = '',
  month = '',
  submit = '',
  time = '',
  week = '',
  hidden = ''
}

export const Input = ({
  icon,
  errorIcon,
  inputRef,
  className = '',
  dataTest = 'input',
  ...rest
}: Props) => {
  const { type } = rest

  const input = (
    <input
      {...rest}
      ref={inputRef}
      data-test={dataTest}
      className={`p peer w-full disabled:bg-gray disabled:text-gray-tertiary disabled:opacity-80 ${TypeStyle[type as keyof typeof TypeStyle]} ${className}`}
    />
  )

  if (type === 'checkbox') return input
  if (type === 'radio') return input

  return (
    <>
      <div className="relative w-full">
        {input}
        {icon && (
          <span
            data-test="input-icon"
            className="absolute right-2 top-0 flex h-full items-center text-gray-form peer-invalid:hidden"
          >
            {icon}
          </span>
        )}
        {errorIcon && (
          <span
            data-test="input-error-icon"
            className="absolute right-2 top-0 hidden h-full items-center text-gray-form peer-invalid:flex peer-invalid:text-primary"
          >
            {errorIcon}
          </span>
        )}
      </div>
    </>
  )
}
