import { variantFragment } from '../fragments'

export const getVariantQuery = /* GraphQL */ `
  query getVariant($id: ID!) {
    node(id: $id) {
      ... on ProductVariant {
        ...variant
      }
    }
  }
  ${variantFragment}
`
