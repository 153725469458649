'use client'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

export function ScrollToTop() {
  const pathname = usePathname()

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes.
    window?.scrollTo(0, 0)
  }, [pathname])

  return null
}
