import React from 'react'

interface Props {
  bottomline: any
  totalQuestions?: number
}

/**
 * Renders a two-column layout:
 * - Left: star distribution (rows for 5..1, each with a percentage bar).
 * - Right: average score, StarRating, and total reviews/questions.
 */
export const ProductScore = ({ bottomline, totalQuestions = 0 }: Props) => {
  const hasData = bottomline && bottomline.star_distribution
  const total_review = hasData ? (bottomline.total_review ?? 0) : 0
  const average_score = hasData ? (bottomline.average_score ?? 0) : 0
  const star_distribution = hasData ? bottomline.star_distribution : {}
  // const { total_review, average_score, star_distribution } = bottomline
  // Safely handle if total_review == 0
  const totalReviews = total_review || 0
  // 1. Build an array [5,4,3,2,1] so we can map through star distribution
  const starRows = [5, 4, 3, 2, 1]

  // 2. Truncate average score to 1 decimal place
  const truncatedScore = Math.round(average_score * 10) / 10

  return (
    <div className="mx-auto grid grid-cols-2 gap-2 bg-neutral-50 p-4 md:gap-6 lg:w-2/3">
      {/* Left Column: star distribution rows */}
      <div className="flex w-full justify-center space-y-2 pr-4 lg:px-4">
        <div className="flex w-full flex-col justify-center">
          {starRows.map((star) => {
            const count =
              star_distribution && Object.keys(star_distribution)?.length > 0
                ? star_distribution[star]
                : 0
            const percentage = totalReviews ? Math.round((count / totalReviews) * 100) : 0
            return (
              <div key={star} className="mb-2 flex items-center gap-3">
                <span className="h6 w-6">{star}</span>
                {/* bar background */}
                <div className="h-4 flex-1 bg-gray-200">
                  {/* gold-filled bar */}
                  <div className="h-full bg-warning" style={{ width: `${percentage}%` }}></div>
                </div>
                {/* <span className="w-8 text-right text-sm">{percentage}%</span> */}
              </div>
            )
          })}
        </div>
      </div>

      {/* Right Column: average score, star rating, total counts */}
      <div className="my-6 flex flex-col items-center space-y-2">
        {/* Average Score + StarRating */}
        <h2 className="h1">{truncatedScore.toFixed(1)}</h2>
        <ProductScoreStars bottomline={{ bottomline: bottomline }} />

        <p className="h6">
          {totalReviews} Reviews, {totalQuestions !== -1 && `, ${totalQuestions} Q&A`}
        </p>
      </div>
    </div>
  )
}

const ProductScoreStars = ({ bottomline }: any) => {
  const averageScore = bottomline?.bottomline?.average_score || 0
  const ratingOutOf10 = Math.round(averageScore * 2)
  const fullStars = Math.floor(ratingOutOf10 / 2)
  const halfStars = ratingOutOf10 % 2
  const emptyStars = 5 - fullStars - halfStars

  const stars: JSX.Element[] = []

  // 1. Full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <span
        key={`full-${i}`}
        aria-hidden
        className="material-symbols-outlined filled icon size-6 text-warning"
      >
        star
      </span>
    )
  }

  // 2. Half star (if any)
  for (let i = 0; i < halfStars; i++) {
    stars.push(
      <span
        key="half"
        aria-hidden
        className="material-symbols-outlined filled icon size-6 text-warning"
      >
        star_half
      </span>
    )
  }

  // 3. Empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <span
        key={`empty-${i}`}
        aria-hidden
        className="material-symbols-outlined icon size-6 text-warning"
      >
        star
      </span>
    )
  }

  return (
    <div className="p flex items-center gap-1 text-sm leading-tight text-blue-500">
      <div className="flex flex-nowrap">{stars}</div>
    </div>
  )
}
