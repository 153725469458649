'use client'
import { getComplementaryProducts, getVariantPriceInCents } from '@/app/(store)/utils'
import React, { createContext, useContext } from 'react'
import { CardStyles, TagPosition } from '.'
import { DEFAULT_OPTION, FALLBACK_IMAGE } from '@/app/(store)/constants'

type ReturnProductCardContextType = {
  product: Product
  currentVariant?: ProductVariant | undefined
  isVariantLevel: boolean
  complementaryProducts?: string[]
  isNew: boolean
  lowStock: boolean
  variantTitle: string | undefined
  enabledWifi: boolean
  isDiscounted: boolean
  imageUrl: string
  imageAltText: string
  amountInCents: string
} & GeneralProps &
  LayoutProps

const ProductCardContext = createContext<ReturnProductCardContextType | null>(null)

type LayoutProps = {
  layout: {
    as?: 'productCard' | 'categoryCard'
    cardStyle: keyof typeof CardStyles
    tagPosition: keyof typeof TagPosition
  } & ButtonProps
}

type ButtonProps = {
  buttonType: 'addToCart' | 'subscribe' | 'cta'
  buttonText: string
  buttonStyle: 'primary' | 'secondary' | 'primary-outline' | 'secondary-outline'
}

type GeneralProps = {
  general: {
    position?: number
    className?: string

    reviews?: YotpoReviewData

    planId?: string
    queryId?: string
    objectId?: string
  }
}

export type ProductProps = {
  children: React.ReactNode
  product: Product
  currentVariant?: ProductVariant | undefined
  isVariantLevel: boolean
}

type Props = GeneralProps & LayoutProps & ProductProps

export const ProductCardContextProvider = ({
  children,
  product,
  currentVariant,
  isVariantLevel,
  general,
  layout
}: Props) => {
  const complementaryProducts = getComplementaryProducts(product)
  const variant = currentVariant || product.variants[0]
  const amountInCents = getVariantPriceInCents(variant)

  const isDiscounted =
    parseFloat(variant?.compareAtPrice?.amount as string) <
    parseFloat(variant?.price?.amount as string)

  const isNew = product?.tags?.includes('pfs:label-new')
  const lowStock = product?.tags?.includes('low-stock')

  const variantTitle = variant?.title !== DEFAULT_OPTION && isVariantLevel ? variant?.title : ''
  const enabledWifi = product?.wifi_enabled?.value === 'true'

  const imageUrl = variant?.image?.url
    ? variant?.image?.url
    : product?.featuredImage?.url || FALLBACK_IMAGE

  const imageAltText = product?.title
  return (
    <ProductCardContext.Provider
      value={{
        product,
        currentVariant: variant,
        isVariantLevel,
        complementaryProducts,
        general,
        layout,
        isNew,
        lowStock,
        variantTitle: variantTitle,
        enabledWifi,
        imageUrl,
        imageAltText,
        isDiscounted,
        amountInCents
      }}
    >
      {children}
    </ProductCardContext.Provider>
  )
}

export const useProductCardContext = () => {
  const context = useContext(ProductCardContext)

  if (!context) {
    throw new Error('useProductCardContext must be used within a ProductCardContextProvider')
  }
  return context
}
