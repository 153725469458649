'use client'
import { useEffect, useRef } from 'react'

export const useInifiniteScroll = (fetcher: () => void, rootMarging = 300) => {
  const observerTarget = useRef(null)

  useEffect(() => {
    const current = observerTarget.current

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetcher()
        }
      },
      { rootMargin: `${rootMarging}px`, threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerTarget, fetcher, rootMarging])

  return {
    observerTarget
  }
}
