'use client'
import { useState } from 'react'
import { Video } from '../video'
import Accordion from '../accordion'
import ModuleHandler from '../module-handler'

interface Props {
  listing: {
    title: string
    description: string
    videos: Sanity.Video[]
  }[]
  componentGlobalConfig?: Sanity.ComponentConfig
}

const ExclusiveVideoContent = ({ listing, componentGlobalConfig }: Props) => {
  const [currentVideo, setCurrentVideo] = useState<any>(
    listing?.length > 0 && listing[0]?.videos?.length > 0 ? listing[0]?.videos[0] : ''
  )
  if (!listing) return <></>

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section className="module-spacing">
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-10">
          <div className="col-span-1 lg:col-span-3 lg:h-full lg:max-h-[536px] lg:overflow-scroll">
            <Accordion
              items={listing?.map((item) => {
                return {
                  ...item,
                  content: (
                    <div className="dark:text-white">
                      <p>{item.description}</p>
                      <div className="mt-4 flex flex-col divide-y divide-gray-tertiary border-y border-gray-tertiary">
                        {item?.videos?.map((video) => {
                          const isSelected = currentVideo._key === video._key
                          return (
                            <button
                              key={video._key}
                              disabled={isSelected ? true : false}
                              onClick={() => setCurrentVideo(video)}
                              className={`h6 group flex w-full cursor-pointer items-center justify-between gap-2 py-4 ${isSelected ? 'text-primary' : ''}`}
                            >
                              <p>{video?.title}</p>{' '}
                              <span className="material-symbols-outlined text-secondary opacity-50 group-disabled:opacity-100 dark:text-white">
                                play_circle
                              </span>
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              })}
            />
          </div>
          <div className="col-span-1 lg:col-span-7">
            {currentVideo && (
              <Video
                key={
                  currentVideo?.desktopSource?.asset?.url ||
                  currentVideo?.mobileSource?.asset?.url ||
                  currentVideo?.youtubeShareUrl
                }
                source={currentVideo.source}
                youtubeShareUrl={currentVideo?.youtubeShareUrl}
                desktopSource={{
                  asset: { url: currentVideo?.desktopSource?.asset?.url },
                  poster: {
                    url: currentVideo?.desktopPoster?.asset?.url,
                    alt: currentVideo?.desktopPoster?.asset?.altText
                  }
                }}
                mobileSource={{
                  asset: { url: currentVideo?.mobileSource?.asset?.url },
                  poster: {
                    url: currentVideo?.mobilePoster?.asset?.url,
                    alt: currentVideo?.mobilePoster?.asset?.altText
                  }
                }}
              />
            )}
          </div>
        </div>
      </section>
    </ModuleHandler>
  )
}

export default ExclusiveVideoContent
