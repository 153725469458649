'use client'
import React, { useState } from 'react'
import { VoteAnswer } from '@/components'

interface Asker {
  display_name: string | null
  // other fields...
}

interface Answerer {
  display_name: string | null
  // ...
}

interface PublicAnswer {
  id: number
  content: string
  votes_up: number
  votes_down: number
  created_at: string
  'is_store_owner_comment?': boolean
  answerer: Answerer
}

interface Question {
  id: number
  content: string
  created_at: string
  asker: Asker
  sorted_public_answers?: PublicAnswer[]
}

interface QuestionItemProps {
  question: Question
}

/**
 * Renders a question in a 3-row layout:
 * Row 1: [red circle w/ initials] | [question date]
 * Row 2: "QUESTION:" label, question content, (ANSWERS(x) button), 50% gray border
 * Row 3: answer list (each answer has a black circle w/ initials or "RT", date, store owner label if relevant)
 */
export function QuestionItem({ question }: QuestionItemProps) {
  const { content, created_at, asker, sorted_public_answers = [] } = question

  const askerName = asker?.display_name || 'Anonymous'
  const initials = getInitials(askerName)
  const dateString = formatDate(created_at)
  const [showAnswers, setShowAnswers] = useState(true) // toggle answers section

  // We'll display (ANSWERS(x)) if we have any answers
  const answerCount = sorted_public_answers.length

  function toggleAnswers() {
    setShowAnswers((prev) => !prev)
  }

  return (
    <div className="mb-4 pb-4">
      {/* === Row 1: top row (asker avatar + date) === */}
      <div className="mb-3 flex items-start justify-between">
        {/* Left: avatar w/ initials in red circle */}
        <div className="flex items-center gap-2">
          <div className="flex h-14 w-14 items-center justify-center rounded-full bg-primary">
            <h6 className="h5 text-white">{initials}</h6>
          </div>
        </div>

        {/* Right: date */}
        <p className="p mt-1 text-sm uppercase text-neutral-300">{dateString}</p>
      </div>

      {/* === Row 2: question content + answers toggle === */}
      <div className="mb-2 border-b border-gray-300/50 pb-2">
        <p className="h5">QUESTION:</p>
        <p className="p mt-2">{content}</p>

        {/* Answers(x) button */}
        {answerCount > 0 && (
          <button
            onClick={toggleAnswers}
            className="mt-2 inline-flex items-center gap-1 text-sm text-primary hover:underline"
          >
            <span className="material-symbols-outlined text-base">chat_bubble</span>
            ANSWERS({answerCount})
          </button>
        )}
      </div>

      {/* === Row 3: answers list === */}
      {showAnswers && answerCount > 0 && (
        <div className="mt-2 space-y-4">
          {sorted_public_answers.map((ans) => (
            <AnswerItem key={ans.id} answer={ans} />
          ))}
        </div>
      )}
    </div>
  )
}

/**
 * Renders a single answer row:
 * - top row: [black circle with initials or "RT"] on left,
 *            [Store Owner (if relevant), date] on right
 * - below that: answer content,
 * - "Was this answer helpful?" + up/down votes
 */
function AnswerItem({ answer }: { answer: PublicAnswer }) {
  const {
    id,
    content,
    votes_up,
    votes_down,
    created_at,
    'is_store_owner_comment?': storeOwner,
    answerer
  } = answer

  // If store owner, we display "RT" by default, else use initials of answerer
  const displayName = answerer?.display_name || ''
  const initials = storeOwner ? 'RT' : getInitials(displayName)
  const dateString = formatDate(created_at)

  return (
    <div className="rounded bg-gray-50 p-2">
      {/* top row */}
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-2">
          {/* black circle avatar */}
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-black text-white">
            <h6 className="h6">{initials}</h6>
          </div>
        </div>
        {/* Right side: store owner + date */}
        <div className="text-right text-xs text-gray-600">
          {storeOwner && <p className="font-semibold uppercase">Store Owner</p>}
          <p className="uppercase text-neutral-400">{dateString}</p>
        </div>
      </div>

      {/* answer content */}
      <p className="p mt-2 text-sm">{content}</p>

      {/* "Was this answer helpful?" + up/down */}
      <div className="mt-2 flex items-center gap-2">
        <p className="text-xs uppercase text-gray-700">Was this answer helpful?</p>
        {/* Upvote/Downvote just like we do in reviews */}
        <VoteAnswer
          answerId={id} // or "answerId" if you rename your route
          initialUpvotes={votes_up}
          initialDownvotes={votes_down}
        />
      </div>
    </div>
  )
}

// --- Helpers ---
function getInitials(name: string) {
  const parts = name.trim().split(/\s+/)
  if (parts.length >= 2) {
    return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase()
  }
  return parts[0][0]?.toUpperCase() || ''
}

function formatDate(dateStr: string): string {
  try {
    const date = new Date(dateStr)
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  } catch {
    return dateStr
  }
}
