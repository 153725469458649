'use client'

import { Button, Input } from '@/components'
import { ReactNode, useMemo, useState } from 'react'
import RangeFacet from './rangeFacet'
import { useAlgoliaContext, useGtm } from '@/app/(store)/context'

type Props = {
  facets: FacetOption[]
  title: string
  checkedFacets?: Map<string, Set<string>>
  entity: any
}

export const facetCleanUp = (facet: string) => {
  return facet
    .replaceAll('options.', '')
    .replaceAll('-', ' ')
    .replaceAll(':', ' - ')
    .replaceAll('_', ' ')
    .replaceAll('meta.custom.', '')
    .replace('true', 'yes')
    .replace('false', 'no')
}

const unitsDirectory = {
  none: '',
  money: '',
  lbs: '(LBS)',
  ['sq.in.']: '(SQ.IN.)',
  minutes: '(Minutes)',
  unit: '(Units)',
  pounds: '(Pounds)'
}
export default function FacetDisplayItem({ title, facets, entity }: Props) {
  const { handleFacetChange, checkedFacets } = useAlgoliaContext()
  const { fireEvent } = useGtm()

  const [isOpen, setIsOpen] = useState(true)

  const memoedFacets = useMemo(() => facets, [facets])

  const facetsMinMax = useMemo(
    () =>
      facets?.reduce(
        (acc, [val]) => {
          if (!acc.min || !acc.max) {
            return {
              min: parseFloat(val),
              max: parseFloat(val)
            }
          }

          return {
            min: parseFloat(val) < acc.min ? parseFloat(val) : acc.min,
            max: parseFloat(val) > acc.max ? parseFloat(val) : acc.max
          }
        },
        { min: 0, max: 0 }
      ),
    [facets]
  )
  const facetConfig = useMemo(
    () => entity?.sanityConfig?.find((config: any) => config.name === title),
    [entity.sanityConfig, title]
  )
  if (facetConfig?.type !== 'checkbox' && facets?.length === 0) {
    return <></>
  }
  const unit = unitsDirectory[facetConfig?.unit as keyof typeof unitsDirectory]
  const icon = facetConfig?.icon

  return (
    <>
      {memoedFacets?.length > 0 && (
        <span
          aria-label={`${facetConfig?.field?.label} ${unit ? unit : ''}`}
          className="_p mb-2 flex w-full items-center justify-between bg-gray p-2 text-left uppercase text-secondary"
        >
          <span
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            {facetConfig?.field?.label} {unit ? unit : ''}
          </span>
          <div className="flex gap-2">
            <span
              aria-hidden="true"
              className={`${isOpen ? 'rotate-180' : ''} material-symbols-outlined text-center text-secondary duration-200`}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              keyboard_arrow_down
            </span>
          </div>
        </span>
      )}

      {memoedFacets?.length > 0 && (
        <section
          className={`${isOpen ? 'max-h-[60svh] lg:max-h-[40svh]' : 'max-h-0'}
        ${entity.loading ? 'pointer-events-none opacity-60' : ''}
            mb-6 overflow-hidden duration-200`}
        >
          {facetConfig?.type === 'checkbox' && (
            <div
              className="flex max-h-80 flex-col gap-2 overflow-auto px-1"
              data-test="accordion-content"
            >
              {memoedFacets &&
                memoedFacets?.map(([name, value]: any[], index: number) => {
                  const facetItem = name.toLowerCase()
                  const facetList = checkedFacets?.get(title)
                  return (
                    <label
                      key={`${name}:${index}`}
                      className={`flex flex-nowrap gap-1 ${!value ? 'pointer-events-none' : 'cursor-pointer'}`}
                      data-insights-filter={`${title}:${value}`}
                    >
                      <div className="h-4 w-4">
                        <Input
                          id={`${facetItem}`}
                          type="checkbox"
                          tabIndex={0}
                          dataTest={`facet-${title}`}
                          aria-label={`${facetCleanUp(facetItem)} `}
                          name={title}
                          value={facetItem}
                          disabled={!value}
                          onClick={() => {
                            fireEvent('facetSelected', {
                              facetList: facetConfig?.field?.label,
                              facetItem
                            })
                          }}
                          onChange={handleFacetChange}
                          checked={facetList?.has(facetItem) || false}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              e.target.click()
                            }
                          }}
                        />
                      </div>
                      <span
                        className={`p col-span-11 ml-2 inline-block leading-7 ${!value ? 'text-gray-tertiary' : 'text-secondary'}`}
                      >
                        {icon ? (
                          <>
                            {
                              Array.from({ length: parseInt(facetItem) }).fill(
                                <>{icon}</>
                              ) as ReactNode
                            }
                          </>
                        ) : (
                          <>{facetItem ? name : <></>}</>
                        )}{' '}
                        <span className={`text-sm ${!value ? 'text-gray-tertiary' : ''}`}>
                          ({value})
                        </span>
                      </span>
                    </label>
                  )
                })}
            </div>
          )}
          {facetConfig?.type === 'button' && (
            <>
              <div
                className="flex max-h-80 flex-wrap gap-2 overflow-auto px-1"
                data-test="accordion-content"
              >
                {memoedFacets &&
                  memoedFacets?.map(([name]: any[], index: number) => {
                    const facetItem = name.toLowerCase()
                    const facetList = checkedFacets?.get(title)
                    return (
                      <label
                        key={`${name}:${index}`}
                        className="flex flex-nowrap gap-1"
                        data-insights-filter={`${title}:${facetItem}`}
                      >
                        <div className="hidden h-4 w-4">
                          <Input
                            id={`${facetItem}`}
                            type="radio"
                            dataTest={`facet-${title}`}
                            aria-label={`${facetCleanUp(facetItem)} `}
                            name={title}
                            value={facetItem}
                            onClick={() => {
                              fireEvent('facetSelected', facetItem)
                            }}
                            onChange={handleFacetChange}
                            checked={facetList?.has(facetItem) || false}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                e.target.click()
                              }
                            }}
                          />
                        </div>
                        <Button
                          size="minimal"
                          color="secondary"
                          tabIndex={0}
                          disabled={facetList?.has(facetItem) || false}
                          renderButton={({ size, color, text, animation }) => {
                            return (
                              <span
                                tabIndex={0}
                                className={`inline-block ${size} ${color} ${text} ${animation} ${facetList?.has(facetItem) && 'opacity-70'} cursor-pointer`}
                                onKeyDown={(e: any) => {
                                  if (e.key === 'Enter') {
                                    e.target.click()
                                  }
                                }}
                              >
                                {icon}
                                {icon && (
                                  <sup className="font-primary ml-2 font-normal lowercase">
                                    x{facetItem}
                                  </sup>
                                )}
                                {!icon && facetItem && <span>{facetItem}</span>}
                              </span>
                            )
                          }}
                        />
                      </label>
                    )
                  })}
              </div>
            </>
          )}
          {facetConfig?.type === 'range' && (
            <>
              <RangeFacet
                title={title}
                facetMin={facetsMinMax.min}
                facetMax={facetsMinMax.max}
                unit={facetConfig.unit}
                icon={icon}
              />
            </>
          )}
        </section>
      )}
    </>
  )
}
