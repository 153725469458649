const PersonalizationBadges = ({ personalizationOptions }: { personalizationOptions: string }) => {
  return (
    <div className="flex items-center">
      {personalizationOptions === 'image-only' && (
        <span
          style={{ fontSize: 18 }}
          className="material-symbols-outlined block aspect-1 rounded-full bg-secondary p-2 text-white "
        >
          wallpaper
        </span>
      )}
      {personalizationOptions === 'text-only' && (
        <span
          style={{ fontSize: 18 }}
          className="material-symbols-outlined block aspect-1 rounded-full bg-secondary p-2 text-white "
        >
          text_fields
        </span>
      )}
      {personalizationOptions === 'image-and-text' && (
        <div className="flex gap-2">
          <span
            title="can personalize text"
            className="material-symbols-outlined block aspect-1 rounded-full bg-secondary p-2 text-white "
            style={{ fontSize: 18 }}
          >
            text_fields
          </span>
          <span
            title="can personalize image"
            className="material-symbols-outlined block aspect-1 rounded-full bg-secondary p-2 text-white"
            style={{ fontSize: 18 }}
          >
            wallpaper
          </span>
        </div>
      )}
    </div>
  )
}

export default PersonalizationBadges
