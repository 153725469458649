'use client'
import { useEffect } from 'react'
import { Button, LoadingIndicator } from '@/components'

interface Props {
  products: number
  resultsPerPage: number
  totalResults: number
  setPage: (page: number) => void
  resetFlag?: string
  page: number
  loading: boolean
  focusLastElement?: () => void
}

export const LoadMore: React.FC<Props> = ({
  products,
  resultsPerPage,
  setPage,
  totalResults,
  resetFlag,
  page,
  loading,
  focusLastElement
}) => {
  const handleShowMoreClick = () => {
    setPage(page + 1)
    focusLastElement && focusLastElement()
  }

  useEffect(() => {
    // when this value changes we need to reset the page count
    setPage(0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFlag])

  return (
    <div className="grid w-full grid-cols-2">
      <p className="p col-span-2 text-center">
        Showing Results{' '}
        <strong>
          {products <= resultsPerPage || products >= totalResults
            ? products
            : (page + 1) * resultsPerPage}
        </strong>{' '}
        of <strong>{totalResults}</strong>
      </p>
      <div className="col-span-2 mt-4 flex justify-center">
        <Button
          color="primary-outline"
          size="large"
          onClick={handleShowMoreClick}
          disabled={products >= totalResults}
          className={`mx-auto`}
        >
          {loading ? <LoadingIndicator iconSize={18} isLoading={loading} /> : 'Load more'}
        </Button>
      </div>
    </div>
  )
}
