'use client'
/*
 *
 * Polimorphic CTA component
 * Can be a rendered as <Button/>, plain anchor tag or a <Link> that looks like a <Button/>
 *
 * - as = 'button' | Will render a <Button/> component, accepts all react props for <button/>
 * - as = 'link' | Will render a literal <a/> tag accepts all react props for <a/>
 * - as = 'button-link' | Will render a next <Link/> and accepts <Button/> layout props, color, size and icon.
 *
 * IMPORTANT: This CTA component implements literal interfaces,
 * meaning as 'button', represent an html <button> element with native react handlers without ref.
 *
 */

import { Button, SanityLink } from '@/components'
import { getHrefFromLinkType } from '@/utils'
import { useGtm } from '@/context'
/*
 * Button styling props
 */
export interface CTALayout {
  color: 'primary' | 'secondary' | 'primary-outline' | 'secondary-outline' | 'white-outline'
  size: 'minimal' | 'normal' | 'large'
  icon?: React.ReactNode
}

/*
 * Button react element props combined with layout props
 */
interface IButton extends React.ComponentPropsWithoutRef<'button'> {
  as: 'button'
  layout: CTALayout
}

/*
 * Anchor react element props combined with layout props
 */
interface ILink extends React.ComponentPropsWithoutRef<'a'> {
  as: 'link'
  layout: Omit<CTALayout, 'size' | 'color'>
}

/*
 * Custom element, only accepts necessary props for styling <Button/> component and Sanity.Link for navigation
 */
interface IButtonLink {
  as: 'button-link'
  layout: CTALayout
}

/*
 * General props for all cases
 */
interface GeneralProps {
  link: Sanity.Link
  component?: string
}

type Props = (IButton & GeneralProps) | (ILink & GeneralProps) | (IButtonLink & GeneralProps)

export const CTA = (props: Props) => {
  const { fireEvent } = useGtm()

  if (!props.as) {
    console.warn('<CTA/> component requires an explicit "as" prop.')
    return <></>
  }
  if (props.as === 'button') {
    const { link, layout, onClick, component, ...rest } = props

    const label = link?.text

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      fireEvent('callToActionClick', { label, component })
      onClick?.(e)
    }

    return (
      <Button
        onClick={handleClick}
        {...rest}
        icon={layout?.icon}
        color={layout?.color}
        size={layout?.size}
      >
        {label}
      </Button>
    )
  }

  if (props.as === 'link') {
    const { link, onClick, component, ...rest } = props

    const href = getHrefFromLinkType(link)
    const target = link?.blank ? '_blank' : '_self'
    const label = link?.text
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      fireEvent('callToActionClick', { label, component })
      onClick?.(e)
    }

    return (
      <SanityLink
        data-test="link"
        href={href}
        target={target}
        onClick={handleClick}
        className={rest.className}
      >
        {label}
      </SanityLink>
    )
  }

  if (props.as === 'button-link') {
    const { link, layout, component } = props || {}

    const href = getHrefFromLinkType(link)
    const label = link?.text
    const target = link?.blank ? '_blank' : '_self'

    return (
      <Button
        size={layout?.size}
        color={layout?.color}
        renderButton={({ text, size, color, animation, testAttr }) => {
          const handleClickAnchor = () => {
            fireEvent('callToActionClick', { label, component })
          }
          return (
            <>
              <SanityLink
                onClick={handleClickAnchor}
                href={href}
                target={target}
                data-test={testAttr}
                className={`${text} ${size} ${color} ${animation} ${testAttr} inline-block text-center`}
              >
                {label}
              </SanityLink>
            </>
          )
        }}
      />
    )
  }

  return <></>
}
