'use client'
import Image from 'next/image'

enum AspectRatios {
  video = 'aspect-w-16 aspect-h-9',
  mobile = 'aspect-w-4 aspect-h-5',
  square = 'aspect-1',
  default = ''
}
const SanityMedia = ({
  media,
  aspectRatio = 'square',
  className = '',
  blend = true,
  height,
  width,
  imageClass
}: {
  media: Sanity.Media
  aspectRatio?: keyof typeof AspectRatios
  className?: string
  blend?: boolean
  height?: number
  width?: number
  imageClass?: string
}) => {
  const isVideo = media?.asset?.url?.includes('mp4')
  return (
    <div
      className={`relative flex h-full items-center self-center lg:self-end ${imageClass ? '' : 'w-full object-cover'} ${isVideo ? '' : AspectRatios[aspectRatio]} ${className}`}
    >
      {media?.asset?.url && (
        <>
          {isVideo && (
            <video autoPlay loop muted playsInline className="h-full w-full object-contain">
              <source src={media.asset.url} type="video/mp4" />
            </video>
          )}
          {!isVideo && (
            <Image
              src={media?.asset?.url}
              data-media={media?.asset?.url}
              alt={media?.asset?.altText || ''}
              width={width ? width : 685}
              height={height ? height : 532}
              className={`${imageClass ? imageClass : 'h-full w-full object-cover'} ${blend ? 'mix-blend-multiply' : ''}`}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SanityMedia
