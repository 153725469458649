import { PortableText, PortableTextBlock } from '@portabletext/react'

const RichTextLight = ({ content }: { content: PortableTextBlock[] }) => {
  return (
    <div className="dark:text-white">
      <PortableText
        value={content || []}
        components={{
          block: {
            h1: ({ children }) => <h1 className="h1 mb-4">{children}</h1>,
            h2: ({ children }) => <h2 className="h2 mb-4">{children}</h2>,
            h3: ({ children }) => <h3 className="h3 mb-4">{children}</h3>,
            h4: ({ children }) => <h4 className="h4 mb-4">{children}</h4>,
            h5: ({ children }) => <h5 className="h5 mb-4">{children}</h5>,
            h6: ({ children }) => <h6 className="h6 mb-4">{children}</h6>,
            normal: ({ children }) => <p className="p mb-4">{children}</p>,
            blockquote: ({ children }) => <blockquote className="blockquote">{children}</blockquote>
          },
          list: {
            bullet: ({ children }) => <ul className="ul mb-4 list-outside">{children}</ul>,
            number: ({ children }) => <ol className="ol mb-4 list-outside">{children}</ol>
          },
          types: {
            image: ({ value }) => (
              <div className="max-w-full">
                <img
                  src={value?.asset?.url}
                  alt={value?.asset?.altText}
                  className="m-4 max-w-full"
                />
              </div>
            )
          }
        }}
      />
    </div>
  )
}
export default RichTextLight
