'use client'
import { Button } from '@/components'
import { useRef, useState } from 'react'
import { isAvailableForSale } from '@/utils'
import crypto from 'crypto'
interface Props {
  product: Product
  selectedVariant: ProductVariant
  bundle?: Product | null
  isDiscontinued?: boolean
  customer?: Customer
  isPending?: boolean
}

const KlaviyoOosForm = ({
  product,
  selectedVariant,
  bundle,
  isDiscontinued,
  customer,
  isPending
}: Props) => {
  const emailFormRef = useRef<HTMLInputElement | null>(null)
  const [formError, setFormError] = useState(false)
  const available = isAvailableForSale(product, bundle ?? undefined, selectedVariant)
  const [hideForm, setHideForm] = useState(false)
  // Product info

  const handleOosSubscribe = async () => {
    let emailToSend = customer?.email
    let userInputEmail = ''

    if (emailFormRef.current) {
      userInputEmail = emailFormRef.current.value
    }
    if (
      (!customer && !userInputEmail.length) ||
      (!customer && userInputEmail.length && !userInputEmail.includes('@'))
    ) {
      setFormError(true)
      return
    }
    if (!customer && userInputEmail.length && userInputEmail.includes('@')) {
      setFormError(false)
      emailToSend = userInputEmail
    }
    setHideForm(true)
    const API_TOKEN = crypto
      .createHmac('sha256', process.env.NEXT_PUBLIC_RECTEQ_AUTH_TOKEN || '')
      .digest('base64')

    await fetch('/api/klaviyo/out-of-stock-notification', {
      method: 'POST',
      headers: {
        'Recteq-Auth-Token': API_TOKEN
      },
      body: JSON.stringify({
        productId: selectedVariant?.id || product?.id,
        email: emailToSend
      })
    })
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err))
  }

  return (
    <section data-test="klaviyo-oos">
      <div className="mt-4 2xl:flex 2xl:flex-wrap 2xl:items-end">
        {!isDiscontinued && !available && !isPending && !hideForm && (
          <>
            <h5 className="h6 w-full self-center text-pretty md:w-auto">
              Sign up for an email notification when this item is back in stock.
            </h5>
            {customer && (
              <div className="w-full 2xl:ml-2 2xl:w-1/3">
                <Button
                  onClick={handleOosSubscribe}
                  size="normal"
                  color="primary"
                  className="w-full py-4 2xl:py-2"
                >
                  Notify me when it&apos;s available
                </Button>
              </div>
            )}
            {!customer && (
              <div className="mt-4 flex w-full flex-wrap items-center pb-4">
                <div className="relative w-full 2xl:w-2/3">
                  <input
                    className="input w-full py-4"
                    placeholder="enter your email address"
                    required
                    type="email"
                    ref={emailFormRef}
                  />
                  {formError && (
                    <span className="block text-sm text-error 2xl:absolute 2xl:top-full">
                      Email is required and must be a valid email.
                    </span>
                  )}
                </div>
                <div className="mt-2 w-full 2xl:mt-0 2xl:w-1/3">
                  <Button
                    onClick={handleOosSubscribe}
                    size="normal"
                    color="primary"
                    className="w-full py-4"
                  >
                    Notify me when it&apos;s available
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        {hideForm && (
          <p className="p my-2 w-full text-pretty bg-success p-2 text-center text-sm text-white md:w-auto">
            Success! You will receive an email notification when this item is back in stock.
          </p>
        )}
      </div>
    </section>
  )
}
export default KlaviyoOosForm
