'use client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import useGetAllSearchParams from './useGetAllSearchParams'
import { ContentFacets, fetchSanity, ProductFacets } from '../lib'

export const useAlgoliaFacets = ({ search }: { search: string }) => {
  const router = useRouter()
  const pathname = usePathname()
  const mySearchParams = useSearchParams()
  const [productMetafields, setProductMetafields] = useState([])
  const [contentMetafields, setContentMetafields] = useState([])
  /*
   * Removing the Search term from query params
   * - productqueries prop inside AlgoliaProductSearch requieres to receive only facets
   */
  const allParams = useGetAllSearchParams()

  const facetsOnly = useMemo(
    () => Object.entries(allParams).filter(([key]) => key != 'q'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allParams, mySearchParams]
  )

  /*
   * Filtering non-facet related params
   */
  const getProductMetafields = useCallback(async () => {
    const { productsFacets } = (await fetchSanity(ProductFacets)) || []

    const metafields = productsFacets?.map(({ metafield, type, unit, icon }: any) => ({
      type,
      unit,
      icon,
      name: metafield?.data?.field_name,
      field: metafield?.data
    }))
    setProductMetafields(metafields)
    return metafields
  }, [])

  const getContentMetafields = useCallback(async () => {
    const { contentFacets } = (await fetchSanity(ContentFacets)) || []

    const metafields = contentFacets?.map(({ metafield, type, unit, icon }: any) => ({
      type,
      unit,
      icon,
      name: metafield?.data?.field_name,
      field: metafield?.data
    }))

    setContentMetafields(metafields)
    return metafields
  }, [])

  useEffect(() => {
    getProductMetafields()
    getContentMetafields()
  }, [])

  /*
   * Using controlled input component to manage facets list
   * - FacetDisplayItem will render a managed component
   * - checkedFacets uses Map and Set data structures,
   *   similar to an object of arrays, that can be accesed by key or value easily
   */
  const checkedFacets = useMemo(() => {
    /*
     * If there are facets in the query params, set the initial checked facets state
     */
    if (facetsOnly.length > 0) {
      const map = new Map()

      facetsOnly
        // ?.filter(([key]) => contentMetafields?.map((metafield: any) => metafield?.field?.field_name).includes(key))
        ?.filter(
          ([key]) =>
            productMetafields
              ?.map((metafield: any) => metafield?.field?.field_name)
              .includes(key) ||
            contentMetafields?.map((metafield: any) => metafield?.field?.field_name).includes(key)
        )
        ?.forEach(([key, value]) => {
          const values = new Set<string>()
          value.forEach((val) => values.add(val))

          map.set(key, values)
        })

      return map
    }
    /*
     * If there are no facets in the query params, set the initial state to an empty Map
     */
    return new Map()
  }, [facetsOnly])

  const handleFacetChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    if (input.target.type === 'range') {
      const nextSearchParams = [
        ...Array.from(mySearchParams).filter(([name]) => name !== input.target.name),
        [
          input.target.name,
          JSON.stringify({ type: 'range', name: input.target.name, value: input.target.value })
        ]
      ]
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

      router.push(`${pathname}?${nextSearchParams}`, { scroll: false })
    }
    if (input.target.type === 'checkbox') {
      if (input.target.checked) {
        /*
         * Using URLSearchParams from hook to manage query params
         * - Extract the query params into an array of key:value array
         * - Add the checked facet to the array
         * - Encode the array into a query string
         * - Push the new query string into the router
         */
        const nextSearchParams = [
          ...Array.from(mySearchParams),
          [input.target.name, input.target.value]
        ]
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&')

        router.push(`${pathname}?${nextSearchParams}`, { scroll: false })
      } else {
        /*
         * Get an array of key:value pairs from the URLSearchParams
         * - Filter out the unchecked facet
         * - Encode the array into a query string
         * - Push the new query string into the router
         */
        const notUncheckedFacets = Array.from(mySearchParams)
          .filter(([_, value]) => {
            return value !== input.target.value
          })
          .map(([key, value]) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          })
          .join('&')

        router.push(`${pathname}?${notUncheckedFacets}`, { scroll: false })
      }
    }
    if (input.target.type === 'radio') {
      if (input.target.checked) {
        /*
         * Using URLSearchParams from hook to manage query params
         * - Extract the query params into an array of key:value array
         * - Add the checked facet to the array
         * - Encode the array into a query string
         * - Push the new query string into the router
         */
        const nextSearchParams = [
          ...Array.from(mySearchParams),
          [input.target.name, input.target.value]
        ]
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&')

        router.push(`${pathname}?${nextSearchParams}`, { scroll: false })
      }
    }
  }

  const handleClearFacets = () => {
    /*
     * Clear the checked facets state with an empty Map
     */
    const nextSearchParams = new URLSearchParams(mySearchParams.toString())

    /*
     * Clear all the params exept from the search term
     */
    Array.from(mySearchParams).forEach(([key]) => {
      nextSearchParams.delete(key)
    })

    if (search) {
      nextSearchParams.append('q', search)
    }
    router.push(`${pathname}?${nextSearchParams}`)
  }

  const handleClearFacet = (key: string) => {
    const nextSearchParams = new URLSearchParams(mySearchParams.toString())

    /*
     * Clear the param with key
     */
    nextSearchParams.delete(key)

    if (search) {
      nextSearchParams.append('q', search)
    }
    router.push(`${pathname}?${nextSearchParams}`)
  }

  return {
    handleClearFacets,
    handleFacetChange,
    handleClearFacet,

    checkedFacets
  }
}
