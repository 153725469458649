'use client'

const Klaviyo = () => {
  return (
    <div className="klaviyo-embed-form-wrapper">
      <div className="klaviyo-form-Vd8Qpz"></div>
    </div>
  )
}

export default Klaviyo
