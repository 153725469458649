'use client'
import { useEffect, useMemo, useState } from 'react'
import { Button, Input } from '@/components'
import { useGtm } from '@/context'

interface Props {
  searchParams: Record<string, string>
  handleSubmit?: (inputValue: string) => void
  handleChange?: (inputValue: string) => void
}
const SearchInput = ({ searchParams, handleSubmit, handleChange }: Props) => {
  const searchTerm = useMemo(() => searchParams?.q, [searchParams])
  const [inputValue, setInputValue] = useState<string>(searchTerm)
  const { fireEvent } = useGtm()

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputValue?.length >= 3 && handleChange) {
        handleChange(inputValue)
        fireEvent('searchInput', { query: inputValue })
      }
      if (inputValue?.length === 0 && handleChange) {
        handleChange(inputValue)
      }
    }, 300)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <section className="flex flex-col">
      <div className="flex w-full">
        <label htmlFor="search-bar-input" className="col-span-10 block w-full md:col-span-9">
          <Input
            id="search-bar-input"
            type="search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            autoFocus={true}
            maxLength={512}
            value={inputValue}
            data-test="search-bar-input"
            className="p peer border-gray-200 py-3"
            onChange={({ target: { value } }) => {
              setInputValue(value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit && handleSubmit(inputValue)
                fireEvent('searchPerformed', { query: inputValue })
              }
            }}
            placeholder="Search for products, blogs and recipes"
          />
        </label>
        <div className="col-span-10 md:col-span-1">
          <Button
            color="secondary"
            type="submit"
            className="h-full w-full py-2"
            onClick={() => {
              handleSubmit && handleSubmit(inputValue)
              fireEvent('searchPerformed', { query: inputValue })
            }}
            icon={
              <span aria-hidden="true" className="material-symbols-outlined">
                search
              </span>
            }
          >
            search
          </Button>
        </div>
      </div>
      {!!searchTerm && (
        <div className="p-alt my-2 uppercase">Results for: &quot;{searchTerm}&quot;</div>
      )}
    </section>
  )
}

export default SearchInput
