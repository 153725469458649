import SanityLink from '../link'

interface Props {
  socialLinks: {
    id?: string
    icon?: string | React.ReactNode
    link?: string | Sanity.Link
    blank?: boolean
    parameters?: string
  }[]
  platforms: {
    id: string
    tag: string
    icon: string | React.ReactNode
    link?: string | Sanity.Link
    blank?: boolean
    parameters: string
  }[]
}

const SocialMenu = ({ socialLinks, platforms }: Props) => {
  return (
    <>
      <div className="col-span-2 gap-4 lg:col-span-3 lg:grid lg:flex-row lg:items-center lg:gap-3 xl:grid-cols-10 2xl:grid-cols-8">
        <div className="col-span-2 flex flex-col items-start">
          <h3 className="h4 mb-4 lg:mb-0">Get Social</h3>
        </div>
        <nav
          aria-label="Social navigation menu"
          className="p mb-2 flex items-center text-white/80 lg:col-span-3 lg:mb-0 xl:col-span-4 2xl:col-span-3"
        >
          <ul className="flex flex-row flex-wrap gap-4">
            {socialLinks?.map(({ link, icon, id, blank, parameters }, idx) => {
              if (!link) return <></>

              return (
                <li key={id + idx.toString()}>
                  <SanityLink
                    key={id + idx.toString()}
                    data-test="footer-social-link"
                    aria-label={`${id}`}
                    className="material-symbols-outlined footer-social-link"
                    href={`${link}${parameters ? parameters : ''}`}
                    target={blank ? '_blank' : '_self'}
                  >
                    {icon}
                  </SanityLink>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className="flex flex-col gap-4 lg:col-span-5 xl:col-span-4 2xl:col-span-3">
          <nav aria-label="App stores links" className="flex flex-wrap gap-2">
            {platforms?.map(({ icon, link, id, parameters, blank, tag }, idx) => (
              <SanityLink
                key={id + idx.toString()}
                ata-test="footer-platform-link"
                aria-label={`${tag}`}
                href={`${link}${parameters ? parameters : ''}`}
                target={blank ? '_blank' : '_self'}
              >
                {icon}
              </SanityLink>
            ))}
          </nav>
        </div>
      </div>
    </>
  )
}

export default SocialMenu
