import { TAGS } from '@/constants'

// Mutations
import {
  createCustomerMutation,
  customerAccessTokenCreate,
  activateCustomerByUrlMutation,
  resetCustomerPasswordByUrlMutation,
  customerAccessTokenDeleteMutation,
  customerAddressCreateMutation,
  customerAddressDeleteMutation,
  customerDefaultAddressUpdateMutation,
  customerAddressUpdateMutation,
  resetCustomerRecoverMutation,
  resetCustomerMutation,
  customerUpdateMutation
} from '@/lib/shopify/mutations'

// Queries
import { getCustomerQuery } from '@/lib/shopify/queries'
import { shopifyFetch } from '..'

export async function registerCustomer({
  email,
  password,
  firstName,
  lastName
}: {
  email: string
  password: string
  firstName: string
  lastName: string
}): Promise<{ customerUserErrors: CustomerUserErrors[]; customer?: Customer }> {
  const res = await shopifyFetch<ShopifyCreateCustomerOperation>({
    query: createCustomerMutation,
    variables: {
      input: {
        email,
        password,
        firstName,
        lastName
      }
    },
    cache: 'no-store'
  })

  return {
    customer: res.body.data.customerCreate.customer,
    customerUserErrors: res.body.data.customerCreate.customerUserErrors
  }
}
export async function getCustomer({
  accessToken
}: {
  accessToken: string
}): Promise<{ customer?: Customer }> {
  const res = await shopifyFetch<ShopifyGetCustomerOperation>({
    query: getCustomerQuery,
    variables: {
      customerAccessToken: accessToken
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return {
    customer: res.body.data.customer
  }
}
export async function getCustomerAccessToken({
  input
}: {
  input: { email: string; password: string }
}): Promise<{
  customerUserErrors: CustomerUserErrors[]
  customerAccessToken: CustomerAccessToken
}> {
  const res = await shopifyFetch<ShopifyCustomerAccessTokenOperation>({
    query: customerAccessTokenCreate,
    variables: {
      input
    },
    cache: 'no-store'
  })

  return {
    customerAccessToken: res.body.data.customerAccessTokenCreate.customerAccessToken,
    customerUserErrors: res.body.data.customerAccessTokenCreate.customerUserErrors
  }
}
export async function customerRecover({ email }: { email: string }): Promise<{
  customerUserErrors: CustomerUserErrors[]
}> {
  const res = await shopifyFetch<ShopifyResetCustomerRecoverOperation>({
    query: resetCustomerRecoverMutation,
    variables: {
      email
    },
    cache: 'no-store'
  })

  return {
    customerUserErrors: res.body.data.customerRecover.customerUserErrors
  }
}
export async function customerReset({
  id,
  input
}: {
  id: string
  input: {
    password: string
    resetToken: string
  }
}): Promise<{
  customer: Customer
  customerUserErrors: CustomerUserErrors[]
  customerAccessToken: CustomerAccessToken
}> {
  const res = await shopifyFetch<ShopifyResetCustomerPasswordOperation>({
    query: resetCustomerMutation,
    variables: {
      id,
      input
    },
    cache: 'no-store'
  })

  return {
    customer: res.body.data.customerReset.customer,
    customerAccessToken: res.body.data.customerReset.customerAccessToken,
    customerUserErrors: res.body.data.customerReset.customerUserErrors
  }
}
export async function customerUpdate({
  customerAccessToken,
  customer
}: {
  customerAccessToken: string
  customer: {
    acceptsMarketing?: string
    email?: string
    firstName?: string
    lastName?: string
    password?: string
    phone?: string
  }
}): Promise<{
  customer: Customer
  customerUserErrors: CustomerUserErrors[]
  customerAccessToken: CustomerAccessToken
}> {
  const res = await shopifyFetch<ShopifyCustomerUpdateOperation>({
    query: customerUpdateMutation,
    variables: {
      customer,
      customerAccessToken
    },
    cache: 'no-store'
  })

  return {
    customer: res.body.data.customerUpdate.customer,
    customerAccessToken: res.body.data.customerUpdate.customerAccessToken,
    customerUserErrors: res.body.data.customerUpdate.customerUserErrors
  }
}
export async function customerResetPassword({
  password,
  resetUrl
}: {
  password: string
  resetUrl: string
}): Promise<{
  customerUserErrors: CustomerUserErrors[]
  customer: Customer
  customerAccessToken: CustomerAccessToken
}> {
  const res = await shopifyFetch<ShopifyResetCustomerPasswordByUrlOperation>({
    query: resetCustomerPasswordByUrlMutation,
    variables: {
      password,
      resetUrl
    },
    cache: 'no-store'
  })

  return {
    customer: res.body.data.customerResetByUrl.customer,
    customerAccessToken: res.body.data.customerResetByUrl.customerAccessToken,
    customerUserErrors: res.body.data.customerResetByUrl.customerUserErrors
  }
}
export async function customerActivateByUrl({
  password,
  activationUrl
}: {
  activationUrl: string
  password: string
}): Promise<{
  customerUserErrors: CustomerUserErrors[]
  customer: Customer
  customerAccessToken: CustomerAccessToken
}> {
  const res = await shopifyFetch<ShopifyActivateCustomerByUrlOperation>({
    query: activateCustomerByUrlMutation,
    variables: {
      password,
      activationUrl
    },
    cache: 'no-store'
  })

  return {
    customer: res.body.data.customerActivateByUrl.customer,
    customerAccessToken: res.body.data.customerActivateByUrl.customerAccessToken,
    customerUserErrors: res.body.data.customerActivateByUrl.customerUserErrors
  }
}
export async function customerAccessTokenDelete({
  customerAccessToken
}: {
  customerAccessToken: string
}): Promise<{
  userErrors: CustomerUserErrors[] | undefined
  deletedAccessToken: string | undefined
  deletedCustomerAccessTokenId: string | undefined
}> {
  try {
    const res = await shopifyFetch<ShopifyCustomerAccessTokenDeleteOperation>({
      query: customerAccessTokenDeleteMutation,
      variables: {
        customerAccessToken
      },
      cache: 'no-store'
    })

    return {
      userErrors: res.body.data.customerAccessTokenDelete.userErrors,
      deletedAccessToken: res.body.data.customerAccessTokenDelete.deletedAccessToken,
      deletedCustomerAccessTokenId:
        res.body.data.customerAccessTokenDelete.deletedCustomerAccessTokenId
    }
  } catch (error) {
    return {
      userErrors: undefined,
      deletedAccessToken: undefined,
      deletedCustomerAccessTokenId: undefined
    }
  }
}
export async function customerAddressCreate({
  address,
  customerAccessToken
}: {
  address: {
    firstName: string
    lastName?: string
    address1: string
    address2: string
    city: string
    zip: string
    country: string
    company?: string
    phone?: string
    province?: string
  }
  customerAccessToken: string
}): Promise<{
  customerUserErrors: CustomerUserErrors[]
  address: Address
}> {
  const res = await shopifyFetch<ShopifyAddresCreateOperation>({
    query: customerAddressCreateMutation,
    variables: {
      customerAccessToken,
      address
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return {
    address: res?.body?.data?.customerAddressCreate?.customerAddress,
    customerUserErrors: res?.body?.data?.customerAddressCreate?.customerUserErrors
  }
}
export async function customerAddressUpdate({
  address,
  customerAccessToken,
  id
}: {
  address: {
    firstName: string
    lastName?: string
    address1: string
    address2: string
    city: string
    zip: string
    country: string
    company?: string
    phone?: string
    province?: string
  }
  customerAccessToken: string
  id: string
}): Promise<{
  customerUserErrors: CustomerUserErrors[]
  address: Address
}> {
  const res = await shopifyFetch<ShopifyAddresUpdateOperation>({
    query: customerAddressUpdateMutation,
    variables: {
      id,
      customerAccessToken,
      address
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return {
    address: res?.body?.data?.customerAddressUpdate?.customerAddress,
    customerUserErrors: res?.body?.data?.customerAddressUpdate?.customerUserErrors
  }
}
export async function customerAddressDelete({
  id,
  customerAccessToken
}: {
  id: string
  customerAccessToken: string
}): Promise<{
  customerUserErrors: CustomerUserErrors[]
  deletedCustomerAddressId: string
}> {
  const res = await shopifyFetch<ShopifyAddresDeleteOperation>({
    query: customerAddressDeleteMutation,
    variables: {
      id,
      customerAccessToken
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return {
    deletedCustomerAddressId: res?.body?.data?.customerAddressDelete?.deletedCustomerAddressId,
    customerUserErrors: res?.body?.data?.customerAddressDelete?.customerUserErrors
  }
}
export async function customerDefaultAddressUpdate({
  addressId,
  customerAccessToken
}: {
  addressId: string
  customerAccessToken: string
}): Promise<{
  customer: Customer
  customerUserErrors: CustomerUserErrors[]
}> {
  const res = await shopifyFetch<ShopifyDefaultAddresUpdateOperation>({
    query: customerDefaultAddressUpdateMutation,
    variables: {
      addressId,
      customerAccessToken
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return {
    customer: res?.body?.data?.customerDefaultAddressUpdate?.customer,
    customerUserErrors: res?.body?.data?.customerDefaultAddressUpdate?.customerUserErrors
  }
}
