'use client'

import { ReactElement, useEffect, useState } from 'react'
import { directory } from '../modules'
import dynamic from 'next/dynamic'
import { getDataBySanityQuery } from '@/utils/query'
import { ProductCard } from '../cards'
import ContentCard from '../cards/content-card'
import { useCustomerContext } from '../../context'
import ModuleHandler from '../module-handler'

interface Props extends DataByQuery {
  modulesSelector: any[]
  alignment: 'start' | 'center' | 'end' | 'evenly' | 'between'
  componentGlobalConfig?: Sanity.ComponentConfig
}
const SanityGrid = (props: Props) => {
  let spacing = 'justify-center'

  const { isRaysClub } = useCustomerContext()

  const [items, setItems] = useState<ReactElement[] | undefined>()
  const { source, querySelection, componentGlobalConfig } = props || {}

  switch (props.alignment) {
    case 'start':
      spacing = 'justfiy-start'
      break
    case 'center':
      spacing = 'justify-center'
      break
    case 'end':
      spacing = ' justify-end'
      break
    case 'evenly':
      spacing = ' justify-evenly'
      break
    case 'between':
      spacing = ' justify-between'
      break
  }

  useEffect(() => {
    const getData = async () => {
      if (source === 'manual') {
        if (props?.modulesSelector?.length > 0) {
          const items = props?.modulesSelector?.map((item: any) => {
            if (!item) {
              return <></>
            }

            if (item._type === 'contentCard') {
              return directory[item._type as keyof typeof directory]({
                content: item.content.reference
              })
            }
            return directory[item._type as keyof typeof directory](item)
          })

          setItems(items)
        }
      }

      if (source === 'query') {
        if (querySelection?.reference?.type === 'products') {
          const data = await getDataBySanityQuery({
            source: 'query',
            type: querySelection?.reference?.type,
            query: querySelection?.reference?.query,
            connective: querySelection?.reference?.connective,
            isRaysClub
          })
          setItems(
            data.map((product: Product) => <ProductCard key={product.id} product={product} />)
          )
        }

        if (querySelection?.reference?.type === 'content') {
          const data = await getDataBySanityQuery({
            source: 'query',
            type: querySelection?.reference?.type,
            query: props.querySelection.reference.contentquery,
            isRaysClub
          })
          data &&
            setItems(data.map((hit: AlgoliaContent) => <ContentCard key={hit._id} content={hit} />))
        }
      }
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Grid = dynamic(() => import('./').then((mod) => mod.Grid), {
    ssr: false,
    loading: () => (
      <section className="module-spacing container flex animate-pulse flex-row flex-wrap">
        <div className={`m-2 flex w-full gap-8 ${spacing}`}>
          {Array.from({ length: props?.modulesSelector?.length || 4 }).map((el, idx) => (
            <div key={idx} className="w-full p-4 sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="mb-5 aspect-1 w-full bg-gray" />
              <div className="mb-8 h-[200px] w-full bg-gray" />
            </div>
          ))}
        </div>
      </section>
    )
  })
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <Grid items={items} alignment={props.alignment} />
    </ModuleHandler>
  )
}
export default SanityGrid
