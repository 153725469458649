'use client'
import React, { useState } from 'react'
import { UgcGalleryModal, UgcGalleryItem } from '@/components'

interface UgcGalleryProps {
  album: any
}

export function UgcGallery({ album }: UgcGalleryProps) {
  const mediaData =
    album?.images
      ?.filter((media: any) => media.media_type === 'image' || media.media_type === 'video')
      .slice(0, 12) || []

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalIndex, setModalIndex] = useState(0) // which media is clicked

  function openModal(index: number) {
    setModalIndex(index)
    setIsModalOpen(true)
  }

  if (!mediaData.length) {
    return <p className="text-center">No images or videos found in this album.</p>
  }

  return (
    <div className="module-spacing container font-oswald text-secondary">
      <div className="mb-4 flex flex-col items-center justify-between gap-4 md:flex-row">
        <div className="h3">Follow us on social media so you don&apos;t miss any of the news!</div>
        <Socials />
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {mediaData.map((item: any, index: number) => (
          <UgcGalleryItem key={item.image_id} img={item} index={index} onClick={openModal} />
        ))}

        {/* The modal for full view (handles both images & video) */}
        <UgcGalleryModal
          images={mediaData}
          isOpen={isModalOpen}
          initialIndex={modalIndex}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  )
}

const Socials = () => {
  return (
    <div className="flex flex-row items-center gap-4">
      <a href="https://www.facebook.com/recteq/" target="_blank" title="Facebook">
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM15 8H13.65C13.112 8 13 8.221 13 8.778V10H15L14.791 12H13V19H10V12H8V10H10V7.692C10 5.923 10.931 5 13.029 5H15V8Z"
            fill="#EA0029"
          ></path>
        </svg>
      </a>
      <a href="https://www.youtube.com/recteq" target="_blank" title="Youtube">
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.441 16.892C14.339 17.036 9.657 17.036 7.558 16.892C5.282 16.736 5.017 15.622 5 12C5.017 8.371 5.285 7.264 7.558 7.108C9.657 6.964 14.34 6.964 16.441 7.108C18.718 7.264 18.982 8.378 19 12C18.982 15.629 18.715 16.736 16.441 16.892ZM10 9.658L14.917 11.996L10 14.342V9.658Z"
            fill="#EA0029"
          ></path>
        </svg>
      </a>
      <a href="https://twitter.com/recteq_official" target="_blank" title="Twitter">
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.066 9.645C18.249 13.685 15.236 18.189 9.902 18.189C8.28 18.189 6.771 17.713 5.5 16.898C7.024 17.078 8.545 16.654 9.752 15.709C8.496 15.686 7.435 14.855 7.068 13.714C7.519 13.8 7.963 13.775 8.366 13.665C6.985 13.387 6.031 12.143 6.062 10.812C6.45 11.027 6.892 11.156 7.363 11.171C6.084 10.316 5.722 8.627 6.474 7.336C7.89 9.074 10.007 10.217 12.394 10.337C11.975 8.541 13.338 6.81 15.193 6.81C16.018 6.81 16.765 7.159 17.289 7.717C17.943 7.589 18.559 7.349 19.113 7.02C18.898 7.691 18.443 8.253 17.85 8.609C18.431 8.539 18.985 8.385 19.499 8.156C19.115 8.734 18.629 9.24 18.066 9.645Z"
            fill="#EA0029"
          ></path>
        </svg>
      </a>
      <a href="https://www.instagram.com/recteq_official/" target="_blank" title="Instagram">
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.829 6.302C14.091 6.268 13.869 6.262 12 6.262C10.131 6.262 9.91 6.269 9.172 6.302C7.273 6.389 6.389 7.288 6.302 9.172C6.269 9.91 6.261 10.131 6.261 12C6.261 13.869 6.269 14.09 6.302 14.829C6.389 16.708 7.269 17.612 9.172 17.699C9.909 17.732 10.131 17.74 12 17.74C13.87 17.74 14.091 17.733 14.829 17.699C16.728 17.613 17.611 16.711 17.699 14.829C17.732 14.091 17.739 13.869 17.739 12C17.739 10.131 17.732 9.91 17.699 9.172C17.611 7.289 16.726 6.389 14.829 6.302ZM12 15.595C10.015 15.595 8.405 13.986 8.405 12C8.405 10.015 10.015 8.406 12 8.406C13.985 8.406 15.595 10.015 15.595 12C15.595 13.985 13.985 15.595 12 15.595ZM15.737 9.104C15.273 9.104 14.897 8.728 14.897 8.264C14.897 7.8 15.273 7.424 15.737 7.424C16.201 7.424 16.577 7.8 16.577 8.264C16.577 8.727 16.201 9.104 15.737 9.104ZM14.333 12C14.333 13.289 13.288 14.333 12 14.333C10.712 14.333 9.667 13.289 9.667 12C9.667 10.711 10.712 9.667 12 9.667C13.288 9.667 14.333 10.711 14.333 12ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.958 14.886C18.843 17.431 17.426 18.841 14.887 18.958C14.14 18.992 13.901 19 12 19C10.099 19 9.861 18.992 9.114 18.958C6.57 18.841 5.159 17.429 5.042 14.886C5.008 14.14 5 13.901 5 12C5 10.099 5.008 9.861 5.042 9.114C5.159 6.57 6.571 5.159 9.114 5.043C9.861 5.008 10.099 5 12 5C13.901 5 14.14 5.008 14.887 5.043C17.432 5.16 18.844 6.575 18.958 9.114C18.992 9.861 19 10.099 19 12C19 13.901 18.992 14.14 18.958 14.886Z"
            fill="#EA0029"
          ></path>
        </svg>
      </a>
      <a href="https://www.pinterest.com/recteq/" target="_blank" title="Pinterest">
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM12 19C11.279 19 10.582 18.891 9.927 18.688C10.213 18.223 10.64 17.461 10.797 16.853L11.234 15.189C11.463 15.625 12.129 15.993 12.838 15.993C14.949 15.993 16.471 14.052 16.471 11.639C16.471 9.327 14.583 7.597 12.155 7.597C9.134 7.597 7.53 9.624 7.53 11.832C7.53 12.859 8.077 14.137 8.952 14.544C9.084 14.606 9.155 14.578 9.186 14.45L9.379 13.657C9.396 13.586 9.388 13.525 9.33 13.455C9.042 13.105 8.809 12.46 8.809 11.858C8.809 10.314 9.978 8.82 11.97 8.82C13.69 8.82 14.894 9.992 14.894 11.668C14.894 13.562 13.937 14.873 12.693 14.873C12.006 14.873 11.492 14.305 11.657 13.608C11.854 12.775 12.237 11.878 12.237 11.277C12.237 10.74 11.949 10.291 11.351 10.291C10.649 10.291 10.083 11.018 10.083 11.991C10.083 12.612 10.294 13.031 10.294 13.031C10.294 13.031 9.6 15.965 9.473 16.51C9.331 17.115 9.387 17.964 9.448 18.518C6.845 17.498 5 14.965 5 12C5 8.134 8.135 5 12 5C15.865 5 19 8.134 19 12C19 15.866 15.865 19 12 19Z"
            fill="#EA0029"
          ></path>
        </svg>
      </a>
    </div>
  )
}
