'use client'
import 'swiper/css'
import { lato } from '../../assets/fonts'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import Link from 'next/link'
import { useScrollDirection, useWindowPosition } from '@/hooks'
import SignOutButton from '../buttons/sign-out-button'
import { useHeaderContext } from '@/context'
import { PortableTextBlock } from 'next-sanity'
import { PortableText } from '@portabletext/react'
import getUuid from 'uuid-by-string'
import { Fragment } from 'react'

interface Props {
  delay?: number
  advertisements?: { textBody: PortableTextBlock[] }[]
  subMenuItems?: MenuItem[]
  hasAcitveSession?: boolean
}

export const Topbar = ({ advertisements, subMenuItems, delay = 2500, hasAcitveSession }: Props) => {
  const { isTransparent, customer } = useHeaderContext()
  const { position } = useWindowPosition()
  const { scrollDir } = useScrollDirection()

  if (!advertisements && !subMenuItems) return <></>
  const isRaysClub = customer?.tags?.includes('Rays-Club')
  return (
    <section
      data-test="tobar-container"
      data-component="topbar"
      className={`relative z-50 flex h-10 w-full items-center ${advertisements ? 'with-items block' : 'hidden lg:block'} ${
        (position > 100 && scrollDir === 'up') || !isTransparent ? 'bg-primary' : 'bg-transparent'
      }`}
    >
      <div className={`container flex h-full items-center justify-between ${lato.variable}`}>
        <div className="flex min-h-6 w-full justify-start sm:max-w-[50%] lg:max-w-[30%]">
          {advertisements && (
            <Swiper
              modules={[Autoplay]}
              slidesPerView={1}
              autoplay={{
                delay: delay,
                disableOnInteraction: true
              }}
              data-test="topbar-advertisements-slider"
            >
              {advertisements?.map((advertisement, index) => (
                <SwiperSlide key={index}>
                  <h5 className="copy mb-0 font-bold text-white">
                    <PortableText value={advertisement.textBody} />
                  </h5>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        {subMenuItems && (
          <div className="hidden text-white lg:flex">
            {subMenuItems?.map(({ id, link }, idx) => {
              if (!link?.path || !link?.title)
                return <Fragment key={getUuid(id.toString())}></Fragment>

              return (
                <div
                  key={getUuid(id.toString())}
                  className={`relative inline-flex items-end
                     ${(idx > 0 || idx < subMenuItems.length - 1) && ''}
                     `}
                >
                  {idx ? <span className="mx-3 inline-block text-lg leading-tight">•</span> : <></>}

                  <Link
                    data-test="submenu-link"
                    href={link.path}
                    className="p-alt capitalize text-white"
                  >
                    {link.title}
                  </Link>
                </div>
              )
            })}
            <div className="relative inline-flex items-center leading-tight">
              <span className="mx-3 inline-block text-lg leading-tight">•</span>

              {!hasAcitveSession ? (
                <Link href="/account/login" className="p-alt capitalize leading-tight text-white">
                  Login
                </Link>
              ) : (
                <>
                  {isRaysClub ? (
                    <>
                      <Link href="/rays-club" className="p-alt capitalize leading-tight text-white">
                        Ray&rsquo;s Club
                      </Link>
                      <span className="mx-3 inline-block text-lg leading-tight">•</span>
                    </>
                  ) : (
                    <></>
                  )}
                  <Link
                    href="/account/dashboard"
                    className="p-alt capitalize leading-tight text-white"
                  >
                    My Account
                  </Link>
                  <span className="mx-3 inline-block text-lg leading-tight">•</span>
                  <SignOutButton className="block" />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
