export const subscribe = (eventName: string, listener: (data: object) => void) => {
  document.addEventListener(eventName, listener)
}

export const unsubscribe = (eventName: string, listener: (data: object) => void) => {
  document.removeEventListener(eventName, listener)
}

export const publish = (eventName: string, data?: object) => {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}
