'use client'
import React from 'react'

interface ThankYouProps {
  type: 'Review' | 'Question'
  onClose: () => void
}

export function ThankYou({ type, onClose }: ThankYouProps) {
  return (
    <div className="relative flex min-h-[30rem] flex-col items-center justify-center bg-gray-100  p-4 text-center md:p-6 ">
      {/* Close button */}
      <button
        onClick={onClose}
        className="absolute right-3 top-3 text-gray-500 hover:text-gray-800 focus:outline-none"
        aria-label="Close"
      >
        <span className="material-symbols-outlined text-xl">close</span>
      </button>

      {/* Title */}
      <h2 className="h2 mb-3">Thank you for your {type}!</h2>

      {/* Body Text */}
      <p className="text-sm text-gray-700">
        We appreciate your feedback and will review it shortly.
      </p>
    </div>
  )
}
