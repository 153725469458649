import styles from './overlay.module.css'

export const Overlay = ({
  color,
  imageUrl,
  transparent = false,
  gradient = 'none',
  lighter = false
}: {
  gradient: 'none' | 'to right' | 'to left' | 'to bottom' | 'to top'
  color: string | 'dark' | 'light'
  imageUrl?: string
  transparent?: boolean
  lighter?: boolean
}) => {
  const hasGradient = gradient !== 'none'
  const gradientOpacity = lighter && hasGradient ? 0.55 : 0.75

  let gradientColor = ''
  let overlayBackground = `bg-black ${lighter ? 'opacity-[.27]' : 'opacity-[.45]'}`

  if (hasGradient && color === 'dark') {
    gradientColor = '0, 0, 0'
    overlayBackground = transparent ? 'bg-none' : 'bg-black'
  }
  if (hasGradient && color === 'light') {
    gradientColor = '225, 225, 225'
    overlayBackground = 'bg-white'
  }

  return (
    <div
      data-test="overlay"
      className={`absolute left-0 top-0 z-[1] h-full w-full overflow-hidden ${overlayBackground}`}
    >
      <div className="relative mx-auto h-full w-full max-w-[1900px]">
        <div
          className="absolute left-0 top-0 h-full w-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `
              linear-gradient(
                  ${gradient === 'none' ? '0deg' : gradient}, 
                  rgba(${gradientColor}, ${gradientOpacity}), 
                  rgba(${gradientColor}, ${hasGradient ? gradientOpacity / 2 : gradientOpacity}), 
                  rgba(${gradientColor}, ${hasGradient ? gradientOpacity / 3 : gradientOpacity})
              )
              ${imageUrl ? `, url('${imageUrl}')` : ''} 
            `
          }}
        >
          <div className="hidden 2xl:block">
            {color === 'dark' && (
              <>
                <div className={styles.gradientOverlayDarkTop}></div>
                <div className={styles.gradientOverlayDarkBottom}></div>
              </>
            )}
            {color === 'light' && (
              <>
                <div className={styles.gradientOverlayLightTop}></div>
                <div className={styles.gradientOverlayLightBottom}></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
