// Utils
import { isShopifyError } from './type-guards'

type ExtractVariables<T> = T extends { variables: object } ? T['variables'] : never

export async function shopifyFetch<T>({
  cache = 'force-cache',
  headers,
  query,
  tags,
  variables
}: {
  cache?: RequestCache
  headers?: HeadersInit
  query: string
  tags?: string[]
  variables?: ExtractVariables<T>
}): Promise<{ status: number; body: T } | never> {
  const domain = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN
  const key = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN
  const endpoint = `https://${domain}/api/${process.env.NEXT_PUBLIC_SHOPIFY_STORE_API_VERSION}/graphql.json`

  try {
    const result = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': key || '',
        ...headers
      },
      body: JSON.stringify({
        ...(query && { query }),
        ...(variables && { variables })
      }),
      cache,
      ...(tags && { next: { tags } })
    })

    const body = await result.json()

    if (body.errors) {
      throw body.errors[0]
    }
    return {
      status: result.status,
      body
    }
  } catch (e) {
    console.warn('e', e)

    if (isShopifyError(e)) {
      throw {
        cause: e.cause?.toString() || 'unknown',
        status: e.status || 500,
        message: e.message,
        query
      }
    }

    throw {
      error: e,
      query
    }
  }
}
export async function shopifyAdminFetch<T>({
  cache = 'force-cache',
  headers,
  query,
  tags,
  variables
}: {
  cache?: RequestCache
  headers?: HeadersInit
  query: string
  tags?: string[]
  variables?: ExtractVariables<T>
}): Promise<{ status: number; body: T } | never> {
  const domain = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN
  const key = process.env.SHOPIFY_SECRET
  const endpoint = `https://${domain}/admin/api/${process.env.NEXT_PUBLIC_SHOPIFY_STORE_API_VERSION}/graphql.json`
  // const endpoint = `https://${domain}/account/customer/api/${process.env.NEXT_PUBLIC_SHOPIFY_STORE_API_VERSION}/graphql`

  try {
    const result = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Access-Token': key || '',
        ...headers
      },
      body: JSON.stringify({
        ...(query && { query }),
        ...(variables && { variables })
      }),
      cache,
      ...(tags && { next: { tags } })
    })

    const body = await result.json()

    if (body.errors) {
      throw body.errors[0]
    }

    return {
      status: result.status,
      body
    }
  } catch (e) {
    console.warn('e', e)

    if (isShopifyError(e)) {
      throw {
        cause: e.cause?.toString() || 'unknown',
        status: e.status || 500,
        message: e.message,
        query
      }
    }

    throw {
      error: e,
      query
    }
  }
}

export * from './actions'
