'use client'
import React, { useState, useMemo } from 'react'
import { PageTitle, SearchInput, SortInput } from '@/components'
import { useRouter } from 'next/navigation'
import HitsWithFacets from '../products/hitsWithFacets'
import { AlgoliaContextProvider } from '@/context'
import SearchResultsTab from './search-results-tab'

type Props = {
  searchParams: Record<string, string>
}

export default function SearchResultsPage({ searchParams }: Props) {
  const [activeResults, setActiveResults] = useState<'product' | 'content'>('product')

  const router = useRouter()

  const searchTerm = useMemo(() => searchParams?.q, [searchParams])

  const handleSubmit = (e: string) => {
    !e && router.push(`/search`)
    e && router.push(`/search?q=${e}`)
  }

  return (
    <>
      <AlgoliaContextProvider search={searchTerm} searchParams={searchParams} openItem="both">
        <div className="container">
          <PageTitle title="Search">
            <div className="w-full">
              <SearchInput searchParams={searchParams} handleSubmit={handleSubmit} />
            </div>
          </PageTitle>

          <section id="results-container" className="py-6">
            <HitsWithFacets openTab={activeResults}>
              <section className="mb-4 flex flex-wrap justify-between gap-5 lg:flex-nowrap lg:gap-0">
                <SearchResultsTab
                  activeResults={activeResults}
                  setActiveResults={setActiveResults}
                />
                <div className="hidden lg:block">
                  <SortInput
                    options={
                      activeResults === 'product'
                        ? [
                            {
                              label: 'DEFAULT',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}`
                            },
                            {
                              label: 'NAME: A-Z',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_az`
                            },
                            {
                              label: 'NAME: Z-A',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_za`
                            },
                            {
                              label: 'PRICE: LOWEST TO HIGHEST',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_price_asc`
                            },
                            {
                              label: 'PRICE: HIGHEST TO LOWEST',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_price_desc`
                            }
                          ]
                        : [
                            {
                              label: 'TITLE: A-Z',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_az`
                            },
                            {
                              label: 'TITLE: Z-A',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_za`
                            },
                            {
                              label: 'TYPE: A-Z',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_type_za`
                            },
                            {
                              label: 'TYPE: Z-A',
                              value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_type_az`
                            }
                          ]
                    }
                  />
                </div>
              </section>
            </HitsWithFacets>
          </section>
        </div>
      </AlgoliaContextProvider>
    </>
  )
}
