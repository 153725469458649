interface Props {
  handleClick?: () => void
  children: React.ReactNode
  className?: string
  id?: string
  selected?: boolean
}

const Option = ({ children, handleClick, className, id, selected }: Props) => {
  return (
    <div
      autoFocus
      id={id}
      tabIndex={0}
      role="option"
      data-test="select-option"
      className={`select ${className}`}
      aria-selected={selected}
      onClick={handleClick}
      onKeyDown={(e) => {
        e.key === 'Enter' && handleClick && handleClick()
      }}
    >
      {children}
    </div>
  )
}

export default Option
