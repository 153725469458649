'use client'

const ObjectComponent = ({ title, value }: { title: string; value: any }) => (
  <div className={`flex flex-nowrap justify-between gap-2`}>
    <p className="p font-bold">{title}</p>
    <p className="p">{value}</p>
  </div>
)

const getMetafieldView = (
  metafield: Metafield,
  directory: 'general' | 'components' = 'components'
) => {
  if (!metafield) return <></>

  let value: any = metafield.value

  if (metafield.type === 'json') {
    value = JSON.parse(metafield.value) as []
  }
  if (metafield.type === 'boolean') {
    value = metafield.value.toLowerCase() == 'true' ? 'Yes' : 'No'
  }

  const dataComponents = {
    wifi_enabled: <ObjectComponent title="Wifi Enabled PID Controller" value={value} />,
    grill_grate_material: <ObjectComponent title="Grill Grate Material" value={value} />,
    stainless_steel_ffs: (
      <ObjectComponent title="Stainless Steel Front-Folding Shelf" value={value} />
    ),
    stainless_steel_barrel: <ObjectComponent title="Stainless Steel Barrel" value={value} />,
    interior_lighting: <ObjectComponent title="Interior Lighting" value={value} />,
    cast_iron_deflector: <ObjectComponent title="Cast Iron Deflector" value={value} />,
    grill_grates: <ObjectComponent title="Number of Grill Grates" value={value} />,
    temperature_probes: <ObjectComponent title="Temperature Probes" value={value} />
  }
  const dataGeneral = {
    direct_flame: <ObjectComponent title="Direct Flame Grilling" value={value} />,
    warranty: <ObjectComponent title="Warranty (Yrs)" value={value} />,
    total_cooking_space: <ObjectComponent title="Total Cooking Space (Sq. In.)" value={value} />,
    total_space_with_second_shelf: (
      <ObjectComponent title="Total Space With Second Shelf" value={value} />
    ),
    min_temp: <ObjectComponent title="Minimum Temp (°F)" value={value} />,
    max_temp: <ObjectComponent title="Maximum Temp (°F)" value={value} />,
    riot_mode: <ObjectComponent title="Riot Mode" value={value} />,
    assembly_time: <ObjectComponent title="Assembly Time (Minutes)" value={value} />,
    hopper_capacity: <ObjectComponent title="Hopper Capacity (LBS)" value={value} />
  }
  if (
    !dataGeneral[metafield?.key as keyof typeof dataGeneral] &&
    !dataComponents[metafield?.key as keyof typeof dataComponents]
  ) {
    return <></>
  }

  return (
    <>
      {directory === 'general' && dataGeneral[metafield?.key as keyof typeof dataGeneral] ? (
        <div key={metafield.id} className={`container_list_color p-2 capitalize`}>
          {dataGeneral[metafield?.key as keyof typeof dataGeneral]}
        </div>
      ) : (
        <></>
      )}
      {directory === 'components' &&
      dataComponents[metafield?.key as keyof typeof dataComponents] ? (
        <div key={metafield.id} className={`container_list_color p-2 capitalize`}>
          {dataComponents[metafield?.key as keyof typeof dataComponents]}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const ProductSpecifications = ({ metafields }: { handle: string; metafields?: Metafield[] }) => {
  if (!metafields || metafields.length === 0) return <></>

  return (
    <section>
      <div className="divide-y lg:flex lg:flex-nowrap lg:gap-5 lg:divide-x lg:divide-y-0">
        <section className="w-full lg:w-1/2">
          <p className="h6 mb-4">General</p>
          <div className="flex flex-col">
            {metafields.map((metafield: Metafield) => {
              if (!metafield) return <></>
              return getMetafieldView(metafield, 'general')
            })}
          </div>
        </section>

        <section className="w-full pt-4 lg:w-1/2 lg:pl-8 lg:pt-0">
          <p className="h6 mb-4">Grill components</p>

          <div className="flex flex-col">
            {metafields.map((metafield: Metafield) => {
              if (!metafield) return <></>
              return getMetafieldView(metafield, 'components')
            })}
          </div>
        </section>
      </div>
    </section>
  )
}

export default ProductSpecifications
