'use client'
import { Button } from '@/components'

const CardTitle = ({ icon, title }: { icon: string; title: string | number }) => {
  return (
    <div className="flex flex-nowrap items-center gap-2">
      <span className="material-symbols-outlined rounded-full bg-secondary p-2 text-white">
        {icon}
      </span>
      <h5 className="h6">{title}</h5>
    </div>
  )
}
const CardValue = ({ value, subtitle }: { value: string | number; subtitle: string }) => {
  return (
    <div className="flex flex-col text-center">
      <h5 className="h3">{value}</h5>
      <p className="p">{subtitle}</p>
    </div>
  )
}
const CardAction = ({ text, action }: { text: string; action: () => void }) => {
  return (
    <div className="flex w-full justify-center">
      <Button size="normal" color="secondary" onClick={action}>
        {text}
      </Button>
    </div>
  )
}
export { CardTitle, CardValue, CardAction }
