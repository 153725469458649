'use client'
import { useFormStatus } from 'react-dom'
import { removeItem } from '@/actions/cart/cart.actions'
import { useCallback } from 'react'
import { useGtm } from '@/context'

const RemoveButton = ({ itemForId, iconSize }: { itemForId: string; iconSize: string }) => {
  const { pending } = useFormStatus()

  return (
    <button
      id="remove-item-button"
      data-test="cart-line-item-delete"
      aria-labelledby={`remove-item-button ${itemForId}`}
      className={`material-symbols-outlined ${iconSize} ${pending && 'animate-spin'}`}
    >
      {pending ? 'progress_activity' : 'delete'}
    </button>
  )
}

const DeleteItemButton = ({
  lineId,
  itemForId,
  additionalToDelete = [],
  iconSize = 'text-base'
}: {
  lineId: string
  itemForId: string
  additionalToDelete?: string[]
  iconSize?: string
}) => {
  const { fireEvent } = useGtm()

  const submitAction = useCallback(async () => {
    let removedIds = [lineId]
    additionalToDelete?.forEach((id) => removedIds.push(id))

    await removeItem(null, removedIds)
    fireEvent('removeFromCart', itemForId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalToDelete, lineId])

  return (
    <form action={submitAction} className="flex items-center justify-center">
      <RemoveButton iconSize={iconSize} itemForId={itemForId} />
    </form>
  )
}

export default DeleteItemButton
