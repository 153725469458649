'use client'
import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import { AddToCart, LoadingIndicator, ModuleHandler } from '@/components'
import { getProduct } from '@/lib/shopify/actions'
import { notFound } from 'next/navigation'

interface AcademyFormProps {
  product: Product
  title: string
  componentGlobalConfig?: Sanity.ComponentConfig
}

const AcademyForm: React.FC<AcademyFormProps> = ({
  product,
  title,
  componentGlobalConfig
}: AcademyFormProps) => {
  const [productObject, setProduct] = useState<Product>()
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()
  const [quantity, setQuantity] = useState(1)
  const [attendees, setAttendees] = useState<string[]>([''])
  const [subtotal, setSubtotal] = useState(0)
  const [displayPrice, setDisplayPrice] = useState(0)
  const [loading, setLoading] = useState(true)
  const quantityAvailable = selectedVariant?.quantityAvailable ?? 20

  useEffect(() => {
    async function fetchProduct() {
      const fetchedProduct = await getProduct(product.handle)
      if (!fetchedProduct) return notFound()
      setProduct(fetchedProduct)
      setSelectedVariant(fetchedProduct.variants[0]) // Select the default variant initially
      setLoading(false)
    }

    fetchProduct()
  }, [product])
  useEffect(() => {
    if (selectedVariant) {
      const price = parseFloat(String(selectedVariant.price.amount))
      setSubtotal(price * quantity)
      setDisplayPrice(price)
    }
  }, [selectedVariant, quantity])

  const handleVariantChange = (variantId: string) => {
    const variant = productObject?.variants.find((v) => v.id === variantId)
    if (variant) {
      setSelectedVariant(variant)
      const newQuantityAvailable = variant.quantityAvailable ?? 20

      setQuantity((prevQuantity) => {
        let newQuantity = prevQuantity

        if (prevQuantity > newQuantityAvailable) {
          newQuantity = newQuantityAvailable

          // Adjust the attendees array
          setAttendees((prevAttendees) => {
            // Slice the attendees array to match the new quantity
            return prevAttendees.slice(0, newQuantity)
          })
        }

        return newQuantity
      })
    }
  }
  const handleQuantityChange = (action: 'add' | 'remove') => {
    setQuantity((prevQuantity) => {
      let newQuantity = prevQuantity
      if (action === 'add' && prevQuantity < quantityAvailable) {
        newQuantity = prevQuantity + 1
      } else if (action === 'remove' && prevQuantity > 1) {
        newQuantity = prevQuantity - 1
      }

      // Adjust the attendees array
      setAttendees((prevAttendees) => {
        let newAttendees = [...prevAttendees]

        if (newQuantity > prevAttendees.length) {
          // Add empty strings to the beginning of attendees array
          newAttendees = ['', ...newAttendees]
        } else if (newQuantity < prevAttendees.length) {
          // Remove extra attendees from the beginning
          newAttendees = newAttendees.slice(1)
        }

        return newAttendees
      })

      return newQuantity
    })
  }
  // Remove empty strings from the attendees array
  const adjustedAttendees = attendees
    .map((attendee) => attendee.trim())
    .filter((attendee) => attendee !== '')

  if (loading) {
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <div
          id="academyForm"
          className="module-spacing w-full bg-secondary"
          data-component="academy-form"
        >
          <div className="container flex flex-col flex-wrap-reverse items-center justify-between py-10 lg:flex-row lg:py-16">
            <div className="absolute flex h-full w-full items-center justify-center">
              <LoadingIndicator isLoading={loading} />
            </div>
            <div className="w-full justify-around p-8 lg:w-1/2 lg:pr-16">
              <div className="mb-4 h-10 w-1/2 animate-pulse rounded bg-gray-300" />
              <div className="mb-4 h-8 w-1/3 animate-pulse rounded bg-gray-300" />
              <div className="mb-4 h-12 w-full animate-pulse rounded bg-gray-300" />
              <div className="mb-4 flex items-center space-x-2">
                <div className="h-10 w-10 animate-pulse rounded-full bg-gray-300" />
                <div className="h-10 w-10 animate-pulse rounded-full bg-gray-300" />
              </div>
              <div className="mb-4 h-12 w-full animate-pulse rounded bg-gray-300" />
              <div className="h-10 w-1/2 animate-pulse rounded bg-gray-300" />
            </div>
            <div className="mb-4 aspect-[5/3] max-h-full w-4/5 shadow-frame lg:w-1/2">
              <div className="h-full w-full animate-pulse rounded bg-gray-300" />
            </div>
          </div>
        </div>
      </ModuleHandler>
    )
  }
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <div className="module-spacing w-full bg-secondary">
        <div className="container flex flex-col flex-wrap-reverse items-center justify-between py-10 lg:flex-row lg:py-16">
          {/* Left Side: Form */}
          <div className="w-full justify-around p-8 lg:w-1/2 lg:pr-16">
            <h2 className="h2 mb-2 font-oswald text-4xl font-bold text-white sm:text-6xl">
              {title}
            </h2>
            <h4 className="text-med mb-4 font-oswald text-white lg:text-lg">
              ${displayPrice.toFixed(2)} + TAX - PER ATTENDEE
            </h4>
            {/* Variant Selector (Dates) */}
            <div className="mb-2 flex flex-row flex-wrap-reverse items-center justify-between">
              <select
                value={selectedVariant?.id}
                onChange={(e) => handleVariantChange(e.target.value)}
                className="w-1/2 min-w-fit border	pl-2 pr-6"
                aria-label="Select an event date"
              >
                {productObject?.variants.map((variant) => (
                  <option
                    key={variant.id}
                    value={variant.id}
                    disabled={variant.quantityAvailable <= 0}
                  >
                    {variant.title}
                    {variant.quantityAvailable <= 0 ? ' (Sold Out)' : ''}
                  </option>
                ))}
              </select>
              {/* Quantity Picker */}
              <div className="flex h-full items-center">
                <span className="mb-2 flex items-center sm:mx-2">
                  <QuantityButton as="remove" onClick={() => handleQuantityChange('remove')} />
                  <div>
                    <span
                      aria-live="polite"
                      data-test="mini-cart-quantity"
                      className="text-m px-2 text-center text-lg font-bold text-white sm:mx-2 sm:text-2xl"
                    >
                      {quantity}
                    </span>
                  </div>
                  <QuantityButton
                    as="add"
                    onClick={() => handleQuantityChange('add')}
                    disabled={quantity === quantityAvailable}
                  />
                </span>
              </div>
            </div>

            {/* Attendees Input */}
            <div className="mb-4 flex flex-col sm:h-36 sm:max-h-36 sm:overflow-y-auto">
              {attendees
                .map((attendee, index) => (
                  <input
                    key={index}
                    type="text"
                    value={attendee}
                    onChange={(e) => {
                      const newAttendees = [...attendees]
                      newAttendees[index] = e.target.value
                      setAttendees(newAttendees)
                    }}
                    placeholder={`Attendee Name ${attendees.length - index}`}
                    className="input"
                  />
                ))
                .reverse()}
            </div>
            {/* Subtotal */}
            <div className="mb-4 text-lg font-bold text-white">
              Subtotal: ${subtotal.toFixed(2)}
            </div>

            <AddToCart
              product={product}
              selectedVariant={selectedVariant || product.variants[0]}
              isPending={false}
              quantity={quantity}
              attributes={
                adjustedAttendees.length > 0
                  ? [{ key: 'Attendees', value: adjustedAttendees.join(', ') }]
                  : undefined
              }
            />
          </div>

          {/* Right Side: Image */}
          <div className="mb-4 aspect-[5/3] max-h-full w-4/5 shadow-frame lg:w-1/2">
            {selectedVariant?.image && (
              <Image
                src={selectedVariant.image.url}
                alt={product.title}
                width={600}
                height={600}
                className="aspect-[5/3] h-auto w-full object-cover"
              />
            )}
          </div>
        </div>
      </div>
    </ModuleHandler>
  )
}

export default AcademyForm

interface QuantityButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  as: 'add' | 'remove'
  className?: string
  onClick: () => void
  disabled?: boolean
}

const QuantityButton: React.FC<QuantityButtonProps> = ({ as, onClick, ...rest }) => {
  return (
    <div className={`flex aspect-1 items-center`}>
      <button
        {...rest}
        onClick={onClick}
        aria-label={as === 'add' ? 'Increase item quantity' : 'Reduce item quantity'}
        className={`material-symbols-outlined flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white duration-100 disabled:bg-tertiary disabled:opacity-50 sm:px-1`}
      >
        {as === 'add' ? 'add' : 'remove'}
      </button>
    </div>
  )
}
