'use client'
import { useEffect, useState } from 'react'

export const useWindowPosition = () => {
  const [position, setPosition] = useState(0)

  useEffect(() => {
    const getPosition = () => window.scrollY

    const onScroll = () => {
      setPosition(getPosition())
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return { position }
}
