import { ALGOLIA_OPERATORS, HITS_PER_PAGE } from '../constants'
import { getProductsById, getProductsLight } from '../lib'
import * as qs from 'qs'

type QueryProps = { source: 'query'; query: DataQuery[] }
type ManualProps = {
  source: 'manual'
  manualSelection: { product: { reference: { gid: string } } }[]
}

type General = {
  type: 'products' | 'content'
  size?: number
  connective?: 'AND' | 'OR'
  isRaysClub: boolean
  sortKey?: string
}

export type SanityAlgoliaQueryProps = (General & QueryProps) | (General & ManualProps)

const getDataSanityQuery = async (props: General & QueryProps) => {
  const { size, query, isRaysClub, connective, sortKey } = props

  if (!query) {
    return undefined
  }

  if (props.type === 'products') {
    let shopifyQuery: string[] = []

    query?.forEach((element: DataQuery) => {
      const operator = element.categoryOperation
      const queryString = `${element.categorySelector}:"${[element.categoryString]}"`

      if (operator === 'is_not') {
        shopifyQuery.push(`NOT ${queryString}`)
      } else {
        shopifyQuery.push(queryString)
      }
    })

    const parsedQuery =
      shopifyQuery.length > 0
        ? shopifyQuery.join(` ${connective ? connective : 'OR'} `)
        : shopifyQuery[0]

    const { products } = (await getProductsLight({
      query: `NOT (tag:Discontinued) AND NOT (tag:Hide From Search) AND ${parsedQuery} ${!isRaysClub ? 'AND NOT (tag:raysclub)' : ''}`,
      first: size,
      sortKey
    })) || { products: [] }

    const sortedProducts = sortKey
      ? products
      : products.sort((a, b) => parseInt(b?.rank?.value || '0') - parseInt(a?.rank?.value || '0'))

    return sortedProducts
  }

  if (props.type === 'content') {
    const searchterms = query
      ?.filter((query: DataQuery) => query.categoryOperation === 'includes')
      ?.map((query: DataQuery) => query.categoryString)

    const queries = query
      ?.filter((query: DataQuery) => query.categoryOperation !== 'includes')
      ?.map((query: DataQuery) => {
        const operator = query.categoryOperation
        const term = query.categoryString?.includes(' ')
          ? `"${query.categoryString}"`
          : query.categoryString

        let formatedQuery = `${query.categorySelector}${ALGOLIA_OPERATORS[query.categoryOperation as keyof typeof ALGOLIA_OPERATORS]}${term}`

        if (operator === 'is_not') {
          return `NOT ${formatedQuery}`
        }

        return formatedQuery
      })

    const contetQuery = qs.stringify({
      query: searchterms ? searchterms.join(' ') : '',
      indexName: process.env.NEXT_PUBLIC_ALGOLIA_CONTENT,
      params: {
        hitsPerPage: HITS_PER_PAGE,
        filters: `${queries.length > 0 ? queries.join(' AND ') : ''}`
      }
    })

    const res = await fetch(`/api/algolia-query/byIndex?${contetQuery}`, {
      next: { revalidate: 3600 }
    })

    const data = await res.json()

    const { hits } = data

    return hits
  }
}

const getDataSanityManual = async (props: General & ManualProps) => {
  if (props.type === 'products') {
    const { manualSelection } = props
    const productIds = manualSelection?.map((item) => item?.product?.reference?.gid)

    const { products } = await getProductsById({ ids: productIds })

    return products
  }
}

export async function getDataBySanityQuery(props: SanityAlgoliaQueryProps) {
  if (props.source === 'query') {
    return await getDataSanityQuery(props)
  }
  if (props.source === 'manual') {
    return await getDataSanityManual(props as General & ManualProps)
  }
}
