export const componentsFragment = /* GraphQL */ `
  fragment components on ProductVariantComponent {
    quantity
    productVariant {
      id
      sku
      title
      image {
        url
        altText
      }
      product {
        id
        availableForSale
        title
        featuredImage {
          ...image
        }
      }
    }
  }
`
