'use client'

import React, { useEffect, useState } from 'react'
import { QuestionItem, Pagination, LoadingIndicator } from '@/components'
import { fetchYotpoQuestionsPage } from '@/utils'

interface ProductQuestionsProps {
  productId: string
  questions: any
  // We can omit 'questions' prop or treat it as initial data if desired
  onAskQuestion?: () => void
}

export function ProductQuestions({ productId, questions, onAskQuestion }: ProductQuestionsProps) {
  const [questionsData, setQuestionsData] = useState<any[]>(questions?.questions)
  const [currentPage, setCurrentPage] = useState(Number(questions?.page))
  const [perPage] = useState(Number(questions?.per_page))
  const [totalQuestions, setTotalQuestions] = useState(questions?.total_questions)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    async function loadQuestions() {
      setLoading(true)
      setError(null)
      try {
        // call our new paginated function
        const data = await fetchYotpoQuestionsPage({
          productId,
          page: currentPage,
          perPage
        })

        const newQuestions = data?.questions || []

        setQuestionsData(newQuestions)
        setTotalQuestions(data?.total_questions)
      } catch (err) {
        setError('Failed to load questions.')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    loadQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage])

  function handlePageChange(newPage: number) {
    setCurrentPage(newPage)
  }

  if (loading) {
    return (
      <div className="flex min-h-[20rem] items-center justify-center">
        <LoadingIndicator isLoading />
      </div>
    )
  }
  if (error) {
    return (
      <div className="flex min-h-[20rem] items-center justify-center">
        <p className="p">{error}</p>
      </div>
    )
  }
  if (!questionsData || questionsData.length === 0) {
    return (
      <div className="flex min-h-[20rem] items-center justify-center">
        <p className="p text-center">No questions found.</p>
      </div>
    )
  }

  return (
    <div>
      <div className="mb-4 flex items-center gap-2">
        {/* On mobile, a button to open a "Submit a Question" form */}
        <button
          className="ml-auto block rounded bg-gray-300 px-3 py-1 text-sm text-black hover:bg-gray-400 md:hidden"
          onClick={onAskQuestion}
        >
          Submit a Question
        </button>
      </div>

      <div className="mb-4 overflow-y-auto pr-4 md:max-h-[35rem]">
        {questionsData.map((question) => (
          <QuestionItem key={question.id} question={question} />
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        perPage={perPage}
        total={totalQuestions}
        onPageChange={handlePageChange}
      />
    </div>
  )
}
