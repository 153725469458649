import { pageInfoFragment } from '../fragments/common'
import { filterFragment } from '../fragments/filters'
import {
  productFragment,
  productLightFragment,
  productPricingFragment,
  productComplementaryFragment
} from '../fragments/products'

export const getProductByIdQuery = /* GraphQL */ `
  query getProduct($id: ID!, $selectedOptions: [SelectedOptionInput!]!) {
    product(id: $id) {
      ...product
      variantBySelectedOptions(selectedOptions: $selectedOptions) {
        id
        title
        availableForSale
        currentlyNotInStock
        quantityAvailable
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        price {
          amount
          currencyCode
        }
        image {
          ...image
        }
      }
    }
  }
  ${productFragment}
`
export const getProductQuery = /* GraphQL */ `
  query getProduct($handle: String!, $selectedOptions: [SelectedOptionInput!]!) {
    product(handle: $handle) {
      ...product
      variantBySelectedOptions(selectedOptions: $selectedOptions) {
        id
        title
        availableForSale
        currentlyNotInStock
        quantityAvailable
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        price {
          amount
          currencyCode
        }
        image {
          ...image
        }
      }
    }
  }
  ${productFragment}
`
export const getProductLightQuery = /* GraphQL */ `
  query getProduct($handle: String!) {
    product(handle: $handle) {
      ...product
    }
  }
  ${productLightFragment}
`
export const getDynamicMetafieldsQuery = (metafields: { namespace: string; key: string }[]) => {
  let query: string = ``

  metafields.forEach((metafield, idx) => {
    if (idx === 0) {
      query = query + `[{namespace: "${metafield.namespace}", key: "${metafield.key}"}`
    } else if (metafields.length - 1 === idx) {
      query = query + ` {namespace: "${metafield.namespace}", key: "${metafield.key}"}]`
    } else {
      query = query + ` {namespace: "${metafield.namespace}", key: "${metafield.key}"}`
    }
  })

  return /* GraphQL */ `
    query getProduct($handle: String!) {
      product(handle: $handle) {
        id
        title
        variants(first: 1) {
          edges {
            node {
              id
              title
            }
          }
        }
        images(first: 1) {
          edges {
            node {
              id
              url
              altText
            }
          }
        }
        metafields: metafields(
          identifiers: ${query}
        ) {
          id
          key
          value
          type
        }
      }
    }
`
}
export const getProductMetafieldsQuery = /* GraphQL */ `
  query getProduct($handle: String!) {
    product(handle: $handle) {
      id
      title
      variants(first: 1) {
        edges {
          node {
            id
            title
          }
        }
      }
      images(first: 1) {
        edges {
          node {
            id
            url
            altText
          }
        }
      }
      metafields: metafields(
        identifiers: [
          { namespace: "custom", key: "wifi_enabled" }
          { namespace: "custom", key: "rank" }
          { namespace: "custom", key: "power_source" }
          { namespace: "custom", key: "fuel_source" }
          { namespace: "custom", key: "warranty" }
          { namespace: "custom", key: "assembly_time" }
          { namespace: "custom", key: "direct_flame" }
          { namespace: "custom", key: "temperature_probes" }
          { namespace: "custom", key: "hopper_capacity" }
          { namespace: "custom", key: "304_stainless_components" }
          { namespace: "custom", key: "stainless_steel_barrel" }
          { namespace: "custom", key: "interior_lighting" }
          { namespace: "custom", key: "ingredients" }
          { namespace: "custom", key: "grill_grate_material" }
          { namespace: "custom", key: "min_temp" }
          { namespace: "custom", key: "max_temp" }
          { namespace: "custom", key: "features_list" }
          { namespace: "custom", key: "complementary_products" }
        ]
      ) {
        id
        key
        value
        type
      }
    }
  }
`
export const getProductsQuery = /* GraphQL */ `
  query getProducts(
    $sortKey: ProductSortKeys
    $reverse: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $query: String
  ) {
    products(
      sortKey: $sortKey
      reverse: $reverse
      after: $after
      before: $before
      first: $first
      last: $last
      query: $query
    ) {
      edges {
        cursor
        node {
          ...product
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${productFragment}
`
export const getProductsLightQuery = /* GraphQL */ `
  query getProducts(
    $sortKey: ProductSortKeys
    $reverse: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $query: String
  ) {
    products(
      sortKey: $sortKey
      reverse: $reverse
      after: $after
      before: $before
      first: $first
      last: $last
      query: $query
    ) {
      edges {
        cursor
        node {
          ...product
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${productLightFragment}
`
export const getProductsByIdQuery = /* GraphQL */ `
  query getProductsById($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        ...product
      }
    }
  }
  ${productFragment}
`
export const getPricingInventoryByIdQuery = /* GraphQL */ `
  query getPricingInventoryById($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        ...productPricing
        ...productComplementary
      }
    }
  }
  ${productPricingFragment}
  ${productComplementaryFragment}
`
export const getSearchQuery = /* GraphQL */ `
  query searchProducts(
    $query: String!
    $productFilters: [ProductFilter!]
    $sortKey: SearchSortKeys
    $reverse: Boolean
  ) {
    search(
      query: $query
      first: 50
      types: PRODUCT
      productFilters: $productFilters
      sortKey: $sortKey
      reverse: $reverse
    ) {
      edges {
        node {
          ...product
        }
      }
      ...filters
      ...pageInfo

      totalCount
    }
  }
  ${productFragment}
  ${filterFragment}
  ${pageInfoFragment}
`
export const getProductRecommendationsQuery = /* GraphQL */ `
  query getProductRecommendations($productId: ID!) {
    productRecommendations(productId: $productId, intent: RELATED) {
      ...product
    }
  }
  ${productFragment}
`
