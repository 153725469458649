export function parseShopifyProductId(gid: string): string {
  // Safety check: if it’s already numeric, just return it
  if (/^\d+$/.test(gid)) {
    return gid
  }

  // Otherwise, split by '/' and return the last part
  const parts = gid?.split('/')
  if (parts) {
    return parts[parts.length - 1]
  } else {
    return gid
  }
}
