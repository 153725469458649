export function SampleComponent({
  title,
  body
}: Partial<{
  title: string
  body: any
}>) {
  if (!title) {
    return
  }

  return (
    <section className="my-4 w-full border">
      <div className="container">
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
    </section>
  )
}
