'use client'

import DeleteItemButton from '@/app/(store)/components/cart/delete-item-button'
import { parseMoneyFormat } from '@/app/(store)/utils'
import Image from 'next/image'
import Link from 'next/link'
import { PELLET_TWO_PACK } from '../../constants'

const CustomLineItem = ({
  isDrawer,
  bundlesWithSellingPlans
}: {
  bundlesWithSellingPlans?: CartItem[]
  isDrawer?: boolean
}) => {
  let customLines: {
    sellingPlan?: SellingPlan
    parentBundle?: ShopifyProduct
    lineItems: CartItem[]
  }[] = []

  const customSellingPlanBundleLineItem = bundlesWithSellingPlans?.reduce(
    (acc, curr) => {
      const currentSellingPlan = curr?.sellingPlanAllocation?.sellingPlan
      const currentBundle = curr?.merchandise?.groupedBy?.nodes.find(
        (node) => node.product.productType === PELLET_TWO_PACK
      )?.product

      const isOnList = acc.has(currentSellingPlan?.id || '')

      if (!currentSellingPlan) {
        return acc
      }
      if (!isOnList) {
        acc.set(currentSellingPlan.id, {
          sellingPlan: currentSellingPlan,
          parentBundle: currentBundle,
          lineItems: [curr]
        })
      } else {
        const existing = acc.get(currentSellingPlan.id)
        existing &&
          acc.set(currentSellingPlan.id, {
            ...existing,
            lineItems: [...existing?.lineItems, curr]
          })
      }

      return acc
    },
    new Map() as Map<
      string,
      { sellingPlan?: SellingPlan; parentBundle?: ShopifyProduct; lineItems: CartItem[] }
    >
  )

  customSellingPlanBundleLineItem?.forEach((line) => {
    customLines.push(line)
  })

  return (
    <>
      {customLines.map((line, idx) => {
        const calculatedCost = line.lineItems.reduce((acc, curr) => {
          return acc + parseFloat((curr.cost.totalAmount.amount as string) || '0')
        }, 0)

        const lineDiscountSavings =
          line.lineItems.reduce((acc, curr) => {
            const lineDiscounts = curr.discountAllocations.reduce((acc, curr) => {
              return acc + parseFloat(curr.discountedAmount.amount as string)
            }, 0)

            return acc + lineDiscounts
          }, 0) || 0

        const sellingPlanSavings =
          line?.sellingPlan?.priceAdjustments?.reduce((acc, curr) => {
            if (curr?.adjustmentValue?.adjustmentPercentage) {
              return (
                acc +
                ((((lineDiscountSavings ? lineDiscountSavings + calculatedCost : calculatedCost) *
                  100) /
                  (100 - curr?.adjustmentValue?.adjustmentPercentage)) *
                  curr?.adjustmentValue?.adjustmentPercentage) /
                  100
              )
            }
            const sellingPlanMultiplier = line.lineItems.reduce((acc, curr) => {
              return acc + curr.quantity
            }, 0)

            return (
              acc +
              parseFloat(curr?.adjustmentValue?.adjustmentAmount?.amount as string) *
                sellingPlanMultiplier
            )
          }, 0) || 0

        const calculatedCompare = calculatedCost + sellingPlanSavings + lineDiscountSavings

        return (
          <li key={line?.sellingPlan?.id || idx}>
            <article data-test="cart-product-line-item" className="py-4 lg:py-2">
              <div className="flex flex-row flex-nowrap gap-2 lg:gap-4">
                <div
                  data-test="cart-product-line-item-image"
                  className={`relative ${isDrawer ? 'w-[125px]' : ''}`}
                >
                  <Link
                    aria-hidden="true"
                    tabIndex={-1}
                    href={`${true ? '' : `/products/${line?.parentBundle?.handle}`}`}
                    className={`inline-block h-[125px] w-[125px] ${isDrawer ? '' : 'lg:h-[300px] lg:w-[300px] lg:min-w-[300px]'}`}
                  >
                    {line?.parentBundle?.featuredImage && (
                      <Image
                        src={line?.parentBundle?.featuredImage?.url}
                        fill
                        alt={line?.parentBundle?.featuredImage?.altText || ''}
                        className="object-contain"
                        sizes="(max-width: 650px) 90vw, 300px"
                      />
                    )}
                  </Link>
                </div>
                <div className="flex w-full items-center">
                  <div className="w-full">
                    <div className="flex w-full flex-row justify-between">
                      <div>
                        <p className="p text-sm text-gray-tertiary">{line?.sellingPlan?.name}</p>
                        <h2 className={`h6 ${isDrawer ? '' : 'lg:h4'}`}>
                          {line?.parentBundle?.title}
                        </h2>
                      </div>
                      <div>
                        <DeleteItemButton
                          lineId={line?.lineItems[0]?.id}
                          iconSize={false ? 'text-[1.3rem]' : 'text-[1.6rem]'}
                          itemForId={`product-cart-${line.lineItems[0].id}`}
                          additionalToDelete={
                            line.lineItems?.length > 0
                              ? line.lineItems.map((line) => line.id)
                              : undefined
                          }
                        />
                      </div>
                    </div>
                    <div className="relative flex w-full items-center justify-center text-secondary md:justify-start lg:w-auto dark:text-gray-tertiary">
                      <p className="p mr-2  block font-semibold text-primary dark:text-white ">
                        {parseMoneyFormat(calculatedCost)}
                      </p>
                      <p className="p text-gray-teritary block font-normal text-secondary line-through  dark:text-white">
                        {parseMoneyFormat(calculatedCompare)}
                      </p>
                      <div
                        className="ml-2 flex gap-1 uppercase text-white"
                        data-component="product-savings"
                      >
                        <div className="p-alt flex items-center gap-1 rounded bg-success p-1 text-xxs">
                          Save
                          <span className="">
                            {parseMoneyFormat(calculatedCompare - calculatedCost)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row flex-wrap gap-2">
                      {line.lineItems.map((item, idx) => {
                        return (
                          <div
                            key={item.id}
                            className={`p flex items-center text-xs text-gray-tertiary ${idx < line.lineItems.length - 1 ? 'border-r pr-2' : ''}`}
                          >
                            {item.merchandise.title}
                            {item.quantity > 1 && (
                              <span className="ml-1 text-xs text-gray-tertiary">
                                ({item.quantity})
                              </span>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </li>
        )
      })}
    </>
  )
}

export default CustomLineItem
