'use client'
import { parseLocaleDateTime, parseMoneyFormat } from '@/app/(store)/utils'
import Image from 'next/image'
import { Button } from '@/components'
import OrderDetail from './order-detail'
import { FALLBACK_IMAGE } from '@/app/(store)/constants'

const OrdersTable = ({ orders }: { orders?: Order[] }) => {
  return (
    <>
      <table data-test={`orders-table`} className="hidden text-secondary lg:inline-table">
        <thead>
          <tr className="capitalize">
            <th scope="col">image</th>
            <th scope="col"># items</th>
            <th scope="col"># order</th>
            <th scope="col">fulfillment status</th>
            <th scope="col">order date</th>
            <th scope="col">total</th>
            <th scope="col">details</th>
          </tr>
        </thead>
        <tbody className="p">
          {orders?.map((order) => {
            const { fulfillmentStatus, processedAt, totalPrice, lineItems, name, statusUrl } =
              order || {}

            const date = new Date(processedAt)
            const quantity = lineItems?.nodes?.reduce((acc, curr) => {
              return acc + curr?.quantity
            }, 0)

            return (
              <tr key={order.id} className="divide-x border text-center text-sm">
                <td className="">
                  <div className="relative mx-auto h-[120px] w-[120px]">
                    <Image
                      src={
                        lineItems?.nodes?.length > 0
                          ? lineItems.nodes[0].variant?.image?.url
                          : FALLBACK_IMAGE
                      }
                      alt={
                        lineItems?.nodes?.length > 0
                          ? lineItems?.nodes[0]?.variant?.image?.altText
                          : ''
                      }
                      fill
                    />
                  </div>
                </td>
                <td>{quantity}</td>
                <td>{name}</td>
                <td className={`lowecase ${fulfillmentStatus === 'CANCELED' && 'text-primary'}`}>
                  {fulfillmentStatus}
                </td>
                <td>{parseLocaleDateTime(date)}</td>
                <td className=" font-semibold">{parseMoneyFormat(totalPrice?.amount)}</td>
                <td>
                  <div className="flex justify-center gap-2">
                    <OrderDetail
                      cta={(handleClick) => (
                        <Button
                          onClick={() => {
                            handleClick()
                          }}
                          size="normal"
                          color="secondary"
                        >
                          view details
                        </Button>
                      )}
                      order={order}
                    />
                    <Button
                      size="normal"
                      color="primary"
                      renderButton={({ size, color }) => {
                        return (
                          <a className={`${size} ${color}`} href={statusUrl} target="_blank">
                            check my order
                          </a>
                        )
                      }}
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {(!orders || orders?.length === 0) && (
        <div className="flex justify-center">
          <h4 className="h5">You don&apos;t have any orders yet.</h4>
        </div>
      )}
      <section className="flex flex-col gap-4 text-secondary lg:hidden">
        {orders?.map((order) => {
          const { lineItems, processedAt, totalPrice, fulfillmentStatus } = order || {}
          const date = new Date(processedAt)
          const quantity = lineItems?.nodes?.reduce((acc, curr) => {
            return acc + curr?.quantity
          }, 0)
          return (
            <div key={order.id}>
              <div className="p flex justify-between bg-gray p-3">
                <p>Order {order.name}</p>
                <p>{quantity} items(s)</p>
              </div>
              <div>
                <div className="grid grid-cols-4 py-4">
                  <div className="relative col-span-2 aspect-1 min-h-[120px] min-w-[120px] max-w-[160px]">
                    <Image
                      src={
                        lineItems?.nodes?.length > 0
                          ? lineItems.nodes[0]?.variant?.image?.url
                          : FALLBACK_IMAGE
                      }
                      alt={
                        lineItems?.nodes?.length > 0
                          ? lineItems?.nodes[0]?.variant?.image?.altText
                          : ''
                      }
                      fill
                      className="object-cover"
                    />
                  </div>
                  <div className="col-span-2 flex flex-col justify-center gap-8">
                    <div className="flex flex-col items-end justify-center">
                      <p>{parseLocaleDateTime(date)}</p>
                      <p className="p-large font-bold">{parseMoneyFormat(totalPrice.amount)}</p>
                      <p>{fulfillmentStatus}</p>
                    </div>
                    <div className="col-span-4 flex justify-end">
                      <OrderDetail
                        cta={(handleClick) => (
                          <Button
                            onClick={() => {
                              handleClick()
                            }}
                            size="normal"
                            color="secondary"
                          >
                            view details
                          </Button>
                        )}
                        order={order}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </section>
    </>
  )
}

export default OrdersTable
