import { useProductPageContext } from '@/context'
import { ProductSellingPlans, Select } from '@/components'

interface Props {
  currentVariant: ProductVariant
  variants: ProductVariant[]
  options: ProductOption[]
  setQuery: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
}
const ProductSwatches = ({ options }: Props) => {
  const { currentVariant, variants, query, setQuery, product } = useProductPageContext()

  return (
    <>
      {variants.length > 1 &&
        options?.map((option, idx) => {
          const isDisabled =
            Object.values(query).length === idx || Object.values(query).length === options.length
          return (
            <div key={option.id} className="my-4 w-full" data-test={`product-variant-selector`}>
              <p className="p mb-2">{option.name}</p>
              <Select
                disabled={idx === 0 ? false : !isDisabled}
                placeholder={
                  currentVariant.selectedOptions.find((selected) => selected.name === option.name)
                    ?.value
                }
                onChange={({ value }) => {
                  setQuery((prev) => ({ ...prev, [option.name]: value }))
                }}
                inputStyles="py-3"
                options={option.values.map((value) => {
                  return {
                    label: value,
                    value: value
                  }
                })}
              />
            </div>
          )
        })}

      {product?.sellingPlanGroups?.nodes.length > 0 && <ProductSellingPlans />}
    </>
  )
}

export default ProductSwatches
