'use client'

import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { Fragment, useMemo, useState, useEffect } from 'react'
import { useIsDesktop, useWindowPosition } from '@/hooks'
import { usePathname, useSearchParams } from 'next/navigation'
import { checkIsItemActive, getHrefFromLinkType } from '@/app/(store)/utils'
import MenuLink from './menu-link'
import Link from 'next/link'
import Image from 'next/image'
import { oswald, lato } from '@/app/(store)/assets/fonts'
import SignOutButton from '../../buttons/sign-out-button'
import { useGtm, useHeaderContext } from '@/context'
import getUuid from 'uuid-by-string'

interface Props {
  logo: string
  menuItems: MenuItem[]
  subMenuItems?: MenuItem[]
  supportMenuItems?: SupportMenuItem[]
  hasAcitveSession?: boolean
}

const MobileMenu = ({
  logo,
  menuItems = [],
  subMenuItems = [],
  supportMenuItems = [],
  hasAcitveSession
}: Props) => {
  const { position } = useWindowPosition()
  const memoizedMenuItems = useMemo(() => menuItems, [menuItems])
  const { fireEvent } = useGtm()
  const isDesktop = useIsDesktop()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { customer } = useHeaderContext()
  const currentUrl =
    pathname + (searchParams && searchParams.toString() ? `?${searchParams.toString()}` : '')
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<MenuItem | null>(null)
  const [activeChild, setActiveChild] = useState<any[]>([])
  const isRaysClub = customer?.tags?.includes('Rays-Club')

  useEffect(() => {
    setIsOpen(false) // Close the menu on route change
    if (isOpen) {
      fireEvent('mobileMenuClosed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleClick = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      fireEvent('mobileMenuOpened')
    } else {
      fireEvent('mobileMenuClosed')
    }
  }

  const launchZendesk = () => {
    if (typeof window !== 'undefined' && window.zE) {
      const zE = window.zE || {}
      zE('messenger', 'open')
    }
  }

  const getNaviation = (items: MenuItem[], deep: number = 0) => {
    if (!items) return null
    if (items.length <= 0) return null

    return items.map((item, idx) => {
      if (!item) return <Fragment key={idx} />
      const itemKey = item.id || `${idx}-${item?.link?.path}`
      const isActive = activeItem === item // Check if the menu item is open
      const isSubMenuActive = activeChild.includes(item.id) // Check if the submenu is open
      const isActiveItem = checkIsItemActive(item, currentUrl)
      const rotateClass = isActive ? 'rotate-90' : ''
      const subMenuRotateClass = isSubMenuActive ? 'rotate-90' : ''

      if (!item?.children && item?.link?.path) {
        if (item.link.path.includes('http')) {
          return (
            <a
              key={itemKey}
              data-test="desktop-menu-link"
              href={item.link.path}
              className={`${deep > 0 ? 'p text-secondary' : 'nav-link'} uppercase`}
              target={item?.link?.blank ? '_blank' : '_self'}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              {item.link.title}
            </a>
          )
        }
        return (
          <MenuLink
            key={itemKey}
            title={item?.link?.title}
            path={item?.link?.path[0] === '/' ? `${item.link.path}` : `/${item.link.path}`}
            deep={deep}
            isactive={isActiveItem}
            setIsOpen={setIsOpen}
          />
        )
      }

      if (deep > 0) {
        return (
          <Fragment key={itemKey}>
            <button
              onClick={() => {
                if (deep === 0) {
                  setActiveItem(item)
                  if (activeItem === item) {
                    setActiveItem(null)
                  }
                }

                if (deep > 0) {
                  setActiveChild([...activeChild, item.id])

                  if (activeChild.includes(item.id)) {
                    setActiveChild((prev) => prev.filter((id) => id !== item.id))
                  }
                }
              }}
              className="container flex w-full items-center justify-between bg-gray text-left"
            >
              <MenuLink
                key={itemKey}
                title={item?.link?.title || ''}
                path={item?.link?.path}
                deep={deep}
                isactive={isActiveItem}
              />

              <span
                aria-hidden="true"
                className={`material-symbols-outlined icon size-3 text-secondary duration-200 ${subMenuRotateClass}`}
              >
                arrow_forward_ios
              </span>
            </button>

            <Transition
              appear
              show={
                activeItem === item || (activeChild?.length > 0 && activeChild?.includes(item.id))
              }
            >
              <div>
                {item?.children ? (
                  <div className="divide-form divide-y">
                    {getNaviation(item?.children, deep + 1)}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Transition>
          </Fragment>
        )
      }

      return (
        <Fragment key={itemKey}>
          <button
            key={itemKey}
            className="container flex w-full items-center justify-between bg-white text-left"
            onClick={() => {
              if (deep === 0) {
                setActiveItem(item)
                fireEvent('menuExpanded')
                if (activeItem === item) {
                  setActiveItem(null)
                  fireEvent('menuCollapsed')
                }
              }

              if (deep > 0) {
                setActiveChild([...activeChild, item.id])
                fireEvent('subMenuExpanded')
                if (activeChild.includes(item.id)) {
                  setActiveChild((prev) => prev.filter((id) => id !== item.id))
                  fireEvent('subMenuCollapsed')
                }
              }
            }}
          >
            <MenuLink
              key={itemKey}
              title={item?.link?.title || ''}
              deep={deep}
              isactive={isActiveItem}
            />

            <span
              aria-hidden="true"
              className={`material-symbols-outlined icon size-3 text-secondary duration-200 ${rotateClass}`}
            >
              arrow_forward_ios
            </span>
          </button>

          <Transition
            appear
            show={
              activeItem === item || (activeChild?.length > 0 && activeChild?.includes(item.id))
            }
          >
            <div>
              {item?.children ? (
                <div className="divide-form divide-y">{getNaviation(item?.children, deep + 1)}</div>
              ) : (
                <></>
              )}
            </div>
          </Transition>
        </Fragment>
      )
    })
  }

  return (
    <>
      {!isDesktop && (
        <section className="flex items-center">
          <button onClick={handleClick} aria-label="Menu" className="leading-[0]">
            {!isOpen ? (
              <span aria-hidden="true" className="material-symbols-outlined">
                menu
              </span>
            ) : (
              <span aria-hidden="true" className="material-symbols-outlined">
                close
              </span>
            )}
          </button>

          <Transition as={'div'} show={isOpen} appear>
            <Dialog onClose={handleClick} className="relative z-50">
              <DialogPanel
                className={`fixed inset-0 top-0 z-[11] flex h-[100svh] flex-col overflow-hidden uppercase ${oswald.variable} ${lato.variable}`}
              >
                <TransitionChild
                  as={'div'}
                  enter="transition-all ease-in duration-50 delay-100"
                  enterFrom={`${position < 80 ? 'translate-y-10 ' : ''}`}
                  enterTo="translate-y-0"
                  leave="transition-all ease-in duration-100"
                  leaveFrom=""
                  leaveTo={`${position < 80 ? 'translate-y-10' : ''}`}
                >
                  <div className="flex min-h-[80px] items-center bg-secondary text-white">
                    <div className="container flex w-full items-center justify-between">
                      <div className="flex w-full items-center gap-14">
                        {logo && (
                          <Link href="/">
                            <Image
                              src={logo}
                              width={200}
                              height={75}
                              alt="Recteq Logo"
                              priority
                              className="w-full max-w-32 lg:max-w-40"
                            />
                          </Link>
                        )}
                      </div>

                      <div className="flex items-center gap-6">
                        <button
                          onClick={handleClick}
                          aria-label="Menu button"
                          className="leading-[0]"
                          data-test="mobile-menu-close-button"
                        >
                          <span aria-hidden="true" className="material-symbols-outlined">
                            close
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </TransitionChild>
                <TransitionChild
                  as={'div'}
                  enter="transition-all ease-in duration-200"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition-all ease-in duration-100"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full opacity-0"
                >
                  <div className="h-[calc(100svh-80px)] overflow-hidden overflow-y-auto bg-neutral-100">
                    <nav
                      aria-label="Main menu"
                      className="flex flex-col divide-y divide-gray-divide bg-white"
                    >
                      {getNaviation(memoizedMenuItems)}
                    </nav>
                    <hr />
                    <nav
                      aria-label="Submenu"
                      className="flex flex-col divide-y divide-gray-divide bg-white"
                    >
                      {subMenuItems &&
                        subMenuItems.map((item, idx) => {
                          if (!item?.link?.path)
                            return (
                              <Fragment
                                key={getUuid(item.id + idx.toString() + item?.link?.path)}
                              ></Fragment>
                            )

                          return (
                            <Link
                              key={getUuid(item.id + idx.toString() + item?.link?.path)}
                              data-test="mobile-header-submenu-link"
                              href={item.link.path}
                              className="h5 p-4"
                            >
                              {item.link.title}
                            </Link>
                          )
                        })}
                      {!hasAcitveSession ? (
                        <Link
                          href="/account/login"
                          data-test="mobile-header-submenu-link"
                          className="h5 p-4"
                        >
                          Login
                        </Link>
                      ) : (
                        <>
                          <Link href="/account/dashboard" className="h5 p-4">
                            My Account
                          </Link>
                          {isRaysClub && (
                            <Link href="/rays-club" className="h5 p-4">
                              Ray&rsquo;s Club
                            </Link>
                          )}
                          <SignOutButton className="p-4" asMobileButton={true} />
                        </>
                      )}
                    </nav>
                    <nav
                      aria-label="Main menu"
                      className="flex flex-col divide-y divide-gray-divide pt-4"
                    >
                      {supportMenuItems &&
                        supportMenuItems.map(({ id, icon, title, subtitle, link }, idx) => {
                          if (link?.type == 'external' && link.url == 'zendesk') {
                            return (
                              <div
                                key={getUuid(title || id.toString() || '') || idx.toString()}
                                className="container flex items-center gap-6 py-3"
                              >
                                <span
                                  aria-hidden="true"
                                  className="material-symbols-outlined text-[24px]"
                                >
                                  {icon}
                                </span>
                                <div>
                                  <button onClick={launchZendesk}>
                                    <h5 className="h6 block text-left">{title}</h5>
                                    <p className="p text-left text-sm uppercase">{subtitle}</p>
                                  </button>
                                </div>
                              </div>
                            )
                          }
                          return (
                            <div
                              key={
                                getUuid(title || id.toString() || link?.type || '') ||
                                idx.toString()
                              }
                              className="container flex items-center gap-6 py-3"
                            >
                              <span
                                aria-hidden="true"
                                className="material-symbols-outlined text-[24px]"
                              >
                                {icon}
                              </span>
                              <div>
                                {link?.type ? (
                                  <Link
                                    data-test="mobile-header-support-menu-link"
                                    href={getHrefFromLinkType(link)}
                                    className="h6 block"
                                  >
                                    {title}
                                  </Link>
                                ) : (
                                  <h5 className="h6 block">{title}</h5>
                                )}
                                {link?.type ? (
                                  <Link
                                    data-test="mobile-header-support-menu-link"
                                    href={getHrefFromLinkType(link)}
                                    className="p text-sm"
                                  >
                                    {subtitle}
                                  </Link>
                                ) : (
                                  <p className="p text-sm">{subtitle}</p>
                                )}
                              </div>
                            </div>
                          )
                        })}
                    </nav>
                  </div>
                </TransitionChild>
              </DialogPanel>
            </Dialog>
          </Transition>
        </section>
      )}
    </>
  )
}

export default MobileMenu
