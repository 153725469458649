import { SanityLink } from '@/components'

interface Props {
  title: string
  links: MenuItem[]
}
const SectionMenu = ({ links, title }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <h3 className="h4">{title}</h3>

      <nav className="p text-gray-secondary">
        <ul className="flex flex-col gap-2">
          {links?.map(({ id, link }, idx) => {
            const target = link?.blank ? '_blank' : '_self'
            return (
              <li key={id + idx.toString()}>
                <SanityLink href={link?.path || ''} target={target}>
                  {link?.title}
                </SanityLink>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

export default SectionMenu
