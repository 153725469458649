export const getReadableTime = (prepTime: { hours?: number; minutes: number }) => {
  if (!prepTime?.hours && !prepTime?.minutes) return ''

  let hours: number | string = 0
  let minutes: number | string = 0

  if (prepTime?.hours && prepTime?.hours > 1) {
    hours = `${prepTime.hours} hrs`
  } else if (prepTime.hours === 1) {
    hours = `${prepTime.hours} hrs`
  }

  if (prepTime.minutes > 1) {
    minutes = `${prepTime.minutes} mins`
  } else if (prepTime.minutes === 1) {
    minutes = `${prepTime.minutes} mins`
  }

  return `${hours ? hours + ' ' : ''}${minutes ? minutes : ''}`
}

const DATE_UNITS = {
  month: 2678400,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1
}

const getSecondsDiff = (timestamp: number) => {
  return (Date.now() - timestamp) / 1000
}

const getUnitAndValueDate = (secondsElapsed: number) => {
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsElapsed / DATE_UNITS.month > 1) {
      const value = Math.floor(secondsElapsed / DATE_UNITS.month) * -1

      return { value, unit: 'month' } as { value: number; unit: keyof typeof DATE_UNITS }
    }
    if (secondsElapsed >= secondsInUnit || unit === 'second') {
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1
      return { value, unit } as { value: number; unit: keyof typeof DATE_UNITS }
    }
  }
}

export const getTimeAgo = (timestamp: number) => {
  const rtf = new Intl.RelativeTimeFormat('en', {
    style: 'short'
  })

  const secondsElapsed = getSecondsDiff(timestamp)
  const { value, unit } = getUnitAndValueDate(secondsElapsed) || {}

  return value && unit ? rtf.format(value, unit) : ''
}

export const parseLocaleDateTime = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  return date.toLocaleDateString(
    'en-US',
    options || { year: 'numeric', month: 'long', day: '2-digit' }
  )
}
