import toast from 'react-hot-toast'

export const accessibleErrorToast = (message: string) =>
  toast.error(message, {
    ariaProps: {
      role: 'alert',
      'aria-live': 'assertive'
    }
  })

export const accessibleSuccessToast = (message: string) =>
  toast.success(message, {
    ariaProps: {
      role: 'alert',
      'aria-live': 'assertive'
    }
  })
