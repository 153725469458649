'use client'
import 'swiper/css'
import 'swiper/css/pagination'

import { PortableTextBlock } from 'next-sanity'
import { RichText, CTALayout, CTA, SanityMedia, ModuleHandler } from '@/components'
import dynamic from 'next/dynamic'
enum MediaPosition {
  left = 'order-2',
  right = ''
}
enum ContentAlign {
  left = 'text-left',
  right = 'text-right items-end'
}

enum BackgroundColors {
  none = 'bg-none text-secondary',
  primary = 'bg-primary text-white',
  secondary = 'bg-secondary text-white',
  tertiary = 'bg-tertiary text-white',
  gray = 'bg-gray-secondary text-secondary',
  'gray-lighter' = 'bg-gray text-secondary'
}
enum MediaStyle {
  full = 'realtive mt-3 h-full w-full lg:mt-0',
  box = 'relative h-full'
}

interface Props {
  _key: string
  mediaPosition: keyof typeof MediaPosition
  backgroundColor?: keyof typeof BackgroundColors
  mediaStyle: keyof typeof MediaStyle

  contentAlign?: keyof typeof ContentAlign
  showNavigation?: boolean
  showPagination?: boolean
  delay?: boolean
  paginationTheme?: 'light' | 'dark'
  content: {
    cta: {
      link: Sanity.Link
    } & CTALayout
    media: Sanity.Media
    title: string
    subtitle: string
    body?: PortableTextBlock[]
  }[]
  componentGlobalConfig?: Sanity.ComponentConfig
}

const TwoColumnCallout = (props: Props) => {
  const {
    content,
    backgroundColor = 'secondary',
    contentAlign = 'left',
    mediaPosition = 'left',
    mediaStyle = 'full',
    showNavigation = false,
    showPagination = true,
    delay = false,
    paginationTheme,
    componentGlobalConfig
  } = props || {}

  const Carousel = dynamic(() => import('../carousel').then((mod) => mod.Carousel), {
    ssr: false,
    loading: () => (
      <section className="module-spacing container animate-pulse py-5 lg:px-12">
        <div className={`flex w-full flex-col py-8 ${contentAlign === 'right' ? 'items-end' : ''}`}>
          <div className="mb-2 h-[25px] w-1/4 bg-gray" />
          <div className="mb-6 h-[115px] w-3/4 bg-gray" />
          <div className="mb-6 h-[180px] w-full bg-gray" />
          <div className="h-[42px] w-1/4 bg-gray" />
        </div>
      </section>
    )
  })

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section
        className={`module-spacing w-full lg:min-h-[400px]`}
        data-component="two-col-callout"
      >
        <div className={`${BackgroundColors[backgroundColor]} px-4 lg:px-0`}>
          <Carousel
            loop={false}
            delay={delay}
            slidesPerView={1}
            mobileSlidesPerView={1}
            showNavigation={showNavigation}
            showPagination={showPagination}
            paginationStyles={paginationTheme}
            controlsContained={true}
            effect="fade"
            embedded={true}
            items={content?.map((item, index) => {
              return (
                <div
                  key={`carousel-${index}`}
                  className={`${BackgroundColors[backgroundColor]} ${backgroundColor === 'none' ? '' : 'lg:px-4'} lg:grid lg:h-full lg:grid-cols-12`}
                >
                  <div
                    key={index}
                    className={`col-span-12 lg:px-10 ${backgroundColor === 'none' ? 'lg:py-5' : 'py-5'} flex flex-wrap items-center lg:col-span-5 lg:min-h-[30rem] lg:py-12 ${MediaPosition[mediaPosition]} ${ContentAlign[contentAlign]}`}
                  >
                    <div>
                      <span className="p-alt uppercase text-inherit">{item.subtitle}</span>
                      <h2 className="h1 mb-5">{item.title}</h2>
                      {item.body && (
                        <RichText className={`mb-5 text-inherit`} content={item.body} />
                      )}
                      {item?.cta?.link?.text && (
                        <CTA
                          as="button-link"
                          link={item?.cta.link}
                          layout={{ color: item?.cta?.color, size: item?.cta?.size }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`${BackgroundColors[backgroundColor]} relative col-span-12 grid h-full min-h-[50svw] w-full lg:col-span-7 lg:h-full lg:min-h-[400px]`}
                  >
                    <div
                      className={`flex w-full items-center justify-center ${mediaPosition === 'left' ? 'lg:justify-start' : 'lg:justify-end'} self-center ${mediaStyle == 'box' ? 'pb-4 pr-4' : ''} ${MediaStyle[mediaStyle]} `}
                    >
                      <SanityMedia
                        blend={false}
                        media={item?.media}
                        aspectRatio="default"
                        imageClass={`lg:object-cover lg:h-full ${mediaStyle === 'box' ? 'border-[12px] border-white drop-shadow-xl' : ''} `}
                        height={600}
                        width={600}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          />
        </div>
      </section>
    </ModuleHandler>
  )
}

export default TwoColumnCallout
