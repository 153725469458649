enum BgColors {
  primary = 'bg-tertiary text-white',
  secondary = 'bg-secondary text-white',
  tertiary = 'bg-primary text-white',
  gray = 'bg-gray',
  none = ''
}
const Address = ({
  bgColor = 'none',
  addressType,
  name,
  address1,
  address2,
  countryCodeV2,
  city,
  zip,
  country
}: {
  bgColor?: keyof typeof BgColors
  addressType?: string
  name: string
  address1: string
  address2: string
  countryCodeV2: string
  city: string
  zip: string
  country: string
}) => {
  return (
    <div data-test="address" className={`p ${BgColors[bgColor]} p-4`}>
      {addressType && <p className="mb-4 font-bold capitalize">{addressType} Address</p>}
      {name && <p>{name}</p>}
      <p>
        <span>{address1}</span>
        {address2 && <span>, {address2} </span>}
      </p>
      <p>
        {city && <span>{city} </span>}
        {countryCodeV2 && <span>{countryCodeV2} </span>}
        {zip && <span>{zip}</span>}
      </p>
      {country && <p>{country}</p>}
    </div>
  )
}

export default Address
