'use client'
import { useState, useEffect, useMemo, useCallback } from 'react'
import * as qs from 'qs'
import { ContentFacets, fetchSanity } from '../lib'
import { HITS_PER_PAGE, RAYSCLUB_TAG } from '../constants'
import { useCustomerContext } from '../context'

export const useAlgoliaContent = ({
  search,
  sorting,
  type,
  preventFetching,
  searchParams
}: {
  search: string
  sorting?: string
  type?: string
  preventFetching?: boolean
  searchParams?: Record<string, string | string[]>
}) => {
  const { isRaysClub } = useCustomerContext()
  const [metafields, setMetafields] = useState([])

  const [content, setContent] = useState<AlgoliaResponse<AlgoliaContent>>({
    page: 0,
    hits: [],
    queryID: '',
    facets: {},
    initialFacets: {},
    loading: true,
    nbHits: 0,
    nbPages: 0
  })
  /*
   * Removing the Search term from query params
   * - productqueries prop inside AlgoliaProductSearch requieres to receive only facets
   */
  const facetsOnly = useMemo(
    () => Object.entries(searchParams || []).filter((item) => item[0] != 'q'),
    [searchParams]
  )

  const getMetafields = useCallback(async () => {
    const { contentFacets } = (await fetchSanity(ContentFacets)) || []

    const metafields = contentFacets?.map(({ metafield, type, unit, icon }: any) => ({
      type,
      unit,
      icon,
      name: metafield?.data?.field_name,
      field: metafield?.data
    }))

    setMetafields(metafields)
    return metafields
  }, [])

  const getContentResults = useCallback(
    async (page?: number) => {
      const localMetafields = metafields?.length > 0 ? metafields : await getMetafields()

      setContent((prev: any) => ({ ...prev, loading: true, sanityConfig: localMetafields }))

      const recteqQueries = facetsOnly
        ?.filter(([key]) => key.includes('rq_'))
        ?.map(([key, value]) => [key.replace('rq_', ''), value])

      const filteredFacets = facetsOnly?.filter(([key]) =>
        localMetafields.map((metafield: any) => metafield?.field?.field_name).includes(key)
      )

      const contentQueries = [...recteqQueries, ...filteredFacets]?.map(([key, input]) => {
        if (typeof input === 'object') {
          return input.map((value) => `${key}:${value}`)
        }

        try {
          const json = JSON.parse(input)

          return Object.keys(json).length > 0 ? JSON.parse(input) : `${key}: ${input}`
        } catch (e) {
          return `${key}:${input}`
        }
      })

      const ranges = contentQueries
        .filter((value) => value?.type === 'range')
        .map((range) => `${range?.name}:${range?.value}`)
        .filter((item) => item)

      const decodedQueries = contentQueries
        .map((item) => (typeof item === 'string' ? decodeURIComponent(item) : item))
        .map((item) => {
          return item
        })
        .filter((item) => !item?.type)

      let filters = []

      if (!isRaysClub) {
        filters.push(`NOT allowedUserType:${RAYSCLUB_TAG}`)
      }

      if (type) {
        filters.push(`_type:${type}`)
      }

      if (ranges.length > 0) {
        ranges.forEach((range) => filters.push(range))
      }

      const productQuery = qs.stringify({
        query: search,
        indexName: sorting || process.env.NEXT_PUBLIC_ALGOLIA_CONTENT,
        params: {
          hitsPerPage: HITS_PER_PAGE,
          filters: filters.join(' AND '),
          facets: localMetafields?.map(({ field }: any) => field?.field_name),
          page: page || content.page,
          facetFilters: decodedQueries
        }
      })
      const res = await fetch(`/api/algolia-query/byIndex?${productQuery}`, {})
      const data = await res.json()
      const { hits, queryID } = data

      setContent((prev: any) => {
        const prevInitial = prev?.initialFacets
        const metafields = localMetafields.map((metafield: any) => metafield.name)

        let saved: any = {}

        metafields.forEach((range: any) => {
          saved[range] = prevInitial[range] || data?.facets[range]
        })

        const newInitial = {
          ...data?.facets,
          ...saved
        }

        return {
          ...data,
          initialFacets: newInitial,
          hits: [...prev.hits, ...hits],
          queryID,
          sanityConfig: localMetafields,
          loading: false
        }
      })
    },
    [search, content.page, type, facetsOnly, sorting, metafields, getMetafields, isRaysClub]
  )

  useEffect(() => {
    if (!preventFetching) {
      setContent((prev: any) => ({ ...prev, page: 0, hits: [] }))
      getContentResults()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sorting, searchParams])

  const getNextContentPage = () => {
    if (content.page + 1 <= content.nbPages) {
      getContentResults(content.page + 1)
    }
  }
  return {
    content,
    metafields,
    getNextContentPage
  }
}
