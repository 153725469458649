export const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith) ? stringToCheck : `${startsWith}${stringToCheck}`

/*
 * Phone number utilities formatting for Shopify customer.phone property
 * formatPhoneNumberView - Removes country code out of the phone number, formats as (xxx) xxx xxxx
 * cleanPhoneNumberFormat - Removes formatting created by formatPhoneNumberView, but doesn't return country code
 */
export const formatPhoneNumberView = (phoneNumber: string) => {
  let cleanedUpNumber = phoneNumber

  if (cleanPhoneNumberFormat(phoneNumber).length > 10) {
    cleanedUpNumber = cleanPhoneNumberFormat(phoneNumber).slice(
      phoneNumber.length - 11,
      phoneNumber.length
    )
  }
  return cleanPhoneNumberFormat(cleanedUpNumber)
    .replace(/^(\d{3})(\d{0,3})(\d{0,4})$/, '($1) $2 $3')
    .trim()
}

export const cleanPhoneNumberFormat = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '').trim()
}
