const Sku = ({ merchandise }: { merchandise: ProductVariant }) => {
  const { product, requiresComponents, sku, id } = merchandise
  // const bundleSkus = requiresComponents
  //   ? product.variants.edges[0].node.components.nodes.map(({ productVariant: { sku } }) => sku)
  //   : []
  const getBundleSkus = () => {
    if (!requiresComponents) return []
    // Attempt to find the variant whose ID matches the merchandise ID
    const matchingVariantEdge = product.variants.edges.find((edge) => edge.node.id === id)
    // Use the matching variant's component SKUs if available
    if (matchingVariantEdge) {
      return matchingVariantEdge.node.components.nodes.map(({ productVariant: { sku } }) => sku)
    }
    // Fallback to the default behavior if no match is found
    return product.variants.edges[0].node.components.nodes.map(({ productVariant: { sku } }) => sku)
  }

  const bundleSkus = getBundleSkus()
  return (
    <>
      {sku || bundleSkus.length ? (
        <div>
          <p
            title={requiresComponents ? bundleSkus.filter((item) => item !== '').join(' / ') : sku}
            className="p line-clamp-1 w-full text-xs text-gray-tertiary"
          >
            {requiresComponents ? (
              <>SKU: {bundleSkus.filter((item) => item !== '').join(' / ')}</>
            ) : (
              <>SKU: {sku}</>
            )}
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Sku
