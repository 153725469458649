enum Colors {
  primary = 'bg-primary text-white border-[3px] border-primary',
  secondary = 'bg-secondary text-white border-[3px] border-secondary',
  'primary-outline' = 'bg-white text-primary border-[3px] border-primary',
  'secondary-outline' = 'bg-white text-secondary border-[3px] border-secondary'
}

interface Props {
  children: React.ReactElement | string
  color?: keyof typeof Colors
  className?: string
}
const Badge = ({ color = 'primary', children, className }: Props) => {
  return (
    <span
      data-test="badge"
      className={`line-clamp-1 inline-block text-nowrap px-2 py-1 font-oswald text-sm	 uppercase duration-100 lg:px-4 lg:py-1 ${Colors[color]} ${className} `}
    >
      {children}
    </span>
  )
}
export default Badge
