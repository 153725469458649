'use client'
import { notFound, useRouter } from 'next/navigation'
import { RichText } from '@/components'
import { isCustomerAllowed } from '../utils/customers'

const CustomerHandler = ({
  children,
  customerTypes,
  page,
  site
}: {
  children: React.ReactNode
  customerTypes?: string[]
  customerId?: string
  page: Sanity.Page | Sanity.Recipe | Sanity.Article
  site: Sanity.Settings
}) => {
  const allowedUsers = page?.allowedUserType || []
  const messages = site?.unauthorizedMessage
  const router = useRouter()

  let isAllowed = isCustomerAllowed(allowedUsers, customerTypes || [])

  allowedUsers?.forEach((role) => {
    if (customerTypes?.includes(role) || role === 'Standard') {
      isAllowed = true
    }
  })

  let finalMessage

  if (allowedUsers.length > 0 && !isAllowed) {
    if (allowedUsers?.includes('Business')) {
      finalMessage = messages.businessUsers
    } else if (allowedUsers?.includes('Rays-Club')) {
      finalMessage = messages.raysClubsUsers
      router.push('/rays-club')
    } else if (allowedUsers?.includes('None')) {
      notFound()
    } else if (customerTypes?.length === 0 || !customerTypes) {
      finalMessage = messages?.standardUsers
    } else {
      finalMessage = messages?.unauthenticated || []
    }
    return (
      <>
        <section className="container mb-5 py-2">
          <h2 className="h2">403</h2>
          <p className="p text-left uppercase">Authorization error</p>
          <div className="module-spacing flex h-full w-full items-center justify-start py-10">
            <RichText content={finalMessage} />
          </div>
        </section>
        <section className="container mb-5 pb-5">
          <div className="p mt-6">
            <h4 className="h4">order by phone:</h4>
            <p className="mb-4 mt-2 uppercase">
              Having trouble ordering online? Give us a call. one of our customer service
              representatives will be happy to assist you.
            </p>
            <h5 className="h4 mb-2">(706) 922-0890</h5>
            <p>Mon - Fri: 9am - 7pm ET</p>
            <p>Sat: 9am - 3pm ET</p>
          </div>
        </section>
      </>
    )
  }

  return <>{children}</>
}

export default CustomerHandler
