'use client'
import { Transition } from '@headlessui/react'
import { usePathname, useSearchParams } from 'next/navigation'
import { Fragment } from 'react'
import { useHeaderContext, useGtm } from '@/context'
import { checkIsItemActive } from '@/app/(store)/utils'
import getUuidByString from 'uuid-by-string'
import SanityLink from '../link'
interface Props {
  menuItems: MenuItem[]
}

const DesktopMenu = ({ menuItems }: Props) => {
  const {
    activeItem,
    setActiveItem,
    activeChild,
    setActiveChild,
    setIsTransparent,
    defaultTransparent
  } = useHeaderContext()

  const pathname = usePathname()
  const searchParams = useSearchParams()
  const currentUrl =
    pathname + (searchParams && searchParams.toString() ? `?${searchParams.toString()}` : '')

  const { fireEvent } = useGtm()

  const getNaviation = (items: MenuItem[], deep: number = 0) => {
    if (!items) return null
    if (items.length <= 0) return null
    return items?.map((item, idx) => {
      const isItemActive = activeItem === item || activeChild?.includes(item.id)

      if (!item) return <Fragment key={getUuidByString(idx.toString())} />
      const isActive = checkIsItemActive(item, currentUrl)

      if (!item?.children && item?.link?.path) {
        return (
          <div
            tabIndex={1}
            key={getUuidByString(item?.id.toString() || idx.toString())}
            className="relative flex items-center"
          >
            <SanityLink
              key={getUuidByString(item?.id.toString() || idx.toString())}
              data-test="desktop-menu-link"
              href={item.link.path}
              className={`${deep > 0 ? 'p text-secondary' : 'nav-link'} uppercase`}
              target={item?.link?.blank ? '_blank' : '_self'}
            >
              {item.link.title}
            </SanityLink>
            <div className="absolute -bottom-1 left-0 w-full overflow-hidden">
              <Transition
                show={isActive}
                appear
                enter="duration-200"
                enterFrom="translate-x-full"
                leave="duration-100"
                leaveTo="translate-x-full"
              >
                <div
                  className={` h-[3px] ${isActive && deep > 0 ? 'bg-secondary' : 'bg-white'} `}
                />
              </Transition>
            </div>
          </div>
        )
      }

      return (
        <div key={item.id}>
          <button
            tabIndex={0}
            data-test="desktop-disclosure-button"
            className={`${deep > 0 ? 'p text-secondary' : 'nav-link'} group relative flex items-center justify-between text-left uppercase`}
            onClick={() => {
              setIsTransparent(false)
              if (deep === 0) {
                setActiveChild([])
                setActiveItem(item)
                if (activeItem === item) {
                  defaultTransparent && setIsTransparent(true)
                  setActiveItem(null)
                  fireEvent('menuCollapsed')
                } else {
                  fireEvent('menuExpanded')
                }
              }

              if (deep > 0) {
                setActiveChild([item?.id])
                if (activeChild.includes(item.id)) {
                  setActiveChild((prev) => prev.filter((id) => id !== item.id))
                  fireEvent('subMenuCollapsed')
                } else {
                  fireEvent('subMenuExpanded')
                }
              }
            }}
          >
            {item?.link?.title}

            <span
              aria-hidden="true"
              className={`material-symbols-outlined -mt-1 ml-1 rotate-90 text-[24px] duration-300 ${(isItemActive || isActive) && '-rotate-90'}`}
            >
              chevron_right
            </span>

            <div className="absolute -bottom-1 left-0  w-full overflow-hidden">
              <Transition
                show={isItemActive || isActive}
                appear
                enter="duration-200"
                enterFrom="translate-x-full"
                leave="duration-100"
                leaveTo="translate-x-full"
              >
                <div className={`h-[3px] ${isItemActive ? 'bg-primary' : 'bg-white'}`} />
              </Transition>
            </div>
          </button>
          <nav
            tabIndex={deep + 1}
            inert={
              activeItem === item || (activeChild?.length > 0 && activeChild?.includes(item.id))
                ? (undefined as any)
                : ('true' as any)
            }
            data-test="desktop-disclosure-panel"
            className={`ease absolute left-0 top-full z-[-1] w-full transition-all duration-200
                ${deep > 0 ? 'bg-white' : 'bg-gray '}
                ${activeItem === item || (activeChild?.length > 0 && activeChild?.includes(item.id)) ? 'delay-100' : '-translate-y-full opacity-0 duration-75'}
              `}
          >
            <div className="container my-6 flex h-full w-full flex-wrap gap-x-10 gap-y-5">
              {item?.children && getNaviation(item.children, deep + 1)}
            </div>
          </nav>
        </div>
      )
    })
  }
  return (
    <nav
      aria-label="Main navigation menu"
      className=" hidden items-center gap-6 pt-3 lg:flex"
      data-test="navigation-menu"
    >
      {getNaviation(menuItems, 0)}
    </nav>
  )
}

export default DesktopMenu
