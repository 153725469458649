export function GtmCookieFetch(cookieStore: any) {
  // Safely retrieve each cookie value; if not present, fallback to undefined
  const _gaCookie = cookieStore.get('_ga')?.value
  const gaCookieId = process.env.NEXT_PUBLIC_GA_COOKIE_ID || '_ga_G5QK4TPEJV'
  const _gtmCookie = cookieStore.get(gaCookieId)?.value
  const _cartCookie = cookieStore.get('cartId')?.value

  return {
    _gaCookie,
    _gtmCookie,
    _cartCookie
  }
}
