const PageTitle = ({
  title,
  children,
  className
}: {
  title: string
  children?: React.ReactElement
  className?: string
}) => {
  return (
    <section
      className={`${className ? className : 'flex w-full flex-col items-center justify-between gap-6 lg:flex-row lg:gap-10'}`}
    >
      <h1 className="h2">{title}</h1>
      {children}
    </section>
  )
}

export default PageTitle
