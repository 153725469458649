/**
 * Returns the lowest variant price (in cents) across a product’s variants.
 * @param product A Shopify product object
 * @returns The lowest price in cents (integer). Defaults to 0 if no valid pricing is found.
 */
export function getLowestVariantPriceInCents(product: Product): string {
  if (!product || !Array.isArray(product.variants)) {
    return '0'
  }

  let minPrice = Number.POSITIVE_INFINITY

  product.variants.forEach((variant: any) => {
    // Safely parse the prices
    const price = parseFloat(variant?.price?.amount ?? '0')
    const compareAt = parseFloat(variant?.compareAtPrice?.amount ?? '0')

    // Take whichever is lower, ignoring zeros if they’re truly placeholders or invalid
    // (If compareAtPrice is 0, it’s typically invalid, so we only use compareAt if it’s > 0 and lower.)
    let currentLowest = price
    if (compareAt > 0 && compareAt < currentLowest) {
      currentLowest = compareAt
    }

    if (currentLowest < minPrice) {
      minPrice = currentLowest
    }
  })

  // If somehow minPrice is still Infinity, return 0
  if (!isFinite(minPrice)) {
    return '0'
  }

  // Convert to cents and return integer
  const cents = Math.round(minPrice * 100)
  return cents.toString()
}

/**
 * Returns the lowest price (in cents) for a single variant object.
 * @param variant A single Shopify variant object
 * @returns The lowest price in cents (as a string). Defaults to '0' if no valid pricing is found.
 */
export function getVariantPriceInCents(variant: any): string {
  if (!variant) {
    return '0'
  }

  // Safely parse the prices
  const price = parseFloat(variant?.price?.amount ?? '0')
  const compareAt = parseFloat(variant?.compareAtPrice?.amount ?? '0')

  // Determine lowest valid price
  let lowest = price
  if (compareAt > 0 && compareAt < lowest) {
    lowest = compareAt
  }

  // If it’s still zero or not valid, just return '0'
  if (!isFinite(lowest) || lowest <= 0) {
    return '0'
  }

  // Convert to cents and return as string
  const cents = Math.round(lowest * 100)
  return cents.toString()
}
