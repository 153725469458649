export async function loopFetch<T>({
  method = 'POST',
  cache = 'force-cache',
  headers,
  tags,
  body,
  queryParams = '',
  path = ''
}: {
  method?: 'POST' | 'GET' | 'PUT' | 'HEAD' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH'
  cache?: RequestCache
  headers?: HeadersInit
  tags?: string[]
  path?: string
  queryParams?: string
  body?: Record<string, any>
}): Promise<T | never> {
  const apiVersion = '2023-10'
  const domain = process.env.NEXT_PUBLIC_LOOP_API_DOMAIN
  const endpoint = `https://${domain}/storefront/${apiVersion}/${path}?${queryParams}`

  const accessToken = window.localStorage.getItem('loop_accessToken')
  try {
    const result = await fetch(endpoint, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken || ''}`,
        accept: 'application/json',
        ...headers
      },
      body: JSON.stringify(body),
      cache,
      ...(tags && { next: { tags } })
    })

    const res = await result.json()

    if (res.errors) {
      throw res.errors[0]
    }

    return res
  } catch (e) {
    console.warn('e', e)

    throw {
      error: e
    }
  }
}
export async function loopAdminFetch<T>({
  method = 'POST',
  cache = 'force-cache',
  headers,
  tags,
  body = undefined,
  queryParams = '',
  path = ''
}: {
  method?: 'POST' | 'GET' | 'PUT' | 'HEAD' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH'
  cache?: RequestCache
  headers?: HeadersInit
  tags?: string[]
  path?: string
  queryParams?: string
  body?: Record<string, any>
}): Promise<T | undefined> {
  const key = process.env.LOOP_SECRET_TOKEN
  const apiVersion = process.env.LOOP_API_VERSION
  const domain = process.env.NEXT_PUBLIC_LOOP_API_DOMAIN
  const endpoint = `https://${domain}/admin/${apiVersion}/${path}?${queryParams}`

  try {
    const result = await fetch(endpoint, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-Loop-Token': key || '',
        accept: 'application/json',
        ...headers
      },
      body: body ? JSON.stringify(body) : undefined,
      cache,
      ...(tags && { next: { tags } })
    })

    const res = await result.json()

    if (res.errors) {
      console.warn('res', res)
    }

    return res
  } catch (e) {
    console.warn('e', e)
  }
}

export * from './actions'
