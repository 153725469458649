import { shopifyAdminFetch } from '@/lib'
import { addTagsAdminMutation, removeTagsAdminMutation } from '../../mutations'
import { TAGS } from '@/constants'

export async function addCustomerTagsMutation({
  id,
  tags
}: {
  id: string
  tags: string[]
}): Promise<{
  node: {
    id: string
  }
  userErrors: CustomerUserErrors[]
}> {
  const res = await shopifyAdminFetch<ShopifyAdminAddCustomerTagsOperation>({
    query: addTagsAdminMutation,
    variables: {
      id,
      tags
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data.tagsAdd
}
export async function removeCustomerTagsMutation({
  id,
  tags
}: {
  id: string
  tags: string[]
}): Promise<{
  node: {
    id: string
  }
  userErrors: CustomerUserErrors[]
}> {
  const res = await shopifyAdminFetch<ShopifyAdminRemoveCustomerTagsOperation>({
    query: removeTagsAdminMutation,
    variables: {
      id,
      tags
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data.tagsRemove
}
