import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { PortableTextBlock } from 'next-sanity'
import { RichText } from '@/components'

enum ActiveColor {
  primary = 'bg-primary border-primary',
  secondary = 'bg-secondary border-secondary',
  tertiary = 'bg-tertiary border-tertiary'
}

interface TabItem {
  id: string
  name: string
  content?: PortableTextBlock[] | string
  icon?: string
}

interface Props {
  activeColor: keyof typeof ActiveColor
  asPill?: boolean
  tabItems?: TabItem[]
  renderTab?: ({
    idx,
    icon,
    middleItem,
    firstItem,
    lastItem,
    isFirstItemPill,
    isLastItemPill,
    selectedItem,
    className,
    name
  }: {
    idx: number
    icon?: string
    middleItem: string | false
    firstItem: string | false
    lastItem: string | false
    isFirstItemPill: string
    isLastItemPill: string
    selectedItem: string | false
    className: string
    name: string
  }) => React.ReactElement
}

const Tabs = ({ asPill = false, activeColor = 'primary', tabItems = [], renderTab }: Props) => {
  if (tabItems.length <= 0) return <></>

  return (
    <TabGroup data-test="tab-container">
      <TabList data-test="tab-heading-container" className="block divide-x">
        {tabItems?.map(({ name, icon }, idx) => (
          <Tab data-test="tab-heading" key={idx} className="focus-visible:outline-none ">
            {({ selected }) => {
              const tabsLength = tabItems.length - 1

              const firstItem = idx === 0 && !selected && `border border-r-0`
              const lastItem = idx === tabsLength && !selected && ' border'
              const middleItem = idx > 0 && idx < tabsLength && !selected && 'border border-r-0'

              const selectedItem = selected && `border ${ActiveColor[activeColor]} text-white`

              const isFirstItemPill = asPill && idx === 0 ? 'rounded-l-full' : ''
              const isLastItemPill = asPill && idx === tabsLength ? 'rounded-r-full' : ''

              return (
                <>
                  {renderTab &&
                    renderTab({
                      idx,
                      icon,
                      middleItem,
                      firstItem,
                      lastItem,
                      isFirstItemPill,
                      isLastItemPill,
                      selectedItem,
                      className: `inline-flex w-full justify-center text-secondary ${icon && 'flex justify-between'}`,
                      name
                    })}
                  {!renderTab && (
                    <span
                      className={`p inline-flex w-full min-w-[160px] justify-center border-gray-200 px-3 py-2
                    ${icon && 'flex justify-between'}
                    ${middleItem} 
                    ${firstItem} 
                    ${lastItem}
                    ${isFirstItemPill}
                    ${isLastItemPill}
                    ${selectedItem} 
                  `}
                    >
                      {name}
                      {icon && (
                        <span aria-hidden="true" className="material-symbols-outlined">
                          {icon}
                        </span>
                      )}
                    </span>
                  )}
                </>
              )
            }}
          </Tab>
        ))}
      </TabList>
      <TabPanels data-test="tab-panel-container" className="mt-3">
        {tabItems.map(({ content }, idx) => {
          if (!content) return null
          const isObject = typeof content === 'object'

          return (
            <TabPanel data-test="tab-panel" key={idx} className="rounded-xl bg-white/5 p-3">
              {isObject ? <RichText content={content} /> : content}
            </TabPanel>
          )
        })}
      </TabPanels>
    </TabGroup>
  )
}

export default Tabs
