'use client'
import 'swiper/css'
import 'swiper/css/navigation'
import React, { Key, useCallback, useState, useEffect, useRef } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination, Keyboard, EffectFade } from 'swiper/modules'
import { PaginationOptions, SwiperOptions } from 'swiper/types'
import { useWindowDimensions } from '@/hooks'
import { DESKTOP_BREAKPOINT } from '@/constants'
import { useGtm } from '@/context'
import 'swiper/css/effect-fade'

export const PaginationStyles = {
  dark: {
    '--swiper-pagination-color': '#F1F1F1',
    '--swiper-pagination-bullet-inactive-color': '#D9D9D9',
    '--swiper-pagination-bullet-inactive-opacity': '1',
    '--swiper-pagination-bullet-size': '10px',
    '--swiper-pagination-bullet-horizontal-gap': '3.5px',
    '--swiper-pagination-top': '100%'
  },
  light: {
    '--swiper-pagination-color': '#CCC',
    '--swiper-pagination-bullet-inactive-color': '#F1F1F1',
    '--swiper-pagination-bullet-inactive-opacity': '1',
    '--swiper-pagination-bullet-size': '10px',
    '--swiper-pagination-bullet-horizontal-gap': '3.5px',
    '--swiper-pagination-top': '100%'
  }
}

const ControlsStyles = {
  dark: {
    color: '#FFF'
  },
  light: {
    color: '#242424'
  }
}

interface Props extends Omit<SwiperOptions, 'pagination'> {
  items: React.ReactElement[]
  title?: string

  delay: boolean

  slidesPerView?: number
  mobileSlidesPerView?: number

  pagination?: PaginationOptions | null
  paginationId?: string
  paginationStyles?: keyof typeof PaginationStyles

  showNavigation?: boolean
  showPagination?: boolean
  wrapperStyles?: string
  titleAlign?: string
  loop?: boolean
  testimonials?: boolean
  controlsContained?: boolean
  effect?: string
  embedded?: boolean
}

export const Carousel = ({
  title,
  items,
  delay = false,
  speed = 1000,
  pagination = {
    el: '.swiper-custom-pagination',
    clickable: true,
    type: 'progressbar'
  },
  showNavigation = true,
  showPagination = true,
  slidesPerView = 4.5,
  mobileSlidesPerView = 1.25,
  paginationStyles = 'light',
  wrapperStyles,
  titleAlign,
  loop = false,
  testimonials = false,
  controlsContained = false,
  effect = 'slide',
  embedded = false,
  ...rest
}: Props) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState(0)
  const screen = useWindowDimensions()
  const [disabledArrow, setDisabledArrow] = useState({
    start: true,
    end: false
  })
  const { fireEvent } = useGtm()
  const TotalPages = Math.round(
    items?.length / (screen.width < DESKTOP_BREAKPOINT ? mobileSlidesPerView : slidesPerView)
  )
  const affirmRefreshed = useRef(false)
  useEffect(() => {
    if (!affirmRefreshed.current && typeof window !== 'undefined') {
      if (window.affirm && window.affirm.ui && typeof window.affirm.ui.refresh === 'function') {
        window.affirm.ui.ready(() => {
          try {
            window.affirm.ui.refresh()
            affirmRefreshed.current = true
          } catch (error) {
            console.error(error)
          }
        })
      }
    }
  }, [])
  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev(speed)
    fireEvent('carouselLeft', title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperRef, speed])

  const handleNext = useCallback(() => {
    swiperRef?.slideNext(speed)
    fireEvent('carouselRight', title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperRef, speed])

  useEffect(() => {
    if (swiperRef) {
      setActiveIndex(swiperRef.realIndex)
    }
  }, [swiperRef])

  return (
    <section
      data-component="carousel"
      className={`${embedded || TotalPages > 1 ? 'lg:py-[2rem]' : ''} ${!embedded && showPagination ? 'pb-4' : ''} flex flex-col items-center justify-center gap-10 lg:container print:hidden ${wrapperStyles}`}
    >
      {title && (
        <h2
          className={`h1 self-${titleAlign || 'start'} text-secondary dark:text-white ${testimonials ? 'container text-center lg:text-left' : ''}`}
        >
          {title}
        </h2>
      )}

      <div
        className={`relative h-full w-full ${items?.length > slidesPerView && !!showNavigation && controlsContained ? 'md:px-8' : 'md:mb-6'}`}
      >
        <Swiper
          {...rest}
          effect={effect}
          onSwiper={setSwiperRef}
          modules={[Navigation, Autoplay, Pagination, Keyboard, EffectFade]}
          slidesPerView={mobileSlidesPerView}
          spaceBetween={8}
          speed={speed}
          pagination={pagination ? pagination : undefined}
          centeredSlides={!!testimonials}
          observeParents
          keyboard={{
            enabled: true,
            onlyInViewport: true
          }}
          loop={loop}
          observer
          autoplay={
            delay && {
              delay: delay ? 3000 : 0
            }
          }
          onSlideChange={(e) => {
            setActiveIndex(e.realIndex)
            setDisabledArrow({ end: e.isEnd, start: e.isBeginning })
          }}
          breakpoints={{
            [DESKTOP_BREAKPOINT]: testimonials
              ? { slidesPerView: 2, slidesPerGroup: 1 }
              : { slidesPerView, slidesPerGroup: slidesPerView }
          }}
          className="h-full lg:px-8"
          {...(testimonials && { style: { overflow: 'visible' } })}
        >
          {items?.map((item, index: Key | null | undefined) => {
            const isActive = index === activeIndex
            return (
              <SwiperSlide
                key={index}
                className={`w-full
                  ${testimonials ? 'h-full' : 'h-auto'}
                `}
                style={{ height: 'auto' }}
              >
                {React.cloneElement(item, { isActive })}
              </SwiperSlide>
            )
          })}
        </Swiper>
        {(showPagination || screen.width < DESKTOP_BREAKPOINT) && TotalPages > 1 && (
          <div
            className={`left-0 z-[1] mt-4 lg:mt-0 ${controlsContained ? 'mb-8 lg:bottom-4 lg:mb-0 lg:w-5/12' : 'w-full lg:-bottom-8'} lg:absolute `}
          >
            <div className="container">
              <div className={`relative grid h-4 grid-cols-12 lg:px-4`}>
                <div
                  className={`col-span-10 lg:col-span-11 ${pagination?.el?.toString().replaceAll('.', '') || 'swiper-custom-pagination'}`}
                  style={PaginationStyles[paginationStyles] as React.CSSProperties}
                />
                <div className="col-span-2 text-center text-xs text-gray-tertiary lg:col-span-1 dark:text-gray">
                  {Math.round(activeIndex / slidesPerView) + 1}
                  {' / '}
                  {TotalPages}
                </div>
              </div>
            </div>
          </div>
        )}

        {DESKTOP_BREAKPOINT < screen.width &&
          slidesPerView <= items?.length - 1 &&
          showNavigation && (
            <div
              className={`absolute bottom-1/2 left-0 top-1/2 ${controlsContained ? 'w-5/12 md:left-4 lg:px-4' : 'w-full xl:w-[calc(100%+30px)]'}`}
            >
              <button
                type="button"
                onClick={handlePrevious}
                aria-label="Previous Item"
                disabled={!loop && disabledArrow.start}
                style={
                  !disabledArrow.start || loop
                    ? (ControlsStyles[paginationStyles] as React.CSSProperties)
                    : undefined
                }
                className={`${testimonials ? 'text-4xl' : 'text-3xl'} material-symbols-outlined arrow_back_ios aspect-square relative z-[1] flex h-8 origin-center -translate-y-1/2 cursor-pointer items-center justify-center text-center text-secondary ${paginationStyles === 'dark' ? 'disabled:text-gray-tertiary' : 'disabled:text-gray'} ${testimonials ? 'left-0 lg:left-[10%]' : 'xl:-left-6'}`}
              >
                arrow_back_ios
              </button>
              <button
                type="button"
                onClick={handleNext}
                aria-label="Next Item"
                disabled={!loop && disabledArrow.end}
                style={
                  !disabledArrow.end || loop
                    ? (ControlsStyles[paginationStyles] as React.CSSProperties)
                    : undefined
                }
                className={`${testimonials ? 'text-4xl' : 'text-3xl'} material-symbols-outlined arrow_forward_ios aspect-square absolute bottom-1/2 right-0 top-1/2 z-[1] flex h-8 origin-center -translate-y-1/2 cursor-pointer items-center justify-center text-center text-secondary ${paginationStyles === 'dark' ? 'disabled:text-gray-tertiary' : 'disabled:text-gray'} ${testimonials ? 'right-0 lg:right-[10%]' : 'right-0'}`}
              >
                arrow_forward_ios
              </button>
            </div>
          )}
      </div>
    </section>
  )
}
