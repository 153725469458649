'use client'
import React, { useCallback, useEffect, useState } from 'react'

export function useInViewport(options?: object): {
  isInViewport: boolean
  ref: React.RefCallback<HTMLElement>
} {
  const [isInViewport, setIsInViewport] = useState(false)
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)

  const setRef = useCallback((current: HTMLElement | null) => {
    if (current !== null) {
      setRefElement(current)
    }
  }, [])

  useEffect(() => {
    if (refElement) {
      const observer = new IntersectionObserver(
        ([entry]) => setIsInViewport(entry.isIntersecting),
        options
      )
      observer.observe(refElement)

      return () => {
        observer.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInViewport, refElement])

  return { isInViewport, ref: setRef }
}
