'use client'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { Button, ProductPrice, ProductTitle } from '@/components'
import { isAvailableForSale, parseMoneyFormat } from '@/app/(store)/utils'
import { useRouter } from 'next/navigation'
import PersonalizationBadges from './personalization-badges'
import { useEtchATeqContext } from '@/app/(store)/context'

const Carousel = dynamic(() => import('@/components').then((mod) => mod.Carousel), { ssr: false })

const ProductsSelection = () => {
  const { products, selectedProduct } = useEtchATeqContext()
  const initialIndex = selectedProduct
    ? products.map((product) => product.id).indexOf(selectedProduct?.id)
    : 0

  return (
    <section
      className="container z-[1] flex min-h-[400px] -translate-y-28 flex-col bg-white pt-24 md:-translate-y-32 lg:-translate-y-8 lg:gap-4 xl:-translate-y-28 2xl:-translate-y-32"
      data-component="etch-a-teq-selector"
    >
      <div>
        <h2 className="h2">Step 1.</h2>
        <p>Select your product</p>

        <div className="mt-4 pb-5">
          <div>
            <Carousel
              initialSlide={initialIndex}
              delay={false}
              showPagination={false}
              showNavigation={true}
              slidesPerView={4}
              pagination={{
                el: `.swiper-custom-pagination`,
                clickable: true,
                type: 'progressbar'
              }}
              items={products?.map((product) => <EAQProduct key={product.id} product={product} />)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const EAQProduct = ({ product }: { product: Product & SanityFeeReference }) => {
  const { fees, selectedProduct, setSelectedProduct, selectedVariant } = useEtchATeqContext()

  const router = useRouter()

  const metafield = product.metafields.find((metafield) => metafield?.key === 'personalization_fee')
  const fee = fees?.find((fee) => fee.id === metafield?.value)
  const available = isAvailableForSale(product, undefined, selectedVariant)

  const hasSomeAvailableVariant = product.variants.some((variant) =>
    isAvailableForSale(product, undefined, variant)
  )

  return (
    <div
      key={product.id}
      className="flex flex-col gap-4 border p-2 text-secondary lg:border-none xl:px-5"
    >
      <div className="flex min-h-[40px] items-center justify-between">
        <PersonalizationBadges
          personalizationOptions={product?.personalization?.personalizationOptions}
        />

        {fee && (
          <div className="p block bg-gray p-1 text-sm lg:p-2 lg:text-base">
            + {parseMoneyFormat(fee?.variants[0].price.amount as string)}
          </div>
        )}
      </div>
      <div>
        <ProductTitle product={product} size="h5 lg:text-center line-clamp-1" />
        <div className="flex justify-start lg:justify-center">
          <ProductPrice
            product={product}
            currentVariant={product.variants[0]}
            showRange={true}
            size="p lg:p-large md:text-center w-full"
          />
        </div>
      </div>
      <div className="relative hidden aspect-1 object-cover lg:block xl:h-[300px] xl:max-w-[300px]">
        <Image src={product?.featuredImage?.url} alt="" fill className="object-contain" />
      </div>
      <div>
        <Button
          id="select-button"
          size="normal"
          aria-labelledby={`select-button ${product?.id} `}
          color={selectedProduct?.id === product.id ? 'secondary' : 'primary'}
          className="w-full py-1"
          disabled={!hasSomeAvailableVariant || !available}
          onClick={async () => {
            router.push(`/etch-a-teq?product=${btoa(product.id)}`, { scroll: false })

            setSelectedProduct && setSelectedProduct(product)
          }}
        >
          {hasSomeAvailableVariant && available && (
            <>{selectedProduct?.id === product.id ? 'Selected' : 'Select'}</>
          )}
          {(!hasSomeAvailableVariant || !available) && <>Not available</>}
        </Button>
      </div>
    </div>
  )
}

export default ProductsSelection
