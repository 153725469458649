'use client'
import React, { isValidElement, useEffect, useState } from 'react'
import { PortableTextBlock } from 'next-sanity'
import { CTA, CTALayout, RichText, Overlay } from '@/components'
import { useWindowDimensions } from '@/hooks'
import { DESKTOP_BREAKPOINT } from '@/constants'
// Type Prop styles
enum ComponentStyle {
  default = 'lg:min-h-[21rem]',
  normal = 'lg:min-h-[21rem]',
  minimal = 'lg:min-h-[14rem] py-6',
  full = 'lg:min-h-[25rem]'
}

// Alignment Prop styles
enum Alignment {
  left = 'text-left',
  center = 'text-center',
  right = 'text-right'
}
enum BlockAlignment {
  left = 'justify-start pt-0',
  center = 'justify-center ',
  right = 'justify-end'
}
enum RichTextAlignment {
  left = 'self-start text-left',
  center = 'self-center text-center',
  right = 'self-end text-right'
}

export interface Props {
  title: string
  type: keyof typeof ComponentStyle
  align: keyof typeof Alignment
  desktopMedia: Sanity.Media
  mobileMedia: Sanity.Media
  body?: PortableTextBlock[] | React.ReactNode
  subtitles?: string[]
  cta?: {
    link: Sanity.Link
  } & CTALayout
}

export const Hero = ({
  title,
  subtitles,
  body,
  desktopMedia,
  mobileMedia,
  cta,
  align = 'center',
  type = 'default'
}: Props) => {
  const [mediaAsset, setMediaAsset] = useState<Sanity.Media>(desktopMedia)
  const screen = useWindowDimensions()

  const isReactElement = isValidElement(body)
  const isObject = typeof body === 'object'

  useEffect(() => {
    return screen.width < DESKTOP_BREAKPOINT
      ? setMediaAsset(mobileMedia)
      : setMediaAsset(desktopMedia)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])

  if (!title) {
    console.warn('Title is required on <Hero> component.')
    return
  }

  if (body && !isReactElement && !isObject) {
    throw new Error('Content must be a React element or an object at <Hero> component.')
  }
  return (
    <>
      <div className="flex flex-col lg:bg-black" data-component="standard-hero">
        <section
          className={`relative mx-auto flex min-h-[200px] w-full max-w-[2200px] py-14 ${ComponentStyle[type]}`}
        >
          <div
            id="left-gradient"
            className="absolute -left-[10px] bottom-0 z-10 hidden h-full bg-gradient-to-l from-transparent to-black lg:w-[120px] 3xl:block"
          />
          <div
            id="right-gradient"
            className="absolute -right-[10px] bottom-0 z-10 hidden h-full bg-gradient-to-r from-transparent to-black lg:w-[120px] 3xl:block"
          />
          {mediaAsset?.asset?.url && (
            <>
              {mediaAsset?.asset?.extension === 'mp4' && (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="absolute left-0 top-0 h-full w-full object-cover"
                >
                  <source src={mediaAsset.asset.url} type="video/mp4" />
                </video>
              )}

              <Overlay
                imageUrl={
                  mediaAsset?.asset?.extension !== 'mp4' ? mediaAsset?.asset?.url : undefined
                }
                gradient="to top"
                color="dark"
              />
            </>
          )}
          <div
            className={`relative z-10 hidden w-full gap-4 py-10 text-white lg:flex lg:flex-nowrap ${type === 'default' ? 'flex-row items-end justify-between' : 'flex-col justify-center '}`}
          >
            <div className="container">
              <div className="flex w-full flex-col justify-self-center">
                <h2
                  data-test="hero-title"
                  className={`h2 uppercase leading-tight ${Alignment[align]} mb-4`}
                >
                  {title}
                </h2>

                {subtitles && subtitles?.length > 0 && (
                  <div
                    className={`flex flex-col items-center py-6 lg:flex-row lg:gap-4 lg:py-0 ${BlockAlignment[align]}`}
                  >
                    {subtitles.map((subtitle, idx) => {
                      if (idx > 0 && idx <= subtitles.length - 1) {
                        return (
                          <>
                            <span
                              aria-hidden="true"
                              className="material-symbols-outlined filled mt-1 text-sm text-primary"
                            >
                              star
                            </span>
                            <h3
                              data-test="hero-subtitle"
                              key={idx}
                              className={`${type === 'minimal' ? 'p' : 'h4'} text-center uppercase leading-tight`}
                            >
                              {subtitle}
                            </h3>
                          </>
                        )
                      }
                      return (
                        <h3
                          key={idx}
                          data-test="hero-subtitle"
                          className={`${type === 'minimal' ? 'p' : 'h4 uppercase'}  text-center leading-tight`}
                        >
                          {subtitle}
                        </h3>
                      )
                    })}
                  </div>
                )}
              </div>

              {body && isReactElement ? (
                body
              ) : (
                <RichText
                  className={`p-secondary w-full max-w-6xl text-pretty py-4 md:text-white ${align === 'center' ? 'container' : ''} ${RichTextAlignment[align]}`}
                  content={body as PortableTextBlock[]}
                />
              )}
            </div>
            {cta && cta?.link?.text && (
              <div
                data-test="hero-cta-container"
                className={`container flex ${BlockAlignment[align]}`}
              >
                <CTA
                  as="button-link"
                  link={cta.link}
                  layout={{
                    color: cta.color,
                    size: cta.size
                  }}
                  component="hero"
                />
              </div>
            )}
          </div>
        </section>
        <div
          className={`relative z-10 flex gap-4 py-4 text-secondary lg:hidden lg:flex-nowrap lg:py-10 dark:text-white ${type === 'default' ? 'flex-row items-end justify-between' : 'flex-col justify-center '}`}
        >
          <div className="flex flex-col">
            <div className="container ">
              <h2
                data-test="hero-title"
                className={`h2 uppercase leading-tight ${type !== 'default' && Alignment[align]}`}
              >
                {title}
              </h2>

              {subtitles && subtitles?.length > 0 && (
                <div
                  className={`flex flex-col items-center py-6 lg:flex-row lg:gap-4 lg:py-0 ${type !== 'default' && BlockAlignment[align]}`}
                >
                  {subtitles.map((subtitle, idx) => {
                    if (idx > 0 && idx <= subtitles.length - 1) {
                      return (
                        <>
                          <span
                            aria-hidden="true"
                            className="material-symbols-outlined filled mt-1 text-sm text-primary"
                          >
                            star
                          </span>
                          <h3
                            data-test="hero-subtitle"
                            key={idx}
                            className={`h4 text-center uppercase leading-tight`}
                          >
                            {subtitle}
                          </h3>
                        </>
                      )
                    }
                    return (
                      <h3
                        key={idx}
                        data-test="hero-subtitle"
                        className={`h4 text-center uppercase leading-tight`}
                      >
                        {subtitle}
                      </h3>
                    )
                  })}
                </div>
              )}
            </div>

            {body && isReactElement ? (
              body
            ) : (
              <RichText
                className={`p-secondary w-full max-w-6xl text-pretty py-4 pl-4 md:pl-0 md:text-white ${align === 'center' ? 'container' : ''} ${RichTextAlignment[align]}`}
                content={body as PortableTextBlock[]}
              />
            )}
          </div>
          {cta && cta?.link?.text && (
            <div
              data-test="hero-cta-container"
              className={`container flex ${BlockAlignment[align]}`}
            >
              <CTA
                as="button-link"
                link={cta.link}
                layout={{
                  color: cta.color,
                  size: cta.size
                }}
                component="hero"
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
