'use client'
import React, { useState } from 'react'
import { Button } from '../button'
import LoadingIndicator from '../loadingIndicator'

interface LoadMoreProps {
  perPage: number
  total: number
  loading: boolean
  onPageChange: (newPage: number) => void
}

export function YotpoLoadMore({ perPage, total, onPageChange, loading }: LoadMoreProps) {
  const [currentPage, setCurrentPage] = useState(1)
  let currentResults = currentPage * perPage

  if (currentResults >= total) {
    currentResults = total
  }

  function handleNext() {
    if (currentResults < total) {
      setCurrentPage(currentPage + 1)
      onPageChange(currentPage + 1)
    }
  }

  if (loading) {
    return (
      <div className="flex min-h-[20rem] items-center justify-center">
        <LoadingIndicator isLoading={true} />
      </div>
    )
  }
  return (
    <div className="col-span-4 flex flex-col items-center justify-center gap-5 py-10 uppercase">
      <p className="p">
        Showing <b>{currentResults}</b> of <b>{total}</b>
      </p>
      <div>
        <Button
          size="normal"
          disabled={currentResults < perPage || currentResults == total}
          onClick={() => handleNext()}
        >
          Load more
        </Button>
      </div>
    </div>
  )
}
