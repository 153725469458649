'use client'
import Link from 'next/link'
import { Dispatch, SetStateAction } from 'react'
import getUuidByString from 'uuid-by-string'

interface Props {
  title: string
  path?: string
  deep?: number
  isactive?: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
}

const MenuLink = ({ title, path, deep = 1, isactive = false, setIsOpen }: Props) => {
  if (!path) {
    return (
      <div
        key={getUuidByString(title)}
        style={{ paddingLeft: deep > 1 ? `${deep * 10}px` : '0' }}
        className={`${deep > 0 ? 'p bg-gray py-3 text-base text-secondary' : 'h5 py-3'} uppercase ${isactive ? 'text-primary' : ''}`}
      >
        {title}
      </div>
    )
  }
  return (
    <div
      key={getUuidByString(title)}
      className={`${deep > 0 ? 'p bg-gray py-3 text-base text-secondary' : 'h5 py-3'} ${deep > 1 ? 'bg-zinc-100' : ''} container uppercase`}
    >
      <Link
        data-test="menu-link"
        href={path}
        style={{
          paddingLeft: deep > 1 ? `${deep * 10}px` : '0'
        }}
        className={`${deep > 0 && 'p text-base'} ${isactive ? 'text-primary' : ''}`}
        onClick={() => {
          setIsOpen && setIsOpen(false)
        }}
      >
        {title}
      </Link>
    </div>
  )
}

export default MenuLink
