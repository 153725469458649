'use client'
import React, { useState } from 'react'
import { FaInstagram, FaVideo } from 'react-icons/fa'

interface UgcGalleryItemProps {
  img: any // the album media object
  index: number
  onClick: (index: number) => void
}

export function UgcGalleryItem({ img, index, onClick }: UgcGalleryItemProps) {
  const [hovered, setHovered] = useState(false)

  // If it's video, use the Yotpo "thumbnail_url" for the preview.
  // If it's image, fallback to "medium_image_url" or your preference.
  const thumbnailSrc =
    img.media_type === 'video' ? img.thumbnail_url : img.medium_image_url || img.image_url

  // We show a camera (or video) icon if it's video; otherwise show Instagram icon
  const HoverIcon = img.media_type === 'video' ? FaVideo : FaInstagram

  return (
    <div
      className="relative cursor-pointer font-oswald text-secondary"
      style={{ maxHeight: '350px' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onClick(index)}
    >
      <img
        src={thumbnailSrc}
        alt={img.post?.content || 'UGC Media'}
        className="aspect-square h-full w-full object-cover"
      />
      {/* 1) If it's video, show a small camera icon at top-left, visible at all times */}
      {img.media_type === 'video' && (
        <div
          className={`absolute left-2 top-2 text-white transition-opacity duration-200 ${
            hovered ? 'opacity-0' : 'opacity-100'
          }`}
        >
          <FaVideo size={20} />
        </div>
      )}
      {/* Overlay: Show only if hovered */}
      <div
        className={`
          absolute inset-0 flex 
          flex-col items-center justify-center bg-black/40 text-white
          transition-opacity duration-200
          ${hovered ? 'opacity-100' : 'opacity-0'}
        `}
      >
        <HoverIcon size={24} />
      </div>
    </div>
  )
}
