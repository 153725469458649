'use client'
import React, { useState } from 'react'
import { VoteReview, ReviewGalleryModal } from '@/components'
// If you have actual upvote/downvote components, import them
// import { UpvoteReviewButton, DownvoteReviewButton } from '@/components'

interface YotpoReview {
  id: number
  score: number
  content: string
  title: string
  votes_up: number
  votes_down: number
  created_at: string
  images_data?: {
    id: number
    thumb_url: string
    original_url: string
  }[]
  user?: {
    display_name: string
    // more fields ...
  }
  // plus any additional fields you might use
}

interface ReviewItemProps {
  review: YotpoReview
}

/**
 * Renders a single review with a 3-row layout:
 *  Row 1: [avatar w/ initials] | [star rating + date]
 *  Row 2: [title, content, images, reviewer name]
 *  Row 3: "Was this review helpful?" + up/down vote
 */
export function ReviewItem({ review }: ReviewItemProps) {
  // 1. Extract data
  const { id, score, content, title, created_at, images_data, user, votes_up, votes_down } = review
  const displayName = user?.display_name || 'Anonymous'
  const dateString = formatDate(created_at) // e.g. "Dec 27, 2024"
  const initials = getInitials(displayName) // e.g. "AH"
  const displayNameFirstLast = getFirstNameLastInitial(displayName) // "Anthony H."
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  function handleThumbnailClick(index: number) {
    setActiveImageIndex(index)
    setIsGalleryOpen(true)
  }
  return (
    <div className="mb-4 border-b pb-4">
      {/* === Row 1 === */}
      <div className="mb-3 flex items-start justify-between">
        {/* Left: avatar w/ initials */}
        <div className="flex items-center gap-2">
          <div className="flex h-14 w-14 items-center justify-center rounded-full bg-primary">
            <h6 className="h5 text-white">{initials}</h6>
          </div>
        </div>

        {/* Right: star rating + date */}
        <div className="text-right">
          <ScoreStars score={score} />
          <p className="p mt-1 text-sm uppercase text-neutral-300">{dateString}</p>
        </div>
      </div>

      {/* === Row 2 === */}
      <div className="mb-3">
        {/* Title */}
        <h3 className="h5">{title}</h3>
        {/* Content */}
        <p className="p mt-1">{content}</p>
        {/* Reviewer name */}
        <p className="p mt-2 text-sm uppercase text-neutral-500">- {displayNameFirstLast}</p>

        {/* Thumbnails (if any) */}
        {images_data && images_data.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {images_data.map((img, index) => (
              <img
                key={img.id}
                src={img.thumb_url}
                alt="Review image"
                className="h-16 w-16 rounded object-cover"
                onClick={() => handleThumbnailClick(index)}
              />
            ))}
          </div>
        )}
      </div>

      {/* === Row 3 === */}
      <div className="mt-2 flex items-center justify-end gap-2">
        <p className="p text-nuetral-300 text-xs uppercase">Was this review helpful?</p>
        <VoteReview reviewId={id} initialUpvotes={votes_up} initialDownvotes={votes_down} />
      </div>

      <ReviewGalleryModal
        images={images_data || []}
        isOpen={isGalleryOpen}
        onClose={() => setIsGalleryOpen(false)}
        initialIndex={activeImageIndex}
      />
    </div>
  )
}

// --- Helpers ---

/**
 * Formats an ISO date string to something like "Dec 27, 2024".
 * Use your own library or formatting approach if desired.
 */
function formatDate(dateStr: string): string {
  try {
    const date = new Date(dateStr)
    // e.g. using built-in toLocaleDateString:
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  } catch {
    return dateStr
  }
}

/**
 * Extracts initials from a display name.
 * E.g. "Anthony H." => "AH"
 */
function getInitials(displayName: string): string {
  const parts = displayName.trim().split(/\s+/)
  if (parts.length >= 2) {
    return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase()
  }
  return parts[0][0].toUpperCase()
}

/**
 * Returns "Anthony H." or "Anthony H." from "Anthony H."
 * If it's only one name, just returns it.
 */
function getFirstNameLastInitial(displayName: string): string {
  const parts = displayName.trim().split(/\s+/)
  if (parts.length >= 2) {
    const firstName = parts[0]
    let lastName = parts[parts.length - 1]
    // remove trailing "." if it exists
    if (lastName.endsWith('.')) {
      lastName = lastName.slice(0, -1)
    }
    return `${firstName} ${lastName[0]}.`
  }
  return displayName
}
interface ScoreStarsProps {
  score: number
}
function ScoreStars({ score }: ScoreStarsProps) {
  // Ensure score is between 1 and 5
  const clampedScore = Math.max(1, Math.min(score, 5))

  const fullStars = clampedScore
  const emptyStars = 5 - fullStars

  const stars: JSX.Element[] = []

  // Full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <span
        key={`full-${i}`}
        aria-hidden
        className="material-symbols-outlined filled icon size-4 text-warning"
      >
        star
      </span>
    )
  }

  // Empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <span
        key={`empty-${i}`}
        aria-hidden
        className="material-symbols-outlined icon size-4 text-gray-300"
      >
        star
      </span>
    )
  }

  return <div className="flex items-center gap-1">{stars}</div>
}
