'use client'

import parse from 'html-react-parser'

const ObjectComponent = ({
  title,
  value,
  icon,
  outlined = true
}: {
  title: string
  value: any
  icon: string
  outlined?: boolean
}) => (
  <div className="flex flex-nowrap items-center gap-2">
    <span
      aria-hidden={true}
      className={`material-symbols-outlined ${outlined ? 'rounded-full border-2 border-black p-1' : ''}`}
    >
      {icon}
    </span>
    <p className="p">
      {title && <b>{title}: </b>}
      {value}
    </p>
  </div>
)

const getMetafieldView = (metafield: Metafield) => {
  if (!metafield) return <></>
  if (metafield?.key === 'max_temp' || metafield?.key === 'min_temp') return <></>

  let value: any = metafield.value

  if (metafield.type === 'json') {
    value = JSON.parse(metafield.value) as []
  }

  const data = {
    power_source: <ObjectComponent title="Power Source" icon="bolt" value={value[0].text} />,
    fuel_source: (
      <ObjectComponent title="Fuel Source" icon="local_fire_department" value={value[0].text} />
    ),
    warranty: (
      <ObjectComponent
        title="Warranty"
        icon="check"
        value={`Industry-leading ${value} year warranty`}
      />
    ),
    assembly_time: <ObjectComponent title="Assembly Time" icon="settings" value={value} />,
    default: <></>
  }

  return data[(metafield?.key as keyof typeof data) || 'default']
}

const ProductFeatures = ({ metafields }: { handle: string; metafields: Metafield[] }) => {
  if (!metafields || metafields.length === 0) return <></>

  const temperatureRange = metafields?.reduce(
    (acc, curr) => {
      if (curr?.key === 'max_temp' || curr?.key === 'min_temp') {
        return {
          ...acc,
          [curr.key]: curr.value
        }
      }
      return acc
    },
    { min_temp: 0, max_temp: 0 }
  )

  const metafield_features_list = metafields?.find(
    (metafield) => metafield?.key === 'features_list'
  )
  const metafield_ingredients = metafields?.find((metafield) => metafield?.key === 'ingredients')
  const featuresList = metafield_features_list ? JSON.parse(metafield_features_list.value) : null

  return (
    <section>
      <div className="gap-5 divide-y lg:flex lg:flex-nowrap lg:divide-x lg:divide-y-0">
        {featuresList?.length > 0 && (
          <section className="w-full lg:w-1/2">
            <div className="prose-links prose flex flex-col gap-6 py-8 prose-p:my-0 prose-a:text-primary">
              {featuresList?.map((data: any) => {
                if (!data) return <></>
                return (
                  <ObjectComponent
                    key={data.key}
                    title=""
                    icon="check"
                    outlined={false}
                    value={parse(data.feature)}
                  />
                )
              })}
            </div>
          </section>
        )}
        {metafield_ingredients && (
          <section className="w-full lg:w-1/2 lg:pl-8">
            <div className="prose-links flex flex-col gap-8 py-8">
              <h5 className="h5">Ingredients</h5>
              <p className="p">{metafield_ingredients.value}</p>
            </div>
          </section>
        )}
        {!metafield_ingredients && metafields?.length > 0 ? (
          <section className="w-full lg:w-1/2 lg:pl-8">
            <div className="prose-links flex flex-col gap-8 py-8">
              {temperatureRange.min_temp > 0 && temperatureRange.max_temp > 0 && (
                <ObjectComponent
                  title="Temperature Range"
                  icon="device_thermostat"
                  value={`${temperatureRange.min_temp}°F to ${temperatureRange.max_temp}°F`}
                />
              )}
              {metafields.map((metafield: Metafield) => {
                if (!metafield) return <></>
                return getMetafieldView(metafield)
              })}
            </div>
          </section>
        ) : (
          <></>
        )}
      </div>
    </section>
  )
}

export default ProductFeatures
