'use client'
import { useHeaderContext } from '@/app/(store)/context'
import { useWindowPosition, useWindowDimensions } from '@/app/(store)/hooks'
import { useEffect } from 'react'
import { DESKTOP_BREAKPOINT, HEADER_THRESHOLD } from '@/app/(store)/constants'
const HeaderHandler = ({
  shouldHaveTransparentHeader = false,
  isContentPage = false
}: {
  shouldHaveTransparentHeader?: boolean
  isContentPage?: boolean
}) => {
  const { setIsTransparent, setDefaultTransparent } = useHeaderContext()
  const { position } = useWindowPosition()
  const screen = useWindowDimensions()

  useEffect(() => {
    if (position < HEADER_THRESHOLD) {
      setIsTransparent(
        shouldHaveTransparentHeader || (isContentPage && screen.width < DESKTOP_BREAKPOINT)
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, shouldHaveTransparentHeader, screen.width])

  useEffect(() => {
    setDefaultTransparent(
      shouldHaveTransparentHeader || (isContentPage && screen.width < DESKTOP_BREAKPOINT)
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHaveTransparentHeader, screen.width])
  return <></>
}

export default HeaderHandler
