'use client'
import { MOBILE_BREAKPOINT } from '@/constants'
import { useIsDesktop } from '@/hooks'
import ModuleHandler from '../module-handler'

interface Props extends React.ComponentPropsWithoutRef<'video'> {
  componentGlobalConfig?: Sanity.ComponentConfig
  youtubeShareUrl?: string
  source?: string
  shopifySource?: Media
  desktopSource?: {
    asset: {
      url: string
      title?: string
    }
    poster: {
      url: string
      alt: string
    }
  }
  mobileSource?: {
    asset: {
      url: string
      title?: string
    }
    poster: {
      url: string
      alt: string
    }
  }
}

export const Video = ({
  desktopSource,
  mobileSource,
  youtubeShareUrl,
  shopifySource,
  componentGlobalConfig,
  ...rest
}: Props) => {
  const isDesktop = useIsDesktop()

  const poster = isDesktop ? desktopSource?.poster?.url : mobileSource?.poster?.url

  if (youtubeShareUrl && rest?.source === 'youtube') {
    const youtubeUrl = youtubeShareUrl
      .replace('https://youtu.be/', 'https://www.youtube.com/embed/')
      .replace('https://www.youtube.com/live/', 'https://www.youtube.com/embed/')
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <div className="aspect-h-5 aspect-w-4 min-h-[500px] lg:aspect-h-9 lg:aspect-w-16  print:hidden">
          <iframe
            className="h-full w-full"
            src={`${youtubeUrl}?controls=0`}
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          />
        </div>
      </ModuleHandler>
    )
  }
  if (rest?.source === 'shopify') {
    const source = shopifySource?.sources?.find((source) => source.mimeType.includes('video'))
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <video
          {...rest}
          controls
          src={source?.url}
          poster={poster}
          className={`block h-full w-full object-cover print:hidden ${rest?.className}`}
          title=""
        >
          Your browser does not support HTML5 video.
        </video>
      </ModuleHandler>
    )
  }
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <video
        {...rest}
        controls
        poster={poster}
        className="block h-full w-full object-cover print:hidden"
        title=""
      >
        <source
          media={`(max-width: ${MOBILE_BREAKPOINT}px)`}
          src={mobileSource?.asset?.url}
          type="video/mp4"
        />
        <source
          media={`(min-width: ${MOBILE_BREAKPOINT}px)`}
          src={desktopSource?.asset?.url}
          type="video/mp4"
        />
        Your browser does not support HTML5 video.
      </video>
    </ModuleHandler>
  )
}
