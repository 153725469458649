import {
  Disclosure as UIDisclosure,
  DisclosureButton,
  DisclosurePanel,
  _internal_ComponentButton,
  _internal_ComponentDisclosureButton
} from '@headlessui/react'
import { RichText } from '@/components'
import { PortableTextBlock } from 'next-sanity'
import React, { isValidElement } from 'react'

interface Props {
  title: string
  content: PortableTextBlock[] | React.ReactNode
  defaultOpen?: boolean
  renderButton?: (button: typeof DisclosureButton) => React.ReactNode
  renderPanel?: (panel: typeof DisclosurePanel, isReactElement?: boolean) => React.ReactNode
}

export const Disclosure = ({ title, content, defaultOpen, renderButton, renderPanel }: Props) => {
  const isReactElement = isValidElement(content)
  const isObject = typeof content === 'object'

  if (!isReactElement && !isObject) {
    throw new Error('Content must be a React element or an object')
  }

  return (
    <UIDisclosure defaultOpen={defaultOpen}>
      {renderButton && renderButton(DisclosureButton)}
      {!renderButton && (
        <DisclosureButton
          data-test="disclosure-button"
          className="group container flex w-full items-center justify-between gap-20 py-[14px] text-left font-oswald font-bold text-secondary"
        >
          {title}
          <span
            aria-hidden="true"
            className="material-symbols-outlined text-[16px] duration-200 group-data-[open]:rotate-90"
          >
            arrow_forward_ios
          </span>
        </DisclosureButton>
      )}

      {renderPanel && renderPanel(DisclosurePanel, isReactElement)}
      {!renderPanel && (
        <DisclosurePanel data-test="disclosure-content" className="container w-full py-4">
          {isReactElement ? content : <RichText content={content as PortableTextBlock[]} />}
        </DisclosurePanel>
      )}
    </UIDisclosure>
  )
}
