import { getPricingInventoryById } from '@/app/(store)/lib/shopify'

export const ShopifyPricingAndInventory = async (products: AlgoliaProduct[]) => {
  const itemIds: string[] = []

  products?.forEach((item) => {
    item?._tags.filter((i) => {
      if (i.includes('id:')) {
        const val = i.replace('id:', 'gid://shopify/Product/')
        itemIds.push(val)
      }
    })
  })
  const shopifyProducts = await getPricingInventoryById({ ids: itemIds })

  return shopifyProducts.products.map((product) => {
    const current = products.find((item) => item.handle === product.handle)

    return {
      objectID: current?.objectID,
      queryId: current?.queryID,
      ...product
    }
  })
}

export const isAvailableForSale = (
  product: Product | ShopifyProduct,
  bundle?: Product,
  currentVariant?: ProductVariant
) => {
  const { metafields } = product || {}

  const safetyStockMetafield = metafields?.find(
    (metafield) => metafield?.key === 'safety_stock_amount'
  )?.value
  const safetyStock = safetyStockMetafield ? parseInt(safetyStockMetafield) : undefined

  const bundleSafetyStockMetafield = bundle?.metafields?.find(
    (metafield) => metafield?.key === 'safety_stock_amount'
  )?.value

  const bundleSafetyStock = bundleSafetyStockMetafield
    ? parseInt(bundleSafetyStockMetafield)
    : undefined

  let available = true

  if (bundle) {
    available = bundle.availableForSale
  } else if (currentVariant) {
    available = currentVariant.quantityAvailable > 0
  } else {
    available = product.availableForSale
  }

  if (safetyStock && currentVariant) {
    if (currentVariant?.quantityAvailable <= safetyStock) {
      available = false
    }
  }

  if (bundle && bundleSafetyStock) {
    if (
      bundle?.variants?.length > 0 &&
      bundle?.variants[0]?.quantityAvailable <= bundleSafetyStock
    ) {
      available = false
    }
  }

  return available
}
