'use client'

import { useScrollDirection, useWindowPosition } from '@/hooks'
import { useHeaderContext } from '../../context/headerContext'
import { HEADER_THRESHOLD } from '@/constants'

const Background = () => {
  const { isTransparent } = useHeaderContext()
  const { position } = useWindowPosition()
  const { scrollDir } = useScrollDirection()

  return (
    <div
      className={`nav-background ${
        (position > HEADER_THRESHOLD && scrollDir === 'up') || !isTransparent
          ? 'bg-secondary'
          : 'bg-transparent'
      } absolute left-0 top-0 z-0 w-full`}
    ></div>
  )
}

export default Background
