import { Button, CardTitle, CardValue } from '@/components'
import Link from 'next/link'

type Props = {
  orders?: Order[]
  customer: Customer
  subscriptionsCount: number
}

const FastInfo = ({ orders, customer, subscriptionsCount }: Props) => {
  return (
    <div className="hidden w-full flex-nowrap gap-7 lg:flex">
      <div className="flex w-full flex-col gap-5 bg-gray p-6">
        <CardTitle title="Orders" icon="orders" />
        <CardValue value={orders?.length || '0'} subtitle="Orders" />
      </div>
      <div className="flex w-full flex-col gap-5 bg-gray  p-6">
        <CardTitle title="subscriptions" icon="markunread_mailbox" />
        <CardValue value={subscriptionsCount || '0'} subtitle="Subscriptions" />
        <Button
          size="normal"
          color="secondary"
          renderButton={({ color, size }) => {
            return (
              <Link href="/account/subscriptions" className={`${color} ${size}`}>
                manage subscriptions
              </Link>
            )
          }}
        />
      </div>
      <div className="flex w-full flex-col gap-5 bg-gray  p-6">
        <CardTitle title="address info" icon="home" />
        <CardValue value={customer.addresses.nodes.length || 0} subtitle="Addresses" />
        <Button
          size="normal"
          color="secondary"
          renderButton={({ color, size }) => {
            return (
              <Link href="/account/addresses" className={`${color} ${size}`}>
                manage addresses
              </Link>
            )
          }}
        />
      </div>
    </div>
  )
}

export default FastInfo
