'use client'
import { Drawer, LoadingIndicator, SearchInput } from '@/components'
import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { useAlgoliaMultipleQuery } from '@/app/(store)/hooks'
import { FALLBACK_IMAGE } from '@/app/(store)/constants'
import { useGtm } from '@/context'

function CustomProductHits({ searchTerm }: { searchTerm: string }) {
  const { algolia } = useAlgoliaMultipleQuery({ search: searchTerm })

  const [_, products] = algolia.results || []
  const { fireEvent } = useGtm()

  if (algolia.loading) {
    return <LoadingIndicator isLoading={true} />
  }

  if (!algolia.loading && products.nbHits === 0) {
    return <NoResults />
  }

  return (
    <div className="grid grid-cols-2 gap-0 md:grid-cols-3">
      {products.hits.map((hit, index) => (
        <article
          className="col-span-1 border-b border-l border-r border-gray-100 p-6 lg:border-l-transparent"
          key={`id:${index}`}
        >
          <Link
            href={`/products/${hit?.handle || ''}`}
            className="grid h-full w-full grid-cols-1 justify-items-stretch text-center"
            onClick={() => {
              fireEvent('searchProductClicked', hit.title)
            }}
          >
            <Image
              src={hit.product_image || FALLBACK_IMAGE}
              width={400}
              height={200}
              alt=""
              priority
              className="mx-auto w-full"
            />

            <div className="flex-wrap self-end md:flex">
              <h5 className="_h w-full">{hit.title}</h5>
            </div>
          </Link>
        </article>
      ))}
    </div>
  )
}

function CustomContenttHits({ searchTerm }: { searchTerm: string }) {
  const { algolia } = useAlgoliaMultipleQuery({ search: searchTerm })

  const [content] = algolia.results || []
  const { fireEvent } = useGtm()

  if (algolia.loading) {
    return <LoadingIndicator isLoading={true} />
  }

  if (!algolia.loading && content.nbHits === 0) {
    return <NoResults />
  }

  return (
    <div className="divide-y">
      {content.hits.map((hit, index) => (
        <Link
          href={`/${hit?.slug?.current}` || '/'}
          key={`id:${index}`}
          className="flex flex-row gap-4 border-gray-100 py-2"
          onClick={() => {
            fireEvent('searchContentClicked', hit.title)
          }}
        >
          <div className="relative h-[75px] w-[75px]">
            <Image
              src={
                hit.image ||
                hit.legacyRecipeImage ||
                (hit as AlgoliaArticle).legacyImage ||
                FALLBACK_IMAGE
              }
              fill
              alt={''}
              sizes="(max-width: 650px) 90vw, 300px"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="w-2/3">
            <span className="p text-xs font-semibold uppercase text-primary">{hit._type}</span>
            <h5 className="_h line-clamp-2 uppercase">{hit.title}</h5>
          </div>
        </Link>
      ))}
    </div>
  )
}

function NoResults() {
  return (
    <div className="py-8">
      <p className="p text-center">No results found.</p>
    </div>
  )
}

const Search = () => {
  const [searchTerm, setSeaerchTerm] = useState<string>('')
  const { fireEvent } = useGtm()

  const handleSubmit = (e: string) => {
    setSeaerchTerm(e)
    fireEvent('searchPerformed', { query: e })
  }

  const handleChange = (e: string) => {
    setSeaerchTerm(e)
  }
  return (
    <>
      <Drawer
        icon="search"
        testAttr="search"
        type="search"
        className="w-full max-w-[1280px] xl:w-[75svw]"
      >
        <div className="mb-4 p-2 lg:p-4">
          <section className="mb-8 px-2 md:px-4">
            <h2 className="h3 mb-4">Search</h2>
            <SearchInput
              searchParams={{}}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <Link
              href={`/search${searchTerm ? `?q=${searchTerm}` : ''}`}
              className="p-alt mt-2 inline-block pl-1 underline"
            >
              Advanced Search
            </Link>
          </section>
          <section
            data-test="search-results-container"
            className="grid grid-cols-3 gap-0 px-2 md:px-4"
          >
            <div
              data-test="content-results"
              className="order-last col-span-3 bg-gray-50 px-2 lg:order-first lg:col-span-1 lg:pr-4"
            >
              <h4 className="p-alt border-b border-gray-100 py-4 capitalize">content results</h4>
              <div className="">
                <CustomContenttHits searchTerm={searchTerm} />
              </div>
            </div>
            <div data-test="product-results" className="col-span-3 md:col-span-3 lg:col-span-2">
              <h4 className="p-alt border-b border-gray-100 py-4 pl-4 capitalize">
                products results
              </h4>
              <div className="pb-8">
                <CustomProductHits searchTerm={searchTerm} />
              </div>
            </div>
          </section>
        </div>
      </Drawer>
    </>
  )
}

export default Search
