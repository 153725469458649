import { TAGS } from '@/constants'
import { loopFetch } from '../..'

// Client
export async function readSubscriptionsForCustomer(): Promise<LoopResponse<Subscription[]>> {
  return await loopFetch<LoopResponse<Subscription[]>>({
    path: `subscription`,
    method: 'GET',
    tags: [TAGS.customer],
    cache: 'no-store'
  })
}

// Admin
export async function createSubscription(
  body: CreateSubscriptionPayload
): Promise<LoopResponse<unknown> | undefined> {
  return await loopFetch<LoopResponse<unknown>>({
    path: 'subscription',
    tags: [TAGS.customer],
    cache: 'no-store',
    body
  })
}
export async function readSubscriptionDetail(
  body: ReadSubscriptionsDetailayload
): Promise<LoopResponse<Subscription>> {
  return await loopFetch<LoopResponse<Subscription>>({
    path: `subscription/${body.id}`,
    method: 'GET',
    tags: [TAGS.customer],
    cache: 'no-store'
  })
}
