import { createClient, type QueryParams, type ResponseQueryOptions } from 'next-sanity'
import dev from '../env'
export { groq } from 'next-sanity'

export const client = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: !dev,
  stega: {
    enabled: false,
    studioUrl: '/admin'
  }
})

export function fetchSanity<T = any>(
  query: string,
  {
    params = {},
    ...next
  }: { params?: QueryParams & { asDraft?: boolean } } & ResponseQueryOptions['next'] = {}
) {
  const shouldRevalidate = dev ? false : next?.revalidate
  return client.fetch<T>(query, params, {
    ...{
      token: process.env.SANITY_API_READ_TOKEN,
      perspective: params.asDraft ? 'previewDrafts' : 'published',
      useCdn: !params.asDraft
    },
    next: {
      revalidate: shouldRevalidate ? shouldRevalidate : next.revalidate,
      ...next
    }
  })
}

export * from './queries'
export * from './fragments'
