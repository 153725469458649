'use client'
import React, { useEffect } from 'react'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { Carousel } from '@/components'

interface UgcGalleryModalProps {
  images: any[]
  isOpen: boolean
  initialIndex?: number
  onClose: () => void
}

export function UgcGalleryModal({
  images,
  isOpen,
  onClose,
  initialIndex = 0
}: UgcGalleryModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])
  return (
    <Dialog open={isOpen} as="div" className="relative z-50" onClose={onClose}>
      <Transition show={isOpen} as="div">
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel className="relative h-full w-full overflow-y-auto bg-white pt-4 shadow-lg md:h-auto md:max-h-[calc(100vh-4rem)] md:w-[80vw] lg:max-h-[calc(100vh-2rem)] lg:px-4">
              {/* Close button */}
              <button
                type="button"
                onClick={onClose}
                className="absolute right-3 top-3 z-10 p-1 text-gray-600 hover:text-gray-900"
              >
                <span className="material-symbols-outlined p-2 text-2xl">close</span>
              </button>

              <Carousel
                items={images.map((media) => {
                  const isVideo = media.media_type === 'video'
                  return (
                    <div
                      key={media.image_id}
                      className="
                        flex h-[75vh] flex-col gap-4
                        px-8 pt-12 font-oswald text-secondary md:h-auto
                        md:flex-row
                        md:pt-0
                        lg:px-2
                      "
                    >
                      {/* Left: big media container */}
                      <div className="flex items-center justify-center md:max-h-[500px]  md:basis-2/3">
                        {isVideo ? (
                          <video
                            controls
                            className="h-full max-h-[50vh] w-auto object-cover md:max-h-[100vh]"
                          >
                            <source src={media.standard_resolution_url} type="video/mp4" />
                            {/* fallback text */}
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={media.original_image_url || media.image_url}
                            alt={media.post?.content || 'UGC Media'}
                            className="h-full max-h-[50vh] w-auto object-cover md:max-h-[100vh]"
                          />
                        )}
                      </div>

                      {/* Right: product + post info */}
                      <div className="mb-4 flex max-h-[100vh] flex-col justify-start overflow-y-auto text-secondary md:max-h-[500px] md:basis-1/3">
                        {media.tagged_products?.length
                          ? media.tagged_products.map((prod: any) => {
                              if (prod.name === 'Placeholder title') return null
                              return (
                                <div
                                  key={prod.id}
                                  className="mb-4 flex flex-col items-center border-b"
                                >
                                  <img
                                    src={prod.image_url}
                                    alt={prod.name}
                                    className="mb-2 h-24 w-24 object-cover"
                                  />
                                  <p className="font-semibold">{prod.name}</p>
                                  <button
                                    onClick={() => (window.location.href = prod.product_url)}
                                    className="my-2 bg-primary px-3 py-1 text-white hover:bg-primaryHover"
                                  >
                                    Buy Now
                                  </button>
                                </div>
                              )
                            })
                          : null}

                        {/* Post info (username, content) */}
                        {media.post && (
                          <div className="mt-4 space-y-2 text-sm text-secondary">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-secondary text-sm font-bold text-white">
                                  {getInitials(media.post.username) || 'U'}
                                </span>
                                <div className="flex flex-col">
                                  <span className="text-sm font-bold">{media.post.username}</span>
                                  <span className="p mt-1 text-sm uppercase text-neutral-700">
                                    {formatDate(media.post.created_time)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <p>{media.post.content}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
                loop={false}
                showPagination
                showNavigation
                slidesPerView={1}
                mobileSlidesPerView={1}
                delay={false}
                initialSlide={initialIndex}
              />
            </DialogPanel>
          </div>
        </div>
      </Transition>
    </Dialog>
  )
}

/**
 * Utility: Extract initials from a user name
 */
function getInitials(displayName: string): string {
  const parts = displayName?.trim().split(/\s+/) || []
  if (parts.length >= 2) {
    return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase()
  }
  return parts[0]?.[0]?.toUpperCase() || 'U'
}

/**
 * Utility: Format date
 */
function formatDate(dateStr: string): string {
  try {
    const date = new Date(dateStr)
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  } catch {
    return dateStr
  }
}
