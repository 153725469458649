'use client'
import { useAlgoliaContext } from '@/app/(store)/context'

const SearchResultsTab = ({
  activeResults,
  setActiveResults
}: {
  activeResults: string
  setActiveResults: React.Dispatch<'product' | 'content'>
}) => {
  const { products, content } = useAlgoliaContext()

  const inactiveClass = 'border border-b-0 border-gray px-8 py-2 text-gray-tertiary'
  const activeClass = 'bg-primary px-8 py-2 text-white'

  return (
    <section className="flex w-full border-b border-gray">
      <button
        className={`p ${activeResults == 'product' ? activeClass : inactiveClass}`}
        onClick={() => setActiveResults('product')}
      >
        Products ({products?.nbHits || 0})
      </button>
      <button
        className={`p ${activeResults == 'content' ? activeClass : inactiveClass}`}
        onClick={() => setActiveResults('content')}
      >
        Content ({content?.nbHits || 0})
      </button>
    </section>
  )
}

export default SearchResultsTab
