'use client'
import { ReactNode, createContext, useContext } from 'react'
import { useLocalStorage } from '../hooks'
import toast from 'react-hot-toast'
import dynamic from 'next/dynamic'
import { getProductById } from '../lib'
import { usePathname } from 'next/navigation'

const CompareWidget = dynamic(() => import('@/components/compare-widget'), { ssr: false })

type CompareContextType = {
  compareProducts: Product[]
  previews: Product[]
  addToProductToCompare: (prod: Product) => void
  removeProductToCompare: (prod: Product) => void
}

const CompareContext = createContext<CompareContextType | null>(null)

export const CompareContextProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname()
  const [previews, setPreviews] = useLocalStorage<Product[]>('previewProducts', [])
  const [compareProducts, setCompareProducts] = useLocalStorage<Product[]>('compareProducts', [])

  const addToProductToCompare = async (product: Product) => {
    const isProductAdded = previews.find((prod) => prod.id === product.id)

    if (previews.length === 4) {
      toast.error('You can only add up to 4 items to the compare list.', {
        ariaProps: {
          role: 'alert',
          'aria-live': 'assertive'
        }
      })
      return
    }

    if (previews.length < 4 && isProductAdded) {
      toast.error('Product is already on the compare list.', {
        ariaProps: {
          role: 'alert',
          'aria-live': 'assertive'
        }
      })
      return
    }

    if (previews.length <= 4 && !isProductAdded) {
      setPreviews([...previews, product])

      const completeProduct = await getProductById(product.id)

      completeProduct && setCompareProducts([...compareProducts, completeProduct])
      toast.success('Product added to the compare list')
    }
  }

  const removeProductToCompare = (product: Product) => {
    setPreviews(previews.filter((item) => item.id !== product.id))
    setCompareProducts(compareProducts.filter((item) => item.id !== product.id))
  }

  return (
    <CompareContext.Provider
      value={{
        previews,
        compareProducts,
        addToProductToCompare,
        removeProductToCompare
      }}
    >
      {children}

      {!pathname.startsWith('/products') && !pathname.includes('/compare') && <CompareWidget />}
    </CompareContext.Provider>
  )
}

export const useCompareContext = () => {
  const context = useContext(CompareContext)

  if (!context) {
    throw new Error('useCompareContext must be used within a CompareContextProvider')
  }
  return context
}
