'use client'

import { Button } from '../button'
import { Input } from '../input'
import { useState } from 'react'
import Image from 'next/image'
import crypto from 'crypto'
import ModuleHandler from '../module-handler'

export default function KlaviyoForm(props: any) {
  const {
    klaviyoId,
    asEmbed,
    title,
    description,
    disclaimer,
    submitButtonText,
    media,
    componentGlobalConfig
  } = props
  const [email, setEmail] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [formError, setFormError] = useState(false)
  const [hideForm, setHideForm] = useState(false)
  const API_TOKEN = crypto
    .createHmac('sha256', process.env.NEXT_PUBLIC_RECTEQ_AUTH_TOKEN || '')
    .digest('base64')

  if (asEmbed) {
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <section>
          <div className="container">
            <div className={`klaviyo-form-${klaviyoId}`}></div>
          </div>
        </section>
      </ModuleHandler>
    )
  }
  const handleOnClick = async () => {
    if (!firstName || !lastName || !email) {
      setFormError(true)
      return
    }
    if (!email.length || (email.length && !email.includes('@'))) {
      setFormError(true)
      return
    }
    if (email.length && email.includes('@')) {
      setFormError(false)
    }
    setHideForm(true)
    await fetch('/api/klaviyo/add-to-list', {
      method: 'POST',
      headers: {
        'Recteq-Auth-Token': API_TOKEN
      },
      body: JSON.stringify({
        listId: klaviyoId,
        email: email,
        firstName: firstName,
        lastName: lastName
      })
    })
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err))
  }
  if (hideForm) {
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <section className="mb-[32px] bg-secondary py-8 text-white lg:mb-[64px]">
          <div className="container items-center justify-between gap-4 lg:flex">
            <div className="w-full lg:w-5/12">
              <h5 className="h2 mb-2">{title}</h5>
              <p className="p mb-2">{description}</p>
              <div className="py-4">
                <h5 className="h3">Form submission successful. Thank you!</h5>
              </div>
            </div>
            <div className="flex w-full justify-end lg:w-6/12">
              <div className="relative -ml-[1.7rem] h-[100svw] w-full lg:-ml-0 lg:aspect-1 lg:h-auto lg:w-[600px] lg:overflow-hidden lg:rounded-lg lg:shadow-frame print:hidden">
                {media?.asset && (
                  <>
                    <Image
                      src={media?.asset?.url}
                      alt={media?.asset?.altText || ''}
                      fill
                      priority
                      sizes="(max-width: 1024px) 100vw, 60vw"
                      className="relative object-cover lg:rounded lg:object-cover"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </ModuleHandler>
    )
  }
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section className="mb-[32px] bg-secondary py-8 text-white lg:mb-[64px]">
        <div className="container items-center justify-between gap-4 lg:flex">
          <div className="w-full lg:w-5/12">
            <h5 className="h2 mb-2">{title}</h5>
            <p className="p mb-2">{description}</p>
            {formError && (
              <span className="block bg-primary p-4 text-center text-sm text-white">
                All form fields are required. Email must be a valid email.
              </span>
            )}
            <div className="grid grid-cols-8 lg:gap-4">
              <div className="col-span-8 py-4 lg:col-span-4">
                <label htmlFor="firstName" className="h6 mb-2 block">
                  First Name
                </label>
                <Input
                  id="firstName"
                  type="text"
                  name="firstName"
                  data-test="klaviyo-form-first-name"
                  onInput={(e: any) => setFirstName(e?.target?.value)}
                  required
                />
              </div>
              <div className="col-span-8 py-4 lg:col-span-4">
                <label htmlFor="lastName" className="h6 mb-2 block">
                  Last Name
                </label>
                <Input
                  id="lastName"
                  type="text"
                  name="lastName"
                  data-test="klaviyo-form-last-name"
                  onInput={(e: any) => setLastName(e?.target?.value)}
                  required
                />
              </div>
            </div>
            <div className="grid-cols-8 py-4">
              <label htmlFor="email" className="h6 mb-2 block">
                Email Address
              </label>
              <Input
                id="email"
                type="text"
                name="email"
                data-test="klaviyo-form-email"
                onInput={(e: any) => setEmail(e?.target?.value)}
                required
              />
            </div>
            <span className="text-sm italic">{disclaimer}</span>
            <div className="mt-4 grid-cols-8 py-4">
              <Button onClick={handleOnClick} color="primary">
                {submitButtonText}
              </Button>
            </div>
          </div>
          <div className="flex w-full justify-end lg:w-6/12">
            <div className="relative -ml-[1.7rem] h-[100svw] w-full lg:-ml-0 lg:aspect-1 lg:h-auto lg:w-[600px] lg:overflow-hidden lg:rounded-lg lg:shadow-frame print:hidden">
              {media?.asset && (
                <>
                  <Image
                    src={media?.asset?.url}
                    alt={media?.asset?.altText || ''}
                    fill
                    priority
                    sizes="(max-width: 1024px) 100vw, 60vw"
                    className="relative object-cover lg:rounded lg:object-cover"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </ModuleHandler>
  )
}
