'use client'
import Link from 'next/link'
import { useHeaderContext } from '@/context'

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  children?: React.ReactNode
  href: string
  target?: string
  className?: string
}
const SanityLink = ({ children, href, target, className, ...rest }: Props) => {
  const { setIsOpen, setActiveChild, setActiveItem } = useHeaderContext()

  let cleanHref = href

  if (!href) {
    return <>{children}</>
  }

  if (!href.includes('mailto') && !href.includes('tel')) {
    cleanHref = href[0] === '/' ? href : `/${href}`
  }
  if (href === 'do-not-sell') {
    return (
      <a
        href="javascript:void(0)"
        onClick={() => {
          if (window?.cookieyes) {
            window.revisitCkyConsent()
          }
        }}
      >
        Do Not Share My Data
      </a>
    )
  }
  return (
    <>
      {href.includes('http') ? (
        <a
          {...rest}
          data-test="desktop-menu-link"
          href={href}
          className={`relative z-[5] ${className}`}
          target={target || '_blank'}
          onClick={() => {
            setActiveChild([])
            setActiveItem(null)
            setTimeout(() => {
              setIsOpen(false)
            }, 200)
          }}
        >
          {children}
        </a>
      ) : (
        <Link
          {...rest}
          data-test="desktop-menu-link"
          href={cleanHref}
          className={`relative z-[5] ${className}`}
          onClick={() => {
            setActiveChild([])
            setActiveItem(null)
            setTimeout(() => {
              setIsOpen(false)
            }, 200)
          }}
        >
          {children}
        </Link>
      )}
    </>
  )
}

export default SanityLink
