import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function nl2br(str?: string) {
  if (!str) return ''
  return str.split('\n').join('<br>')
}

export function slug(str: string) {
  return str.toLowerCase().replace(/[\s]+/g, '-')
}

export const getHrefFromLinkType = (link: Sanity.Link) => {
  let href = '#'

  if (link?.type === 'internal') {
    href = link?.internalLink?.slug?.current || '/'
  }
  if (link?.type === 'external') {
    href = link?.url || '/'
  }
  if (link?.type === 'phone') {
    href = 'tel:' + link?.phone || '/'
  }
  if (link?.type === 'email') {
    href = 'mailto:' + link?.email || '/'
  }

  return href
}
export const reshapeFacetItems = (facets: { [s: string]: unknown } | ArrayLike<unknown>) => {
  const justFacets = Object.entries(facets).filter((item: any) => item[0] != 'q')
  return justFacets
    .map((item: any) =>
      Array.isArray(item[1])
        ? item[1].map((itemArry: any) => `${item[0]}:${itemArry}`).join('&')
        : `${item[0]}:${item[1]}`
    )
    .join('&')
}

export const GroupBy = (array: any[], key: string) => {
  const grouped = array.reduce((acc, curr) => {
    if (acc?.hasOwnProperty(curr[key])) {
      acc[curr[key]].push(curr)
      return acc
    }

    acc[curr[key]] = curr ? [curr] : []
    return acc
  }, {})

  return grouped
}

export const chunkMaxLength = (arr: any[], chunkSize: number) => {
  const arrCopy = [...arr]
  let maxLength
  if (arr.length % chunkSize) {
    maxLength = arr.length / chunkSize + 1
  } else {
    maxLength = arr.length / chunkSize
  }
  return Array.from({ length: maxLength }, () => arrCopy.splice(0, chunkSize))
}

export const fetchBlob = async (url: string) => {
  const response = await fetch(url)

  return response.blob()
}

export const slugify = (input: string) =>
  input
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-') // remove consecutive hyphens
