export const pageInfoFragment = /* GraphQL */ `
  fragment pageInfo on SearchResultItemConnection {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`
