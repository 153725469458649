import { SITE_URL } from '../../constants'

export async function fetchYotpoSiteReviewsPage({
  page = 1,
  perPage = 50,
  sort,
  direction
}: {
  page?: number
  perPage?: number
  sort?: string
  direction?: string
}) {
  const queryParams = new URLSearchParams()
  queryParams.set('page', page.toString())
  queryParams.set('per_page', perPage.toString())

  if (sort) queryParams.set('sort', sort)
  if (direction) queryParams.set('direction', direction)

  const url = `${SITE_URL}/api/yotpo/getSiteReviews?${queryParams}`
  const res = await fetch(url, { cache: 'no-store' })

  if (!res.ok) {
    console.warn(`Failed to fetch paginated Yotpo **site** reviews. Status: ${res.status}`)
    return {}
  }

  const data = await res.json()
  return data?.response ?? {}
}
