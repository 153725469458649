import { shopifyFetch } from '..'
import { getRecipeBlogQuery, getArticleBlogQuery } from '../queries'

export async function getRecipeBlogs(): Promise<{ nodes: any[] } | undefined> {
  const res = await shopifyFetch<ShopifyGetArticlesOperation>({
    query: getRecipeBlogQuery
  })

  return res?.body?.data?.blogs
}

export async function getArticleBlogs(): Promise<{ nodes: any[] } | undefined> {
  const res = await shopifyFetch<ShopifyGetArticlesOperation>({
    query: getArticleBlogQuery
  })

  return res?.body?.data?.blogs
}
