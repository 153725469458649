'use client'
import Image from 'next/image'
import { Button, CTALayout, SanityLink } from '@/components'
import { useState } from 'react'
import { getHrefFromLinkType } from '@/utils'

enum AlignClass {
  left = 'left-0 transform translate-x-0',
  right = 'right-0 transform translate-x-0',
  center = 'left-1/2 transform -translate-x-1/2'
}

interface Props {
  rolloverImage?: Sanity.Image
  asFeatured: boolean
  contentAlign: keyof typeof AlignClass
  media: Sanity.Image
  label: string
  cta: {
    link: Sanity.Link
  } & CTALayout
}

export const VisualCta = ({
  rolloverImage,
  media,
  cta,
  contentAlign = 'center',
  asFeatured = false
}: Props) => {
  const [animateLeave, setAnimateLeave] = useState(false)
  const [activeImage, setActiveImage] = useState(media)

  const heightClass = asFeatured ? 'h-[100vw] md:h-[550px]' : 'h-[50vw] md:h-[350px]'
  const boxShadow = asFeatured ? 'shadow-2xl' : ''
  const borderThickness = asFeatured ? `border-[20px] border-white` : ''
  const labelPosition = asFeatured ? '-bottom-[8px]' : 'bottom-[2px]'

  const href = getHrefFromLinkType(cta?.link)

  return (
    <figure
      className={`group relative mb-5 w-full lg:mb-0
        ${boxShadow}
        ${heightClass}
        ${borderThickness}
      `}
      data-test="Visual CTA Component"
      onMouseLeave={() => {
        if (rolloverImage) {
          setActiveImage(media)

          setTimeout(() => {
            setAnimateLeave(false)
          }, 300)

          setAnimateLeave(true)
        }
      }}
      onMouseEnter={() => {
        if (!rolloverImage) {
          setActiveImage(media)
        }

        if (rolloverImage) {
          setActiveImage(rolloverImage)
        }
      }}
    >
      <SanityLink href={href} className={`relative flex h-full w-full flex-col`}>
        <span className={`relative block h-full w-full`}>
          {media?.asset?.url && (
            <Image
              fill
              src={media?.asset?.url}
              alt=""
              sizes="(max-width: 768px) 100vw, 50vw"
              className={`animate-flash absolute left-0 top-0 object-cover 
              ${media?.asset?.assetId === activeImage.asset.assetId ? 'animate-' : 'animate-'}
          `}
            />
          )}
          {rolloverImage && (
            <Image
              fill
              src={rolloverImage.asset?.url}
              alt=""
              sizes="(max-width: 768px) 100vw, 50vw"
              className={`animate-flash absolute left-0 top-0 object-cover
              ${rolloverImage.asset?.assetId === activeImage.asset?.assetId ? 'animate-appear' : 'opacity-0'}
              ${rolloverImage.asset?.assetId !== activeImage.asset?.assetId && animateLeave ? 'animate-disappear' : 'opacity-0'}
            `}
            />
          )}
        </span>
        {cta?.link?.text && (
          <figcaption
            className={`absolute w-full ${labelPosition} ${AlignClass[contentAlign]} text-center`}
          >
            <div className="translate-y-1/2">
              <Button
                renderButton={({ testAttr, color, size, animation }) => {
                  return (
                    <span
                      data-test={testAttr}
                      className={`${color} ${size} ${animation} pointer-events-none inline-flex items-center justify-center text-center`}
                    >
                      {cta?.link?.text}
                    </span>
                  )
                }}
                color={cta.color}
                size={cta.size}
              />
            </div>
          </figcaption>
        )}
      </SanityLink>
    </figure>
  )
}
