'use client'
import { useState, useEffect } from 'react'

interface WindowDimentions {
  width: number
  height: number
}

function getWindowDimensions(): WindowDimentions {
  if (typeof window === 'undefined') {
    return {
      width: 0,
      height: 0
    }
  }
  const { innerWidth: width, innerHeight: height } = window

  return {
    width,
    height
  }
}

export function useWindowDimensions(): WindowDimentions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>(getWindowDimensions())

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
