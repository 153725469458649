'use client'

import { chunkMaxLength } from '@/utils'
import { Carousel, Input } from '@/components'
import { useEtchATeqContext } from '@/context'
import Image from 'next/image'

const SelectionCustomization = () => {
  const {
    page,
    customizationOption,
    selectedDesign,
    setText,
    text,
    setSelectedDesign,
    selectedProduct,
    selectedVariant,
    available,
    hasSomeAvailableVariant
  } = useEtchATeqContext()

  return (
    <>
      {customizationOption &&
        customizationOption !== 'text-only' &&
        available &&
        hasSomeAvailableVariant && (
          <section
            className={`p module-spacing container text-secondary ${!selectedProduct || (selectedProduct && selectedProduct.variants.length > 1 && !selectedVariant && 'pointer-events-none opacity-40')}`}
          >
            <div className="mt-8">
              <h2 className="h2">Step 2.</h2>
              <p>Select your design</p>
            </div>
            <div>
              <Carousel
                delay={false}
                showPagination={false}
                showNavigation={true}
                slidesPerView={1}
                mobileSlidesPerView={1}
                items={chunkMaxLength([{ name: 'NONE' }, ...page.etchOptions.options], 10)?.map(
                  (options, idx) => (
                    <div
                      key={idx}
                      className="grid grid-cols-2 gap-8 lg:auto-rows-[minmax(0,_2fr)] lg:grid-cols-5"
                    >
                      {options.map((option, idx) => {
                        if (option.name === 'NONE') {
                          return (
                            <button key={idx} onClick={() => setSelectedDesign({ name: 'NONE' })}>
                              <div
                                className={`relative flex aspect-1 w-full flex-col items-center justify-center ${selectedDesign?.name === option.name && 'border-2 border-primary duration-100'}`}
                              >
                                <span
                                  className="material-symbols-outlined rounded-full border-[4px] border-black "
                                  style={{ fontSize: 60 }}
                                >
                                  close
                                </span>
                                <p className="h4 my-4 text-center font-bold">{option.name}</p>
                              </div>
                              <p className="p-alt-large text-center font-bold">
                                <br />
                              </p>
                            </button>
                          )
                        }
                        return (
                          <button key={option.name} onClick={() => setSelectedDesign(option)}>
                            <div
                              className={`relative aspect-1 w-full ${selectedDesign?.name === option.name && 'border-[4px] border-primary duration-100'}`}
                            >
                              <Image
                                src={option?.image?.asset?.url}
                                alt=""
                                fill
                                className="object-cover"
                              />
                            </div>
                            <p className="p-alt-large my-4 text-center font-bold">{option.name}</p>
                          </button>
                        )
                      })}
                    </div>
                  )
                )}
              />
            </div>
          </section>
        )}

      {customizationOption &&
        customizationOption !== 'image-only' &&
        available &&
        hasSomeAvailableVariant && (
          <section
            className={`p module-spacing container mt-0 flex flex-col gap-14 text-secondary ${!selectedProduct || (selectedProduct && selectedProduct.variants.length > 1 && !selectedVariant && 'pointer-events-none opacity-40')}`}
          >
            <div
              className={`${customizationOption === 'text-only' ? 'mt-8' : ''}  flex flex-col gap-4`}
            >
              <div>
                <h2 className="h2">
                  {customizationOption === 'text-only' ? 'Step 2.' : 'Step 3.'}{' '}
                </h2>
                <p>Add your text</p>
              </div>
              <div>
                <div>
                  <Input
                    placeholder={`Text to appear on your ${selectedProduct?.title ? selectedProduct?.title : 'product.'}`}
                    className="input w-full cursor-text border-gray-form px-5 py-5"
                    maxLength={30}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  )
}

export default SelectionCustomization
