import Image from 'next/image'
import Link from 'next/link'
import { ArticleCard, Button } from '@/components'
import { algoliaToSanityArticleAdapter, minutesToHours } from '@/utils'
import { FALLBACK_IMAGE } from '@/app/(store)/constants'
import PageCard from '../page-card'

type Props = {
  content: AlgoliaContent
  inFeed?: boolean
  queryId?: string
  position?: number
}

const ContentCard = ({ content, inFeed, queryId, position }: Props) => {
  if (content?._type === 'recipe') {
    const data = content as Sanity.Recipe & { image: string }
    return (
      <RecipeCard
        recipe={{
          ...data,
          recipeImage: {
            ...data.recipeImage,
            asset: {
              _id: data._id,
              url: data?.image || content?.legacyRecipeImage || data?.recipeImage?.asset?.url,
              altText: ''
            }
          },
          inFeed: inFeed
        }}
        queryId={queryId || ''}
        position={position || 0}
        objectId={content.objectID || ''}
      />
    )
  }
  if (content?._type === 'article') {
    return (
      <ArticleCard
        article={algoliaToSanityArticleAdapter(content as AlgoliaArticle)}
        queryId={queryId}
        position={position}
        contentType="article"
      />
    )
  }
  if (content?._type === 'page') {
    return <PageCard data={content as AlgoliaPage} />
  }
  return <></>
}

export const RecipeCard = ({
  recipe,
  queryId,
  position,
  objectId
}: {
  recipe: Sanity.Recipe
  queryId?: string
  position?: number
  objectId?: string
}) => {
  const {
    recipeImage,
    recipeType,
    recipeName,
    difficulty,
    prepTimeMins,
    cookTimeMins,
    slug,
    _id,
    inFeed
  } = recipe || {}

  return (
    <article
      className="mb-5 lg:mb-0 dark:text-white"
      data-insights-object-id={`${objectId}`}
      data-insights-position={`${position}`}
      data-insights-query-id={`${queryId}`}
    >
      <div className="relative mb-5 aspect-1 min-h-[235px] w-full">
        <Link
          data-test="content-card-link-image"
          aria-hidden="true"
          tabIndex={-1}
          href={`/${slug?.current}` || '#'}
        >
          <Image
            src={recipeImage?.asset?.url || FALLBACK_IMAGE}
            alt={recipeImage?.asset?.altText || ''}
            sizes="(max-width: 640px) 100vw, 25vw"
            fill
            className="object-fill"
          />
        </Link>
      </div>

      <span
        aria-label={`Recipe type ${recipeType}`}
        className="p-alt-large text-[1.25rem] uppercase text-primary"
      >
        {recipeType}
      </span>
      <Link data-test="content-card-link-heading" href={`/${slug?.current}` || '/'}>
        <h4
          id={_id}
          className={`h5 mb-3 text-ellipsis text-secondary dark:text-white ${inFeed ? 'lg:line-clamp-2 lg:h-[80px]' : 'line-clamp-2 h-[72px] lg:h-[80px]'}`}
          aria-label={recipeName}
          title={recipeName}
        >
          {recipeName}
        </h4>
      </Link>
      {difficulty && (
        <div data-test="content-card-dificulty-container" className="mb-4">
          <p className="p text-secondary dark:text-white">
            Difficulty: <strong>{recipe.difficulty}</strong>
          </p>
        </div>
      )}
      {prepTimeMins && (
        <div className="mb-8 flex flex-wrap justify-between gap-2 text-secondary lg:flex-col dark:text-white">
          {prepTimeMins && (
            <div
              data-test="content-card-prep-time-container"
              className="p inline-flex items-center gap-1"
            >
              <span
                className="material-symbols-outlined  align-text-bottom"
                aria-hidden="true"
                tabIndex={-1}
              >
                schedule
              </span>
              <p>Prep Time: {minutesToHours(prepTimeMins)}</p>
            </div>
          )}
          {cookTimeMins && (
            <div
              data-test="content-card-cooking-time-container"
              className="p inline-flex items-center gap-1"
            >
              <span className="material-symbols-outlined" aria-hidden="true" tabIndex={-1}>
                outdoor_grill
              </span>
              <p>Cook Time: {minutesToHours(cookTimeMins)} </p>
            </div>
          )}
        </div>
      )}

      <Button
        size="normal"
        color="primary-outline"
        renderButton={({ testAttr, color, size, text, animation }) => (
          <Link
            id="view-recipe"
            href={`/${slug.current}` || '/'}
            data-test={testAttr}
            className={`${color} ${size} ${animation} ${text} flex justify-between text-xl`}
            aria-labelledby={`view-recipe ${_id}`}
          >
            view recipe
            <div className="flex items-center">
              <span className="material-symbols-outlined" aria-hidden="true" tabIndex={-1}>
                arrow_forward
              </span>
            </div>
          </Link>
        )}
      />
    </article>
  )
}
export default ContentCard
