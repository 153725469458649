'use client'
import { Button, SanityMedia } from '@/components'
import Link from 'next/link'
import { parseLocaleDateTime } from '@/app/(store)/utils'
import { FALLBACK_IMAGE } from '@/app/(store)/constants'
const ArticleCard = ({
  article,
  position,
  queryId,
  contentType = 'article'
}: {
  article: Sanity.Article
  position?: number
  queryId?: string
  contentType?: string
}) => {
  const date = article.publishDate
    ? new Date(article.publishDate.replace(/-/g, '/').replace(/T.+/, ''))
    : new Date()
  const articleAsset = {
    media: {
      _type: 'image',
      asset: {
        url: article?.media?.asset?.url || article.legacyImage || FALLBACK_IMAGE,
        altText: '',
        _id: article._id
      }
    }
  }

  return (
    <>
      <article
        data-test={`article-card-${article._id}`}
        className="flex h-full flex-col gap-4"
        data-insights-object-id={`${article._id}`}
        data-insights-position={position}
        data-insights-query-id={`${queryId}`}
      >
        <div className="relative h-[250px] max-h-[300px] min-h-[250px] w-full">
          <SanityMedia media={articleAsset.media} aspectRatio="video" />
        </div>
        <div className="flex h-full flex-col justify-between gap-4">
          <div className="flex flex-col gap-1">
            <p className="p text-sm uppercase text-gray-tertiary">{parseLocaleDateTime(date)} </p>
            <h2 id={article._id} className="h5 line-clamp-2">
              {article?.title}
            </h2>
          </div>

          <div>
            <Button
              size="normal"
              renderButton={({ size, color, animation, text }) => {
                return (
                  <Link
                    id={`article-view-button-${article._id}`}
                    data-test="article-view-button"
                    aria-labelledby={`article-view-button-${article._id}`}
                    className={`${size} ${color} ${animation} ${text} inline-flex w-full items-center justify-between text-lg`}
                    href={`/${article?.slug?.current}`}
                  >
                    view {contentType}{' '}
                    <span className="material-symbols-outlined">chevron_right</span>
                  </Link>
                )
              }}
            />
          </div>
        </div>
      </article>
    </>
  )
}

export default ArticleCard
