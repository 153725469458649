import type { Metadata } from 'next'
import { BASE_URL } from '@/lib/env'
import { FALLBACK_OG_IMAGE } from '../constants'
export async function processMetadata({
  seo,
  slug
}: Sanity.Page | Sanity.Recipe | Sanity.StaticContent | Sanity.Article): Promise<Metadata> {
  const { title, description, ogimage, image, noIndex, noFollow, ogimageAsURL } = seo || {}
  const titleWithFallback = title || 'Best Wood Pellet Grills & Smokers'
  return {
    metadataBase: new URL(BASE_URL),
    title: `${titleWithFallback} | recteq`,
    description,
    manifest: '',
    alternates: {
      canonical: `/${[slug?.current === 'index' ? '' : slug?.current].filter(Boolean).join('/')}`
    },
    openGraph: {
      type: 'website',
      url: [slug?.current === 'index' ? '/' : slug?.current].filter(Boolean).join('/'),
      title: titleWithFallback,
      description,
      images: ogimage?.asset?.url || ogimageAsURL || image || FALLBACK_OG_IMAGE
    },
    robots: {
      index: !noIndex,
      follow: !noFollow
    }
  }
}

export default processMetadata
