export const findVariantById = (variants: ProductVariant[] = [], variantId?: string) => {
  if (!variantId) {
    return variants[0]
  }

  return variants?.find((variant) => variant.id === variantId) || variants[0]
}

export const getSelectedVariantByOptions = (
  options: VariantOption[],
  variants: ProductVariant[]
) => {
  return variants.find((variant) => {
    return variant.selectedOptions.every((option) => {
      return options.some((selected) => {
        return selected.name === option.name && selected.value === option.value
      })
    })
  })
}

export const getComplementaryProducts = (product: Product | AlgoliaProduct) => {
  let complementaryProducts: string[] = []

  // For ShopifyProduct
  if ('id' in product) {
    // Access the complementaryProducts metafield directly
    const complementaryProductsMetafield = product.complementaryProducts

    if (complementaryProductsMetafield && complementaryProductsMetafield.value) {
      try {
        complementaryProducts = JSON.parse(complementaryProductsMetafield.value)
      } catch (error) {
        console.error('Error parsing complementaryProducts from ShopifyProduct:', error)
      }
    }
  }
  // For AlgoliaProduct
  else if ('objectID' in product) {
    // Check if complementaryProducts exists
    if (product.complementaryProducts) {
      try {
        // If it's a JSON string, parse it
        if (typeof product.complementaryProducts === 'string') {
          complementaryProducts = JSON.parse(product.complementaryProducts)
        } else if (Array.isArray(product.complementaryProducts)) {
          // If it's already an array, use it directly
          complementaryProducts = product.complementaryProducts
        }
      } catch (error) {
        console.error('Error parsing complementaryProducts from AlgoliaProduct:', error)
      }
    }
  }

  return complementaryProducts
}
