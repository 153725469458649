import { TAGS } from '@/app/(store)/constants'
import { loopFetch } from '../..'

export async function generateTokens(sessionToken: string): Promise<any | undefined> {
  const res = await loopFetch({
    method: 'POST',
    path: 'auth/refreshToken',
    tags: [TAGS.customer],
    cache: 'no-store',
    body: {
      sessionToken
    }
  })

  return res
}
export async function sendLoginLink(email: string): Promise<any | undefined> {
  const res = await loopFetch({
    method: 'POST',
    path: 'auth/loginLink',
    tags: [TAGS.customer],
    cache: 'no-store',
    body: {
      myshopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
      email
    }
  })

  return res
}
