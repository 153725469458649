import { SITE_URL } from '../../constants'
import { parseShopifyProductId } from './parseShopifyProductId'

export async function fetchYotpoReviewsPage({
  productId,
  page = 1,
  perPage = 6,
  sort,
  direction
}: {
  productId: string
  page?: number
  perPage?: number
  sort?: string
  direction?: string
}) {
  const parsedId = parseShopifyProductId(productId)

  const queryParams = new URLSearchParams()

  queryParams.set('page', page.toString())
  queryParams.set('per_page', perPage.toString())

  if (sort) queryParams.set('sort', sort)
  if (direction) queryParams.set('direction', direction)

  const url = `${SITE_URL}/api/yotpo/getReviews/${parsedId}?${queryParams}`
  const res = await fetch(url, { cache: 'no-store' })
  if (!res.ok) {
    console.warn(`Failed to fetch paginated Yotpo reviews. Status: ${res.status}`)
    return {}
  }

  const data = await res.json()
  return data?.response ?? {}
}
