export const filterFragment = /* GraphQL */ `
  fragment filters on SearchResultItemConnection {
    productFilters {
      id
      label
      type
      values {
        id
        label
        count
        input
      }
    }
  }
`
