'use client'
import { isValidElement, useState } from 'react'
import { ModuleHandler, RichText } from '@/components'
import { PortableTextBlock } from 'next-sanity'
import { useGtm } from '@/context'
interface Props {
  items: Partial<AccordionItemProps | undefined>[]
  sections?: {
    items: {
      title: string
      content: PortableTextBlock[]
    }[]
  }
  asModule?: boolean
  collapseIcon?: {
    name: string
    provider: string
    type: string
  }
  expandIcon?: {
    name: string
    provider: string
    type: string
  }
  withBorder?: boolean
  componentGlobalConfig?: Sanity.ComponentConfig
}

const Accordion = ({
  items,
  expandIcon,
  collapseIcon,
  sections,
  asModule = false,
  withBorder = true,
  componentGlobalConfig
}: Props) => {
  const [state, setState] = useState<number | null>()
  const itemOrGridItem = items ? items : sections?.items
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <div
        className={`module-spacing flex w-full flex-col ${asModule ? 'container ' : ''}`}
        data-component="accordion"
      >
        {itemOrGridItem?.map((item, index: number) => {
          if (!item) {
            return <></>
          }
          const { title = '', content = '' } = item || {}

          return (
            <AccordionItem
              key={index}
              index={index}
              title={title}
              content={content}
              isOpen={state === index}
              setOpenedIndex={setState}
              withBorder={withBorder}
              expandIcon={expandIcon?.name || 'keyboard_arrow_down'}
              collapseIcon={collapseIcon?.name || 'keyboard_arrow_up'}
            />
          )
        })}
      </div>
    </ModuleHandler>
  )
}

interface AccordionItemProps {
  index: number
  title?: string
  content?: PortableTextBlock[] | React.ReactNode
  expandIcon?: string
  collapseIcon?: string
  isOpen?: boolean
  setOpenedIndex?: (index: number | null) => void
  withBorder: boolean
}

const AccordionItem = ({
  title,
  content,
  isOpen,
  setOpenedIndex,
  index,
  expandIcon,
  collapseIcon,
  withBorder
}: AccordionItemProps) => {
  const isReactElement = isValidElement(content)
  const isObject = typeof content === 'object'
  const { fireEvent } = useGtm()

  if (!isReactElement && !isObject) {
    console.error('Content must be a React element or an object at <Accordion/> component.')
    return <></>
  }

  if (!title) {
    console.error('Title must be passed to <Accordion/> component.')
    return <></>
  }

  return (
    <>
      <button
        cy-data="accordion-button"
        aria-label={title}
        onClick={() => {
          if (isOpen) {
            setOpenedIndex && setOpenedIndex(null)
            fireEvent('dropdownCollapsed', title)
          } else {
            setOpenedIndex && setOpenedIndex(index)
            fireEvent('dropdownExpanded', title)
          }
        }}
        className={`h5 flex items-center justify-between ${withBorder ? 'border-b border-gray-divide' : ''} py-[14px] text-left font-bold uppercase text-secondary lg:px-4 dark:text-white ${isOpen ? 'dark:border-none' : 'dark:border-b dark:border-gray-tertiary'}`}
        type="button"
        aria-expanded={isOpen}
      >
        {title}
        <span
          aria-hidden="true"
          className={`material-symbols-outlined inline-flex items-center justify-center duration-200 ${isOpen ? 'rotate-[360deg]' : ''}`}
        >
          {isOpen ? collapseIcon : expandIcon}
        </span>
      </button>

      <div
        className={`${isOpen ? 'max-h-[2000px]' : 'max-h-[0]'} 
          overflow-hidden transition-maxHeight duration-500
        `}
        aria-expanded={isOpen}
        data-test="accordion-content"
      >
        <div className="border-b border-gray-divide py-4 lg:p-4">
          {isReactElement ? content : <RichText content={content as PortableTextBlock[]} />}
        </div>
      </div>
    </>
  )
}

export default Accordion
