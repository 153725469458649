import { SITE_URL } from '../../constants'
import { parseShopifyProductId } from './parseShopifyProductId'

export async function fetchYotpoBottomLine({ productId }: { productId: string }) {
  const parsedId = parseShopifyProductId(productId)

  const url = `${SITE_URL}/api/yotpo/bottomLine/${parsedId}`
  const res = await fetch(url, { cache: 'no-store' })
  if (!res.ok) {
    console.warn(`Failed to fetch paginated Yotpo reviews. Status: ${res.status}`)
    return {}
  }

  const data = await res.json()
  return data?.response ?? {}
}
