'use client'
import React, { useRef, useEffect, useState } from 'react'
import { Input, Button, LoadingIndicator, ModuleHandler } from '@/components'
import mapboxgl from 'mapbox-gl'
import style from './storelocator.module.css'
import Image from 'next/image'
import 'mapbox-gl/dist/mapbox-gl.css'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import { MdOutlineMyLocation } from 'react-icons/md'
import dynamic from 'next/dynamic'
const accessToken = process.env.NEXT_PUBLIC_MAPBOX_KEY
if (!accessToken) {
  throw new Error('Mapbox access token is not defined')
}
const geocodingClient = mbxGeocoding({ accessToken })

const AddressAutofill = dynamic(
  () =>
    import('@mapbox/search-js-react').then((mod) => ({
      default: mod.AddressAutofill as unknown as React.ComponentType<any>
    })),
  { ssr: false }
)

interface LegendItem {
  icon: {
    asset: {
      url: string
      extension?: string
      assetId?: string
      altText?: string | null
    }
  }
  title: string
  description: string
  tier: number
}

interface StoreLocatorProps {
  componentGlobalConfig?: Sanity.ComponentConfig
  storeLocator: {
    title: string
    legends: LegendItem[]
  }
}

interface LocationResult {
  type: string
  geometry: {
    type: string
    coordinates: [number, number] // Longitude and latitude.
  }
  properties: {
    storeid: string
    name?: string
    address: string
    city: string
    state: string
    zip: string
    phone?: string
    diamond_dealer: boolean
    partner?: string
  }
}

const StoreLocatorLegendItem = ({ icon, title, description }: LegendItem) => {
  return (
    <div className="col-span-2 mb-2 flex border-b border-gray-100 pb-2 lg:mb-0 lg:border-0 lg:pb-0">
      <div className="w-10 pr-2">
        <Image
          width={50}
          height={50}
          src={icon.asset.url}
          alt={icon.asset.altText ? icon.asset.altText : 'Legend Icon'}
        />
      </div>
      <div className="">
        <h6 className="h6 uppercase">{title}</h6>
        <p className="text-secondary">{description}</p>
      </div>
    </div>
  )
}

export const StoreLocator = ({ storeLocator, componentGlobalConfig }: StoreLocatorProps) => {
  const { legends } = storeLocator || {}
  const markerMap = useRef(new Map())
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [startingLocation, setStartingLocation] = useState<[number, number]>([
    -82.1175142, 33.5413266
  ])
  const [map, setMap] = useState<mapboxgl.Map>()
  const [locationResults, setLocationResults] = useState<LocationResult[]>([])
  const [filteredLocations, setFilteredLocations] = useState<LocationResult[]>([])
  const mapNode = useRef(null)
  const cloudFrontUrl = 'https://d3d5kupof0nve5.cloudfront.net/store-locations.json'
  const recteqIcon = legends?.find((legend) => legend.tier === 3)?.icon.asset.url
  const diamondDealerIcon = legends?.find((legend) => legend.tier === 2)?.icon.asset.url
  const authorizedRetailerIcon = legends?.find((legend) => legend.tier === 1)?.icon.asset.url
  const earthRadius = 3958.8
  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  const checkLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'granted') {
            // User has granted location permission
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
            // You can now access the user's location here
          } else if (permissionStatus.state === 'prompt') {
            // User has not yet granted or denied permission
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
          }
        })
        .catch((error) => {
          console.error('Error checking location permission:', error)
        })
    } else {
      // Geolocation is not supported by the browser
      console.log('Geolocation is not supported.')
    }
  }
  function successCallback(position: any) {
    // Handle the location data here
    setStartingLocation([position.coords.longitude, position.coords.latitude])
  }

  function errorCallback(error?: any) {
    // Handle errors here
    console.error('Error getting location:', error)
  }
  const haversineDistance = (coords1: [number, number], coords2: [number, number]) => {
    const toRad = (value: number) => (value * Math.PI) / 180
    const [lat1, lon1] = coords1
    const [lat2, lon2] = coords2

    const dLat = toRad(lat2 - lat1)
    const dLon = toRad(lon2 - lon1)

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return earthRadius * c
  }
  const computeBoundingBox = (lng: number, lat: number, distance: number) => {
    const deltaLat = (distance / earthRadius) * (180 / Math.PI)
    const deltaLng = (distance / (earthRadius * Math.cos(lat * (Math.PI / 180)))) * (180 / Math.PI)

    return {
      northLat: lat + deltaLat,
      southLat: lat - deltaLat,
      eastLng: lng + deltaLng,
      westLng: lng - deltaLng
    }
  }

  const isWithinBoundingBox = (store: LocationResult, boundingBox: any) => {
    const [lng, lat] = store.geometry.coordinates
    return (
      lat >= boundingBox.southLat &&
      lat <= boundingBox.northLat &&
      lng >= boundingBox.westLng &&
      lng <= boundingBox.eastLng
    )
  }

  const filterStoresWithinBoundingBox = (
    stores: LocationResult[],
    origin: [number, number],
    distance: number
  ) => {
    const boundingBox = computeBoundingBox(origin[0], origin[1], distance)

    // Filter out stores with invalid coordinates
    const validStores = stores.filter((store) => {
      const coords = store.geometry?.coordinates
      return (
        Array.isArray(coords) &&
        coords.length === 2 &&
        coords[0] != null &&
        coords[1] != null &&
        typeof coords[0] === 'number' &&
        typeof coords[1] === 'number'
      )
    })

    // Filter stores within the bounding box and calculate the distance
    const storesWithDistance = validStores
      .filter((store) => isWithinBoundingBox(store, boundingBox))
      .map((store) => ({
        ...store,
        name: store.properties.name ?? 'Unknown Store',
        distance: haversineDistance(
          [origin[1], origin[0]],
          [store.geometry.coordinates[1], store.geometry.coordinates[0]]
        )
      }))

    const recteqStores = storesWithDistance.filter((store) =>
      (store.properties.partner ?? '').toLowerCase().includes('recteq')
    )
    const diamondDealers = storesWithDistance.filter((store) => store.properties.diamond_dealer)
    const otherStores = storesWithDistance.filter(
      (store) =>
        !(store.properties.partner ?? '').toLowerCase().includes('recteq') &&
        !store.properties.diamond_dealer
    )

    const sortedRecteqStores = recteqStores.sort((a, b) => a.distance - b.distance)
    const sortedDiamondDealers = diamondDealers.sort((a, b) => a.distance - b.distance)
    const sortedOtherStores = otherStores.sort((a, b) => a.distance - b.distance)

    return [...sortedRecteqStores, ...sortedDiamondDealers, ...sortedOtherStores]
  }

  const fetchLocations = async () => {
    const response = await fetch(cloudFrontUrl)
    const data = await response.json()
    setLocationResults(data.features)
    return data.features
  }

  const handleSearch = async (e: React.FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    const response = await geocodingClient
      .forwardGeocode({
        query: search,
        limit: 1
      })
      .send()

    const match = response.body.features[0]

    if (
      match &&
      match.geometry &&
      Array.isArray(match.geometry.coordinates) &&
      match.geometry.coordinates.length === 2
    ) {
      const origin: [number, number] = [
        match.geometry.coordinates[0],
        match.geometry.coordinates[1]
      ]

      const filteredStores = filterStoresWithinBoundingBox(locationResults, origin, 50)
      setFilteredLocations(filteredStores)
      setLoading(false)
      map?.flyTo({
        center: [match.geometry.coordinates[0], match.geometry.coordinates[1]],
        zoom: 10,
        essential: true
      })
    } else {
      console.error('Invalid coordinates returned from geocoding response')
    }
  }

  const handleStoreClick = (location: LocationResult) => {
    setSelectedLocation(location.properties.storeid)
    map?.flyTo({
      center: [location.geometry.coordinates[0], location.geometry.coordinates[1]],
      zoom: 14,
      essential: true
    })

    function openPopup() {
      const marker = markerMap.current.get(location.properties.storeid)
      if (marker) {
        marker.togglePopup() // Open the popup
      }
      map?.off('moveend', openPopup)
    }

    map?.on('moveend', openPopup)
  }

  const resetMapToDefault = () => {
    map?.flyTo({
      center: startingLocation,
      zoom: 9,
      essential: true
    })
    const filteredStores = filterStoresWithinBoundingBox(locationResults, startingLocation, 50)
    setFilteredLocations(filteredStores)
  }

  const handleRetrieve = async (res: any) => {
    if (res.features && res.features.length > 0) {
      const feature = res.features[0].properties
      const fullAddress = feature.full_address

      setSearch(fullAddress)

      // Automatically trigger the geocode search
      try {
        const response = await geocodingClient
          .forwardGeocode({
            query: fullAddress,
            limit: 1
          })
          .send()

        const match = response.body.features[0]

        if (
          match &&
          match.geometry &&
          Array.isArray(match.geometry.coordinates) &&
          match.geometry.coordinates.length === 2
        ) {
          const coords = match.geometry.coordinates as [number, number]

          const origin: [number, number] = [coords[0], coords[1]]
          const filteredStores = filterStoresWithinBoundingBox(locationResults, origin, 50)
          setFilteredLocations(filteredStores)

          map?.flyTo({
            center: coords,
            zoom: 10,
            essential: true
          })
        } else {
          console.error('Invalid coordinates returned from geocoding response')
        }
      } catch (error) {
        console.error('Error during geocoding:', error)
      }
    }
  }

  useEffect(() => {
    const initialize = async () => {
      setLoading(true)

      // Fetch locations and wait until they are available
      const locations = await fetchLocations()

      const mapRef = mapNode.current
      if (typeof window === 'undefined' || mapRef === null) return
      initMap(mapRef, locations)
      // Get user's location or default location
    }

    initialize()

    function initMap(mapRef: HTMLElement | null, locations: LocationResult[]) {
      if (!mapRef) return
      const mapboxMap = new mapboxgl.Map({
        container: mapRef,
        accessToken: process.env.NEXT_PUBLIC_MAPBOX_KEY,
        center: startingLocation,
        zoom: 9,
        attributionControl: false
      })
      mapboxMap.addControl(new mapboxgl.NavigationControl())
      mapboxMap.on('load', () => {
        const mapCenter = mapboxMap.getCenter()
        const origin: [number, number] = [mapCenter.lng, mapCenter.lat]
        const filteredStores = filterStoresWithinBoundingBox(locations, origin, 50)
        setFilteredLocations(filteredStores)

        locations.forEach((location) => {
          let iconUrl = authorizedRetailerIcon
          if (location.properties.partner === 'recteq') {
            iconUrl = recteqIcon
          } else if (location.properties.diamond_dealer) {
            iconUrl = diamondDealerIcon
          }
          const marker = new mapboxgl.Marker({
            element: document.createElement('div')
          })
          const markerElement = marker.getElement()
          markerElement.style.backgroundImage = `url(${iconUrl})`
          markerElement.style.width = '32px'
          markerElement.style.height = '32px'
          markerElement.style.backgroundSize = 'cover'
          markerElement.style.cursor = 'pointer'

          marker
            .setLngLat([location.geometry.coordinates[0], location.geometry.coordinates[1]])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setHTML(
                `<h3 style="text-transform: capitalize;">${location.properties.name}</h3>
                 <p style="text-transform: capitalize;">
                   ${location.properties.address.toLowerCase()}, 
                   ${location.properties.city.toLowerCase()}, 
                   ${location.properties.state.toUpperCase()} ${location.properties.zip}
                 </p>
                 ${location.properties.phone ? `<a href="tel:${location.properties.phone}" style="text-transform: capitalize;">${location.properties.phone}</a>` : ''}`
              )
            )
            .addTo(mapboxMap)

          // Store the marker in the map
          markerMap.current.set(location.properties.storeid, marker)
        })
        setLoading(false)
      })
      setMap(mapboxMap)
      return () => {
        mapboxMap.remove()
        markerMap.current.clear()
      }
    }
    checkLocationPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetMapToDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingLocation])
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section className="module-spacing--bottom container">
        <h1 className="h2 my-4 text-center text-secondary lg:mb-8 lg:mt-0 lg:text-left">
          Store Locator
        </h1>
        <div className="flex flex-col-reverse flex-wrap lg:block">
          <div className="flex flex-col-reverse flex-wrap lg:grid lg:grid-cols-6">
            <aside className="mb-4 border-b border-l border-r border-gray-100 lg:col-span-2 lg:mb-0">
              <div data-test="results-list">
                <header className="bg-primary p-4 text-white">
                  <h5 className="p-large mb-2">Find the nearest store</h5>

                  <form className="flex w-full" onSubmit={handleSearch}>
                    <div className="relative w-full">
                      <span
                        role="button"
                        aria-label="Reset results to default"
                        tabIndex={0}
                        className="absolute inset-y-0 left-0 flex cursor-pointer items-center pl-2 pr-2"
                        onClick={resetMapToDefault}
                      >
                        <MdOutlineMyLocation className="z-10 text-secondary" />
                      </span>
                      <AddressAutofill
                        accessToken={accessToken}
                        onRetrieve={(res: any) => handleRetrieve(res)}
                      >
                        <Input
                          type="text"
                          value={search}
                          autoComplete="street-address postal-code country"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="enter an address"
                          className="pl-10 pr-1"
                        />
                      </AddressAutofill>
                    </div>
                    <Button color="secondary" type="submit">
                      Search
                    </Button>
                  </form>
                </header>
                <div className="scroll-container h-[30svh] overflow-y-auto lg:h-[560px] lg:border-b lg:border-gray-100">
                  <section className="module-spacing--bottom relative flex min-h-[200px] flex-wrap lg:block lg:min-h-[355px]">
                    <div
                      className="absolute inset-0 z-50 flex items-center justify-center"
                      style={{ display: loading ? 'flex' : 'none' }}
                    >
                      <LoadingIndicator isLoading={loading} />
                    </div>
                    {filteredLocations.length === 0 && !loading && (
                      <p className="p-4 text-secondary">
                        No locations found, please try another location.
                      </p>
                    )}
                    {filteredLocations?.map((location) => {
                      return (
                        <div
                          className={`w-full text-pretty border-b border-gray-100 p-4 text-secondary last:border-0 hover:cursor-pointer ${selectedLocation === location.properties.storeid ? 'bg-secondary text-white' : 'hover:bg-gray-100'}`}
                          key={location.properties.storeid}
                          onClick={() => handleStoreClick(location)}
                        >
                          <div className="flex items-center">
                            {location.properties.partner === 'recteq' && recteqIcon && (
                              <Image
                                src={recteqIcon}
                                alt="Diamond Dealer Icon"
                                width={26}
                                height={26}
                                className="mr-2"
                              />
                            )}
                            {location.properties.diamond_dealer && diamondDealerIcon && (
                              <Image
                                src={diamondDealerIcon}
                                alt="Diamond Dealer Icon"
                                width={26}
                                height={26}
                                className="mr-2"
                              />
                            )}
                            <h5 className="p-alt-large font-bold uppercase">
                              {location.properties.name}
                            </h5>
                          </div>
                          <p className="capitalize">
                            {location.properties.address.toLowerCase()},{' '}
                            {location.properties.city.toLowerCase()},{' '}
                            {location.properties.state.toUpperCase()} {location.properties.zip}
                          </p>
                          {!!location.properties.phone && (
                            <a href={`tel:${location.properties.phone}`} className="link">
                              {formatPhoneNumber(location.properties.phone)}
                            </a>
                          )}
                        </div>
                      )
                    })}
                  </section>
                </div>
              </div>
            </aside>
            <section className="relative col-span-4">
              <div
                className="absolute inset-0 z-50 flex items-center justify-center"
                style={{ display: loading ? 'flex' : 'none' }}
              >
                <LoadingIndicator isLoading={loading} />
              </div>
              <div data-test="locations-map" ref={mapNode} className={style.mapbox_container} />
            </section>
          </div>
          <div className="lg:grid lg:grid-cols-6 lg:gap-4">
            <section className="lg:col-span-full">
              <div className="gap-6 pt-4 lg:grid lg:grid-cols-6">
                {legends?.map((legend, index) => (
                  <StoreLocatorLegendItem
                    key={index}
                    icon={legend.icon}
                    title={legend.title}
                    description={legend.description}
                    tier={legend.tier}
                  />
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
    </ModuleHandler>
  )
}
