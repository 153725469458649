'use client'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { useState } from 'react'

export const CookieConsent = ({ consent }: { consent?: string }) => {
  const [closeBar, setCloseBar] = useState(consent ? true : false)

  const handleCookieClose = () => {
    setCloseBar(true)
    Cookies.set('cookieConsent', 'true', { expires: 7 })
  }

  if (!closeBar) {
    return (
      <div className="fixed bottom-4 left-4 z-20 flex items-center rounded-full border bg-white p-4">
        <p className="p text-sm">
          By using this site, you agree to our use of cookies.{' '}
          <Link href="/privacy-policy">Learn more here.</Link>
        </p>
        <button className="ml-4 mt-1" onClick={handleCookieClose}>
          <span className="material-symbols-outlined">cancel</span>
        </button>
      </div>
    )
  }
  return <></>
}
