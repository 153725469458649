'use client'
import { usePathname } from 'next/navigation'

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { Toaster } from 'react-hot-toast'
import { DraftModeBar } from '@/components/draftModeBar'
import { useCustomerContext } from '@/context'

type HeaderContextType = {
  customer?: Customer

  isOpen?: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>

  defaultTransparent?: boolean
  setDefaultTransparent: Dispatch<SetStateAction<boolean>>

  isTransparent?: boolean
  setIsTransparent: Dispatch<SetStateAction<boolean>>

  activeItem: MenuItem | null
  setActiveItem: Dispatch<SetStateAction<MenuItem | null>>

  activeChild: any[]
  setActiveChild: Dispatch<SetStateAction<any[]>>
}

const HeaderContext = createContext<HeaderContextType | null>(null)

export function MockHeaderContextProvider({ children }: any) {
  return (
    <HeaderContext.Provider
      value={{
        customer: {} as Customer,
        isOpen: true,
        setIsOpen: () => {},
        defaultTransparent: false,
        setDefaultTransparent: () => {},
        isTransparent: false,
        setIsTransparent: () => {},
        activeItem: null,
        setActiveItem: () => {},
        activeChild: [],
        setActiveChild: () => {}
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const HeaderContextProvider = ({ children }: { children: ReactNode }) => {
  const pathName = usePathname()
  const { customer } = useCustomerContext()
  const [isManuallyOpen, setIsManuallyOpen] = useState(false)
  const [isTransparent, setIsTransparent] = useState(false)
  const [activeItem, setActiveItem] = useState<MenuItem | null>(null)
  const [activeChild, setActiveChild] = useState<any[]>([])
  const [defaultTransparent, setDefaultTransparent] = useState<boolean>(false)

  useEffect(() => {
    setActiveItem(null)
    setActiveChild([])
  }, [pathName])

  return (
    <HeaderContext.Provider
      value={{
        customer,
        isOpen: isManuallyOpen,
        setIsOpen: setIsManuallyOpen,
        defaultTransparent,
        setDefaultTransparent,
        isTransparent,
        setIsTransparent,
        activeItem,
        setActiveItem,
        activeChild,
        setActiveChild
      }}
    >
      {children}
      <Toaster
        position="bottom-center"
        containerStyle={{
          top: isManuallyOpen ? '150px' : '10px'
        }}
        toastOptions={{
          // Define default options
          duration: 5000,
          style: {
            maxWidth: '800px',
            background: '#242424',
            color: '#fff'
          },
          position: 'top-center',
          success: {
            duration: 3000
          },
          error: {
            duration: 5000
          },
          ariaProps: {
            role: 'alert',
            'aria-live': 'assertive'
          }
        }}
      />
      <DraftModeBar />
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  const context = useContext(HeaderContext)

  if (!context) {
    throw new Error('useHeaderContext must be used within a HeaderContextProvider')
  }
  return context
}
