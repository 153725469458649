'use client'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useProductPrice } from '../hooks'
import { isAvailableForSale } from '../utils'

type EtchATeqContextType = {
  // Content
  page: SanityEtchATeqPage
  products: (Product & SanityFeeReference)[]
  fees?: Product[]

  // Product types
  selectedFee: Product | undefined
  selectedVariant: ProductVariant | undefined
  selectedProduct: (Product & SanityFeeReference) | undefined
  setSelectedVariant: Dispatch<SetStateAction<ProductVariant | undefined>>
  setSelectedProduct: Dispatch<SetStateAction<(Product & SanityFeeReference) | undefined>>

  // Customizations
  text: string
  setText: Dispatch<SetStateAction<string>>
  selectedDesign: { name: string } | undefined
  setSelectedDesign: Dispatch<SetStateAction<{ name: string }>>
  customizationOption: string | undefined

  // Pricing
  subtotal: number

  // Calculations
  available?: boolean
  hasSomeAvailableVariant?: boolean
}

const EtchATeqContext = createContext<EtchATeqContextType | null>(null)

type Props = {
  children: ReactNode
  page: SanityEtchATeqPage
  defaultSelectedProduct?: Product & SanityFeeReference
  defaultSelectedVariant?: ProductVariant
  products: (Product & SanityFeeReference)[]
  fees?: Product[] | undefined
}

export const EtchATeqContextProvider = ({
  children,
  page,
  defaultSelectedProduct,
  defaultSelectedVariant,
  fees,
  products
}: Props) => {
  const [selectedProduct, setSelectedProduct] = useState<
    (Product & SanityFeeReference) | undefined
  >(
    defaultSelectedProduct && Object.keys(defaultSelectedProduct).length > 0
      ? defaultSelectedProduct
      : undefined
  )
  const fallbackSelectedVariant = useMemo(
    () => (defaultSelectedVariant ? defaultSelectedVariant : undefined),
    [defaultSelectedVariant]
  )

  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | undefined>(
    fallbackSelectedVariant
  )
  const { price, compareAtPrice } = useProductPrice({
    currentVariant: selectedVariant || selectedProduct?.variants[0]
  })

  const [text, setText] = useState<string>('')
  const [selectedDesign, setSelectedDesign] = useState<{ name: string }>()

  const selectedFee = useMemo(
    () =>
      fees?.find(
        (fee) =>
          fee.id === selectedProduct?.personalization?.personalizationProductFee?.product?.gid
      ),
    [fees, selectedProduct]
  )
  const subtotal = useMemo(
    () =>
      (price.amount || compareAtPrice.amount) +
      parseFloat((selectedFee?.variants[0].price.amount as string) || '0'),
    [price, selectedFee, compareAtPrice]
  )
  const [firstRender, setFirstRender] = useState(true)

  const available =
    selectedProduct && isAvailableForSale(selectedProduct, undefined, selectedVariant)

  const hasSomeAvailableVariant =
    selectedProduct &&
    selectedProduct?.variants?.some((variant) =>
      isAvailableForSale(selectedProduct, undefined, variant)
    )

  useEffect(() => {
    if (firstRender) {
      return
    } else {
      setSelectedDesign(undefined)
      setSelectedVariant(undefined)
      setFirstRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct])
  return (
    <EtchATeqContext.Provider
      value={{
        // Page
        page,
        products,
        fees,

        // Product types
        selectedFee,
        selectedVariant,
        selectedProduct,
        setSelectedVariant,
        setSelectedProduct,

        // Customizations
        text,
        setText,
        selectedDesign,
        setSelectedDesign,
        customizationOption: selectedProduct?.personalization?.personalizationOptions,

        // Pricing
        subtotal,

        // Calculations
        available,
        hasSomeAvailableVariant
      }}
    >
      {children}
    </EtchATeqContext.Provider>
  )
}

export const useEtchATeqContext = () => {
  const context = useContext(EtchATeqContext)

  if (!context) {
    throw new Error('useEtchATeqContext must be used within a EtchATeqContextProvider')
  }
  return context
}
