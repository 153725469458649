'use client'
import { getHrefFromLinkType } from '@/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

type Props = {
  navigation: Sanity.Navigation
}
const RaysClubHeader = ({ navigation }: Props) => {
  const pathName = usePathname()

  return (
    <div className="h5 flex gap-10 py-8 uppercase">
      {navigation?.raysClubMenu?.links?.map((link: Sanity.Link) => {
        const href = getHrefFromLinkType(link)
        const isActive = pathName.includes(href)
        return (
          <Link
            key={link._key}
            href={href[0] !== '/' ? `/${href}` : href}
            className={`text-white duration-150 hover:text-gray-secondary
              ${isActive ? ' border-b-2 border-primary' : ''}
              `}
          >
            {link.text}
          </Link>
        )
      })}
    </div>
  )
}

export default RaysClubHeader
