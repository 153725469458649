'use client'
import { useAlgoliaContext } from '@/app/(store)/context'
import FacetDisplayItem from './facetDisplayItem'

type Props = {
  entity: any
}

const getFacetValues = (facets: Record<string, number>): FacetOption[] => {
  const facetItems =
    facets && Object.entries(facets).map(([key, value]) => [key, value] as FacetOption)
  return facetItems?.sort()
}

export default function FacetDisplay({ entity }: Props) {
  const { searchParams } = useAlgoliaContext()

  const originalParams = structuredClone(searchParams)
  delete originalParams.q

  const facets = entity?.initialFacets

  return Object.keys(facets || [])
    ?.sort((a: string, b: string) => a.localeCompare(b))
    ?.map((item, index) => {
      const facetOptions = getFacetValues(facets[item as keyof typeof facets])

      return (
        <FacetDisplayItem
          key={`${facets[item]}-${index}`}
          title={item}
          facets={facetOptions}
          entity={entity}
        />
      )
    })
}
