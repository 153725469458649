'use client'

import {
  logoutCustomer,
  removeCustomerTags,
  addCustomerTags
} from '@/app/(store)/actions/account/account.actions'
import { RAYSCLUB_TAG } from '@/app/(store)/constants'
import { customerUpdate } from '@/app/(store)/lib'
import {
  accessibleErrorToast,
  accessibleSuccessToast,
  cleanPhoneNumberFormat,
  formatPhoneNumberView
} from '@/app/(store)/utils'
import { Button, Input } from '@/components'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

const SettingsForm = ({ customer, accessToken }: { customer: Customer; accessToken: string }) => {
  const router = useRouter()
  const [form, setForm] = useState({ ...customer, phone: formatPhoneNumberView(customer.phone) })
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordType, setPasswordType] = useState('password')
  const [confirmPasswordType, setConfirmPasswordType] = useState('password')
  const [isRaysClub, setIsRaysClub] = useState<boolean>(
    customer?.tags?.includes(RAYSCLUB_TAG) || false
  )
  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      accessibleErrorToast('Passwords must match.')
      return
    }

    const { customer, customerUserErrors } = await customerUpdate({
      customerAccessToken: accessToken,
      customer: {
        firstName: form.firstName,
        lastName: form.lastName,
        phone: `+1${cleanPhoneNumberFormat(form.phone)}`,
        password: password ? password : undefined
      }
    })

    if (customerUserErrors.length === 0) {
      setForm(customer)
      accessibleSuccessToast('Success!')

      await logoutCustomer()

      setTimeout(() => {
        router.push('/account/login')
      }, 1000)
    }

    if (customerUserErrors) {
      customerUserErrors.forEach(({ message }) => {
        accessibleErrorToast(message)
      })
    }
  }

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'phone' && cleanPhoneNumberFormat(e.target.value).length > 10) {
      return
    }
    if (e.target.name === 'phone') {
      setForm((prev) => ({ ...prev, [e.target.name]: formatPhoneNumberView(e.target.value) }))
    }
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const raysClubOptOut = async () => {
    const { userErrors } = await removeCustomerTags(null, { id: customer.id, tags: [RAYSCLUB_TAG] })

    if (userErrors && userErrors.length > 0) {
      userErrors.forEach((error) => accessibleErrorToast(error.message))
    }
    accessibleSuccessToast("Success! You are no longer a member of Ray's Club")
    setIsRaysClub(false)
  }

  const raysClubOptIn = async () => {
    const res = await addCustomerTags(null, {
      id: customer.id,
      tags: [RAYSCLUB_TAG]
    })

    if (res.userErrors && res.userErrors.length > 0) {
      res.userErrors.forEach((error) => accessibleErrorToast(error.message))
    }
    accessibleSuccessToast("Success! You are now a member of Ray's Club")
    setIsRaysClub(true)
  }

  return (
    <>
      <section className="col-span-10 grid grid-cols-1 gap-4 lg:col-span-8 lg:grid-cols-2">
        <div>
          <label htmlFor="firstName" className="h5 mb-2 block">
            Fist Name
          </label>
          <Input
            id="firstName"
            type="text"
            name="firstName"
            value={form?.firstName}
            onChange={inputHandler}
          />
        </div>
        <div>
          <label htmlFor="lastName" className="h5 mb-2 block">
            Last Name
          </label>
          <Input
            id="lastName"
            type="text"
            name="lastName"
            value={form?.lastName}
            onChange={inputHandler}
          />
        </div>
        <div>
          <label htmlFor="email" className="h5 mb-2 block">
            Email
          </label>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="you@email.com"
            readOnly
            disabled
            value={form.email}
          />
        </div>
        <div>
          <label htmlFor="phone" className="h5 mb-2 block">
            Phone
          </label>
          <Input
            id="phone"
            type="tel"
            name="phone"
            value={form.phone}
            onChange={inputHandler}
            placeholder="(xxx) xxx-xxxx"
            autoComplete="off"
          />
        </div>
        <div>
          <label htmlFor="password" className="h5 mb-2 block">
            Change Password
          </label>
          <div className="relative">
            <Input
              id="password"
              type={passwordType}
              name="password"
              autoComplete="new-password"
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              value={password}
            />
            <button
              aria-label={
                passwordType !== 'password'
                  ? 'Turn password visibility off'
                  : 'Turn password visibility on'
              }
              type="button"
              onClick={() => setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'))}
              className="material-symbols-outlined absolute bottom-1/2 right-2 h-[24px] w-[24px] translate-y-1/2"
            >
              {passwordType !== 'password' ? 'visibility' : 'visibility_off'}
            </button>
          </div>
        </div>
        <div>
          <label htmlFor="confirmPassword" className="h5 mb-2 block">
            Confirm New Password
          </label>
          <div className="relative">
            <Input
              id="confirmPassword"
              type={confirmPasswordType}
              value={confirmPassword}
              name="confirmPassword"
              autoComplete="new-password"
              onChange={(e) => {
                setConfirmPassword(e.target.value)
              }}
            />
            <button
              aria-label={
                confirmPasswordType !== 'password'
                  ? 'Turn password visibility off'
                  : 'Turn password visibility on'
              }
              type="button"
              onClick={() =>
                setConfirmPasswordType((prev) => (prev === 'password' ? 'text' : 'password'))
              }
              className="material-symbols-outlined absolute bottom-1/2 right-2 h-[24px] w-[24px] translate-y-1/2"
            >
              {confirmPasswordType !== 'password' ? 'visibility' : 'visibility_off'}
            </button>
          </div>
        </div>

        <div className="flex gap-2">
          <Button type="button" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </section>
      <section className="col-span-10 flex items-center border-y border-gray py-4">
        <div className="w-10/12">
          <h3 className="h4">Ray&rsquo;s Club</h3>
          <p>
            A Ray&rsquo;s Club membership gives you access to special offers, members only products
            and recipes and more! Most importantly, it&rsquo;s FREE.
          </p>
        </div>
        <div className="flex w-2/12 justify-end">
          {!isRaysClub && (
            <Button color="primary" type="button" onClick={raysClubOptIn}>
              Join Ray&rsquo;s Club
            </Button>
          )}
          {isRaysClub && (
            <Button color="secondary" type="button" onClick={raysClubOptOut}>
              Leave Ray&rsquo;s Club
            </Button>
          )}
        </div>
      </section>
    </>
  )
}

export default SettingsForm
