import { IconBox, ModuleHandler } from '@/components'

interface IconListingItem {
  icon: Sanity.Image
  title: string
  text: string
}
interface Props {
  iconBoxes: IconListingItem[]
  iconBoxesMinimal: IconListingItem[]
  type: 'default' | 'minimal'
  componentGlobalConfig?: Sanity.ComponentConfig
}

export const IconListing = (props: Props) => {
  let items = []
  let layoutClass = 'lg:grid-cols-2'

  if (props?.type === 'default') {
    items = props?.iconBoxes
  } else {
    items = props?.iconBoxesMinimal
    layoutClass = 'lg:grid-cols-4'
  }

  return (
    <ModuleHandler config={props?.componentGlobalConfig}>
      <section
        data-test="icon-listing-component"
        data-component="icon-listing"
        className="module-spacing px-4 lg:px-0"
      >
        <div className={`grid grid-cols-1 ${layoutClass} container gap-8`}>
          {items?.map(({ title, text, icon }, idx) => {
            return (
              <div key={idx} className="mb-4">
                <IconBox title={title} text={text} icon={icon} type={props?.type || 'default'} />
              </div>
            )
          })}
        </div>
      </section>
    </ModuleHandler>
  )
}
