import { FormatDate, RichText } from '@/components'
import ReadTime from './ReadTime'
import TableOfContents from './TableOfContents'

export function Post({ post }: { post: Sanity.BlogPost }) {
  return (
    <article className="section">
      <header>
        <h1 className="h1">{post.title}</h1>
        <FormatDate value={post.publishDate} />
        <ReadTime value={post.readTime} />
      </header>

      <div className="space-y-4">
        <aside>
          <TableOfContents headings={post.headings} />
        </aside>

        <RichText content={post.body} />
      </div>
    </article>
  )
}
