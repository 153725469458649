'use client'
import Image from 'next/image'
import {
  DEFAULT_OPTION,
  SHOPIFY_QUALIFIERS_HANDLE,
  BUNDLE_PRODUCT_TYPE,
  FALLBACK_IMAGE
} from '@/constants'
import DeleteItemButton from './delete-item-button'
import QuantityButton from './quantity-button'
import { Accordion, Button, ProductPrice } from '@/components'
import Link from 'next/link'
import Sku from './sku'
import { useEffect, useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { removeItem, updateItemQuantity } from '../../actions/cart/cart.actions'
import { accessibleErrorToast } from '@/utils'

const LineItem = ({
  lineItem,
  lines,
  isDrawer = false
}: {
  lineItem: CartItem
  lines: CartItem[]
  isDrawer?: boolean
}) => {
  let aditionalIdsToDelete: string[] = []

  const {
    id,
    quantity,
    discountAllocations,
    sellingPlanAllocation,
    merchandise: { product, title: variantTitle, image, requiresComponents, quantityAvailable }
  } = lineItem || {}

  // Qualifiers Info
  const [qualifiers, setQualifiers] = useState<CartItem[]>([])
  const [qualifierDialog, setQualifierDialog] = useState<{
    open: boolean
    line?: CartItem
    idsToRemove?: string[]
    itemToUpdate?: { lineId: string; variantId: string; quantity: number }
  }>({
    open: false
  })

  useEffect(() => {
    setQualifiers(
      lines.filter((line) => SHOPIFY_QUALIFIERS_HANDLE.includes(line.merchandise.product.handle))
    )
  }, [lines])

  if (SHOPIFY_QUALIFIERS_HANDLE.includes(lineItem.merchandise.product.handle)) {
    return <></>
  }

  /*
   * Bundle products cart logic
   * - Metafield based
   * - Qualifier gets added on the add-to-cart component
   */

  // 1. Validate if current line item is a bundle
  const isBundle = product?.productType === BUNDLE_PRODUCT_TYPE

  // 2. Parse metafield values in case current line item is the parent product
  const bundleIds = product?.bundles?.value ? JSON.parse(product?.bundles?.value) : []

  // 3. Filter bundle items from cart items
  const bundles = lines.filter((line) => bundleIds?.includes(line.merchandise.product.id))

  // 4.Get qualifiers being applied to bundle components
  const qualifierForBundles = qualifiers.filter((qualifier) =>
    qualifier.discountAllocations.every((discount) =>
      bundles.some((bundle) =>
        bundle.discountAllocations.every(({ title }) => title === discount.title)
      )
    )
  )

  // 5. Remove bundle components and qualifiers
  if (product?.bundles?.value) {
    const lineIds = lines
      .filter((line) => bundleIds?.includes(line.merchandise.product.id))
      .map((line) => line.id)

    lineIds.forEach((id) => aditionalIdsToDelete.push(id))
    qualifierForBundles.forEach((line) => aditionalIdsToDelete.push(line.id))
  }

  // 6. If current item is a component, remove qualifier being applied to it
  if (isBundle) {
    const bundleQualifier = qualifiers.filter((qualifier) =>
      qualifier.discountAllocations.every((discount) =>
        lineItem.discountAllocations.every((lineDiscount) => lineDiscount.title === discount.title)
      )
    )
    const lineIds = bundleQualifier.map((line) => line.id)

    lineIds.forEach((id) => aditionalIdsToDelete.push(id))
  }

  /*
   * Complementary products cart logic
   * - Metafield based
   * - Qualifier gets added on the add-to-cart component
   */

  // 1. Get complementary products metafield
  const complementary = product.metafields.find(
    (metafield) => metafield?.key === 'complementary_products'
  )

  // 2. Parse values if exist
  const complementaryIds = complementary?.value ? JSON.parse(complementary?.value) : []

  // 3. Filter which items from the cart are complementary products for the current line item
  const complementaryLines = lines.filter((line) => complementaryIds?.includes(line.merchandise.id))

  // 4. Get the Ids of the complementary items
  const complementaryLinesIds = complementaryLines.map((line) => line.id)

  // 5. Find the qualifier being applied to the complementary products that depend on the current item
  const qualifiersForComplementary = qualifiers.filter((qualifier) =>
    qualifier.discountAllocations.every((discount) =>
      complementaryLines.some((complementary) =>
        complementary.discountAllocations.every(({ title }) => title === discount.title)
      )
    )
  )

  // 6. Find the qualifier attached to discount being applied to the current item
  const componentQualifier = qualifiers.filter((qualifier) =>
    qualifier.discountAllocations.every((discount) =>
      lineItem.discountAllocations.every((lineDiscount) => lineDiscount.title === discount.title)
    )
  )

  // 7. Add items to be removed to the aditionalIdsToDelete list
  // 7.1 Remove complementary products
  complementaryLinesIds?.forEach((id) => aditionalIdsToDelete.push(id))
  // 7.2 Remove qualifiers for complementary products
  qualifiersForComplementary.forEach((line) => aditionalIdsToDelete.push(line.id))
  // 7.3 Remove qualifier applied to component
  componentQualifier.forEach((line) => aditionalIdsToDelete.push(line.id))

  // 8. When the current line item is a component, find parent so it's quantity can be increased when increasing component
  const mainParentBundleProduct = lines
    // 8.1 Filter line items that have bundles (parent items)
    .filter((line) => line?.merchandise?.product?.bundles?.value)
    // 8.2 Find which of available parents is the one applied to current component
    .find((line) => {
      const lineBundleIds = JSON.parse(line?.merchandise?.product?.bundles?.value)
      const ids = lineBundleIds.find((parentIds: string) => parentIds === product.id)
      return ids
    })

  // 9. Fee products info
  const hasProductFee = product.metafields.find(
    (metafield) => metafield?.key === 'personalization_fee'
  )

  if (hasProductFee) {
    const feeLineId = lines.find((line) => line.merchandise.product.id === hasProductFee.value)

    feeLineId?.id && aditionalIdsToDelete.push(feeLineId?.id)
  }

  const images = lineItem?.merchandise.product.images.edges
  const secondImage = images?.length > 1 ? images[1] : undefined

  return (
    <li>
      <article key={product.id} data-test="cart-product-line-item" className="py-4 lg:py-2">
        <div className="flex flex-row flex-nowrap gap-2 lg:gap-4">
          <div
            data-test="cart-product-line-item-image"
            className={`relative ${isDrawer ? 'w-[125px]' : 'w-[150px'}`}
          >
            <Link
              aria-hidden="true"
              tabIndex={-1}
              href={`${isBundle ? '' : `/products/${product?.handle}`}`}
              className={`inline-block h-[125px] w-[125px] ${isDrawer ? '' : 'lg:h-[300px] lg:w-[300px] lg:min-w-[300px]'} ${isBundle ? 'cursor-default' : ''}`}
            >
              {image && (
                <Image
                  src={(isBundle && secondImage?.node?.url) || image.url}
                  fill
                  alt={image.altText || ''}
                  className="object-contain"
                  sizes="(max-width: 650px) 90vw, 300px"
                />
              )}
            </Link>
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-full flex-col justify-center gap-4 lg:gap-8">
              <div>
                <div className="flex w-full items-center justify-between gap-2 lg:gap-4">
                  <div>
                    <Sku merchandise={lineItem?.merchandise} />
                    <Link
                      href={`${isBundle ? '' : `/products/${product?.handle}`}`}
                      className={`${isBundle ? 'cursor-default' : ''}`}
                    >
                      <h2
                        id={`product-cart-${product.id}`}
                        data-test="cart-product-line-item-title"
                        className={`h6 ${isDrawer ? '' : 'lg:h4'}`}
                      >
                        {product?.title}
                      </h2>
                    </Link>
                    {variantTitle !== DEFAULT_OPTION && <h3 className="p mb-2">{variantTitle}</h3>}
                    {sellingPlanAllocation?.sellingPlan?.name && (
                      <p className="text-sm text-gray-tertiary">
                        {sellingPlanAllocation?.sellingPlan?.name}
                      </p>
                    )}
                  </div>
                  <DeleteItemButton
                    lineId={id}
                    iconSize={isDrawer ? 'text-[1.3rem]' : 'text-[1.6rem]'}
                    itemForId={`product-cart-${product.id}`}
                    additionalToDelete={
                      aditionalIdsToDelete?.length > 0 ? aditionalIdsToDelete : undefined
                    }
                  />
                </div>

                <div className="flex flex-col items-start gap-1">
                  <ProductPrice
                    currentVariant={lineItem?.merchandise}
                    size="p"
                    discountedItemsCount={quantity}
                    discountAllocations={discountAllocations}
                    cost={(lineItem?.cost?.totalAmount?.amount as string) || undefined}
                  />
                </div>
              </div>

              <div data-test="cart-line-item-controls">
                <span className="inline-flex divide-x divide-gray-secondary border border-gray-secondary">
                  <QuantityButton
                    as="remove"
                    className="px-1"
                    item={lineItem}
                    interceptChange={
                      qualifiers.length > 1
                        ? (updatedItem) => {
                            setQualifierDialog({
                              open: true,
                              line: lineItem,
                              itemToUpdate: updatedItem
                            })
                          }
                        : undefined
                    }
                  />
                  <div>
                    <span
                      aria-live="polite"
                      data-test="mini-cart-quantity"
                      className="px-2 py-0 text-center text-sm"
                    >
                      {quantity}
                    </span>
                  </div>
                  <QuantityButton
                    as="add"
                    className="px-1"
                    item={lineItem}
                    disabled={quantity === quantityAvailable}
                    interceptChange={
                      mainParentBundleProduct
                        ? async ({ lineId, variantId, quantity }) => {
                            const res = await updateItemQuantity(null, [
                              {
                                lineId,
                                variantId,
                                quantity
                              },
                              {
                                lineId: mainParentBundleProduct.id,
                                quantity: mainParentBundleProduct.quantity + 1,
                                variantId: mainParentBundleProduct.merchandise.id
                              }
                            ])
                            if (res.errors.length > 0) {
                              res.errors.forEach((error) => {
                                accessibleErrorToast(error.message)
                              })
                            }
                          }
                        : undefined
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {lineItem?.attributes && lineItem?.attributes?.length > 0 && (
          <div className="my-3 flex flex-col gap-1">
            {lineItem?.attributes?.map((attr) => (
              <p key={attr.key} className="p line-clamp-2 text-sm text-gray-tertiary">
                <span className="font-bold capitalize">{attr.key}: </span>
                {attr.value}
              </p>
            ))}
          </div>
        )}

        {requiresComponents && isBundle && (
          <div>
            {product?.variants?.edges.length > 0 && (
              <Accordion
                withBorder={false}
                items={[
                  {
                    title: 'Inside your bundle',
                    content: (
                      <section className="flex flex-wrap gap-4">
                        {/* <section className="grid grid-cols-1 gap-x-8 lg:grid-cols-3"> */}
                        {product?.variants?.edges[0]?.node?.components?.nodes?.map(
                          ({ productVariant: { product, title, image }, quantity }) => {
                            return (
                              <div
                                key={product.id}
                                className={`inline-flex w-full flex-nowrap items-center gap-2 md:w-[48%] lg:justify-center ${isDrawer ? '' : 'xl:w-[30%]'}`}
                              >
                                <div className="relative aspect-1 h-[100px] min-h-[100px] w-[100px] min-w-[100px]">
                                  <Image
                                    fill
                                    src={
                                      image?.url || product?.featuredImage?.url || FALLBACK_IMAGE
                                    }
                                    alt={image?.altText || product?.featuredImage?.altText || ''}
                                    sizes="(max-width: 650px) 90vw, 300px"
                                  />
                                </div>
                                <div className="w-[calc(100%-100px)]">
                                  <p className="p text-sm lg:text-center">
                                    {title !== DEFAULT_OPTION ? title : product.title}
                                    {quantity > 1 ? <sup>({quantity})</sup> : ''}
                                  </p>
                                </div>
                              </div>
                            )
                          }
                        )}
                      </section>
                    )
                  }
                ]}
              />
            )}
          </div>
        )}
      </article>

      <Dialog
        open={qualifierDialog.open}
        as="div"
        className="relative z-10 bg-black/60 focus:outline-none"
        onClose={() => {
          setQualifierDialog((prev) => ({ ...prev, open: false }))
        }}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/50">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="data-[closed]:transform-[scale(95%)] w-full max-w-md bg-secondary p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0"
            >
              <DialogTitle
                as="p"
                className="p-large text-pretty text-base/7 font-medium text-white"
              >
                You have more than one discount associated with this product.
              </DialogTitle>
              <p className="p mt-2 text-gray">
                Please select which of the following you want remove.
              </p>
              <div className="my-10 flex flex-col gap-2">
                {qualifierForBundles.map((qualifier) => {
                  return (
                    <button
                      key={qualifier.id}
                      onClick={async () => {
                        const bundlesForQualifier = lines
                          .filter((line) =>
                            line.discountAllocations.every((discount) =>
                              qualifier.discountAllocations.every(
                                ({ title }) => discount.title === title
                              )
                            )
                          )
                          .map((bundle) => bundle.id)

                        setQualifierDialog((prev) => ({
                          ...prev,
                          idsToRemove: bundlesForQualifier
                        }))
                      }}
                      className={`group flex flex-col gap-1 bg-gray-tertiary p-4 hover:bg-white ${qualifierDialog.idsToRemove?.includes(qualifier.id) && 'bg-white '}`}
                    >
                      {qualifier.discountAllocations.map((discount) => (
                        <div
                          key={discount.title}
                          className={`p flex items-center gap-2 group-hover:text-secondary ${qualifierDialog.idsToRemove?.includes(qualifier.id) ? 'text-secondary' : 'text-white'}`}
                        >
                          <span
                            aria-hidden="true"
                            className="material-symbols-outlined"
                            style={{ fontSize: '16px' }}
                          >
                            shoppingmode
                          </span>
                          {discount.title}
                        </div>
                      ))}
                    </button>
                  )
                })}
              </div>
              <div className="mt-4 flex justify-between">
                <Button
                  onClick={() => {
                    setQualifierDialog((prev) => ({ ...prev, open: false }))
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={async () => {
                    let removeIds: string[] = []

                    if (qualifierDialog?.idsToRemove && qualifierDialog?.idsToRemove?.length > 0) {
                      qualifierDialog.idsToRemove.forEach((id) => {
                        removeIds.push(id)
                      })
                    }

                    await removeItem(null, removeIds)

                    qualifierDialog?.itemToUpdate &&
                      (await updateItemQuantity(null, [qualifierDialog.itemToUpdate]))
                    setQualifierDialog(() => ({ open: false }))
                  }}
                >
                  Remove
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </li>
  )
}

export default LineItem
