import { Item } from './item'

interface Props {
  items: React.ReactNode[]
  gap?: string
}

export const NestedContainer = (props: Props) => {
  const { items } = props

  if (!items || items?.length === 0) {
    console.warn('NestedContainer: No items provided')
    return null
  }

  return (
    <section className="grid grid-cols-1 gap-10 lg:grid-cols-6">
      {items.map((item) => {
        return <>{item}</>
      })}
    </section>
  )
}
NestedContainer.Item = Item
