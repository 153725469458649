'use client'
import { useCallback, useEffect, useState } from 'react'
import { getProductsLight } from '@/lib'
import { useCustomerContext } from '../context'

type QueryInfo = {
  productQueryCondition?: string
  resultsPerPage?: number
  productQuery?: DataQuery[]
  productOption?: string
  manualSelection?: ManualProductSelection[]
  sortKey?: string
  reverse?: boolean
}

export const useShopifySearch = (
  queryInfo: QueryInfo,
  preventFetching: boolean = false,
  initialValues?: Product[]
) => {
  const {
    productQuery,
    productQueryCondition,
    productOption,
    manualSelection,
    resultsPerPage,
    sortKey,
    reverse
  } = queryInfo || {}
  const [loading, setLoading] = useState(false)
  const [cursors, setCursors] = useState<string[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null)
  const [products, setProducts] = useState<Product[]>(initialValues || [])
  const { isRaysClub } = useCustomerContext()

  let query: string[] = []

  if (productOption === 'query') {
    productQuery?.forEach((element: DataQuery) => {
      const queryString = `${element.categorySelector}:"${[element.categoryString]}"`
      query.push(queryString)
    })
  }

  if (productOption === 'manual') {
    manualSelection?.forEach((item: ManualProductSelection) => {
      item?.product?.gid &&
        query.push(`${item?.product?.gid.replace('gid://shopify/Product/', '')}`)
    })
  }

  const parsedQuery =
    query.length > 0
      ? query.join(` ${productQueryCondition === 'inclusive' ? 'AND' : 'OR'} `)
      : query[0]

  const getData = useCallback(
    async (firstRender?: boolean, nextCursor?: Maybe<string>) => {
      const { products, pageInfo, cursors } = await getProductsLight({
        query: `${parsedQuery} AND NOT tag:"Discontinued" AND NOT (tag:Hide From Search) ${!isRaysClub ? 'AND NOT (tag:raysclub)' : ''}`,
        first: resultsPerPage,
        after: nextCursor || undefined,
        sortKey,
        reverse
      })
      setCursors(cursors)
      setPageInfo(pageInfo)
      !firstRender &&
        setProducts((prev) => [
          ...prev,
          ...products.sort(
            (a, b) => parseInt(b?.rank?.value || '0') - parseInt(a?.rank?.value || '0')
          )
        ])
      const sortedProducts = sortKey
        ? products
        : products.sort((a, b) => parseInt(b?.rank?.value || '0') - parseInt(a?.rank?.value || '0'))
      firstRender && setProducts(sortedProducts)
    },
    [parsedQuery, resultsPerPage, sortKey, reverse, isRaysClub]
  )

  useEffect(() => {
    if (!preventFetching) {
      setLoading(true)
      getData(true)
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey, reverse])

  const getNextPage = async () => {
    getData(false, pageInfo?.endCursor)
  }

  return {
    data: {
      loading,
      cursors,
      products,
      hasNextPage: pageInfo?.hasNextPage
    },
    getNextPage
  }
}
