'use client'
interface Props {
  isLoading: boolean
  iconSize?: number
}
const LoadingIndicator = ({ isLoading, iconSize = 32 }: Props) => {
  if (!isLoading) {
    return
  }
  return (
    <div className="flex w-full items-center justify-center">
      <span
        aria-hidden="true"
        className="material-symbols-outlined inline-block animate-spin"
        style={{ fontSize: `${iconSize}px` }}
      >
        progress_activity
      </span>
    </div>
  )
}
export default LoadingIndicator
