import { SITE_URL } from '@/app/(store)/constants'
import { Brand } from 'schema-dts'

export const brandSchema: Brand = {
  '@type': 'Brand',
  name: 'recteq',
  logo: `${SITE_URL}/logo.png`,
  description:
    'recteq high quality smokers and grills with WiFi technology will have you spend less time guessing and more time enjoying your grill.'
}
