'use client'

import { useState } from 'react'

interface VoteReviewProps {
  reviewId: number
  initialUpvotes: number
  initialDownvotes: number
}

export function VoteReview({ reviewId, initialUpvotes, initialDownvotes }: VoteReviewProps) {
  const [upvotes, setUpvotes] = useState(initialUpvotes)
  const [downvotes, setDownvotes] = useState(initialDownvotes)
  const [userVote, setUserVote] = useState<'up' | 'down' | null>(null)
  const [isVoting, setIsVoting] = useState(false)

  async function doVote(voteType: 'up' | 'down') {
    setIsVoting(true)

    try {
      const res = await fetch('/api/yotpo/postVoteReview', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reviewId, voteType })
      })
      if (!res.ok) {
        throw new Error(`Failed to vote: ${res.status}`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsVoting(false)
    }
  }

  async function undoVote(voteType: 'up' | 'down') {
    setIsVoting(true)

    try {
      const res = await fetch('/api/yotpo/undoVoteReview', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reviewId, voteType })
      })
      if (!res.ok) {
        throw new Error(`Failed to undo vote: ${res.status}`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsVoting(false)
    }
  }

  async function handleUpvote() {
    if (isVoting) return // ignore clicks if we’re mid-request
    // if user already upvoted, clicking again => undo
    if (userVote === 'up') {
      // local state updates
      setUpvotes((prev) => prev - 1)
      setUserVote(null)
      // server call => undo
      await undoVote('up')
      return
    }

    // if user was downvoting, remove that
    if (userVote === 'down') {
      setDownvotes((prev) => prev - 1)
      await undoVote('down') // remove downvote on server
    }

    // now upvote
    setUpvotes((prev) => prev + 1)
    setUserVote('up')
    await doVote('up')
  }

  async function handleDownvote() {
    if (isVoting) return // ignore clicks if we’re mid-request
    // if user already downvoted, clicking again => undo
    if (userVote === 'down') {
      setDownvotes((prev) => prev - 1)
      setUserVote(null)
      await undoVote('down')
      return
    }

    // if user was upvoting, remove that
    if (userVote === 'up') {
      setUpvotes((prev) => prev - 1)
      await undoVote('up')
    }

    // now downvote
    setDownvotes((prev) => prev + 1)
    setUserVote('down')
    await doVote('down')
  }

  return (
    <div className="flex items-center gap-2">
      {/* Upvote */}
      <button onClick={handleUpvote} className="flex items-center gap-1 focus:outline-none">
        <span
          className={
            'material-symbols-outlined cursor-pointer text-sm ' +
            (userVote === 'up' ? 'text-red-500' : 'text-secondary')
          }
        >
          arrow_upward
        </span>
        <span className="p text-sm">{upvotes}</span>
      </button>

      {/* Downvote */}
      <button onClick={handleDownvote} className="flex items-center gap-1 focus:outline-none">
        <span
          className={
            'material-symbols-outlined cursor-pointer text-sm ' +
            (userVote === 'down' ? 'text-red-500' : 'text-secondary')
          }
        >
          arrow_downward
        </span>
        <span className="p text-sm">{downvotes}</span>
      </button>
    </div>
  )
}
