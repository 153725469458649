export const isCustomerAllowed = (allowedUsers: string[], customerTypes: string[]) => {
  let isAllowed = false

  allowedUsers?.forEach((role) => {
    if (customerTypes?.includes(role)) {
      isAllowed = true
    }
  })

  return isAllowed
}
