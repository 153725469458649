'use client'
import ImageGallery from './image-gallery'

interface Props {
  featuredImage: Image
}
const ProductGallery = ({ featuredImage }: Props) => {
  return (
    <div className="col-span-1 mb-4 sm:col-span-2 sm:px-[20%] md:col-span-1 md:px-0 xl:col-span-3 xl:mb-0">
      <ImageGallery thumbnailsDisplayType="grid" featuredPageImage={featuredImage} />
    </div>
  )
}

export default ProductGallery
