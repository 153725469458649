import Image from 'next/image'

enum TextStyle {
  default = 'p text-secondary',
  minimal = 'p-large font-bold text-secondary'
}

enum Type {
  default,
  minimal
}

interface Props {
  icon?: Sanity.Image
  title: string
  text?: string
  type?: keyof typeof Type
}

export const IconBox = ({ icon, title, text, type = 'default' }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="relative h-16 w-16 text-secondary">
        {icon && (
          <Image
            src={icon?.asset?.url}
            alt={icon?.asset?.altText || ''}
            title={icon?.asset?.title}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        )}
      </div>
      <div className="flex flex-col gap-3 text-center">
        <h4 className="h4 text-secondary">{title}</h4>
        {text && <p className={`${TextStyle[type]}`}>{text}</p>}
      </div>
    </div>
  )
}
