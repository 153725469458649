/*
 * Parse money format
 */
export const parseMoneyFormat = (amount: string | number, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(Number(amount))
}
