'use client'

import { useState } from 'react'
import { fetchSanity, PaginatedArticles } from '../lib'

interface Props {
  initialArticles: Sanity.Article[]
  articlesCount: number
}
export const useSanityArticles = ({ initialArticles }: Props) => {
  const [articles, setArticles] = useState(initialArticles)

  async function getNextArticlePage(lastId: string | null) {
    const data = (await fetchSanity(PaginatedArticles, { params: { lastId } as any })) || []
    setArticles([...articles, ...data])
  }
  return {
    articles,
    getNextArticlePage
  }
}
