'use client'
import { getHrefFromLinkType } from '@/utils'
import SanityLink from '../link'

interface Props {
  supportMenuItems: SupportMenuItem[]
}
const SupportMenu = ({ supportMenuItems }: Props) => {
  const supportMenu = supportMenuItems?.map((item) => {
    return {
      ...item,
      icon: item?.text?.name
    }
  })
  const launchZendesk = () => {
    if (typeof window !== 'undefined' && window.zE) {
      const zE = window.zE || {}
      zE('messenger', 'open')
    }
  }

  return (
    <div className="col-span-2 gap-4 lg:col-span-1 lg:flex lg:flex-col">
      <h3 className="h4 mb-4 lg:mb-0">Get Help</h3>
      <nav aria-label="Support navigation menu" className="p text-white/80">
        <ul className="flex flex-col gap-4">
          {supportMenu?.map(({ id, icon, title, subtitle, link }, idx) => {
            if (!link) {
              return
            }
            return (
              <li key={id + idx.toString()} className="flex flex-nowrap items-center gap-4">
                <span className="material-symbols-outlined">{icon}</span>
                <div className="p flex flex-col text-gray-secondary">
                  {link?.url != 'zendesk' && (
                    <SanityLink href={getHrefFromLinkType(link)}>
                      <h4 className="h5 lg:h6 uppercase">{title}</h4>
                      <p className="p text-sm">{subtitle}</p>
                    </SanityLink>
                  )}

                  {link?.type === 'external' && link.url == 'zendesk' && (
                    <button className="block text-left" onClick={launchZendesk}>
                      <h4 className="h5 lg:h6 uppercase">{title}</h4>
                      <p className="p text-sm">{subtitle}</p>
                    </button>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

export default SupportMenu
