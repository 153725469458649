'use client'
import { Details, ProductGallery } from '@/components'
import { ProductContextProvider } from '@/context'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
interface Props {
  searchParams: Record<string, string>
  product: Product
  currentVariant: ProductVariant
  currentBundle?: Product | null
  page: Sanity.Page
  featuredImage: Image
  productReviewData: any
  etchAteq?: boolean
  customer?: Customer
}
const ProductInfo = ({
  searchParams,
  product,
  currentVariant,
  currentBundle,
  featuredImage,
  productReviewData,
  etchAteq = false,
  customer
}: Props) => {
  const hashParams = useSearchParams()
  useEffect(() => {
    const hash = hashParams.get('scrollTo')
    if (hash && hash?.includes('product-reviews')) {
      const element = document.getElementById('product-reviews')
      element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }, [hashParams])
  return (
    <section className={`module-spacing min-h-[75svh]`}>
      <div className="text-black dark:bg-black">
        <div className="container z-10 py-5 pt-0 dark:mb-0 dark:pb-10">
          <section className="grid min-h-[55svh] grid-cols-1 sm:grid-cols-2 md:gap-5 xl:grid-cols-7 xl:gap-10">
            <ProductContextProvider
              searchParams={searchParams}
              currentBundle={currentBundle || null}
              currentVariant={currentVariant}
              product={product}
            >
              <ProductGallery featuredImage={featuredImage} />
              <Details
                customer={customer}
                bottomline={productReviewData?.bottomline}
                etchAteq={etchAteq}
                darkMode={true}
              />
            </ProductContextProvider>
          </section>
        </div>
      </div>
    </section>
  )
}

export default ProductInfo
