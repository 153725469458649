'use client'

import React, { createContext, useContext, useMemo, useCallback, ReactNode } from 'react'
import { eventHandlers, GtmEventKey } from '@/hooks/useGtmEvents'

/**
 * 1) Define a type for your context value.
 *    We’ll have just one function: fireEvent(key, data).
 */
type GtmContextType = {
  fireEvent: (key: GtmEventKey, data?: any) => void
}

/**
 * 2) Create the context with a default of "undefined"
 *    so we can safely detect if it's missing at runtime.
 */
const GtmContext = createContext<GtmContextType | undefined>(undefined)

/**
 * 3) Create a Provider component that wraps your application.
 */
export function MockGtmlProvider({ children }: any) {
  return <GtmContext.Provider value={{ fireEvent: (_: any) => {} }}>{children}</GtmContext.Provider>
}
export function GtmProvider({ children }: { children: ReactNode }) {
  const isOptedOut = useMemo(() => {
    if (typeof window === 'undefined') return false
    const cookies = document.cookie.split('; ')
    const consentCookie = cookies.find((cookie) => cookie.startsWith('cookieyes-consent='))
    return consentCookie ? consentCookie.includes('consent:yes') : false
  }, [])
  /**
   * Our single function: fireEvent.
   *
   * - Looks up the function in eventHandlers (by the key).
   * - Calls it if found, otherwise logs a warning.
   */
  const fireEvent = useCallback((key: GtmEventKey, data?: any) => {
    if (isOptedOut) {
      return
    }
    const handler = eventHandlers[key]
    if (!handler) {
      console.warn(`No GTM event handler found for key: "${key}"`)
      return
    }
    handler(data)
  }, [])

  return <GtmContext.Provider value={{ fireEvent }}>{children}</GtmContext.Provider>
}

/**
 * 4) Create a simple hook to consume this context.
 */
export function useGtm() {
  const context = useContext(GtmContext)
  if (!context) {
    throw new Error('useGtm must be used within a GtmProvider')
  }
  return context
}
