type Props = {
  children: React.ReactNode
  config?: Sanity.ComponentConfig
}

const ModuleHandler = ({ children, config }: Props) => {
  if (config?.hideComponent) {
    return <></>
  }
  return <>{children}</>
}

export default ModuleHandler
