'use client'

import React, { useState, useEffect } from 'react'
import { fetchYotpoSiteReviewsPage } from '@/utils'
import {
  ReviewItem,
  LoadingIndicator,
  Select,
  Button,
  YotpoLoadMore,
  ProductScore,
  YotpoReviewForm,
  ModuleHandler
} from '@/components'

interface ReviewSortOption {
  label: string
  value: string
}
interface SiteReviewsProps {
  heading?: string
  componentGlobalConfig?: Sanity.ComponentConfig
}

export function SiteReviews({
  heading = 'recteq Reviews',
  componentGlobalConfig
}: SiteReviewsProps) {
  const [reviewsData, setReviewsData] = useState<any[]>([])
  const [bottomline, setBottomline] = useState<any>(null)
  const [totalReviews, setTotalReviews] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortOption, setSortOption] = useState<ReviewSortOption>({
    label: 'Newest',
    value: 'Newest'
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  // On mobile, we can toggle the review form
  const [mobileFormOpen, setMobileFormOpen] = useState(false)

  // Classes to hide/show columns on mobile
  const leftColClasses = mobileFormOpen ? 'hidden md:block' : ''
  const rightColClasses = mobileFormOpen ? '' : 'hidden md:block'

  /** Fetch site reviews (page=1 => reset, page>1 => append) */
  async function loadReviews(page: number, sortOpt: ReviewSortOption) {
    setError(null)
    try {
      setLoading(true)
      const { sort, direction } = translateSortOption(sortOpt.value)
      const data = await fetchYotpoSiteReviewsPage({
        page,
        perPage: 50,
        sort,
        direction
      })

      // Yotpo typical shape: { reviews, bottomline, pagination: { total, ... } }
      const { reviews, bottomline, pagination } = data
      const newReviews = reviews || []
      const total = pagination?.total || 0

      setTotalReviews(total)
      setBottomline(bottomline)
      setCurrentPage(page)

      if (page === 1) {
        setReviewsData(newReviews)
      } else {
        setReviewsData((prev) => [...prev, ...newReviews])
      }
    } catch (err) {
      console.error('Failed to fetch site reviews:', err)
      setError('Failed to fetch site reviews.')
    } finally {
      setLoading(false)
    }
  }

  function handleLoadMore(page: number) {
    loadReviews(page, sortOption)
  }

  function handleSortChange(newSortOpt: ReviewSortOption) {
    setSortOption(newSortOpt)
    // Reset to page=1
    loadReviews(1, newSortOpt)
  }

  useEffect(() => {
    // Fetch first page on mount
    loadReviews(currentPage, sortOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Called when user wants to open the form on mobile */
  function handleWriteReview() {
    setMobileFormOpen(true)
  }

  /** Called by the form to close it on mobile */
  function handleCloseForm() {
    setMobileFormOpen(false)
  }

  // If error & no reviews loaded
  if (error && reviewsData.length === 0) {
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <div className="flex min-h-[20rem] items-center justify-center">
          <p className="p">{error}</p>
        </div>
      </ModuleHandler>
    )
  }

  // If no reviews at all
  if (!reviewsData || reviewsData.length === 0) {
    return (
      <ModuleHandler config={componentGlobalConfig}>
        <div className="flex min-h-[20rem] items-center justify-center">
          <p className="p text-center">No site reviews found.</p>
        </div>
      </ModuleHandler>
    )
  }

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section className="pt-10 text-secondary">
        <div className="module-spacing container font-oswald" data-component="site-reviews">
          {/* TOP ROW */}
          <div className="top-row flex w-full flex-col items-start md:flex-row md:gap-16">
            {/* Left col: Title */}
            <div className="left-col order-1 mb-4 w-full flex-1 md:w-2/5">
              <h2 className="h2 mb-6 font-bold">{heading}</h2>
            </div>
            {/* Right col: star distribution + average score */}
            <div className="right-col order-2 w-full flex-1 md:w-2/5">
              {bottomline && <ProductScore bottomline={bottomline} totalQuestions={-1} />}
            </div>
          </div>

          {/* BOTTOM ROW */}
          <div className="bottom-row mt-2 flex w-full flex-col items-start md:flex-row md:gap-16">
            {/* Left col: Reviews list (hidden on mobile if form open) */}
            <div className={`left-col flex-1 ${leftColClasses} w-full md:w-2/5`}>
              {/* "Sort By" + "Write a Review" on mobile */}
              <div className="mb-4 flex items-center justify-between gap-2 border-b pb-4 md:justify-end">
                {/* Write a review button (mobile only) */}
                <Button
                  size="minimal"
                  color="primary-outline"
                  className="block md:hidden"
                  onClick={handleWriteReview}
                >
                  Write a Review
                </Button>
                <label htmlFor="sortDropdown" className="h6 hidden md:block md:w-1/2">
                  Sort By:
                </label>
                {/* Sorting (desktop + mobile) */}
                <div className="text-right md:w-1/2">
                  <Select
                    placeholder={sortOption.label || 'Sort By'}
                    onChange={handleSortChange}
                    options={[
                      { label: 'Newest', value: 'Newest' },
                      { label: 'Highest Rating', value: 'HighestRating' },
                      { label: 'Lowest Rating', value: 'LowestRating' },
                      { label: 'Most Upvotes', value: 'MostUpvotes' }
                    ]}
                  />
                </div>
              </div>

              <div className="mb-4 pr-4 md:max-h-[35rem] md:overflow-y-auto">
                {reviewsData.map((review) =>
                  review.score > 0 ? <ReviewItem key={review.id} review={review} /> : null
                )}
                <YotpoLoadMore
                  loading={loading}
                  perPage={6}
                  total={totalReviews}
                  onPageChange={handleLoadMore}
                />
                {/* If loading next page, show a small spinner */}
                {loading && reviewsData.length > 0 && (
                  <div className="mt-4 flex justify-center">
                    <LoadingIndicator isLoading />
                  </div>
                )}
              </div>
            </div>

            {/* Right col: The review form (visible on desktop, toggled on mobile) */}
            <div className={`right-col flex-1 ${rightColClasses} w-full md:w-2/5`}>
              <YotpoReviewForm
                productId="yotpo_site_reviews"
                productTitle="Site Review"
                productUrl="recteq.com"
                onCloseForm={handleCloseForm}
              />
            </div>
          </div>
        </div>
      </section>
    </ModuleHandler>
  )
}

/** Translate user-friendly sort labels to Yotpo's API sorting/direction. */
function translateSortOption(option: string) {
  switch (option) {
    case 'Newest':
      return { sort: 'date', direction: 'desc' }
    case 'HighestRating':
      return { sort: 'rating', direction: 'desc' }
    case 'LowestRating':
      return { sort: 'rating', direction: 'asc' }
    case 'MostUpvotes':
      return { sort: 'votes_up', direction: 'desc' }
    default:
      return { sort: '', direction: '' }
  }
}
