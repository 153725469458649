'use client'
enum Colors {
  primary = 'bg-primary text-white border-[3px] border-primary hover:bg-primaryHover hover:border-primaryHover disabled:bg-gray-secondary',
  secondary = 'bg-secondary text-white border-[3px] border-secondary hover:border-secondaryHover hover:bg-secondaryHover disabled:bg-gray-secondary',
  'primary-outline' = 'bg-transparent text-primary border-[3px] border-primary hover:text-white hover:bg-primary disabled:text-gray-secondary',
  'secondary-outline' = 'bg-transparent text-secondary border-[3px] border-secondary hover:bg-secondary hover:text-white disabled:text-gray-secondary',
  'white-outline' = 'text-white bg-transparent border-[3px] border-white disabled:text-gray-secondary'
}
enum Sizes {
  minimal = '_h px-2 py-1 min-w-[100px] text-center',
  normal = '_h px-4 min-w-[110px] py-2 leading-tight text-center',
  large = 'h6 px-8 py-4 text-center'
}

interface renderButton {
  testAttr: string
  color: string
  size: string
  text: string
  animation: string
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: keyof typeof Colors
  size?: keyof typeof Sizes
  icon?: React.ReactNode
  className?: string
  renderButton?: (props: renderButton) => React.ReactNode
  external?: boolean
}

export const Button = ({
  children,
  renderButton,
  className = '',
  size = 'minimal',
  color = 'primary',
  icon,
  ...rest
}: Props) => {
  const headingStyles = 'uppercase font-semibold'
  const animation = 'duration-150'

  return (
    <>
      {renderButton &&
        renderButton({
          testAttr: 'button',
          color: Colors[color],
          size: Sizes[size],
          text: headingStyles,
          animation: animation
        })}

      {!renderButton && (
        <button
          {...rest}
          data-test="button"
          className={`
            ${className}
            ${headingStyles}
            ${Sizes[size]}
            ${Colors[color]}
            ${icon ? 'inline-flex justify-between' : 'justify-center'} 
            ${animation}
            min-w-[110px] cursor-pointer
            items-center disabled:pointer-events-none disabled:border-gray-secondary
        `}
        >
          {children}
          {icon && icon}
        </button>
      )}
    </>
  )
}
