'use client'
import Link from 'next/link'
import Drawer from '@/components/drawer'
import Image from 'next/image'

const ProductPolicies = () => {
  return (
    <>
      <p className="_p mt-2 text-sm">Expected to ship in 1-2 business days</p>
      <section className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div className="col-span-1 flex items-center">
          <span aria-hidden="true" className="material-symbols-outlined mr-2">
            local_shipping
          </span>
          <p className="_p text-xs">Free shipping over $99 in the lower 48 states</p>
        </div>
        <div className="col-span-1 flex items-center">
          <span aria-hidden="true" className="material-symbols-outlined mr-2">
            currency_exchange
          </span>
          <p className="_p text-xs">
            Easy returns -{' '}
            <Link href="/explore/shipping-and-returns" className="link dark:text-white">
              Return Policy
            </Link>
          </p>
        </div>
        <div className="col-span-1">
          <Drawer
            title="Estimated In-Transit Time"
            icon="chevron_right"
            className="w-full lg:max-w-[50%]"
            renderButton={(handleOpen) => {
              return (
                <button onClick={handleOpen} className="flex items-center">
                  <span aria-hidden="true" className="material-symbols-outlined mr-2">
                    calendar_clock
                  </span>
                  <p className="_p text-xs underline">Estimated delivery time</p>
                </button>
              )
            }}
          >
            <div className="px-4">
              <h3 className="h4 my-4">Estimated In-Transit Time</h3>
              <Image
                src="/images/ESTIMATED-DELIVERY-TIME_1.webp"
                data-media="/images/ESTIMATED-DELIVERY-TIME_1.webp"
                alt="Estimated in time transit graphic"
                width={700}
                height={700}
                className="h-full w-full object-cover"
              />
            </div>
          </Drawer>
        </div>
      </section>
    </>
  )
}

export default ProductPolicies
