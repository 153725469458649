export const accessibilityFeature = [
  'ARIA',
  'KeyboardAccessible',
  'ScreenReader',
  'index',
  'readingOrder',
  'structuralNavigation',
  'alternativeText',
  'horizontalWriting',
  'withAdditionalWordSegmentation'
]

export const accessibilityHazard = ['none']

export const accessibilitySummary = 'This publication meets WCAG 2.1 Level AA.'

export const accessMode = ['textual', 'visual']
