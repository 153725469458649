'use client'

import { parseMoneyFormat } from '@/app/(store)/utils'
import { AddToCart } from '@/components'
import { useEtchATeqContext } from '@/context'

const SelectionResume = () => {
  const {
    customizationOption,
    selectedFee,
    selectedDesign,
    selectedProduct,
    selectedVariant,
    text,
    subtotal,
    available,
    hasSomeAvailableVariant
  } = useEtchATeqContext()

  const show =
    !available ||
    !hasSomeAvailableVariant ||
    !selectedProduct ||
    (selectedProduct && selectedProduct.variants.length > 1 && !selectedVariant)

  return (
    <section
      className={`module-spacing container mt-6 flex flex-col gap-6 ${show && 'pointer-events-none opacity-40'}`}
    >
      <div className="flex">
        <div className="w-full bg-gray p-4 lg:w-[500px] lg:max-w-[1000px]">
          <h3 className="h3 mb-4">summary</h3>
          <div className="flex flex-col flex-wrap gap-2">
            <p>
              <b>Product: </b>
              {selectedProduct?.title || 'N/A'}
            </p>
            {customizationOption !== 'text-only' && (
              <p>
                <b>Design: </b>
                {!selectedDesign?.name ? (
                  <span className="text-secondary">Please select an image for your product.</span>
                ) : (
                  selectedDesign?.name
                )}
              </p>
            )}
            {customizationOption !== 'image-only' && (
              <p>
                <b>Text: </b>
                {!text ? (
                  <span className="text-secondary">
                    Please write a custom text for your product.
                  </span>
                ) : (
                  text
                )}
              </p>
            )}

            {subtotal ? (
              <p>
                <b>Subtotal: </b> {parseMoneyFormat(subtotal)}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="lg:max-w-[350px]">
        {selectedProduct && (
          <AddToCart
            isPending={false}
            additionalProducts={selectedFee ? [selectedFee?.variants[0].id] : undefined}
            product={selectedProduct}
            selectedVariant={selectedVariant || selectedProduct.variants[0]}
            attributes={[
              {
                key: 'engraving',
                value: text || ''
              },
              {
                key: 'design',
                value: selectedDesign?.name || ''
              }
            ]}
          />
        )}
      </div>
    </section>
  )
}

export default SelectionResume
