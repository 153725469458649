import Image from 'next/image'
import { PortableTextBlock } from 'next-sanity'
import { CTA, CTALayout, ModuleHandler, RichTextLight } from '@/components'

enum BackgroundColor {
  gray = 'bg-gray',
  none = 'bg-transparent'
}
enum ContainerAlignment {
  default = 'order-last lg:order-first',
  reverse = 'order-last swap text-left list-inside justify-end items-start'
}

type TemperatureRange = {
  min: number
  max: number
}

interface Props {
  backgroundColor: keyof typeof BackgroundColor
  alignment: keyof typeof ContainerAlignment

  title: string
  body?: PortableTextBlock[]
  media?: Sanity.Media

  temperatureRange?: TemperatureRange
  feedAmount?: {
    min: NumericRange<1, 20>
    max: NumericRange<1, 20>
  }

  wifiEnabled: boolean
  cta?: {
    link: Sanity.Link
  } & CTALayout
  componentGlobalConfig?: Sanity.ComponentConfig
}
export const FullBannerTwoColumns = ({
  backgroundColor,
  alignment = 'default',
  media,
  title,
  body,
  temperatureRange,
  feedAmount,
  wifiEnabled = false,
  cta,
  componentGlobalConfig
}: Props) => {
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section
        className={`${BackgroundColor[backgroundColor]} mb-[32px] text-secondary lg:mb-[64px]`}
        data-component="full-banner-two-columns"
      >
        <div className="container col-span-1 grid items-center justify-between lg:grid-cols-8">
          <div
            className={`flex flex-col gap-5 lg:col-span-4 lg:gap-10 lg:p-10 lg:py-5 lg:pl-0 ${ContainerAlignment[alignment]}`}
          >
            <h2 className="h1">{title}</h2>
            {body && <RichTextLight content={body} />}

            {(temperatureRange || feedAmount || wifiEnabled) && (
              <div className="p flex flex-col gap-6 font-bold sm:flex-row sm:flex-wrap ">
                {temperatureRange && (
                  <p className="inline-flex items-center gap-3">
                    <span
                      aria-hidden="true"
                      className="material-symbols-outlined rounded-full bg-gray-secondary p-2"
                    >
                      thermostat
                    </span>
                    {temperatureRange.min}°F - {temperatureRange.max}°F
                  </p>
                )}
                {feedAmount && (
                  <p className="inline-flex items-center gap-3">
                    <span
                      aria-hidden="true"
                      className="material-symbols-outlined rounded-full bg-gray-secondary p-2"
                    >
                      groups
                    </span>
                    {feedAmount.min}-{feedAmount.max} people
                  </p>
                )}
                {wifiEnabled && (
                  <p className="inline-flex items-center gap-3">
                    <span
                      aria-hidden="true"
                      className="material-symbols-outlined rounded-full bg-gray-secondary p-2"
                    >
                      wifi
                    </span>
                    Wi-Fi
                  </p>
                )}
              </div>
            )}

            {cta &&
              ((cta.link.type == 'internal' && !!cta.link.internalLink) ||
                cta.link.type == 'external') && (
                <div>
                  <CTA
                    as="button-link"
                    link={cta.link}
                    layout={{
                      color: cta.color,
                      size: cta.size
                    }}
                    component="full-banner-two-columns"
                  />
                </div>
              )}
          </div>
          <div className={`relative lg:col-span-3`}>
            {media?.asset?.url && (
              <>
                {media?.asset?.extension === 'mp4' && (
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="absolute left-0 top-0 h-full w-full object-cover"
                  >
                    <source src={media.asset.url} type="video/mp4" />
                  </video>
                )}
                {media?.asset?.extension != 'mp4' && (
                  <Image
                    src={media.asset.url}
                    data-media={media.asset.url}
                    alt={media?.asset?.altText || ''}
                    width={685}
                    height={532}
                    className="h-full w-full mix-blend-multiply"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </ModuleHandler>
  )
}
