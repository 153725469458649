import React from 'react'
import Image from 'next/image'
import ModuleHandler from '../module-handler'

interface ImageMosaicProps {
  images: Sanity.Media[]
  pattern: 'simple-square' | 'rect-square-alternating'
  rowHeight?: number
  componentGlobalConfig?: Sanity.ComponentConfig
}

export const ImageMosaic = ({
  images = [],
  pattern,
  rowHeight = 220,
  componentGlobalConfig
}: ImageMosaicProps) => {
  if (!images || images.length === 0) {
    return <></>
  }
  const buildPattern = () => {
    return images?.map((image, index) => {
      let flexGrowClass = 'grow-[1]'

      // Determine the position within the current chunk of 8 images
      const positionInChunk = index % 8

      if (pattern === 'rect-square-alternating') {
        switch (positionInChunk) {
          case 0:
            flexGrowClass = 'grow-[4]'
            break
          case 1:
            flexGrowClass = 'grow-[1]'
            break
          case 2:
            flexGrowClass = 'grow-[1] sm:grow-[4]'
            break
          case 3:
            flexGrowClass = 'grow-[4] sm:grow-[1]'
            break
          case 4:
            flexGrowClass = 'grow-[1]'
            break
          case 5:
            flexGrowClass = 'grow-[1] sm:grow-[4]'
            break
          case 6:
            flexGrowClass = 'grow-[4] sm:grow-[1]'
            break
          case 7:
            flexGrowClass = 'grow-[1] sm:grow-[4]'
            break
          default:
            break
        }
      }

      return {
        ...image,
        flexGrowClass,
        height: rowHeight
      }
    })
  }

  const imageElements = buildPattern()

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <div
        className="module-spacing container flex justify-center overflow-hidden"
        data-component="imageMosaic"
      >
        <div className="mx-auto my-0 flex max-w-[1536px] shrink-0 grow-0 basis-full flex-wrap gap-2 overflow-hidden md:basis-[960px] lg:basis-[1080px] xl:basis-[1500px]">
          {imageElements?.map((image, index) => (
            <div
              key={image?.asset?.assetId && image?.asset?.assetId + index}
              className={`h-40 basis-2/5 lg:h-60 lg:basis-1/5 xl:h-80 ${image?.flexGrowClass}`}
            >
              <Image
                src={image?.asset?.url}
                alt={image?.asset?.altText || image?.asset?.extension || ''}
                width={200}
                height={image?.height}
                className="h-full w-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </ModuleHandler>
  )
}
