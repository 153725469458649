interface Props {
  product: Product
  bundle?: Product | null
  size?: string
}

const ProductTitle = ({
  bundle,
  product,
  size = 'title mb-2 font-semibold text-secondary dark:text-white'
}: Props) => {
  const { display_title: product_display_title, title: product_title } = product || {}
  const { display_title: bundle_display_title, title: bundle_title } = bundle || {}

  const defaultTitle = product_display_title?.value || product_title || bundle_title
  const displayTitleBundle =
    (product_display_title?.value || product_title) +
    ' + ' +
    (bundle_display_title?.value || bundle_title)

  return (
    <p
      id={bundle?.id || product?.id}
      data-test={`product-title-${bundle?.id || product?.id}`}
      className={`${size} text-secondary dark:text-white`}
    >
      {bundle ? displayTitleBundle : defaultTitle}
    </p>
  )
}

export default ProductTitle
