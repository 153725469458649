'use client'

import React, { useState } from 'react'
import { ReviewItem, Select, Button } from '@/components'
import { fetchYotpoReviewsPage } from '@/utils'
import { YotpoLoadMore } from './loadMore'
interface ProductReviewsProps {
  productId: string
  totalReviews?: number
  // A callback from the parent that we call if user wants to "write a review"
  onWriteReview?: () => void
  reviews: any
}
interface ReviewSortOption {
  label: string
  value: string
}
export function ProductReviews({
  productId,
  totalReviews = 0,
  reviews,
  onWriteReview
}: ProductReviewsProps) {
  const [reviewsData, setReviewsData] = useState<any[]>(reviews) // The actual reviews from server
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [sortOption, setSortOption] = useState<ReviewSortOption>({ label: '', value: '' })

  async function loadReviews(page: number, sortOpt: ReviewSortOption) {
    setError(null)
    try {
      setLoading(true)
      const { sort, direction } = translateSortOption(sortOpt.value)
      const data = await fetchYotpoReviewsPage({
        productId,
        page,
        perPage: 6,
        sort,
        direction
      })
      const { reviews } = data

      if (page === 1) {
        setReviewsData(reviews)
      } else {
        setReviewsData((prevReviews) => [...prevReviews, ...reviews])
      }
    } catch (err) {
      setError('Failed to load reviews.')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  function handleSortChange(newSortOpt: ReviewSortOption) {
    setSortOption(newSortOpt)
    loadReviews(1, newSortOpt)
  }
  const handleLoadMore = (page: number) => {
    loadReviews(page, sortOption)
  }

  if (error) {
    return (
      <div className="flex min-h-[20rem] items-center justify-center">
        <p className="p">{error}</p>
      </div>
    )
  }
  if (!reviewsData || reviewsData.length === 0) {
    return (
      <div className="flex min-h-[20rem] items-center justify-center">
        <p className="p text-center">No reviews found.</p>
      </div>
    )
  }
  return (
    <div>
      {/* Sort By dropdown */}
      <div className="mb-4 flex items-center justify-between gap-2 border-b pb-8 md:justify-end">
        {/* "Write a Review" button (visible only on mobile) */}
        <Button
          size="minimal"
          color="primary-outline"
          className="block md:hidden"
          onClick={onWriteReview}
        >
          Write a Review
        </Button>
        <label htmlFor="sortDropdown" className="h6 hidden md:block md:w-1/2">
          Sort By:
        </label>
        <div className="md:w-1/2">
          <Select
            placeholder={`${sortOption.label || 'Sort By'}`}
            onChange={(data) => handleSortChange(data)}
            inputStyles="text-base"
            options={[
              {
                label: 'Newest',
                value: 'Newest'
              },
              {
                label: 'Highest Rating',
                value: 'HighestRating'
              },
              {
                label: 'Lowest Rating',
                value: 'LowestRating'
              },
              {
                label: 'Most Upvotes',
                value: 'MostUpvotes'
              }
            ]}
          />
        </div>
      </div>
      <div className="mb-4 pr-4 md:max-h-[35rem] md:overflow-y-auto">
        {reviewsData.length &&
          reviewsData?.map((review) =>
            review.score > 0 ? <ReviewItem key={review.id} review={review} /> : <></>
          )}
        <YotpoLoadMore
          perPage={6}
          total={totalReviews}
          onPageChange={(newPage) => handleLoadMore(newPage)}
          loading={loading}
        />
      </div>
    </div>
  )
}

function translateSortOption(option: string) {
  switch (option) {
    case 'Newest':
      // newest => sort by date, descending
      return { sort: 'date', direction: 'desc' }
    case 'HighestRating':
      // highest rating => sort by rating, descending
      return { sort: 'rating', direction: 'desc' }
    case 'LowestRating':
      // lowest rating => sort by rating, ascending
      return { sort: 'rating', direction: 'asc' }
    case 'MostUpvotes':
      // most upvotes => sort by votes_up, descending
      return { sort: 'votes_up', direction: 'desc' }
    default:
      // no sorting
      return { sort: '', direction: '' }
  }
}
