import { useSearchParams } from 'next/navigation'

function useGetAllSearchParams() {
  const searchParams = useSearchParams()
  const params: Record<string, string[]> = {}

  searchParams.forEach((value, key) => {
    const prev = params[key]

    if (prev) {
      params[key] = [...prev, value]
    } else {
      params[key] = [value]
    }
  })

  return params
}

export default useGetAllSearchParams
