'use client'

import toast from 'react-hot-toast'
import { Button, Input } from '@/components'
import { useGtm, useCompareContext } from '@/context'

const AddToCompare = ({ product }: { product: Product }) => {
  const { addToProductToCompare, removeProductToCompare, previews } = useCompareContext()
  const { fireEvent } = useGtm()
  return (
    <label
      tabIndex={0}
      title="Add to compare"
      aria-label={`Add ${product.title} to compare`}
      className="p absolute right-1 top-1 z-10 inline-block"
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          e.target.click()
        }
      }}
    >
      <span aria-hidden="true" className="hidden aspect-1 h-[16xp] w-[16px]">
        <Input
          type="checkbox"
          checked={previews.find((prev) => prev.id === product.id) ? true : false}
          onChange={(e) => {
            !e.target.checked && toast.success('Product removed from the compare list')
            e.target.checked && addToProductToCompare(product)
            !e.target.checked && removeProductToCompare(product)
            e.target.checked && fireEvent('compareProductAdded', product)
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              e.target.click()
            }
          }}
        />
      </span>
      <span aria-label={product.title}>
        <Button
          size="minimal"
          color="secondary"
          renderButton={({ color, animation }) => {
            return (
              <p
                area-hidden="true"
                style={{ fontSize: '20px' }}
                className={`
                  ${color} ${animation} ${previews.find((prev) => prev.id === product.id) ? 'opacity-70' : ''} 
                  material-symbols-outlined rounded p-[2px]
                `}
              >
                {previews.find((prev) => prev.id === product.id) ? 'done_all' : `compare_arrows`}
              </p>
            )
          }}
        />
      </span>
    </label>
  )
}

export default AddToCompare
