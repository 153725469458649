'use client'
import { useEffect, useRef, useState } from 'react'
import { facetCleanUp } from './facetDisplayItem'
import { useAlgoliaContext, useGtm } from '@/app/(store)/context'

const RangeFacet = ({
  title,
  facetMin = 0,
  facetMax,
  unit = 'none',
  icon
}: {
  title: string
  facetMin: number
  facetMax: number
  unit: string
  icon?: string
}) => {
  const { handleFacetChange, checkedFacets } = useAlgoliaContext()

  const [checkedMin, setCheckedMin] = useState<number>(facetMin)
  const [checkedMax, setCheckedMax] = useState<number>(facetMax)
  const [firstRender, setFirstRender] = useState(true)
  const [tickwidth, setTickwidth] = useState(0)
  const [clickReset, setClickReset] = useState(false)
  const barRef = useRef<HTMLInputElement>(null)

  const [min, setMin] = useState<number>(checkedMin)
  const [max, setMax] = useState<number>(checkedMax)
  const { fireEvent } = useGtm()
  const inputMinDebounceRef = useRef<NodeJS.Timeout | null>(null)
  const inputMaxDebounceRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    try {
      const facetSize = checkedFacets?.get(title)
      if (facetSize && facetSize?.size > 0) {
        const prices: any = JSON.parse(Array.from(checkedFacets?.get('price') || [])[0])
        const pricesArr = prices?.value?.split(' TO ')

        setMin(parseInt(pricesArr[0]))
        setMax(parseInt(pricesArr[1]))
      }

      if (facetSize && facetSize?.size === 0) {
        setCheckedMin(facetMin)
        setCheckedMax(facetMax)
      } else {
        setClickReset(true)
      }
    } catch (e) {
      setCheckedMin(facetMin)
      setCheckedMax(facetMax)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedFacets])

  useEffect(() => {
    firstRender && setFirstRender(false)
    if (!firstRender && !clickReset) {
      const handler = setTimeout(() => {
        if (min === 1 && max === 4000) {
          handleFacetChange({
            target: { value: `${0} TO ${100000}`, name: title, type: 'range' }
          } as React.ChangeEvent<HTMLInputElement>)
        } else if (min === 1) {
          handleFacetChange({
            target: { value: `0 TO ${max}`, name: title, type: 'range' }
          } as React.ChangeEvent<HTMLInputElement>)
        } else if (max === 4000) {
          handleFacetChange({
            target: { value: `${min} TO ${100000}`, name: title, type: 'range' }
          } as React.ChangeEvent<HTMLInputElement>)
        } else {
          handleFacetChange({
            target: { value: `${min} TO ${max}`, name: title, type: 'range' }
          } as React.ChangeEvent<HTMLInputElement>)
        }
      }, 700)

      return () => {
        clearTimeout(handler)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, min])

  useEffect(() => {
    setTickwidth((barRef.current?.clientWidth && barRef.current?.clientWidth / 4) || 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barRef.current?.clientWidth])

  return (
    <div className="px-1 pb-10">
      <div
        className="flex flex-nowrap items-center justify-center gap-5 py-5"
        data-insights-filter={`${title}:${min}-${max}`}
      >
        <div
          className={`${unit === 'money' && !icon ? '-after:translate-y-1/2 relative after:absolute after:bottom-0 after:left-2 after:top-0 after:inline-flex after:items-center after:text-white after:content-["$"]' : 'relative'}`}
        >
          <input
            aria-label={`lower ${facetCleanUp(title)}`}
            data-test={`lower-${facetCleanUp(title)}`}
            type="number"
            min="0"
            max={max}
            value={min}
            required={false}
            onClick={() => {
              setClickReset(true)
              setMin('' as any)
            }}
            onKeyDown={(e) => {
              if (e.key === '-') {
                e.preventDefault()
                return
              }
            }}
            onChange={(e) => {
              setClickReset(false)
              // Clear any existing timer
              if (inputMinDebounceRef.current) clearTimeout(inputMinDebounceRef.current)
              if (parseFloat(e.target.value) < max) {
                const floatMin = parseFloat(e.target.value)

                floatMin >= 1 && setMin(Math.round(parseFloat(e.target.value)))
                floatMin < 1 && setMin(parseFloat(e.target.value))
                // Set a new timer to fire the event after 300ms
                inputMinDebounceRef.current = setTimeout(() => {
                  fireEvent('facetInput', { title: facetCleanUp(title), value: floatMin })
                }, 300)
              }
            }}
            className={`p-alt min-w-[100px] max-w-[100px] border-secondary bg-secondary text-center text-white
              [appearance:textfield] focus:border-secondary focus:outline-none focus:ring-gray 
              [&::-webkit-inner-spin-button]:appearance-none
              [&::-webkit-outer-spin-button]:appearance-none 
              ${unit === 'money' && 'pl-5 '}
            `}
          />
        </div>
        <span>-</span>
        <div
          className={`${unit === 'money' ? '-after:translate-y-1/2 relative after:absolute after:bottom-0 after:left-2 after:top-0 after:inline-flex after:items-center after:text-white after:content-["$"]' : 'relative'}`}
        >
          <input
            type="number"
            aria-label={`higher ${facetCleanUp(title)}`}
            data-test={`higher-${facetCleanUp(title)}`}
            min="0"
            max={max}
            value={max}
            onClick={() => {
              setClickReset(true)
              setMax('' as any)
            }}
            onChange={(e) => {
              setClickReset(false)
              if (inputMaxDebounceRef.current) clearTimeout(inputMaxDebounceRef.current)

              if (parseFloat(e.target.value) > 0 && parseFloat(e.target.value) <= facetMax) {
                setMax(Math.round(parseFloat(e.target.value)))
                inputMaxDebounceRef.current = setTimeout(() => {
                  fireEvent('facetInput', {
                    title: facetCleanUp(title),
                    value: Math.round(parseFloat(e.target.value))
                  })
                }, 300)
              }
            }}
            className={`p-alt min-w-[100px] max-w-[100px] border-secondary bg-secondary text-center text-white
              [appearance:textfield] focus:border-secondary focus:ring-gray 
              [&::-webkit-inner-spin-button]:appearance-none 
              [&::-webkit-outer-spin-button]:appearance-none 
              ${unit === 'money' && 'pl-5 '}
            `}
          />
        </div>
      </div>
      <div className="relative mx-auto flex w-full flex-nowrap items-center justify-center p-5">
        {Array.from({ length: 5 }).map((el, idx) => {
          let minus = 2
          if (idx === 0) {
            minus = -12
          }
          if (idx === 1) {
            minus = minus - 6
          }
          if (idx === 3) {
            minus = minus + 4
          }
          if (idx === 4) {
            minus = minus + 12
          }

          return (
            <span
              key={idx}
              className="absolute top-5"
              style={{
                left: tickwidth * idx - minus
              }}
            >
              <span className="absolute -translate-y-1/2 translate-x-1/2">
                <div className="h-[17px] w-[2px] bg-secondary " />
              </span>
              <span className="p-alt relative left-[1px] right-0 flex -translate-x-1/2 flex-col pt-5 text-center text-xs text-secondary">
                {idx == 0 && (
                  <span>
                    {title === 'price' && <span>{'< '}</span>}

                    {facetMin}
                  </span>
                )}
                {idx == 4 && (
                  <span>
                    {facetMax}
                    {title === 'price' && <span>{'+'}</span>}
                  </span>
                )}
                {idx > 0 &&
                  idx < 4 &&
                  facetMax - facetMin >= 4 &&
                  Math.floor(((facetMax - facetMin) / 4) * idx + facetMin)}{' '}
                {icon && facetMax - facetMin >= 4 && (idx == 0 || idx == 4) && (
                  <>
                    <span className={`${idx === 4 ? 'text-lg' : ''}`}>{icon}</span>
                  </>
                )}
              </span>
            </span>
          )
        })}
        <input
          ref={barRef}
          id="from"
          type="range"
          value={min}
          min={facetMin}
          max={facetMax}
          required={false}
          tabIndex={-1}
          aria-hidden={true}
          data-test={`lower-price-${facetCleanUp(title)}-input`}
          onMouseDown={(e) =>
            fireEvent('facetDragStarted', { title: facetCleanUp(title), value: e.target })
          }
          onMouseUp={(e) =>
            fireEvent('facetDragEnded', { title: facetCleanUp(title), value: e.target })
          }
          onTouchStart={(e) =>
            fireEvent('facetDragStarted', { title: facetCleanUp(title), value: e.target })
          }
          onTouchEnd={(e) =>
            fireEvent('facetDragEnded', { title: facetCleanUp(title), value: e.target })
          }
          onChange={(e) => {
            setClickReset(false)

            if (parseFloat(e.target.value) < max) {
              const floatMin = parseFloat(e.target.value)

              floatMin >= 1 && setMin(parseFloat(e.target.value))
              floatMin < 1 && setMin(Math.round(parseFloat(e.target.value)))
            }
          }}
          className="slide left bg-black"
        />
        <input
          id="to"
          type="range"
          tabIndex={-1}
          value={max}
          min={facetMin}
          required={false}
          max={facetMax}
          aria-hidden={true}
          data-test={`higher-price-${facetCleanUp(title)}-input`}
          onMouseDown={(e) =>
            fireEvent('facetDragStarted', { title: facetCleanUp(title), value: e.target })
          }
          onMouseUp={(e) =>
            fireEvent('facetDragEnded', { title: facetCleanUp(title), value: e.target })
          }
          onTouchStart={(e) =>
            fireEvent('facetDragStarted', { title: facetCleanUp(title), value: e.target })
          }
          onTouchEnd={(e) =>
            fireEvent('facetDragEnded', { title: facetCleanUp(title), value: e.target })
          }
          onChange={(e) => {
            setClickReset(false)

            if (parseFloat(e.target.value) > min) {
              setMax(Math.round(parseFloat(e.target.value)))
            }
          }}
          className="slide right bg-transparent"
        />
      </div>
    </div>
  )
}

export default RangeFacet
