'use client'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Carousel } from '@/components'

interface ReviewImage {
  id: number
  thumb_url: string
  original_url: string
}

interface ReviewGalleryModalProps {
  images: ReviewImage[]
  isOpen: boolean
  onClose: () => void
  initialIndex?: number
}

export function ReviewGalleryModal({
  images,
  isOpen,
  onClose
  // initialIndex = 0
}: ReviewGalleryModalProps) {
  // We can let the Carousel handle which slide is active internally.
  // Or optionally store a local “activeIndex” to pass to the Carousel.

  // const [startIndex, setStartIndex] = useState(initialIndex)

  // useEffect(() => {
  //   // Update start index if a new image is clicked
  //   setStartIndex(initialIndex)
  // }, [initialIndex])

  return (
    <Dialog open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={onClose}>
      {/* Overlay transition */}
      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition-all ease-in-out duration-300"
        enterFrom="opacity-0 backdrop-blur-none"
        enterTo="opacity-100 backdrop-blur-[.5px]"
        leave="transition-all ease-in-out duration-200"
        leaveFrom="opacity-100 backdrop-blur-[.5px]"
        leaveTo="opacity-0 backdrop-blur-none"
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      </Transition>

      {/* Centered container */}
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <Transition
          as={Fragment}
          show={isOpen}
          enter="transition-all ease-out duration-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition-all ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <DialogPanel className="relative w-full max-w-3xl rounded-xl bg-white p-4 shadow-lg">
            {/* Close button */}
            <button
              type="button"
              onClick={onClose}
              className="absolute right-3 top-3 text-gray-600 hover:text-gray-900"
            >
              <span className="material-symbols-outlined text-2xl">close</span>
            </button>

            {/* Carousel with images */}
            <Carousel
              items={images.map((img) => (
                <img
                  key={img.id}
                  src={img.original_url}
                  alt="Review image"
                  className="m-auto block max-h-[80vh] object-contain"
                />
              ))}
              loop={false}
              speed={1000}
              delay={false}
              paginationStyles="light"
              showNavigation
              showPagination
              slidesPerView={1}
              mobileSlidesPerView={1}
              // If you want to pass an initial slide, you'd have to modify
              // the Carousel to accept a "initialSlide" or something similar
            />
          </DialogPanel>
        </Transition>
      </div>
    </Dialog>
  )
}
