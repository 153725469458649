'use client'
import { ReactNode, createContext, useContext } from 'react'
import { RAYSCLUB_TAG } from '../constants'
import { useLocalStorage } from '../hooks'
type CustomerContextType = {
  customer: Customer | undefined
  isRaysClub: boolean
}

const CustomerContext = createContext<CustomerContextType | null>(null)

export const CustomerContextProvider = ({
  children,
  customer
}: {
  children: ReactNode
  customer: Customer | undefined
}) => {
  return (
    <CustomerContext.Provider
      value={{
        customer,
        isRaysClub: customer?.tags?.includes(RAYSCLUB_TAG) || false
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export function MockCustomerContextProvider({ children }: any) {
  return (
    <CustomerContext.Provider value={{ customer: undefined, isRaysClub: false }}>
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomerContext = () => {
  const context = useContext(CustomerContext)
  const [customerData, setCustomerData] = useLocalStorage('customer', '')
  if (!context) {
    throw new Error('useCustomerContext must be used within a CustomerContextProvider')
  }

  if (!customerData && context) {
    const customer: any = context?.customer || []
    setCustomerData(customer)
  }

  return context
}
