'use client'

import { useEffect, useState } from 'react'
import { getCart } from '../lib'
import { useGtm } from '@/context'
interface DataLayerPushProps {
  cookies?: any
  trigger?: string
}

const DataLayerPush = ({ cookies, trigger }: DataLayerPushProps) => {
  const [cookieCart, setCookieCart] = useState<Cart | undefined>(undefined)
  const { fireEvent } = useGtm()
  useEffect(() => {
    const fetchCart = async () => {
      const cart = await getCart(cookies._cartCookie)
      setCookieCart(cart)
    }

    if (cookies?._cartCookie) {
      fetchCart()
    }
  }, [cookies])

  useEffect(() => {
    if (cookieCart && trigger === 'user') {
      fireEvent('userData', { ...cookies, cookieCart })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieCart])

  useEffect(() => {
    if (cookieCart && trigger === 'cart') {
      fireEvent('viewCart', { ...cookies, cookieCart })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieCart])

  return null
}

export default DataLayerPush
