'use client'
import {
  WithContext,
  WebPage,
  Offer,
  AggregateRating,
  Product as SchemaProduct,
  QuantitativeValue,
  Recipe,
  Thing
} from 'schema-dts'
import {
  accessibilityFeature,
  accessibilityHazard,
  accessibilitySummary,
  accessMode,
  brandSchema,
  getProductsById,
  organizationSchema
} from '@/lib'
import { minutesToISO8601Duration } from '@/utils'
import Script from 'next/script'
import { useEffect, useMemo, useState } from 'react'
import { useShopifySearch } from '../hooks'
import { SITE_URL } from '../constants'

/*
 * Refers to on of the Schema.org types
 * - This type in incomplete, and could accept all the Schema.org types
 * Reference: https://schema.org/Thing
 */
type SchemaType = 'ItemPage' | 'Recipe' | 'Product' | 'Article'

/*
 * Refers to the internal entity type used to determine the schema information
 */
type Page = Sanity.Page | Sanity.Recipe | Product | Sanity.Article

const getSchemaType = async (
  type: SchemaType,
  page?: Page,
  product?: Product,
  variant?: ProductVariant,
  bundles?: string[],
  addOns?: any,
  aggregateRating?: YotpoReviewData
): Promise<WithContext<WebPage> | null> => {
  const asSanityPage = page as Sanity.Page
  const asSanityRecipe = page as Sanity.Recipe
  const asSanityProduct = page as Sanity.Page
  const asArticle = page as Sanity.Article

  const globalSchema: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    // WebSite Globals Static
    accessibilityFeature,
    accessibilityHazard,
    accessibilitySummary,
    accessMode
  }

  if (type === 'Article' && asSanityPage) {
    const date = asArticle?.publishDate
      ? new Date(asArticle?.publishDate.replace(/-/g, '/').replace(/T.+/, ''))
      : new Date()

    const schema: WithContext<WebPage> = {
      ...globalSchema,

      mainEntity: {
        '@type': 'ItemPage',
        author: {
          '@type': 'Organization',
          name: 'recteq'
        },
        dateCreated: asArticle?._createdAt,
        datePublished: date.toLocaleDateString(),
        dateModified: asArticle?._updatedAt,
        headline: asArticle?.seo?.title || asArticle.title,
        description: asArticle?.seo?.description,
        primaryImageOfPage:
          asArticle?.metadata?.ogimage?.asset?.url || asArticle?.media?.asset?.url,
        breadcrumb: '/articles/' + asArticle?.slug?.current.replace('/', ' > ')
      }
    }

    return schema
  }

  if (type === 'ItemPage' && asSanityPage) {
    const schema: WithContext<WebPage> = {
      ...globalSchema,

      mainEntity: {
        '@type': 'ItemPage',
        author: {
          '@type': 'Organization',
          name: 'recteq'
        },
        dateCreated: asSanityPage?._createdAt,
        datePublished: asSanityPage?._createdAt,
        dateModified: asSanityPage?._updatedAt,
        headline: asSanityPage?.metadata?.title,
        description: asSanityPage?.metadata?.description,
        about: asSanityPage?.metadata?.description,
        primaryImageOfPage: asSanityPage?.metadata?.ogimage?.asset.url,
        breadcrumb: asSanityPage?.slug?.current.replace('/', ' > ')
      }
    }

    return schema
  }

  if (type === 'Product' && asSanityProduct && product && variant) {
    const addOnsProductsSchema = addOns?.map(({ props }: { props: { product: Product } }) => {
      const { product } = props || {}
      const variant = product?.variants[0]

      return {
        '@type': 'Product',
        identifier: product?.id,
        productID: product?.id,
        name: product?.display_title?.value || product?.title,
        model: product?.model_number?.value,
        url: `${SITE_URL}/products/${product?.handle}`,
        alternateName: product?.display_title?.value,
        // Variant Related
        sku: variant?.sku,
        description: product?.description,
        image: product?.featuredImage?.url,
        weight: {
          '@type': 'QuantitativeValue',
          value: variant?.weight,
          unitText: variant?.weightUnit
        } as QuantitativeValue
      } as SchemaProduct
    })

    const getBundles = async (ids: string[]) => {
      const res = await getProductsById({ ids })
      return res.products
    }

    let bundlesData = bundles && (await getBundles(bundles))

    const schema: WithContext<WebPage> = {
      ...globalSchema,

      mainEntity: {
        '@type': 'Product',
        // Product Related
        identifier: asSanityProduct?._id || product?.id,
        productID: asSanityProduct?._id || product?.id,
        name: asSanityProduct?.title || product?.display_title?.value || product?.title,
        model: product?.model_number?.value,
        url: `${SITE_URL}/products/${asSanityProduct?.handle}`,
        alternateName: product?.display_title?.value,
        // Averga Rating
        aggregateRating: aggregateRating?.bottomline?.total_reviews
          ? ({
              '@type': 'AggregateRating',
              bestRating: 5,
              worstRating: 0,
              ratingValue: aggregateRating?.bottomline?.average_score || 0,
              reviewCount:
                aggregateRating?.bottomline?.total_reviews ||
                aggregateRating?.bottomline?.total_review ||
                0
            } as AggregateRating)
          : undefined,
        // Variant Related
        sku: variant?.sku,
        description: product?.description,
        image: asSanityProduct?.previewImageUrl || product?.featuredImage?.url,
        weight: {
          '@type': 'QuantitativeValue',
          value: variant?.weight,
          unitText: variant.weightUnit
        } as QuantitativeValue,

        // Bundle Related
        offers:
          bundlesData && bundlesData?.length > 0
            ? bundlesData?.map((bundle) => {
                return {
                  '@type': 'Offer',
                  sku: bundle.variants[0]?.sku,
                  price: bundle.variants[0].price.amount,
                  priceCurrency: bundle.variants[0].price.currencyCode,
                  seller: organizationSchema,
                  availability: bundle?.availableForSale
                    ? 'https://schema.org/InStock'
                    : 'https://schema.org/SoldOut',
                  itemOffered: !aggregateRating?.bottomline?.total_reviews
                    ? ({
                        '@type': 'Thing',
                        identifier: bundle?.id,
                        name: bundle.display_title?.value || bundle?.title,
                        url: `${SITE_URL}/products/${bundle?.handle}`,
                        alternateName: bundle?.display_title?.value,
                        description: bundle?.description,
                        image: bundle?.featuredImage?.url
                      } as Thing)
                    : ({
                        '@type': 'Product',
                        identifier: bundle?.id,
                        productID: bundle?.id,
                        name: bundle.display_title?.value || bundle?.title,
                        model: bundle?.model_number?.value,
                        url: `${SITE_URL}/products/${bundle?.handle}`,
                        alternateName: bundle?.display_title?.value,

                        // Reviews
                        aggregateRating: {
                          '@type': 'AggregateRating',
                          bestRating: 5,
                          worstRating: 0,
                          ratingValue: aggregateRating?.bottomline?.average_score || 0,
                          reviewCount:
                            aggregateRating?.bottomline?.total_reviews ||
                            aggregateRating?.bottomline?.total_review ||
                            0
                        } as AggregateRating,
                        // Variant Related
                        sku: bundle.variants[0]?.sku,
                        description: bundle?.description,
                        image: bundle?.featuredImage?.url,
                        weight: {
                          '@type': 'QuantitativeValue',
                          value: bundle.variants[0]?.weight,
                          unitText: bundle.variants[0].weightUnit
                        }
                      } as SchemaProduct)
                } as Offer
              })
            : undefined,

        // Addons
        isRelatedTo: addOns ? addOnsProductsSchema : undefined,
        // Static fields
        brand: brandSchema,
        manufacturer: organizationSchema
      }
    }

    return schema
  }

  if (type === 'Recipe' && asSanityRecipe) {
    const schema: WithContext<WebPage> = {
      ...globalSchema,
      mainEntity: {
        '@type': 'Recipe',
        author: {
          '@type': 'Organization',
          name: 'recteq Chef'
        },
        name: asSanityRecipe?.recipeName,
        cookingMethod: asSanityRecipe?.recipeType,
        recipeCategory:
          asSanityRecipe?.category?.length > 0 ? asSanityRecipe?.category[0]?.name : 'unknown',
        description: asSanityRecipe?.recipeDescription || `${asSanityRecipe?.recipeName} Recipe`,
        image: asSanityRecipe?.recipeImage?.asset?.url || asSanityRecipe?.legacyRecipeImage,
        recipeInstructions: asSanityRecipe?.directions.map((item: any, idx: number) => {
          return {
            '@type': 'HowToStep',
            name: `Step ${idx + 1}`,
            text: item,
            url: `https://www.recteq.com/recipes/${asSanityRecipe?.slug.current}`
          }
        }),
        recipeIngredient: asSanityRecipe?.ingredients?.map(
          (ingredient) =>
            `${ingredient?.servingSize?.length > 0 ? ingredient?.servingSize[0]?.amount : ''} ${ingredient?.unit} ${ingredient?.name}`
        ),
        recipeCuisine: asSanityRecipe?.meal,
        cookTime: minutesToISO8601Duration(asSanityRecipe?.cookTimeMins || 0),
        prepTime: minutesToISO8601Duration(asSanityRecipe.prepTimeMins || 0),
        totalTime: minutesToISO8601Duration(
          asSanityRecipe.prepTimeMins + asSanityRecipe?.cookTimeMins || 0
        )
      } as Recipe
    }
    return schema
  }

  return null
}

function PageSchemaHandler({
  page,
  schemaType,
  product,
  variant,
  bundles,
  addOns,
  aggregateRating
}: {
  page: Page
  schemaType: SchemaType
  product?: Product
  variant?: ProductVariant
  bundles?: string[]
  addOns?: any
  aggregateRating?: YotpoReviewData
}) {
  const [schema, setSchema] = useState<WithContext<WebPage> | null>(null)

  const addOnsData = useShopifySearch(
    addOns?.length > 0 ? addOns[0] : {},
    !addOns || addOns?.length === 0 ? true : false
  )

  useEffect(() => {
    const getData = async () => {
      const res = await getSchemaType(
        schemaType,
        page,
        product,
        variant,
        bundles,
        addOnsData?.data?.products,
        aggregateRating
      )

      setSchema(res)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaType, page, product, variant, bundles, addOns])

  const json = useMemo(() => (schema && JSON.stringify(schema)) || '', [schema])

  return (
    <>
      {json && (
        <Script
          id="json-ld-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: json
          }}
        />
      )}
    </>
  )
}

export default PageSchemaHandler
