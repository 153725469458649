'use client'
import { ProductCard } from '@/components'
import { useShopifySearch } from '@/hooks'

export const ProductCardResolver = (props: any) => {
  const product = props?.product && props?.product?.length > 0 ? props?.product[0] : props?.product
  const { data } = useShopifySearch({ ...product }, false)
  if (!props?.product || props?.product?.length === 0) {
    return <></>
  }

  return data?.products?.map((product) => {
    return (
      <ProductCard
        {...props}
        key={product.id}
        showReviews={false}
        product={product}
        buttonType={props.buttonAction}
      />
    )
  })
}
export default ProductCardResolver
