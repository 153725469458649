// Pagination.tsx
'use client'
import React from 'react'
import { Button } from '../button'

interface PaginationProps {
  currentPage: number
  perPage: number
  total: number
  onPageChange: (newPage: number) => void
}

export function Pagination({ currentPage, perPage, total, onPageChange }: PaginationProps) {
  const totalPages = Math.ceil(total / perPage)

  // If there's only 1 page, no need to show controls
  if (totalPages <= 1) {
    return null
  }

  function handlePrev() {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }
  function handleNext() {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  return (
    <div className="mt-4 flex items-center justify-center gap-4">
      <Button size="normal" color="primary" disabled={currentPage === 1} onClick={handlePrev}>
        Previous
      </Button>
      <span className="p">
        Page {currentPage} of {totalPages}
      </span>
      <Button
        size="normal"
        color="primary"
        disabled={currentPage === totalPages}
        onClick={handleNext}
      >
        Next
      </Button>
    </div>
  )
}
