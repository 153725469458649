'use client'
import React, { useRef, useState } from 'react'
import Option from './option'

export type CustomOption = {
  label: string
  value: string
}
interface Props {
  icon?: React.ReactNode
  options: CustomOption[]
  error?: boolean
  placeholder?: string
  inputStyles?: string
  containerStyles?: string
  dropdownStyles?: string
  onChange?: (...args: any[]) => void
  disabled?: boolean
}

export const Select = ({
  disabled,
  placeholder,
  options,
  icon,
  error = false,
  onChange,
  inputStyles,
  containerStyles,
  dropdownStyles
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLLabelElement>(null)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<CustomOption | null>()
  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  return (
    <label
      id="custom-combobox"
      ref={labelRef}
      tabIndex={0}
      className={`relative inline-block w-full cursor-pointer ${containerStyles} ${disabled ? 'pointer-events-none' : ''}`}
      data-test={`input-select`}
      aria-haspopup="listbox"
      aria-expanded={open}
      aria-labelledby="combobox-label"
      autoFocus={true}
      onKeyDown={(e) => {
        e.key === 'Enter' && handleClick()
      }}
    >
      <input
        ref={ref}
        tabIndex={1}
        role="combobox"
        aria-expanded={open}
        aria-controls="listbox"
        type="text"
        value={value?.label || ''}
        className={`input p w-full text-base placeholder:text-gray-tertiary disabled:opacity-80 ${error && 'border-red-500'} ${inputStyles}`}
        placeholder={placeholder}
        readOnly
        onClick={handleClick}
        aria-invalid={error}
        data-test="select-input"
        disabled={disabled}
        onKeyDown={(e) => {
          e.key === 'Enter' && handleClick()
        }}
      />

      {open && (
        <div
          id="listbox"
          tabIndex={1}
          className={`absolute top-full z-20 max-h-[180px] w-full divide-y divide-gray-form overflow-auto border border-t-0 border-gray ${dropdownStyles}`}
          role="listbox"
          autoFocus
          aria-expanded={open}
          aria-activedescendant="combobox-label"
          onKeyUp={(e) => {
            e.key === 'Enter' && handleClick()
          }}
        >
          <Option
            className="p"
            id="combobox-label"
            handleClick={() => {
              setValue(null)
            }}
          >
            ------
          </Option>
          {options?.map((option, idx) => (
            <Option
              key={idx}
              className="p text-base"
              id={`${option.value}`}
              selected={value == option}
              handleClick={() => {
                !!onChange && onChange(option)
                setValue(option)
                labelRef.current?.focus()
              }}
            >
              {option.label}
            </Option>
          ))}
        </div>
      )}

      <button
        data-test="select-button"
        type="button"
        className="absolute bottom-0 right-0 top-0 flex h-full w-10 items-center "
        onClick={handleClick}
        tabIndex={-1}
      >
        {!!icon ? (
          icon
        ) : (
          <span aria-hidden="true" className="material-symbols-outlined rotate-180 text-gray-200">
            keyboard_arrow_up
          </span>
        )}
      </button>
    </label>
  )
}
