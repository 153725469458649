'use client'
import { Button, ProductPrice, ProductTitle, Carousel } from '@/components'
import Image from 'next/image'
import Link from 'next/link'
import FocusTrap from 'focus-trap-react'
import { addItem, updateDiscount } from '@/actions/cart/cart.actions'
import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react'
import { Transition, TransitionChild } from '@headlessui/react'
import { isAvailableForSale, subscribe, unsubscribe } from '@/utils'
import { DEFAULT_OPTION } from '@/constants'
import { useGtm } from '@/context'
import { formatUpsellAddToCart } from '@/hooks/useGtmEvents'

interface UpsellData {
  discount_code: string
  discount: string
  variants: ProductVariant[]
}

const ProductUpsells = ({ product, upsells }: { product: Product; upsells: UpsellData[] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    subscribe(`added-to-cart-${product.id}`, () => {
      document.body.classList.add('locked')
      setIsOpen(true)
    })

    return () => {
      unsubscribe(`added-to-cart-${product.id}`, () => {
        setIsOpen(false)
      })
    }
  }, [product])

  const upsellsItems = upsells.flatMap((upsell) => {
    const upselVariants = upsell.variants
      .filter((variant) => isAvailableForSale(product, undefined, variant))
      .map((variant) => {
        return (
          <UpsellItem
            key={variant.id}
            variant={variant}
            discount_code={upsell.discount_code}
            discount={upsell.discount}
            setIsOpen={setIsOpen}
          />
        )
      })
    return upselVariants
  })

  return (
    <Transition as={'div'} show={upsellsItems.length > 0 && isOpen}>
      <div
        className="fixed left-0 top-0 z-[25] h-full w-full bg-black/20"
        onClick={() => setIsOpen(false)}
      />
      <TransitionChild
        as={'div'}
        enter="transition-all ease-in-out duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition-all ease-in-out duration-200"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="fixed right-0 top-0 z-[25] flex h-full w-full max-w-[550px] flex-col bg-white"
        ref={modalRef}
        tabIndex={-1}
      >
        <FocusTrap
          active={isOpen}
          focusTrapOptions={{
            initialFocus: () => modalRef.current!,
            fallbackFocus: () => modalRef.current!,
            onDeactivate: () => {
              if (modalRef.current) {
                modalRef.current.focus()
              }
            }
          }}
        >
          <div>
            <span className="mt-4 flex w-full justify-end pr-4 pt-0">
              <button
                data-test="cart-close-button"
                type="button"
                onClick={() => setIsOpen(false)}
                className="material-symbols-outlined"
              >
                close
              </button>
            </span>
            <section className="container relative max-h-[98vh] overflow-y-auto pb-7">
              <Carousel
                items={upsellsItems}
                slidesPerView={1}
                mobileSlidesPerView={1}
                showNavigation={true}
                showPagination={true}
                paginationStyles="light"
                delay={false}
              />
            </section>
          </div>
        </FocusTrap>
      </TransitionChild>
    </Transition>
  )
}

interface UpsellItemProps {
  variant: ProductVariant
  discount_code: string
  discount: string
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const UpsellItem: React.FC<UpsellItemProps> = ({ variant, discount_code, discount, setIsOpen }) => {
  const upsellProduct = variant
  const upsellProductDiscountCode = discount_code

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <div className="relative mx-auto aspect-1 w-full max-w-[350px]">
          <Image src={upsellProduct?.image?.url} alt={`upsellProduct?.title`} fill />
        </div>
        <div>
          <h4 className="p-large mb-4 text-center font-bold uppercase text-primary sm:text-left">
            Offer available now only!
          </h4>
          <Link
            href={`/products/${upsellProduct?.product?.handle}`}
            id={upsellProduct?.id}
            className="text-center text-secondary sm:text-left"
          >
            <ProductTitle product={upsellProduct.product as any} size="h2" />
          </Link>
          {upsellProduct?.title !== DEFAULT_OPTION && (
            <h4 className="h6 mb-5 text-center text-secondary sm:text-left">
              {upsellProduct?.title}
            </h4>
          )}
          <ProductPrice currentVariant={upsellProduct} upsell={discount} />
        </div>
      </div>
      <div className="flex w-full flex-row gap-3">
        {upsellProduct && (
          <SubmitButton
            upsellProduct={upsellProduct}
            upsellProductDiscountCode={upsellProductDiscountCode}
            setIsOpen={setIsOpen}
            discount={discount}
          />
        )}
        <Button color="secondary" className="w-1/2" size="large" onClick={() => setIsOpen(false)}>
          Continue shopping
        </Button>
      </div>
      <p className="p text-secondary">{upsellProduct?.product?.description}</p>
    </div>
  )
}

const SubmitButton = ({
  setIsOpen,
  upsellProduct,
  upsellProductDiscountCode,
  discount
}: {
  upsellProduct: ProductVariant
  setIsOpen: Dispatch<SetStateAction<boolean>>
  upsellProductDiscountCode: string
  discount?: string
}) => {
  const [loading, setLoading] = useState(false)
  const { fireEvent } = useGtm()
  const upsellAction = async () => {
    setLoading(true)
    if (upsellProduct) {
      await addItem(null, { gids: [upsellProduct.id] })
      await updateDiscount(null, { discountCodes: [upsellProductDiscountCode] })
      // google add to cart event
      let calcedCompPrice = null
      if (discount) {
        calcedCompPrice = formatUpsellAddToCart(upsellProduct, discount)
      }
      fireEvent('addToCartFromPDP', {
        selectedVariant: { ...upsellProduct, compareAtPrice: calcedCompPrice },
        quantity: 1,
        bundle: null
      })
    }
    setLoading(false)
    setIsOpen(false)
  }

  return (
    <>
      <Button
        type="submit"
        className="w-1/2"
        color="primary"
        size="large"
        aria-labelledby={`add-to-cart-label-${upsellProduct?.id} ${upsellProduct?.id}`}
        id={`add-to-cart-label-${upsellProduct?.id}`}
        disabled={!upsellProduct.availableForSale || loading}
        data-test="button"
        onClick={upsellAction}
      >
        {loading && <span className="material-symbols-outlined leading-tight">more_horiz</span>}
        {upsellProduct.availableForSale && !loading && <>add to cart</>}
        {!upsellProduct.availableForSale && !loading && <>not available</>}
      </Button>
    </>
  )
}

export default ProductUpsells
