'use client'
import { useState, useEffect, useCallback } from 'react'
import * as qs from 'qs'
import { useCustomerContext } from '../context'
import { RAYSCLUB_TAG } from '../constants'

export const useAlgoliaMultipleQuery = ({
  search,
  preventFetching
}: {
  search: string
  preventFetching?: boolean
}) => {
  const { isRaysClub } = useCustomerContext()

  const [currentPage, setCurrentPage] = useState(0)
  const [algolia, setAlgolia] = useState<{
    results: [AlgoliaResponse<AlgoliaContent>, AlgoliaResponse<AlgoliaProduct>]
    loading: boolean
  }>({
    results: [{} as AlgoliaResponse<AlgoliaContent>, {} as AlgoliaResponse<AlgoliaProduct>],
    loading: true
  })

  const getAlgoliaResults = useCallback(
    async (page?: number) => {
      setAlgolia((prev: any) => ({ ...prev, loading: true }))

      let filters = ['NOT tags:"Hide From Search"', 'NOT tags:"Discontinued"']

      if (!isRaysClub) {
        filters.push(`NOT allowedUserType:${RAYSCLUB_TAG}`)
        filters.push(`NOT tags:raysclub`)
      }

      const productQuery = qs.stringify([
        {
          query: search,
          indexName: process.env.NEXT_PUBLIC_ALGOLIA_CONTENT,

          params: {
            hitsPerPage: 200,
            page: page || 0
          }
        },
        {
          query: search || 'recteq',
          indexName: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS,
          params: {
            hitsPerPage: 200,
            page: page || 0,
            filters: filters.join(' AND '),
            attributesToRetrieve: ['title', 'handle', 'product_image']
          }
        }
      ])

      const res = await fetch(`/api/algolia-query/multipleQuery?${productQuery}`, {
        cache: 'force-cache'
      })
      const data = await res.json()

      setCurrentPage((prev) => prev + 1)
      setAlgolia({
        ...data,
        loading: false
      })
    },
    [search, isRaysClub]
  )

  useEffect(() => {
    if (!preventFetching) {
      setAlgolia((prev: any) => ({ ...prev, page: 0, hits: [] }))
      getAlgoliaResults()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const getNextAlgoliaPage = () => {
    getAlgoliaResults(currentPage + 1)
  }
  return {
    algolia,
    getNextAlgoliaPage
  }
}
