'use client'
import { parseLocaleDateTime, parseMoneyFormat } from '@/app/(store)/utils'
import { Address, Drawer } from '@/components'

const OrderDetail = ({
  order,
  cta
}: {
  order: Order
  cta: (handleClick: () => void) => React.ReactNode
}) => {
  const {
    lineItems,
    totalPrice,
    totalTax,
    totalShippingPrice,
    shippingAddress,
    billingAddress,
    discountApplications
  } = order || {}
  const discountedAmounts = discountApplications.nodes.map((discount) =>
    parseFloat(discount?.value?.amount || '0')
  )
  const totalDiscounts =
    discountedAmounts?.reduce((acc, curr) => {
      return acc + curr
    }, 0) || 0
  const totalProducts = lineItems.nodes
    ?.map((line) => parseFloat((line?.originalTotalPrice?.amount as string) || '0'))
    ?.reduce((acc, curr) => {
      return acc + curr
    }, 0)

  return (
    <Drawer icon="" renderButton={cta} className="max-w-[525px]">
      <section className="flex max-h-[calc(100svh-40px)] flex-col gap-2 overflow-y-auto p-5 pb-[40px]">
        <h3 className="h4">order details</h3>
        <div className="mb-10 flex items-center justify-between">
          <p className="p-large block uppercase">
            Order <b>{order.name}</b>
          </p>
          <p className="p block uppercase">{parseLocaleDateTime(new Date(order.processedAt))}</p>
        </div>
        <div className="flex flex-col gap-10">
          <table data-test={`order-detail-table-${order.name}`} className="w-full">
            <thead>
              <tr className="p bg-gray font-bold capitalize">
                <th>product</th>
                <th>price</th>
                <th>quantity</th>
                <th>total</th>
              </tr>
            </thead>
            <tbody>
              {lineItems.nodes.map(
                ({ variant, quantity, originalTotalPrice, discountedTotalPrice }) => {
                  return (
                    <tr key={variant?.id} className="p  text-sm">
                      <td className="text-left">{variant?.product?.title}</td>
                      <td>{parseMoneyFormat(variant?.price?.amount)}</td>
                      <td>{quantity}</td>
                      <td>
                        {parseMoneyFormat(discountedTotalPrice?.amount)} {''}
                        {discountedTotalPrice?.amount !== originalTotalPrice?.amount && (
                          <span className="inline text-xs line-through">
                            {parseMoneyFormat(originalTotalPrice?.amount)}
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>

          <div className="flex flex-col gap-3">
            <div
              data-test="order-shipping"
              className="p flex items-center justify-between font-bold"
            >
              <p>Shipping (Standard)</p>
              <b>{parseMoneyFormat(totalShippingPrice?.amount)}</b>
            </div>

            <div
              data-test={`order-taxes-`}
              className="p flex items-center justify-between font-bold"
            >
              <p>Taxes</p>
              <b>{parseMoneyFormat(totalTax?.amount)}</b>
            </div>
            <div
              data-test={`order-taxes-`}
              className="p flex items-center justify-between font-bold"
            >
              <p>Subtotal</p>
              <b>{parseMoneyFormat(totalProducts)}</b>
            </div>
            {totalDiscounts > 0 && (
              <div
                data-test={`order-taxes-`}
                className="p flex items-center justify-between font-bold"
              >
                <p>Discounts</p>
                <b>-{parseMoneyFormat(totalDiscounts)}</b>
              </div>
            )}
            <div
              data-test="order-total"
              className="p my-8 flex items-center justify-between bg-primary p-4 font-bold text-white"
            >
              <p>Total</p>
              <p>{parseMoneyFormat(totalPrice?.amount)}</p>
            </div>

            {shippingAddress && <Address addressType="Shipping" {...shippingAddress} />}
            {billingAddress && <Address addressType="Billing" {...billingAddress} />}
          </div>
        </div>
      </section>
    </Drawer>
  )
}

export default OrderDetail
