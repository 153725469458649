import { groq } from 'next-sanity'
import {
  assetFragment,
  ctaFragment,
  globalComponentConfigFragment,
  iconFragment,
  mediaFragment
} from './common'

export const imageMosaicFragment = groq`
_type == "imageMosaic" => {
  pattern,
  images[] {
    ${assetFragment}
  },
  ${globalComponentConfigFragment}
}
`
export const twoColumnCalloutFragment = groq`
_type == "twoColumnCallout" => {
  ...,
  content[] {
    ...,
    ${mediaFragment},
    ${ctaFragment},
  },
  ${globalComponentConfigFragment}
}
`
export const twoColumnBioFragment = groq`
_type == "twoColumnBio" => {
  ...,
  items[] {
    ...,
    ${mediaFragment},
    title,
    body,
    label,
    imagePosition
  },
  ${globalComponentConfigFragment}
}
`
export const storeLocatorFragment = groq`
  _type == "storeLocatorModule" => {
    storeLocator ->{
      title,
      legends[] {
        icon {
          ${assetFragment}
        },
        title,
        description,
        tier
      }
    },
    ${globalComponentConfigFragment}
  }
`

export const heroFragment = groq`
_type == "hero" => {
  ...,
  desktopMedia {
    ${assetFragment}
  },
  mobileMedia {
    ${assetFragment}
  },
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`
export const iconListingFragment = groq`
_type == "iconListing" => {
  ...,
  iconBoxes[]{
    ...,
    ${iconFragment}
  },
  iconBoxesMinimal[]{
    ...,
    ${iconFragment}
  },
  ${globalComponentConfigFragment}
}
`
export const sectionHeaderFragment = groq`
_type == 'sectionHeader' => {
  ...,
  title,
  subtitle,
  text,
  border,
  alignment,
  ${mediaFragment},
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`
export const featuredHeroFragment = groq`
_type == 'featuredHero' => {
  ...,
  body,
  title,
  'subtitle': {
    'text': subtitle,
    'style': subtitleColor
  },
  'media': {
    'desktop': desktopMedia {
      ${assetFragment}
    },
    'mobile': mobileMedia {
      ${assetFragment}
    }
  },
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`
export const accordionFragment = groq`
_type == "accordion" => {
  title,
  _key,
  _type,
  "items": sections.items,
  ${globalComponentConfigFragment}
}
`
export const contentCardFragment = groq`
_type == 'contentCard' => {
  ...,
  content {
    'card': *[_type == 'recipe' && _id == ^._ref][0]{
      ...,
      recipeImage {
        ${assetFragment}
      }
    }
  },
  ${globalComponentConfigFragment}
}
`
export const bioCardFragment = groq`
_type == 'bioCard' => {
  ...,
  ${mediaFragment},
  rolloverImage {
    ${assetFragment}
  },
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`
export const productCardFragment = groq`
_type == 'productCard' => {
  ...,
  product[] {
    ...,
    manualSelection[] {
      ...,
      'product': *[_type == 'product' && _id == ^._ref][0]{
        'gid': gid,
        'handle': handle
      }
    }    
  },
  ${globalComponentConfigFragment}
}
`
export const heroCardFragment = groq`
_type == 'heroCard' => {
  ...,
  ${mediaFragment},
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`
export const visualCTAFragment = groq`
_type == 'visualCta' => {
  ...,
  ${mediaFragment},
  'rolloverImage': rolloverMedia {
    ${assetFragment}
  },
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`

export const testimonialsFragment = groq`
_type == 'testimonials' => {
  ...,
  testimonials[]{
    ...,
    review,
    author
  },
  ${globalComponentConfigFragment}
}
`

export const countdownTimerFragment = groq`
  _type == 'countdownTimer' => {
    title,
    subtitle,
    date,
    backgroundColor,
    countDownTheme,
    ${ctaFragment},
    ${globalComponentConfigFragment}
  }
`
export const videoFragment = groq`
_type == "video" => {
  ...,
  mobileSource {
    ${assetFragment}
  },
  desktopSource {
    ${assetFragment}
  },
  ${ctaFragment},
  ${globalComponentConfigFragment}
}
`

export const klaviyoFormFragment = groq`
_type == "klaviyoForm" => {
  ...,
  ${mediaFragment}
}
`

const modulesSelector = [
  iconListingFragment,
  visualCTAFragment,
  heroCardFragment,
  bioCardFragment,
  contentCardFragment,
  productCardFragment,
  countdownTimerFragment,
  videoFragment
].join(',')

export const fullBannerFragment = groq`
_type == "fullBanner" => {
  ...,
  ${ctaFragment},
  ${mediaFragment},
  ${globalComponentConfigFragment}
}
`
export const gridFragment = groq`
_type == "grid" => {
  ...,
  'gridItems': *[_type == 'gridItem' && _id == ^.items._ref] {
    'item': modulesSelector[] {
      ...,
      ${modulesSelector}
    }
  },
  ${globalComponentConfigFragment}
}
`

export const carouselFragment = groq`
  _type == "carousel" => {
    ...,
    slidesPerView,
    showNavigation,
    navigationTheme,
    delay,
    manualSelections {
      'reference': *[_type == 'manualSelections' && _id == ^._ref][0] {
        ...,
        manualSelection[] {
          ...,
          ${[bioCardFragment, visualCTAFragment, heroCardFragment].join(',')},

          content {
            ...,
            'reference': *[_type == 'recipe' && _id == ^._ref][0] {
              ...
            }
          },
          product {
            ...,
            'reference': *[_type == 'product' && _id == ^._ref][0] {
              ...
            }
          }
        }

      },
    },
    querySelection {
      ...,
      'reference': *[_type == 'productQuery' && _id == ^._ref][0] {
        ...
      }
    },
    'carouselItems': *[_type == 'carouselItem' && _id == ^.items._ref] {
      'item': modulesSelector[] {
        ...,
        ${modulesSelector},
      }
    },
    ${globalComponentConfigFragment}
  }
`
export const academyFormFragment = groq`
_type == 'academyForm' => {
  ...,
  product -> {
    ...,
    title
  },
  ${globalComponentConfigFragment}
}
`
export const simpleMapFragment = groq`
_type == 'simpleMap' => {
  title,
  address,
  ${globalComponentConfigFragment}
}
`
export const ugcGalleryFragment = groq`
_type == 'ugcGallery' => {
  ...,
  ${globalComponentConfigFragment}
}`
const academyListingsFragment = groq`
  _type == 'academyListing' => {
    _type,
    _key,
    "academies": *[
      _type == 'academy' &&
      location != "index"
      ]{
      ...,
      _id,
      title,
      location,
      listingAddress,
      slug,
      seo {
        ...,
        ogimage {${assetFragment}}
      }
    },
    ${globalComponentConfigFragment}
  }
`

export const advancedPortableTextFragment = groq`
  content[] {
    ...,
    items[]{
      ...,
      ${[
        iconListingFragment,
        sectionHeaderFragment,
        carouselFragment,
        gridFragment,
        twoColumnCalloutFragment,
        twoColumnBioFragment,
        featuredHeroFragment,
        accordionFragment,
        fullBannerFragment,
        imageMosaicFragment,
        bioCardFragment,
        visualCTAFragment,
        heroCardFragment,
        countdownTimerFragment,
        testimonialsFragment,
        academyFormFragment,
        academyListingsFragment,
        assetFragment,
        simpleMapFragment,
        videoFragment,
        storeLocatorFragment,
        ugcGalleryFragment,
        klaviyoFormFragment
      ].join(',')}
    },
    querySelection {
      ...,
      'reference': *[_type == 'productQuery' && _id == ^._ref][0] {
        ...
      }
    },
    modulesSelector[] {
      ...,
      ${[bioCardFragment, visualCTAFragment, heroCardFragment].join(',')}
    },
    manualSelections {
      'reference': *[_type == 'manualSelections' && _id == ^._ref][0] {
        ...,
        manualSelection[] {
          ...,
          ${[bioCardFragment, visualCTAFragment, heroCardFragment].join(',')},
          content {
            ...,
            'reference': *[_type == 'recipe' && _id == ^._ref][0] {
              ...
            }
          },
          product {
            ...,
            'reference': *[_type == 'product' && _id == ^._ref][0] {
              ...
            }
          }
        }

      },
    },
    querySelection {
      'reference': *[_type == 'productQuery' && _id == ^._ref][0] {
        ...
      }
    },
    _type == "image" => {
      ...,
      asset->
    }
  }
`
export const videoListingFragment = groq`
  listing[]{
    ...,
    videos[]{
      ...,
      desktopPoster {
        ${assetFragment}
      },
      mobilePoster {
        ${assetFragment}
      },
      mobileSource {
        ...,
        ${assetFragment}
      },
      desktopSource {
        ...,
        ${assetFragment}
      }
    }
  },
  ${globalComponentConfigFragment}
`
