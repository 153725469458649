export const transformMenu = (menu: any = []) => {
  if (!menu || menu.length < 1) return

  return (menu || [])?.map((item: any) => {
    if (item._type === 'subMenu') {
      return {
        id: item._key,
        type: item._type,
        link: {
          title: item.title
        },
        children: transformMenu(item?.link)
      }
    }
    if (item.type === 'external') {
      return {
        id: item._key,
        type: item._type,
        link: {
          title: item?.text || item?.internalLink?.title,
          path: item?.url || '/',
          blank: item?.blank
        }
      }
    }

    return {
      id: item._key,
      type: item._type,
      link: {
        title: item?.text || item?.internalLink?.title,
        path: item?.internalLink?.slug?.current || '/',
        target: item?.blank
      }
    }
  })
}

/**
 * Recursively checks if a menu item (or any of its descendants)
 * has a link that exactly matches the current URL.
 *
 * @param item - A MenuItem from your menu structure.
 * @param currentUrl - The current URL (including query string) to check against.
 *                     For example: "/shop/all?tags=classic"
 * @returns true if this item or any descendant matches the currentUrl.
 */
export const checkIsItemActive = (item: MenuItem, currentUrl: string): boolean => {
  // If the item has a link, compare the full (normalized) URL.
  if (item.link?.path) {
    let itemUrl = item.link.path

    // Normalize the item URL: ensure it starts with a slash.
    if (!itemUrl.startsWith('/')) {
      itemUrl = '/' + itemUrl
    }

    if (itemUrl !== '/' && itemUrl.endsWith('/')) {
      itemUrl = itemUrl.slice(0, -1)
    }

    // Similarly, normalize the current URL.
    let normalizedCurrentUrl = currentUrl
    if (!normalizedCurrentUrl.startsWith('/')) {
      normalizedCurrentUrl = '/' + normalizedCurrentUrl
    }
    if (normalizedCurrentUrl !== '/' && normalizedCurrentUrl.endsWith('/')) {
      normalizedCurrentUrl = normalizedCurrentUrl.slice(0, -1)
    }

    // If the full URL matches, mark this item as active.
    if (itemUrl === normalizedCurrentUrl) {
      return true
    }
  }

  // If this item does not have a matching link,
  // check if any of its children (at any depth) are active.
  if (item.children && item.children.length > 0) {
    return item.children.some((child) => checkIsItemActive(child, currentUrl))
  }

  // No match found at this level or any descendant.
  return false
}
