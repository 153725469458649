import { FALLBACK_IMAGE } from '@/app/(store)/constants'
import Image from 'next/image'
import Link from 'next/link'
import { Button } from '../../button'

const PageCard = ({ data }: { data: AlgoliaPage }) => {
  const { image, slug, title, _id, _type } = data

  return (
    <article className="flex h-full flex-col justify-between gap-4">
      <div className="relative aspect-1 h-[250px] max-h-[300px] min-h-[250px] w-full">
        <Link
          data-test="content-card-link-image"
          aria-hidden="true"
          tabIndex={-1}
          href={`/${slug?.current}` || '#'}
        >
          <Image
            src={image || FALLBACK_IMAGE}
            alt={''}
            sizes="(max-width: 640px) 100vw, 25vw"
            fill
            className="object-cover"
          />
        </Link>
      </div>
      <div className="flex h-full flex-col justify-between gap-5">
        <div className="flex flex-col gap-1">
          <h2 id={_id} className="h5 line-clamp-2">
            {title}
          </h2>
        </div>
        <div className="">
          <Button
            size="normal"
            renderButton={({ size, color, animation, text }) => {
              return (
                <Link
                  id={`page-view-button-${_id}`}
                  data-test="page-view-button"
                  aria-labelledby={`page-view-button-${_id}`}
                  className={`${size} ${color} ${animation} ${text} inline-flex w-full items-center justify-between text-lg`}
                  href={`/${slug?.current}`}
                >
                  view {_type} <span className="material-symbols-outlined">chevron_right</span>
                </Link>
              )
            }}
          />
        </div>
      </div>
    </article>
  )
}

export default PageCard
