'use client'
import { Fragment } from 'react'

import {
  FeaturedHero,
  StoreLocator,
  IconListing,
  SanityCarousel,
  TwoColumnBio,
  TwoColumnCallout,
  SectionHeader,
  Hero,
  ImageGallery,
  ImageMosaic,
  Accordion,
  HeroCard,
  BioCard,
  FullBannerTwoColumns,
  ContentCard,
  SanityGrid,
  VisualCta,
  RichTextLight,
  CountdownTimer,
  Testimonials,
  AcademyForm,
  AcademyListing,
  SimpleMap,
  Video,
  ProductCard,
  UgcGalleryComponent,
  SiteReviews,
  RaysClubForm,
  KlaviyoForm
} from '@/components'
import ExclusiveVideoContent from './video-content'

export const directory = {
  default: () => <></>,
  accordion: (sanityModule: any) => <Accordion asModule={true} {...sanityModule} />,
  bioCard: (sanityModule: any) => <BioCard {...sanityModule} />,
  content: (sanityModule: any) => <RichTextLight content={sanityModule?.text || []} />,
  carousel: (sanityModule: any) => <SanityCarousel {...sanityModule} />,
  contentCard: (sanityModule: any) => <ContentCard {...sanityModule} />,
  countdownTimer: (sanityModule: any) => <CountdownTimer {...sanityModule} />,
  featuredHero: (sanityModule: any) => <FeaturedHero {...sanityModule} />,
  fullBanner: (sanityModule: any) => <FullBannerTwoColumns {...sanityModule} />,
  grid: (sanityModule: any) => <SanityGrid {...sanityModule} />,
  hero: (sanityModule: any) => <Hero {...sanityModule} />,
  heroCard: (sanityModule: any) => <HeroCard {...sanityModule} />,
  videoListing: (sanityModule: any) => <ExclusiveVideoContent {...sanityModule} />,
  video: (sanityModule: any) => <Video {...sanityModule} />,
  image: (sanityModule: any) => (
    <img src={sanityModule?.asset?.url} alt={sanityModule?.asset?.altText} />
  ),
  imageGallery: (sanityModule: any) => <ImageGallery {...sanityModule} />,
  imageMosaic: (sanityModule: any) => <ImageMosaic {...sanityModule} />,
  iconListing: (sanityModule: any) => <IconListing {...sanityModule} />,
  productCard: (sanityModule: any) => <ProductCard {...sanityModule} />,
  twoColumnCallout: (sanityModule: any) => <TwoColumnCallout {...sanityModule} />,
  twoColumnBio: (sanityModule: any) => <TwoColumnBio {...sanityModule} />,
  sectionHeader: (sanityModule: any) => <SectionHeader {...sanityModule} />,
  storeLocatorModule: (sanityModule: any) => <StoreLocator {...sanityModule} />,
  visualCta: (sanityModule: any) => <VisualCta {...sanityModule} />,
  testimonials: (sanityModule: any) => <Testimonials {...sanityModule} />,
  academyForm: (sanityModule: any) => <AcademyForm {...sanityModule} />,
  academyListing: (sanityModule: any) => <AcademyListing {...sanityModule} />,
  simpleMap: (sanityModule: any) => <SimpleMap {...sanityModule} />,
  ugcGallery: (sanityModule: any) => <UgcGalleryComponent {...sanityModule} />,
  siteReviews: (sanityModule: any) => <SiteReviews {...sanityModule} />,
  raysClubForm: (sanityModule: any) => <RaysClubForm {...sanityModule} />,
  klaviyoForm: (sanityModule: any) => <KlaviyoForm {...sanityModule} />
}

export default function Modules({ modules }: any) {
  return (
    <>
      {modules?.map((sanityModule: any, idx: number) => {
        if (!sanityModule?._type) return <Fragment key={idx} />
        const component =
          directory[sanityModule?._type as keyof typeof directory] || directory?.default

        return <Fragment key={sanityModule?.id || idx}>{component(sanityModule)}</Fragment>
      })}
    </>
  )
}
