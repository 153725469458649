'use client'
import { Button, Drawer, ProductPrice, ProductTitle } from '@/components'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { BUNDLE_PRODUCT_TYPE, DEFAULT_OPTION, FALLBACK_IMAGE } from '@/app/(store)/constants'
interface Props {
  parent: Product
  options: Product[]
  currentVariant: ProductVariant
  selectedBundleId?: string | null
  onSelection: (props: Product) => void
}

const BundleOptions: React.FC<Props> = ({
  parent,
  options,
  onSelection,
  currentVariant,
  selectedBundleId
}) => {
  const searchParams = useSearchParams()
  const imageFallback = FALLBACK_IMAGE

  return options.map((item, idx) => {
    const hasDefaultSelection = item.id === parent.id && !searchParams.get('bundleId')
    const isFirstInList = idx === 0
    const buttonStyle =
      item.id === selectedBundleId || hasDefaultSelection ? 'primary' : 'primary-outline'

    const shouldShowComponents = item?.productType === BUNDLE_PRODUCT_TYPE
    const bundleComponents =
      (item?.variants?.length > 0 && item?.variants[0]?.components?.nodes) || []
    const images = item.images
    const secondImage = idx > 1 && images.length >= 2 ? item.images[1] : undefined

    return (
      <>
        <section className={`flex flex-col`} key={`bundle-${idx}`}>
          <div
            className={`flex flex-col items-center justify-center gap-4 border-t border-gray py-3 pr-4 md:flex-row md:items-start lg:items-center lg:justify-start dark:border-secondary`}
            key={item.handle}
          >
            <div className="h-[200px] w-[200px] justify-center md:h-[80px] md:w-[80px] lg:h-[120px] lg:w-[120px] lg:max-w-[120px]">
              <div className="relative h-[200px] w-[200px] md:h-[80px] md:w-[80px] lg:h-[120px] lg:w-[120px]">
                <Image
                  data-test={`product-bundle-image-${item?.id}`}
                  src={secondImage?.url || item?.featuredImage?.url || imageFallback}
                  alt={item.featuredImage?.altText || ''}
                  sizes="(max-width: 400px) 200px, 200px"
                  className="object-cover"
                  fill
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-4 lg:flex-row lg:justify-between">
              <div className="flex flex-col gap-5">
                <div className="w-full justify-center text-center md:text-left lg:justify-start">
                  <ProductTitle
                    product={
                      isFirstInList
                        ? {
                            ...item,
                            display_title: {
                              value: `${item?.display_title?.value || parent.title} - grill only`
                            } as Metafield
                          }
                        : parent
                    }
                    bundle={isFirstInList ? undefined : item}
                    size="h5 font-medium"
                  />
                  <ProductPrice currentVariant={currentVariant} bundle={item} />
                </div>

                {shouldShowComponents && (
                  <div className="flex justify-center lg:justify-start">
                    <Drawer
                      title="WHAT'S INCLUDED"
                      icon="chevron_right"
                      className="w-full max-w-[525px] text-base"
                    >
                      <div className="w-full px-5">
                        <section>
                          <h3 className="h2 text-center">BUNDLE AND SAVE</h3>
                          <ProductTitle
                            product={
                              isFirstInList
                                ? {
                                    ...item,
                                    display_title: {
                                      value: `${item?.display_title?.value || parent.title} - grill only`
                                    } as Metafield
                                  }
                                : parent
                            }
                            bundle={isFirstInList ? undefined : item}
                            size="h5 text-center font-medium"
                          />
                        </section>
                        <section className="grid grid-cols-1 gap-4 py-4">
                          {bundleComponents?.map(
                            ({ productVariant: { title, image, product }, quantity }) => {
                              return (
                                <div
                                  key={product.id}
                                  className="inline-flex flex-nowrap items-center gap-5"
                                >
                                  <div className="relative aspect-1 h-[50px] min-h-[50px] w-[50px] min-w-[50px] max-w-[50px]">
                                    <Image
                                      data-test={`product-bundle-component-image`}
                                      fill
                                      src={
                                        image?.url || product?.featuredImage?.url || imageFallback
                                      }
                                      alt={image?.altText || product?.featuredImage?.altText || ''}
                                      sizes="(max-width: 650px) 90vw, 300px"
                                    />
                                  </div>
                                  <p className="p" data-test={`product-bundle-component-title`}>
                                    {title !== DEFAULT_OPTION ? title : product.title}
                                    {quantity > 1 ? (
                                      <sup className="text-sm">({quantity})</sup>
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </div>
                              )
                            }
                          )}
                        </section>
                      </div>
                    </Drawer>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center md:justify-start ">
                <div>
                  <Button
                    id={`bundle-select-${item.handle}`}
                    className={`w-full ${item.id === selectedBundleId || hasDefaultSelection ? 'dark:bg-primary' : 'dark:border-white dark:text-white dark:hover:border-primary dark:hover:bg-transparent dark:hover:text-primary'}`}
                    color={buttonStyle}
                    size="normal"
                    onClick={() => {
                      if (item.id != selectedBundleId && !hasDefaultSelection) {
                        onSelection(item)
                      }
                    }}
                    aria-labelledby={`bundle-select-${item.handle} ${item.id || parent.id}`}
                    data-test="button"
                  >
                    {item.id === selectedBundleId || hasDefaultSelection ? 'selected' : 'select'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  })
}
export default BundleOptions
