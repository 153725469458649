import { Button, ModuleHandler } from '@/components'
import Link from 'next/link'
import Image from 'next/image'

interface AcademyListingProps {
  academies: Academy[]
  componentGlobalConfig?: Sanity.ComponentConfig
}
export default function AcademyListing({ academies, componentGlobalConfig }: AcademyListingProps) {
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section className="module_spacing">
        <div className="container mx-auto py-10">
          <div className="grid min-h-[100vh] grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {academies?.map((academy: Academy) => (
              <AcademyCard key={academy._id} academy={academy} />
            ))}
          </div>
        </div>
      </section>
    </ModuleHandler>
  )
}

interface Academy {
  _id: string
  title: string
  location: string
  listingAddress: string
  slug: { current: string }
  seo: {
    ogimage: {
      asset: {
        url: string
        extension: string
        assetId: string
        altText: string
      }
    }
  }
}

interface AcademyCardProps {
  academy: Academy
}

const AcademyCard = ({ academy }: AcademyCardProps) => {
  const imageUrl = academy.seo?.ogimage?.asset.url
  return (
    <div className="col-span-1 justify-center font-oswald">
      {/* Location in all caps */}
      <h3 className="h4 mb-4 uppercase">{academy.location}</h3>
      {imageUrl && (
        <Image
          src={imageUrl}
          alt={academy.title ? `${academy.title} - Academy Image` : 'Academy Image'}
          width={400}
          height={600}
          className="aspect-1 h-72 w-full object-cover"
        />
      )}
      {/* Listing Address */}
      <div className="flex flex-col items-center p-4">
        <p className="p mb-4">{academy.listingAddress}</p>
        {/* Button to Academy Page */}
        <Button size="minimal" color="primary">
          <Link href={`/${academy.slug.current}`}>View Details</Link>
        </Button>
      </div>
    </div>
  )
}
