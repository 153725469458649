import SanityLink from '../link'

interface Props {
  title: string
  legalMenuItems: MenuItem[]
}

const LegalMenu = ({ title, legalMenuItems }: Props) => {
  return (
    <>
      <div className="container flex flex-nowrap justify-center lg:mx-0 lg:w-full lg:max-w-none lg:px-0">
        <section className="flex flex-col gap-4 py-4 lg:flex-col-reverse lg:items-start">
          <p className="p upercase w-full text-center text-sm text-white/80">
            &copy; {new Date().getFullYear()} {title}
          </p>
          <nav aria-label="Legal navigation menu">
            <ul className="p flex flex-wrap justify-center gap-4 text-sm text-white/80">
              {legalMenuItems?.map(({ id, link }, idx) => {
                const target = link?.blank ? '_blank' : '_self'
                if (!link?.path && !link?.title) return null
                return (
                  <li key={id + idx.toString()}>
                    {link?.path ? (
                      <SanityLink key={id + idx.toString()} href={link?.path} target={target}>
                        {link?.title}
                      </SanityLink>
                    ) : (
                      <p className="p-alt">{link.title}</p>
                    )}
                  </li>
                )
              })}
              <li>
                <SanityLink href="do-not-sell">Manage cookie preferences</SanityLink>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </>
  )
}

export default LegalMenu
