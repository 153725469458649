import { productFragment } from './products'

export const cartFragment = /* GraphQL */ `
  fragment cart on Cart {
    id
    checkoutUrl
    discountCodes {
      code
      applicable
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
          sellingPlanAllocation {
            ... on SellingPlanAllocation {
              sellingPlan {
                id
                name
                priceAdjustments {
                  adjustmentValue {
                    ... on SellingPlanFixedAmountPriceAdjustment {
                      adjustmentAmount {
                        amount
                      }
                    }
                    ... on SellingPlanPercentagePriceAdjustment {
                      adjustmentPercentage
                    }
                  }
                }
                billingPolicy {
                  ... on SellingPlanRecurringBillingPolicy {
                    interval
                    intervalCount
                  }
                }
                deliveryPolicy {
                  ... on SellingPlanRecurringDeliveryPolicy {
                    interval
                    intervalCount
                  }
                }
                description
                options {
                  name
                  value
                }
              }
            }
          }
          discountAllocations {
            ... on CartAutomaticDiscountAllocation {
              title
              discountedAmount {
                currencyCode
                amount
              }
            }
            ... on CartDiscountAllocation {
              discountedAmount {
                currencyCode
                amount
              }
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              sku
              requiresComponents
              groupedBy(first: 250) {
                nodes {
                  id
                  title
                  product {
                    title
                    productType
                    featuredImage {
                      url
                      altText
                    }
                  }
                }
              }
              compareAtPrice {
                amount
              }
              selectedOptions {
                name
                value
              }
              price {
                amount
                currencyCode
              }
              image {
                id
                url
                altText
              }
              product {
                ...product
              }
            }
          }
        }
      }
    }
    totalQuantity
  }
  ${productFragment}
`
