import { TAGS } from '@/constants'
import { removeNullUndefined } from '@/utils'
import { removeEdgesAndNodes, reshapeProduct, reshapeProducts } from '../utils'

import {
  getProductByIdQuery,
  getProductQuery,
  getProductsByIdQuery,
  getProductsQuery,
  getPricingInventoryByIdQuery,
  getProductsLightQuery,
  getDynamicMetafieldsQuery,
  getProductLightQuery
} from '@/lib/shopify/queries'

import { shopifyFetch } from '..'

export async function getProductById(
  id: string,
  selectedOptions: { name: string; value: string }[] = []
): Promise<Product | null | undefined> {
  const res = await shopifyFetch<ShopifyProductByIdOperation>({
    query: getProductByIdQuery,
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: {
      id,
      selectedOptions
    }
  })

  return reshapeProduct(res.body.data.product, false)
}
export async function getProduct(
  handle: string,
  selectedOptions: { name: string; value: string }[] = []
): Promise<Product | null | undefined> {
  const res = await shopifyFetch<ShopifyProductOperation>({
    query: getProductQuery,
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: {
      handle,
      selectedOptions
    }
  })

  return reshapeProduct(res.body.data.product, false)
}
export async function getProductLight(handle: string): Promise<Product | null | undefined> {
  const res = await shopifyFetch<ShopifyProductOperation>({
    query: getProductLightQuery,
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: {
      handle
    }
  })

  return reshapeProduct(res.body.data.product, false)
}
export async function getProductMetafields(
  handle: string,
  metafields: { namespace: string; key: string }[]
): Promise<Product | null | undefined> {
  const res = await shopifyFetch<ShopifyProductOperation>({
    query: getDynamicMetafieldsQuery(metafields),
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: {
      handle
    }
  })

  return reshapeProduct(res.body.data.product, false)
}
export async function getProducts({
  query,
  reverse,
  sortKey,
  first = 250,
  after
}: {
  sortKey?: string
  reverse?: boolean
  first?: number
  last?: number
  after?: string
  before?: string
  query?: string
}): Promise<{ pageInfo: PageInfo; products: Product[]; cursors: string[] }> {
  const res = await shopifyFetch<ShopifyProductsOperation>({
    query: getProductsQuery,
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: removeNullUndefined({
      query,
      reverse,
      sortKey,
      first,
      after
    })
  })

  return {
    pageInfo: res.body.data.products.pageInfo,
    cursors: res.body.data.products.edges.map((edge) => edge?.cursor),
    products: reshapeProducts(removeEdgesAndNodes(res.body.data.products))
  }
}
export async function getProductsLight({
  query,
  reverse,
  sortKey,
  first = 250,
  after
}: {
  sortKey?: string
  reverse?: boolean
  first?: number
  last?: number
  after?: string
  before?: string
  query?: string
}): Promise<{ pageInfo: PageInfo; products: Product[]; cursors: string[] }> {
  const res = await shopifyFetch<ShopifyProductsOperation>({
    query: getProductsLightQuery,
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: removeNullUndefined({
      query,
      reverse,
      sortKey,
      first,
      after
    })
  })

  return {
    pageInfo: res.body.data.products.pageInfo,
    cursors: res.body.data.products.edges.map((edge) => edge?.cursor),
    products: reshapeProducts(removeEdgesAndNodes(res.body.data.products))
  }
}
export async function getProductsById({
  ids
}: {
  ids: string[]
}): Promise<{ products: Product[] }> {
  const res = await shopifyFetch<ShopifyProductsByIdOperation>({
    query: getProductsByIdQuery,
    tags: [TAGS.products],
    cache: 'no-cache',
    variables: { ids }
  })

  return {
    products: reshapeProducts(res.body.data.nodes)
  }
}
export async function getPricingInventoryById({
  ids
}: {
  ids: string[]
}): Promise<{ products: Product[] }> {
  const res = await shopifyFetch<ShopifyProductsByIdOperation>({
    query: getPricingInventoryByIdQuery,
    cache: 'no-cache',
    variables: { ids }
  })
  return {
    products: reshapeProducts(res.body.data.nodes)
  }
}
