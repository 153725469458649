'use client'
import { PortableTextBlock } from 'next-sanity'
import { CTA, CTALayout, ModuleHandler, Overlay, RichText } from '@/components'

enum TextAlignment {
  left = 'text-left col-span-8',
  center = 'text-center col-span-8 justify-center flex',
  right = 'text-right col-span-8 flex justify-end',
  default = 'text-left col-span-8'
}
enum SubTitleAlignment {
  left = 'text-left',
  center = 'text-center',
  right = 'text-right',
  default = 'text-left'
}
enum CtaAlignment {
  left = 'mt-4 text-left',
  center = 'mt-4 flex justify-center',
  right = 'mt-4 flex justify-end',
  default = 'mt-4'
}
enum TitleAlignment {
  left = 'text-left col-span-8 md:col-span-6',
  center = 'text-center col-span-8',
  right = 'text-right col-span-8',
  default = 'text-left col-span-8 md:col-span-6 self-center'
}
enum Border {
  none = '',
  bottom = 'border-b',
  top = 'border-t',
  both = 'border-y py-6'
}
enum Theme {
  light = 'bg-gray text-secondary',
  dark = 'bg-secondary text-white'
}

interface Props {
  title: string
  titleSize: string
  subtitle?: string
  body?: PortableTextBlock[]
  alignment?: keyof typeof TitleAlignment
  border?: keyof typeof Border
  theme?: 'dark' | 'light' | 'none'
  className: string
  cta: {
    link: Sanity.Link
  } & CTALayout
  media?: Sanity.Media
  componentGlobalConfig?: Sanity.ComponentConfig
}

export const SectionHeader: React.FC<Props> = ({
  title,
  subtitle,
  body,
  cta,
  alignment = 'center',
  border = 'none',
  theme = 'light',
  className = '',
  media,
  titleSize = 'h1',
  componentGlobalConfig
}) => {
  if (!title && !subtitle && !body && !cta) {
    console.warn('The <SectionComponent> element must receive at least one piece of content.')
  }

  const getBackgroundStyles = (background?: Sanity.Media) => {
    if (background?.asset?.extension === 'mp4') {
      return {}
    }
    if (!background?.asset?.url) {
      console.warn('Background object is missing Sanity.Image prop at <SectionHeader> component.')
      return {}
    }

    return {
      background: `#fff url(${background.asset.url}) no-repeat left top`,
      backgroundSize: 'cover'
    }
  }

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section
        className={`${theme && theme != 'none' ? Theme[theme] : ''} ${media?.asset?.url || (theme && theme != 'none') ? 'mb-[32px] lg:mb-[64px]' : ''} relative dark:text-white`}
        style={media?.asset?.url ? getBackgroundStyles(media) : {}}
        data-test="section-header-container"
        data-component="section-header"
      >
        {media?.asset?.url && <Overlay gradient="none" color={theme} />}
        <div className="container relative py-10">
          {media?.asset?.extension === 'mp4' && (
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute left-0 top-0 h-full w-full object-cover"
            >
              <source src={media.asset.url} type="video/mp4" />
            </video>
          )}
          <div
            className={`relative z-[2] grid grid-cols-8 border-gray 
          ${border ? Border[border] : ''} ${className ? className : ''}
        `}
          >
            <h2
              data-test="section-header-title"
              className={`${titleSize} uppercase ${alignment ? TitleAlignment[alignment] : ''}`}
            >
              {title}
            </h2>
            {subtitle && (
              <h3
                data-test="section-header-subtitle"
                className={`p-alt-large col-span-8 uppercase ${alignment ? SubTitleAlignment[alignment] : ''}`}
              >
                {subtitle}
              </h3>
            )}
          </div>
          {body && (
            <div
              data-test="section-header-text"
              className={`mt-4 font-lato font-normal text-inherit ${alignment ? TextAlignment[alignment] : ''} `}
            >
              <RichText content={body} className="text-inherit" />
            </div>
          )}
          {cta?.link && cta?.link?.text && (
            <div
              data-test="section-header-cta-container"
              className={`w-full  ${CtaAlignment[alignment]}`}
            >
              <CTA
                as="button-link"
                link={cta.link}
                layout={{
                  color: cta.color,
                  size: cta.size
                }}
                component="section-header"
              />
            </div>
          )}
        </div>
      </section>
    </ModuleHandler>
  )
}
