import { SITE_URL } from '../../constants'

export async function fetchYotpoAlbum(albumName: string) {
  if (!albumName) {
    albumName = 'My First Album'
  }

  const queryParams = new URLSearchParams()
  queryParams.set('album_name', albumName)

  const url = `${SITE_URL}/api/yotpo/getAlbum?${queryParams.toString()}`
  const res = await fetch(url, { cache: 'no-store' })
  if (!res.ok) {
    throw new Error(`Failed to fetch Yotpo album. Status: ${res.status}`)
  }

  const data = await res.json()
  return data?.response ?? {}
}
