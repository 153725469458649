'use client'

import { ReactElement, useEffect, useState } from 'react'
import { PaginationStyles } from '.'

import dynamic from 'next/dynamic'
import { ProductCard } from '../cards'
import { directory } from '../modules'
import ContentCard from '../cards/content-card'
import { getDataBySanityQuery } from '../../utils/query'
import { useCustomerContext } from '../../context'
import ModuleHandler from '../module-handler'

interface Props extends DataByQuery {
  title?: string
  titleAlignment?: 'start' | 'center' | 'end'
  showNavigation?: boolean
  showPagination?: boolean
  paginationTheme?: 'light' | 'dark'
  speed?: number
  slidesPerView?: number
  delay?: boolean
  componentGlobalConfig?: Sanity.ComponentConfig
  _key?: string
}

const SanityCarousel = (props: Props) => {
  const [items, setItems] = useState<ReactElement[] | undefined>()
  const { source, manualSelections, querySelection, componentGlobalConfig } = props || {}
  const { isRaysClub } = useCustomerContext()

  useEffect(() => {
    const getData = async () => {
      if (source === 'manual') {
        if (manualSelections?.reference?.manualSelection?.length > 0) {
          const sanityProducts = manualSelections.reference?.manualSelection.filter(
            (selection) => selection._type === 'productCard'
          )
          const data = await getDataBySanityQuery({
            source: 'manual',
            type: 'products',
            manualSelection: sanityProducts,
            isRaysClub
          })

          const items = manualSelections?.reference?.manualSelection
            ?.map((selection) => {
              if (selection._type === 'productCard') {
                const product = data.find(
                  (item: Product) => item.id === selection.product.reference.gid
                )
                return directory[selection._type as keyof typeof directory]({
                  product: product,
                  buttonType: selection.buttonAction,
                  buttonText: selection.buttonAction === 'subscribe' ? 'Subscribe' : 'Add To Cart',
                  planId: selection?.subscriptionId
                    ? `gid://shopify/SellingPlan/${selection?.subscriptionId}`
                    : undefined
                })
              }
              if (selection._type === 'contentCard') {
                return directory[selection._type as keyof typeof directory]({
                  content: selection.content.reference
                })
              }
              return directory[selection._type as keyof typeof directory]({
                ...selection
              })
            })
            ?.flat()

          setItems(items)
        }
      }

      if (source === 'query') {
        if (querySelection?.reference?.type === 'products') {
          const data = await getDataBySanityQuery({
            source: 'query',
            type: querySelection?.reference?.type,
            query: querySelection?.reference?.query,
            connective: querySelection?.reference?.connective,
            isRaysClub
          })
          setItems(
            data.map((product: Product) => <ProductCard key={product.id} product={product} />)
          )
        }

        if (querySelection?.reference?.type === 'content') {
          const data = await getDataBySanityQuery({
            source: 'query',
            type: querySelection?.reference?.type,
            query: props.querySelection.reference.contentquery,
            isRaysClub
          })
          data &&
            setItems(data.map((hit: AlgoliaContent) => <ContentCard key={hit._id} content={hit} />))
        }
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Carousel = dynamic(() => import('@/components').then((mod) => mod.Carousel), {
    ssr: false,
    loading: () => (
      <SanityCarouselSkelletonLoader title={props.title} slidesPerView={props?.slidesPerView} />
    )
  })

  return (
    <ModuleHandler config={componentGlobalConfig}>
      {items && items?.length > 0 && (
        <div className={`${querySelection?.reference?.type != 'product' ? 'px-4 lg:px-0' : ''}`}>
          <Carousel
            titleAlign={props?.titleAlignment}
            showNavigation={props?.showNavigation || false}
            showPagination={props?.showPagination || false}
            paginationStyles={props?.paginationTheme as keyof typeof PaginationStyles}
            speed={props?.speed}
            slidesPerView={props?.slidesPerView || 1.25}
            delay={props?.delay || false}
            items={items}
            pagination={{
              el: `.swiper-custom-pagination-${props?._key}`,
              clickable: true,
              type: 'progressbar'
            }}
          />
        </div>
      )}
      {(!items || items?.length === 0) && (
        <SanityCarouselSkelletonLoader title={props.title} slidesPerView={props?.slidesPerView} />
      )}
    </ModuleHandler>
  )
}

export const SanityCarouselSkelletonLoader = (props: any) => {
  return (
    <section className="module-spacing container flex animate-pulse flex-col items-start gap-10">
      {props?.title && (
        <div className={`h-[64px] w-2/3 self-${props?.titleAlignment} bg-gray lg:w-1/3`} />
      )}
      <div className="flex w-full gap-8 lg:px-8">
        {Array.from({ length: props?.slidesPerView || 0 }).map((_, idx) => (
          <div
            key={idx}
            className={`flex w-full flex-col rounded py-3 ${idx > 2 ? 'hidden lg:block' : ''}`}
          >
            <div className="mb-5 aspect-1 w-full bg-gray" />
            <div className="mb-8 h-[200px] w-3/4 bg-gray" />
            <div className="h-[50px] w-full bg-gray" />
          </div>
        ))}
      </div>
    </section>
  )
}
export default SanityCarousel
