import { groq } from 'next-sanity'

export const internalLinkFragment = `internalLink->{_type,slug,title, metadata}`
export const assetFragment = `asset->{url,extension,assetId, altText}`

export const globalComponentConfigFragment = `
  componentGlobalConfig {
    hideComponent
  }
`
export const linkFragment = `
  link {
    ...,
    ${internalLinkFragment},
  }
`
export const ctaFragment = `
  cta {
    ...,
    ${linkFragment}
  }
`
export const mediaFragment = `
  'media': media {
    ${assetFragment}
  }
`
export const iconFragment = `
  icon {
    ${assetFragment}
  }
`

export const tagsFragment = `
  'tags': tags[] {
    'tag': *[_type == 'tagsType' && _id == ^._ref][0] {
      ...,
    }
  }
`

export const videoContentFragment = groq`
  source,
  desktopPoster {
    ${assetFragment}
  },
  mobilePoster {
    ${assetFragment}
  },
  mobileSource {
    ...,
    ${assetFragment}
  },
  desktopSource {
    ...,
    ${assetFragment}
  }
`
