'use client'

import { useIsDesktop } from '@/hooks'
import Modules from '../modules'
import ModuleHandler from '../module-handler'

const Row = ({ sanityModule, className }: { sanityModule: any; className?: string }) => {
  const isDesktop = useIsDesktop()

  return (
    <ModuleHandler config={sanityModule?.value?.componentGlobalConfig}>
      <div
        className={`module-spacing grild-cols-1 container pt-10 ${className ? className : ''}`}
        style={{
          display: 'grid',
          gridTemplateColumns: isDesktop
            ? sanityModule?.value?.items?.map(() => `1fr`)?.join(' ') || '1fr'
            : '1fr',
          gap: '2rem'
        }}
      >
        <Modules modules={sanityModule.value.items} />
      </div>
    </ModuleHandler>
  )
}

export default Row
