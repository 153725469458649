'use client'

import { useEffect, useState } from 'react'
import { disableDraftMode, draftModeCheck } from '@/actions/preview/preview.action'
import { usePathname, useRouter } from 'next/navigation'

export const DraftModeBar = () => {
  const [isDraft, setIsDraft] = useState(false)
  const router = useRouter()
  const pathName = usePathname()

  const handleClick = async () => {
    await disableDraftMode()
    setIsDraft(false)
    router.refresh()
  }

  useEffect(() => {
    const checkDocumentStatus = async () => {
      const draft = await draftModeCheck()
      setIsDraft(draft)
    }
    checkDocumentStatus()
  }, [pathName])

  if (isDraft) {
    return (
      <section className="fixed bottom-0 z-50 w-full bg-gray-500 py-4 text-white">
        <div className="container flex items-center justify-between">
          <p>You are viewing in draft mode. You will be able to see all draft documents.</p>
          <button className="bg-gray-600 px-4 py-2" onClick={handleClick}>
            Exit
          </button>
        </div>
      </section>
    )
  }
  return
}
