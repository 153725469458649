'use client'
import { useEtchATeqContext } from '@/app/(store)/context'
import Image from 'next/image'
import RichText from '@/components/rich-text'

const SelectionProduct = () => {
  const { page, selectedProduct } = useEtchATeqContext()
  const { title, body, defaultImage } = page || {}
  return (
    <section className="relative bg-primary">
      <div className="container relative flex flex-col pt-[100px] lg:pt-[140px]">
        <div className="absolute left-2 top-[120px] z-0 w-[100px] opacity-50 md:w-[150px] lg:left-[20%] lg:top-[150px] lg:w-[160px]">
          <div className="radial-shadow-dognut absolute z-10 h-full w-full" />
          <div className="relative h-[100px] w-[120px] lg:h-[120px] lg:w-[220px]">
            <Image
              src="/images/etch-a-teq-patter-2.svg"
              alt=""
              fill
              className="relative rotate-[-10deg]"
            />
          </div>
        </div>
        <div className="absolute right-0 top-[100px] z-0 w-[150px] opacity-50 md:w-[200px] lg:w-[300px]">
          <div className="radial-shadow-dognut absolute z-10 h-full w-full" />
          <Image
            src="/images/etch-a-teq-patter-2.svg"
            alt=""
            width={340}
            height={280}
            className="relative rotate-[70deg]"
          />
        </div>
        <div className="absolute left-[-5%] top-[50%] z-0 w-[130px] opacity-50 lg:w-[100px] xl:w-[150px]">
          <div className="radial-shadow-dognut absolute z-10 h-full w-full" />
          <Image
            src="/images/etch-a-teq-patter-2.svg"
            alt=""
            width={190}
            height={120}
            className="relative rotate-[-10deg]"
          />
        </div>
        <div className="absolute right-0 top-[60%] z-0 w-[75px] opacity-50 md:w-[125px] lg:w-[145px]">
          <div className="radial-shadow-dognut absolute z-10 h-full w-full" />
          <Image
            src="/images/etch-a-teq-patter-2.svg"
            alt=""
            width={190}
            height={120}
            className="relative rotate-[-10deg]"
          />
        </div>
        {/* Logo */}
        <div className="mb-4 mt-8 h-full w-full max-w-[150px] md:mt-0 lg:ml-8 lg:max-w-[200px] xl:mb-12">
          <div className="aspect-h-11 aspect-w-16 relative z-[5]">
            <Image
              src={'/images/etchateq-logo.svg'}
              height={206}
              width={300}
              alt=""
              className="png-shadow"
            />
          </div>
        </div>
        {/* Headings */}
        <div
          className="z-[2] items-center justify-between lg:flex lg:flex-nowrap xl:items-start xl:justify-start"
          data-component="etch-a-teq-hero"
        >
          <div className="z-10 max-w-[100%] text-white lg:max-w-[50%] lg:px-4 xl:max-w-[45%]">
            <h1 className="h1 mb-4 text-white">{title}</h1>
            {body && <RichText content={body} />}
          </div>
          <div className="relative z-10 aspect-1 w-full md:w-3/4 lg:w-1/2 lg:translate-y-28 xl:translate-y-0 2xl:w-[40%]">
            <Image
              role="presentation"
              src={
                selectedProduct?.personalization?.etchATeqImage?.asset?.url ||
                selectedProduct?.featuredImage?.url ||
                defaultImage?.asset?.url
              }
              fill
              alt={defaultImage?.asset?.altText}
              className="relative z-[1] object-contain"
            />
            <div className="absolute left-[40px] top-0 z-0 w-full overflow-visible">
              <div className="hidden h-full w-full items-center justify-center xl:flex">
                <div className="radial-shadow lg:max-w-noneoverflow-visible ml-2 aspect-1 w-[350px] opacity-60 md:w-[400px] lg:ml-[28rem] lg:w-[500px] xl:mx-auto xl:w-[600px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SelectionProduct
