'use client'
import { parseLocaleDateTime, parseMoneyFormat } from '@/app/(store)/utils'
import {
  cancelSubscription,
  readAvailableSubscriptionFrequency,
  removeLineFromSubscription,
  updateSubscriptionFrequency
} from '@/lib'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Select } from '../../../select'
import { Button } from '../../../button'
import Drawer from '../../../drawer'
import { DEFAULT_OPTION } from '@/app/(store)/constants'
import toast from 'react-hot-toast'
import LoadingIndicator from '../../../loadingIndicator'
import Link from 'next/link'

const ManageSubscriptions = ({
  products,
  subscriptions
}: {
  subscriptions: Subscription[]
  products: Product[]
}) => {
  return (
    <section className="col-span-10 w-full overflow-y-auto lg:col-span-8">
      <div className={`h-full w-full `}>
        {subscriptions && subscriptions?.length > 0 && (
          <table className="p w-full text-secondary">
            <thead>
              <tr className="p capitalize">
                <th>Subscription ID</th>
                <th>Status</th>
                <th>Next Order Date</th>
                <th>Delivery Interval</th>
                <th>Items</th>
                <th>Items Total</th>
                <th>Order Frequency</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions?.map((sub) => {
                const {
                  status,
                  lines,
                  totalLineItemPrice,
                  deliveryPolicy,
                  nextBillingDateEpoch,
                  shopifyId
                } = sub || {}

                const billingData = nextBillingDateEpoch ? (
                  parseLocaleDateTime(new Date(nextBillingDateEpoch * 1000))
                ) : (
                  <>---</>
                )

                return (
                  <tr key={sub.id} className="divide-x border text-center text-sm">
                    <td>#{shopifyId}</td>
                    <td className="capitalize">{status.toLocaleLowerCase()}</td>
                    <td>{billingData}</td>
                    <td className="capitalize">
                      {deliveryPolicy.intervalCount} {deliveryPolicy.interval.toLocaleLowerCase()}
                    </td>
                    <td>
                      <div className="flex flex-col gap-2">
                        {lines.map((line) => {
                          return (
                            <div key={line.id} className="flex flex-nowrap items-center gap-4">
                              <div className="flex flex-nowrap items-center gap-2">
                                <p className="p line-clamp-1 text-left text-sm">{line.name}</p>{' '}
                                <span
                                  className="material-symbols-outlined flex h-[12px] items-center"
                                  style={{ fontSize: '12px' }}
                                >
                                  close
                                </span>{' '}
                                <p className="p text-sm">{line.quantity}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </td>
                    <td>{parseMoneyFormat(totalLineItemPrice)}</td>
                    <td>
                      {nextBillingDateEpoch && (
                        <SubscriptionDetail subscription={sub} products={products} />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {(!subscriptions || subscriptions.length === 0) && (
          <div className="flex h-full items-center justify-center">
            <h2 className="h5">You don&apos;t have any subscriptions yet</h2>
          </div>
        )}
      </div>
    </section>
  )
}
const SubscriptionDetail = ({
  subscription,
  products
}: {
  subscription: Subscription
  products: Product[]
}) => {
  const {
    id,
    status,
    createdAt,
    lines,
    totalLineItemPrice,
    deliveryPolicy,
    shippingAddress,
    nextBillingDateEpoch
  } = subscription

  const billingData = new Date(nextBillingDateEpoch * 1000)

  return (
    <Drawer
      icon=""
      className="max-w-[550px]"
      renderButton={(handleOpen) => {
        return (
          <Button onClick={handleOpen} size="normal" color="secondary">
            Manage
          </Button>
        )
      }}
    >
      {(handleOpen, handleClose) => {
        return (
          <>
            <div className="p flex h-full max-h-[90svh] flex-col justify-between gap-10 overflow-y-auto px-8">
              <div className="flex flex-col gap-5">
                <section>
                  <h4 className="h4">Subscription Detail </h4>
                  <h4 className="h5">#{id}</h4>
                </section>

                <section>
                  <h4 className="h5 mb-2">Details</h4>

                  <table className="p text-secondary">
                    <thead>
                      <tr className="p text-sm capitalize">
                        <th className="p-2">Status</th>
                        <th className="p-2">Created On</th>
                        <th className="p-2">Next Order</th>
                        <th className="p-2">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="p divide-x border text-center text-sm">
                        <td className="p-2 capitalize">{status.toLocaleLowerCase()}</td>
                        <td className="p-2">
                          {parseLocaleDateTime(new Date(createdAt), {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit'
                          })}
                        </td>
                        <td className="p-2">
                          {parseLocaleDateTime(billingData, {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit'
                          })}
                        </td>

                        <td>{parseMoneyFormat(totalLineItemPrice)}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h4 className="h5 mb-2">Shipping Address</h4>

                  <div className="flex flex-col gap-2">
                    <div className="p-2">
                      <p>
                        {shippingAddress?.firstName} {shippingAddress?.lastName} {''}
                      </p>
                      <p>
                        {shippingAddress?.address1}, {shippingAddress?.address2},{' '}
                      </p>
                      <p>
                        {shippingAddress?.city}, {shippingAddress?.provinceCode}.{' '}
                        {shippingAddress?.zip}
                      </p>
                      <p>{shippingAddress?.countryCode}. </p>
                    </div>
                    <div className="flex items-center justify-center p-2 capitalize">
                      <Options
                        id={id}
                        currentDeliveryPolicy={deliveryPolicy}
                        nextBillingDateEpoch={nextBillingDateEpoch}
                        handleClose={handleClose}
                      />
                    </div>
                  </div>
                </section>
                <section>
                  <h4 className="h5 mb-2">Order Products</h4>
                  <div className=" flex flex-col gap-4">
                    {lines?.map((line) => {
                      const product = products.find((product: Product) =>
                        (product?.id as any).includes(line?.productShopifyId) ? true : false
                      )
                      return (
                        <div key={line.id} className="flex flex-nowrap items-center gap-4">
                          <Link
                            href={`/products/${product?.handle}`}
                            className="relative h-[150px] w-[150px] border border-gray"
                          >
                            <Image
                              src={product?.featuredImage?.url || line.image}
                              fill
                              className="object-cover"
                              alt=""
                            />
                          </Link>
                          <div className="flex flex-col flex-nowrap items-start gap-2">
                            <div className="flex flex-col gap-2">
                              <Link href={`/products/${product?.handle}`}>
                                <p className="h6 text-left">
                                  {line.variantTitle !== DEFAULT_OPTION
                                    ? line.variantTitle
                                    : line.productTitle}
                                </p>{' '}
                              </Link>
                              <p className="p text-sm">
                                <b>Quantity:</b> {line.quantity}
                              </p>
                              <p className="p text-sm">
                                <b>Price:</b> {parseMoneyFormat(line.discountedPrice)}
                                {line.discountedPrice < line.basePrice && (
                                  <span className="pl-2 line-through">
                                    {parseMoneyFormat(line.basePrice)}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div>
                              <RemoveLineButton
                                id={id}
                                lineId={line.id}
                                disabled={lines.length <= 1}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </section>
              </div>

              <div className="flex flex-nowrap gap-4">
                <CancelSubscriptionButton id={id} status={status} handleClose={handleClose} />
              </div>
            </div>
          </>
        )
      }}
    </Drawer>
  )
}

const CancelSubscriptionButton = ({
  id,
  status,
  handleClose
}: {
  id: number
  status: string
  handleClose: () => void
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Button
      size="large"
      className="w-full"
      disabled={loading || status === 'CANCELLED'}
      onClick={async () => {
        setLoading(true)
        const res = await cancelSubscription(id)
        if (res.success) {
          toast.success(res?.message)
          handleClose()
        } else {
          toast.error(res.message)
        }
        setLoading(false)
      }}
    >
      {loading ? <LoadingIndicator isLoading={loading} iconSize={24} /> : 'Cancel Subscription'}
    </Button>
  )
}

const RemoveLineButton = ({
  id,
  lineId,
  disabled
}: {
  id: number
  lineId: number
  disabled: boolean
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Button
      color="secondary"
      className=""
      disabled={disabled}
      onClick={async () => {
        setLoading(true)
        const res = await removeLineFromSubscription(id, lineId)
        if (res.success) {
          toast.success(res.message)
        } else {
          toast.error(res.message)
        }
        setLoading(false)
      }}
    >
      {loading ? (
        <LoadingIndicator isLoading={loading} iconSize={24} />
      ) : (
        'Remove from subscription'
      )}
    </Button>
  )
}

const Options = ({
  id,
  nextBillingDateEpoch,
  currentDeliveryPolicy,
  handleClose
}: {
  id: number
  nextBillingDateEpoch: number
  currentDeliveryPolicy: { intervalCount: number; interval: string }
  handleClose: () => void
}) => {
  const [selectedInterval, setSelectedInterval] = useState<Intervals>()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Intervals[]>([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const { data } = (await readAvailableSubscriptionFrequency(id)) || { data: undefined }

      data && setOptions(data)
      setLoading(false)
    }

    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeFrequency = async () => {
    setLoading(true)

    const res =
      selectedInterval &&
      (await updateSubscriptionFrequency({
        subscriptionId: id,
        billingPolicy: selectedInterval.billingPolicy,
        deliveryPolicy: selectedInterval.deliveryPolicy,
        nextBillingDateEpoch
      }))
    if (res?.success) {
      toast.success(res?.message)
      handleClose()
    } else {
      toast.error(res?.message || `Please select another subscription interval.`)
    }
    setLoading(false)
  }

  return (
    <>
      <Select
        placeholder={
          selectedInterval
            ? `Deliver every ${selectedInterval?.billingPolicy.intervalCount > 1 ? selectedInterval?.billingPolicy.intervalCount : ''} ${selectedInterval?.billingPolicy.interval.trim()}`
            : `Deliver every ${currentDeliveryPolicy?.intervalCount > 1 ? currentDeliveryPolicy?.intervalCount : ''} ${currentDeliveryPolicy?.interval.trim()}`
        }
        options={options?.map((option) => ({
          label: `Deliver every ${option?.deliveryPolicy?.intervalCount > 1 ? option?.deliveryPolicy?.intervalCount : ''} ${option?.deliveryPolicy?.interval}`,
          value: JSON.stringify(option)
        }))}
        onChange={(selection) => {
          selection.value && setSelectedInterval(JSON.parse(selection?.value))
        }}
      />
      <Button
        disabled={options.length <= 1 || !selectedInterval}
        size="minimal"
        className="py-[6px]"
        onClick={handleChangeFrequency}
      >
        {loading ? <LoadingIndicator isLoading={loading} iconSize={24} /> : 'Update'}
      </Button>
    </>
  )
}

export default ManageSubscriptions
