'use client'
import { useState } from 'react'
import { Button } from '../button'
import { CALL_TO_ORDER_TAG } from '@/constants'
import { isAvailableForSale } from '@/utils'
import { useGtm } from '@/context'
import { addItem } from '@/actions/cart/cart.actions'
import Link from 'next/link'
import { useProductCardContext } from '../cards/product-card/productCardContext'

const CardAddToCart = () => {
  const { product, currentVariant, isVariantLevel, complementaryProducts, general, layout } =
    useProductCardContext()
  const { planId } = general
  const { buttonStyle, buttonText, buttonType } = layout

  const [loading, setLoading] = useState(false)
  const { fireEvent } = useGtm()

  const { id, sku } = product || {}

  const variants = (product as Product)?.variants
  const variant = currentVariant || product.variants[0]

  const callToOrder = product?.tags?.includes(CALL_TO_ORDER_TAG)
  const multipleOptions = !isVariantLevel && variants && variants.length > 1
  const isCustomAvailableForSale = isAvailableForSale(product, undefined, variant)

  const buttonHandler = {
    addToCart: async () => {
      setLoading(true)
      await addItem(null, { gids: [variant?.id, ...(complementaryProducts || [])] })

      fireEvent('addToCartFromCategoryCard', {
        selectedVariant: { ...product },
        quantity: 1,
        bundle: null,
        from: 'product-card'
      })
      setLoading(false)
    },

    subscribe: async () => {
      setLoading(true)
      await addItem(null, {
        gids: [variant.id, ...(complementaryProducts || [])],
        selectedPlan: planId ? { id: planId } : undefined
      })
      setLoading(false)
    }
  }

  return (
    <div>
      {callToOrder && !multipleOptions && (
        <Button
          color={buttonStyle}
          size="normal"
          disabled={loading || !isCustomAvailableForSale}
          renderButton={({ size, color }) => {
            return (
              <a
                id={`${buttonType}-label-${sku}`}
                aria-labelledby={`${buttonType}-label-${sku} ${id}`}
                data-buttonaction={buttonType}
                tabIndex={0}
                href="tel:+17069220890"
                className={`block w-full cursor-pointer
                  ${size} 
                  ${loading ? 'pointer-events-none border-[3px] border-gray-secondary bg-gray-secondary text-white' : color}
                  `}
              >
                {multipleOptions ? 'See Options' : 'Call to order'}
              </a>
            )
          }}
        />
      )}
      {multipleOptions && (
        <>
          <Button
            color="primary"
            size="normal"
            disabled={loading}
            renderButton={({ size, color }) => {
              return (
                <Link
                  id={`${buttonType}-label-${sku}`}
                  aria-labelledby={`${buttonType}-label-${sku} ${id}`}
                  data-buttonaction={buttonType}
                  href={`/products/${product?.handle}`}
                  className={`block w-full cursor-pointer
                    ${size} 
                    ${loading ? 'pointer-events-none border-[3px] border-gray-secondary bg-gray-secondary text-white' : color}
                    `}
                  tabIndex={0}
                >
                  {!loading && <>{'See options'}</>}
                </Link>
              )
            }}
          />
        </>
      )}
      {buttonType !== 'cta' && !callToOrder && !multipleOptions && (
        <Button
          disabled={loading || !isCustomAvailableForSale}
          color={buttonStyle}
          size="normal"
          onClick={buttonHandler[buttonType]}
          aria-labelledby={`${buttonType}-label-${sku} ${id}`}
          id={`${buttonType}-label-${sku}`}
          data-buttonaction={buttonType}
          tabIndex={0}
          className="w-full"
        >
          {loading && (
            <span
              aria-hidden="true"
              className="material-symbols-outlined animate-spin"
              style={{ fontSize: '17px' }}
            >
              progress_activity
            </span>
          )}
          {!loading && <>{!isCustomAvailableForSale ? 'Out of stock' : buttonText}</>}
        </Button>
      )}
    </div>
  )
}

export default CardAddToCart
