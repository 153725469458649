import { TAGS } from '@/constants'
import { removeNullUndefined } from '@/utils'
import { shopifyFetch } from '..'
import { reshapeCart } from '../utils'

import {
  addToCartMutation,
  createCartMutation,
  editCartItemsMutation,
  removeFromCartMutation,
  editCartDiscountMutation
} from '@/lib/shopify/mutations'
import { getCartQuery } from '../queries'

// Cart
export async function createCart(input?: {
  buyerIdentity?: {
    customerAccessToken?: string
    email?: string
    phone?: string
    deliveryAddressPreferences?: {
      customerAddressId: string
    }
  }
}): Promise<Cart> {
  const res = await shopifyFetch<ShopifyCreateCartOperation>({
    query: createCartMutation,
    variables: input ? removeNullUndefined(input) : undefined,
    cache: 'no-store'
  })
  return reshapeCart(res.body.data.cartCreate.cart)
}
export async function addToCart(
  cartId: string,
  lines: {
    merchandiseId: string
    quantity: number
    sellingPlanId?: string
    attributes?: { key: string; value: string }[]
  }[]
): Promise<Cart> {
  const res = await shopifyFetch<ShopifyAddToCartOperation>({
    query: addToCartMutation,
    variables: {
      cartId,
      lines
    },
    tags: [TAGS.cart],
    cache: 'no-store'
  })

  return reshapeCart(res.body.data.cartLinesAdd.cart)
}
export async function removeFromCart(
  cartId: string,
  lineIds: string[]
): Promise<{ cart: Cart; errors: ShopifyCartUserError[] }> {
  const res = await shopifyFetch<ShopifyRemoveFromCartOperation>({
    query: removeFromCartMutation,
    variables: {
      cartId,
      lineIds
    },
    tags: [TAGS.cart],
    cache: 'no-store'
  })

  return {
    errors: res.body.data.cartLinesRemove.userErrors,
    cart: reshapeCart(res.body.data.cartLinesRemove.cart)
  }
}
export async function updateCart(
  cartId: string,
  lines: { id: string; merchandiseId: string; quantity: number }[]
): Promise<{ cart: Cart; errors: ShopifyCartUserError[] }> {
  const res = await shopifyFetch<ShopifyUpdateCartOperation>({
    query: editCartItemsMutation,
    variables: {
      cartId,
      lines
    },
    tags: [TAGS.cart],
    cache: 'no-store'
  })

  return {
    errors: res.body.data.cartLinesUpdate.userErrors,
    cart: reshapeCart(res.body.data.cartLinesUpdate.cart)
  }
}
export async function getCart(cartId: string): Promise<Cart | undefined> {
  const res = await shopifyFetch<ShopifyCartOperation>({
    query: getCartQuery,
    variables: { cartId },
    tags: [TAGS.cart],
    cache: 'no-store'
  })

  // Old carts becomes `null` when you checkout.
  if (!res.body.data.cart) {
    return undefined
  }
  return reshapeCart(res.body.data.cart)
}
export async function addDiscount(cartId: string, discountCodes: string[]): Promise<Cart> {
  const res = await shopifyFetch<ShopifyDiscountOperation>({
    query: editCartDiscountMutation,
    variables: { cartId, discountCodes },
    tags: [TAGS.cart],
    cache: 'no-store'
  })

  return reshapeCart(res.body.data.cartDiscountCodesUpdate.cart)
}
