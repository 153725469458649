import SectionMenu from './section-menu'
import SupportMenu from './support-menu'
import LegalMenu from './legal-menu'
import SocialMenu from './social-menu'
import Link from 'next/link'
import Image from 'next/image'
import Logo from '../../assets/images/logo-light.svg'
import GrabLifeText from '../../assets/images/tagline-stacked.svg'
import { COMPANY_NAME } from '@/constants'
import { transformMenu } from '@/utils'
import {
  AppleTag,
  FacebookIcon,
  GoogleTag,
  InstagramIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon
} from '../icons/social'

export const Footer = ({
  footer,
  supportMenu
}: {
  footer: Sanity.Footer
  supportMenu: SupportMenuItem[]
}) => {
  const legal = transformMenu(footer?.legalMenu)

  return (
    <>
      <div className="klaviyo-embed-form-wrapper">
        <div className="klaviyo-form-VGUntV module-spacing container"></div>
      </div>

      <footer className="bg-secondary py-16 text-white xl:pb-20 print:hidden">
        <div className="container flex flex-col justify-between gap-2 divide-y divide-tertiary lg:flex-row lg:divide-y-0">
          <section className="container lg:mx-0 lg:w-2/5 lg:max-w-none lg:px-0">
            {Logo && (
              <Link href="/" className="flex justify-center">
                <Image
                  src={Logo}
                  width={200}
                  height={75}
                  alt="Recteq Logo"
                  priority
                  data-test="footer-logo"
                  className="mb-6 w-full max-w-64"
                />
              </Link>
            )}
            <Image
              src={GrabLifeText}
              width={600}
              height={300}
              alt="Recteq Grab Life By the horns"
              priority
              data-test="footer-logo"
              className="mx-auto mb-6 w-full max-w-[500px]"
            />
            <div className="hidden lg:block">
              <LegalMenu title={COMPANY_NAME} legalMenuItems={legal} />
            </div>
          </section>

          <section className="container grid grid-cols-2 gap-6 p-0 py-7 lg:mx-0 lg:w-3/5 lg:max-w-none lg:grid-cols-3">
            {footer?.menus?.map((section) => {
              return (
                <SectionMenu
                  key={section._key}
                  title={section.title}
                  links={transformMenu(section?.links)}
                />
              )
            })}
            <SupportMenu supportMenuItems={supportMenu} />
            <SocialMenu
              socialLinks={
                footer?.socialMenu &&
                Object.entries(footer?.socialMenu)?.map(([name, value]) => {
                  const IconLibrary = {
                    facebook: <FacebookIcon />,
                    youtube: <YoutubeIcon />,
                    twitter: <TwitterIcon />,
                    instagram: <InstagramIcon />,
                    pinterest: <PinterestIcon />,
                    tiktok: <TiktokIcon />
                  }

                  return {
                    id: name,
                    icon: IconLibrary[name as keyof typeof IconLibrary],
                    link: value?.url,
                    blank: value?.blank,
                    parameters: value?.parameters
                  }
                })
              }
              platforms={
                footer?.platformsMenu &&
                Object.entries(footer?.platformsMenu).map(([name, value]) => {
                  const SVGLibrary = {
                    apple: <AppleTag />,
                    google: <GoogleTag />
                  }

                  return {
                    ...value,
                    id: value._key,
                    tag: name,
                    icon: SVGLibrary[name as keyof typeof SVGLibrary],
                    link: value.url
                  }
                })
              }
            />
          </section>
        </div>
        <div className="flex justify-center lg:hidden">
          <LegalMenu title={COMPANY_NAME} legalMenuItems={legal} />
        </div>
      </footer>
    </>
  )
}
