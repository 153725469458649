import { groq } from 'next-sanity'
import {
  internalLinkFragment,
  carouselFragment,
  gridFragment,
  heroFragment,
  iconListingFragment,
  sectionHeaderFragment,
  twoColumnBioFragment,
  twoColumnCalloutFragment,
  featuredHeroFragment,
  accordionFragment,
  assetFragment,
  imageMosaicFragment,
  ctaFragment,
  linkFragment,
  tagsFragment,
  advancedPortableTextFragment,
  fullBannerFragment,
  bioCardFragment,
  visualCTAFragment,
  heroCardFragment,
  countdownTimerFragment,
  videoContentFragment,
  videoListingFragment,
  globalComponentConfigFragment
} from './fragments'

const SiteContent = groq` 
  *[_type == 'site'][0]{
    ...,
    'raysClubLogo': raysClubLogo.asset->url,
    'logo': logo.asset->url,
    'favicon': favicon.asset->url,
    supportMenu,
    fourOhFour,
    globalMessages[]{..., textBody},
    popularAddOns [] {
      ...,
      manualSelections {
        'reference': *[_type == 'manualSelections' && _id == ^._ref][0] {
          ...,
          manualSelection[] {
            ...,
            ${[bioCardFragment, visualCTAFragment, heroCardFragment].join(',')},

            product {
              ...,
              'reference': *[_type == 'product' && _id == ^._ref][0] {
                ...
              }
            }
          } 
        },
      },
      querySelection {
        ...,
        'reference': *[_type == 'productQuery' && _id == ^._ref][0] {
          ...
        }
      },
    },
  }
`
const StaticContent = groq`
  *[_type == 'staticContent' && slug.current == $slug][0]{
    ...
  }
`
const NavigationContent = groq`
*[_type == 'navigation'][0]{
  ...,
  secondaryMenu,
  raysClubMenu {
    links[] {
      ...,
      ${internalLinkFragment},
    }
  },
  'menu':  menu.links[] {
    ...,
    ${internalLinkFragment},
    link[] {
      ...,
      ${internalLinkFragment},
      link[] {
        ...,
        ${internalLinkFragment},
      }     
    }
  }
,

'footer': footer {
  ...,
  platformsMenu {
    ...,
    apple {
      ...,
      ${internalLinkFragment},
      parameters
    },
    google {
      ...,
      ${internalLinkFragment},
      parameters
    }
  },
  legalMenu[] {
    ...,
    ${internalLinkFragment}
  },
  menus[] {
    ...,
    links[] {
      ...,
      ${internalLinkFragment}
    }
  },
  socialMenu {
    ...
  }
},

}
`
const HomePageContent = groq`*[_type == 'homepage'] | order(_updatedAt desc)[0]{
  ...,
  seo {
    ...,
    ogimage{
      ${assetFragment}
    }
  },
  slug,
  hero[0] {
    ...,
    body,
    title,
    'subtitle': {
      'text': subtitle,
      'style': subtitleColor
    },
    'media': {
      'desktop': desktopMedia {
        ${assetFragment}
      },
      'mobile': mobileMedia {
        ${assetFragment}
      }
    },
    ${ctaFragment}
  },
  ${advancedPortableTextFragment},
}`
const StoreLocatorContent = groq`*[_type == 'storeLocator'][0]{
  "storeLocator": {
    title,
    legends[] {
      icon {
        ${assetFragment}
      },
      title,
      description,
      tier
    }
  },
  ${globalComponentConfigFragment}
}`
const DynamicPageContent = groq`*[_type == 'page' && slug.current == $slug][0]{
  ...,
  seo {
    ...,
    ogimage{
      ${assetFragment}
    }
  },
  slug,
  allowedUserType,
  messageForRestrictedUsers,
  ${tagsFragment},
  mainHero[] {
    ${[heroFragment, featuredHeroFragment].join(',')},
  },
  ${advancedPortableTextFragment}
}`
const DynamicRecipeContent = (includeRaysClub?: boolean) => {
  return groq`*[_type == 'recipe' && ${!includeRaysClub ? '!("Rays-Club" in allowedUserType[]) &&' : ''} categoryRecipe._ref in *[_type=='recipeCategory' && slug.current == $slug ]._id ]{
    ...,
    ${tagsFragment},
    chefs [] {
      ...,
      avatar {
        ${assetFragment}
      }
    },
    recipeImage {
      ${assetFragment}
    },
}`
}
const CategoryPageContent = groq`*[
  _type == 'category' &&
  slug.current == $slug][0]{
  seo {
    ...,
    ogimage{
      ${assetFragment}
    }
  },
  slug,
  title,
  description,
  ...,
        
  productQueryNumResults,
  productSelections {
    ...,
    'selection': *[_type == 'productQuery' && _id == ^.query._ref][0] {
      ...
    }
  }
}`
const PDPcontent = groq`
*[
  _type == 'product' &&
  slug.current == $slug &&
  !(slug.current in ['index', '404']) ]
  [0] {
    ...,
    seo {
      ...,
      ogimage{
        ${assetFragment}
      }
    },
    featuredImage {
      ...,
      // 'image': asset->{_id,url,extension,assetId, altText}
    },
    specs[] {
      'image': ${assetFragment}
    },
    
    productDetails[] {
      ...,
      "items": sections.items
    },
    ${advancedPortableTextFragment},
}
`
const BlogPosts = groq`*[_type == 'blog.post' && metadata.slug.current == $slug][0]{
    ...,
    'body': select(_type == 'image' => asset->, body),
    'readTime': length(pt::text(body)) / 200,
    'headings': body[style in ['h2', 'h3']]{
        style,
        'text': pt::text(@)
    },
    categories[]->
}`
const AcademyPageContent = groq`
{
  "page": *[_type == 'academy' && slug.current == $slug][0]{
    ...,
    seo {
      ...,
      ogimage {
        ...,
        ${assetFragment}
      }
    },
    mainHero[] {
      ${featuredHeroFragment}
    },
    ${advancedPortableTextFragment}
  },
  "locations": *[
    _type == 'academy' &&
    location != "index"
    ]{
    ...,
    "label": location,
    "value": slug.current
  }
}
`
const RecipesContent = groq`*[_type == 'recipe' && slug.current == $slug][0]{
    seo {
      ...,
      ogimage{
        ${assetFragment}
      }
    },
    slug,
    legacyRecipeImage,
    allowedUserType,
    recipeName,
    recipeDescription,
    prepTimeMins,
    cookTimeMins,
    difficulty,
    guidedCook,
    directions,
    chefPairings,
    ${tagsFragment},
    chefs[] -> {
      ...,
      avatar {
        ${assetFragment}
      }
    },
    content[] {
      ...,
      items[]{
        ...,
        ${[videoContentFragment, videoListingFragment].join(',')}
      },
      ${[
        iconListingFragment,
        sectionHeaderFragment,
        carouselFragment,
        gridFragment,
        twoColumnCalloutFragment,
        twoColumnBioFragment,
        heroFragment,
        accordionFragment,
        imageMosaicFragment,
        videoContentFragment,
        videoListingFragment
      ].join(',')}
    },
    recipeImage {
      ${assetFragment}
    },
    'category':  *[_type == 'recipeCategory' && _id == ^.categoryRecipe._ref] { 
      name
    },
}`
const AllRaysClubRecipes = groq`*[_type == 'recipe' && "Rays-Club" in allowedUserType[]] {
  ...,
  recipeImage {
    ...,
    ${assetFragment}
  },
  'category':  *[_type == 'recipeCategory' && _id == ^.categoryRecipe._ref] { 
    name
  },
}`
const AllRecipes = groq`*[_type == 'recipe']{
    ingredients,
    toolsNeeded,
    seo {
      ...,
      ogimage{
        ${assetFragment}
      }
    },
    legacyRecipeImage,
    allowedUserType,
    recipeName,
    recipeDescription,
    chefPairings,
    prepTimeMins,
    cookTimeMins,
    difficulty,
    guidedCook,
    directions,
    ${tagsFragment},
    chefs[] -> {
      ...,
      avatar {
        ${assetFragment}
      }
    },
    content[] {
      ...,
      items[]{
        ...,
        ${[videoContentFragment, videoListingFragment].join(',')}
      },
      ${[
        iconListingFragment,
        sectionHeaderFragment,
        carouselFragment,
        gridFragment,
        twoColumnCalloutFragment,
        twoColumnBioFragment,
        heroFragment,
        accordionFragment,
        imageMosaicFragment,
        videoContentFragment,
        videoListingFragment
      ].join(',')}
    },
    recipeImage {
      ${assetFragment}
    },
    'category':  *[_type == 'recipeCategory' && _id == ^.categoryRecipe._ref] { 
      name
    },
}`
const ApiRecipes = groq`*[_type == 'recipe' && !("Rays-Club" in allowedUserType)]{
  ...,
  'recipe_id': _id,
  'content': null,
  'seo': null,
  'categoryRecipe': null,
  'createdAt': _createdAt,
  chefs[] -> {
    ...,
    avatar {
      ${assetFragment}
    }
  },
  recipeImage {
    ${assetFragment}
  },
  recommendedSeasonings [] {
    ...*[_type == 'seasoning' && _id == ^._ref][0] { 
      ...
    }
  },
  allowedUserType,
  'category':  *[_type == 'recipeCategory' && _id == ^.categoryRecipe._ref] { 
    name
  },
  ${tagsFragment},
}`
const RecipeIngredients = groq`*[_type == 'recipe' && slug.current == $slug][0]{
  ingredients[] {
    ...,
    link {
      ...,
      ${internalLinkFragment}
    }
  },
  toolsNeeded[] {
    ...,
    ${linkFragment}
  }
}`
const RecipesCategoryContent = groq`*[_type == 'recipeCategory' && slug.current == $slug][0]{
  ...,
  ${tagsFragment},
  hero[] {
    ...,
    media {${assetFragment}},
    cta {${ctaFragment}}
  }
}`
const ProductFacets = groq`
  *[_type == 'facets'][0]{
    productsFacets[] {
      ...,
      metafield {
        'data':  *[_type == 'metafield' && _id == ^._ref][0] { 
          ...
        }
      }
    }
  }
`
const ContentFacets = groq`
*[_type == 'facets'][0]{
  contentFacets[] {
    ...,
    metafield {
      'data':  *[_type == 'metafield' && _id == ^._ref][0] { 
        ...
      }
    }
  }
}
`
const CompareMetafields = groq`
  *[_type == 'site'][0]{
    comparePage[] {
      'metafield':  *[_type == 'metafield' && _id == ^._ref][0] { 
        ...
      }
    }
  }
`
const ArticleContent = groq`
  *[_type == 'article' && slug.current == $slug][0]{
    ...,
    ${advancedPortableTextFragment},
    media {
     ${assetFragment}
    },
    ${tagsFragment},
    relatedArticles[] {
      ...,
      'data':  *[_type == 'article' && _id == ^._ref][0] {
        _id,
        publishDate,
        title,
        slug {
          ...
        },
        seo {
          ...,
          description
        }
      }
    },
    'fallbackRelated':  *[_type == 'article'] { 
      _id,
      title,
      publishDate,
      slug {
        ...
      },
      seo {
        ...,
        description
      }
    }[0...11],
    modules[] {
      ...,
      ${[
        iconListingFragment,
        sectionHeaderFragment,
        carouselFragment,
        gridFragment,
        twoColumnCalloutFragment,
        twoColumnBioFragment,
        heroFragment,
        accordionFragment,
        imageMosaicFragment
      ].join(',')},
    }
  }
`
const Articles = groq`
  *[_type == 'article'] | order(_id) [0...4]{
    ...,
    ${advancedPortableTextFragment},
    media {
     ${assetFragment}
    },
    ${tagsFragment},
    relatedArticles[] {
      ...,
      'data':  *[_type == 'article' && _id == ^._ref][0] {
        _id,
        publishDate,
        title,
        slug {
          ...
        },
        seo {
          ...,
          description
        }
      }
    },
    'fallbackRelated':  *[_type == 'article'] { 
      _id,
      title,
      publishDate,
      slug {
        ...
      },
      seo {
        ...,
        description
      }
    }[0...11],
    modules[] {
      ...,
      ${[
        iconListingFragment,
        sectionHeaderFragment,
        carouselFragment,
        gridFragment,
        twoColumnCalloutFragment,
        twoColumnBioFragment,
        heroFragment,
        accordionFragment,
        imageMosaicFragment
      ].join(',')},
    }
  }
`
const PaginatedArticles = groq`
  *[_type == 'article' && _id > $lastId] | order(_id) [0...4] {
    ...,
    ${advancedPortableTextFragment},
    media {
     ${assetFragment}
    },
    ${tagsFragment},
    relatedArticles[] {
      ...,
      'data':  *[_type == 'article' && _id == ^._ref][0] {
        _id,
        publishDate,
        title,
        slug {
          ...
        },
        seo {
          ...,
          description
        }
      }
    },
    'fallbackRelated':  *[_type == 'article'] { 
      _id,
      title,
      publishDate,
      slug {
        ...
      },
      seo {
        ...,
        description
      }
    }[0...11],
    modules[] {
      ...,
      ${[
        iconListingFragment,
        sectionHeaderFragment,
        carouselFragment,
        gridFragment,
        twoColumnCalloutFragment,
        twoColumnBioFragment,
        heroFragment,
        accordionFragment,
        imageMosaicFragment
      ].join(',')},
    }
  }
`
const ArticlesCount = `count(*[_type in ['article']])`

const RaysClubContent = groq`
  *[_type == 'raysClub' && slug.current == $slug][0]{
    ...,
    seo {
      ...,
      ogimage{
        ${assetFragment}
      }
    },
    slug,
    allowedUserType,
    content[] {
      ...,
      manualSelections {
        'reference': *[_type == 'manualSelections' && _id == ^._ref][0] {
          ...,
          manualSelection[] {
            ...,
            ${[bioCardFragment, visualCTAFragment, heroCardFragment].join(',')},

            content {
              ...,
              'reference': *[_type == 'recipe' && _id == ^._ref][0] {
                ...
              }
            },
            product {
              ...,
              'reference': *[_type == 'product' && _id == ^._ref][0] {
                ...
              }
            }
          }

        },
      },
      querySelection {
        ...,
        'reference': *[_type == 'productQuery' && _id == ^._ref][0] {
          ...
        }
      },
      modulesSelector[] {
        ...,
          content {
            ...,
            'reference': *[_type == 'recipe' && _id == ^._ref][0] {
              ...
            }
          },
      },
      items[]{
        ...,
        ${[
          iconListingFragment,
          sectionHeaderFragment,
          carouselFragment,
          gridFragment,
          twoColumnCalloutFragment,
          twoColumnBioFragment,
          featuredHeroFragment,
          accordionFragment,
          fullBannerFragment,
          imageMosaicFragment,
          bioCardFragment,
          visualCTAFragment,
          heroCardFragment,
          countdownTimerFragment,
          assetFragment
        ].join(',')},
        'listing': listing[] {
          ...,
          videos[] {
            ...,
            ${videoContentFragment}
          }
        }
        
      },
      _type == "image" => {
        ...,                
        asset->
      },
    
    }
  }
`
const RaysClubRecipesContent = groq`
  *[_type == 'raysClub' && slug.current == $slug][0]{
    ...,
    seo {
      ...,
      ogimage{
        ${assetFragment}
      }
    },
    slug,
    allowedUserType,
    content[] {
      ...,
      manualSelections {
        'reference': *[_type == 'manualSelections' && _id == ^._ref][0] {
          ...,
          manualSelection[] {
            ...,
            ${[bioCardFragment, visualCTAFragment, heroCardFragment].join(',')},

            content {
              ...,
              'reference': *[_type == 'recipe' && _id == ^._ref][0] {
                ...
              }
            },
            product {
              ...,
              'reference': *[_type == 'product' && _id == ^._ref][0] {
                ...
              }
            }
          }

        },
      },
      querySelection {
        ...,
        'reference': *[_type == 'productQuery' && _id == ^._ref][0] {
          ...
        }
      },
      modulesSelector[] {
        ...,
          content {
            ...,
            'reference': *[_type == 'recipe' && _id == ^._ref][0] {
              ...
            }
          },
      },
      items[]{
        ...,
        ${[
          iconListingFragment,
          sectionHeaderFragment,
          carouselFragment,
          gridFragment,
          twoColumnCalloutFragment,
          twoColumnBioFragment,
          featuredHeroFragment,
          accordionFragment,
          fullBannerFragment,
          imageMosaicFragment,
          bioCardFragment,
          visualCTAFragment,
          heroCardFragment,
          countdownTimerFragment,
          assetFragment
        ].join(',')},
        'listing': listing[] {
          ...,
          videos[] {
            ...,
            ${videoContentFragment}
          }
        }
        
      },
      _type == "image" => {
        ...,                
        asset->
      },
    
    }
  }
`
const EtchATeqContent = groq`
  *[_type == 'etchAteq'][0]{
    ...,
    etchOptions {
        options[] {
          ...,
          image {
            ${assetFragment}
          }
        }
    },
    defaultImage {
      ${assetFragment}
    }
  }
`
const EtchATeqProducts = groq`
  *[_type == 'product' && canPersonalize == true && (!hidden || hidden == null)]{
    _id,
    title,
    hidden,
    gid,
    etchATeqImage {
      ${assetFragment}
    },
    personalizationOptions,
    personalizationAreaLabel,
    personalizationArea {
      personalizationImages[] {
        'image': ${assetFragment}
      }
    },
    personalizationProductFee {
      'product':  *[_type == 'product' && _id == ^._ref][0] {
        _id,
        gid,
        title,
        price
      }
    }
  }
`

export {
  StaticContent,
  HomePageContent,
  PDPcontent,
  DynamicPageContent,
  BlogPosts,
  NavigationContent,
  SiteContent,
  CategoryPageContent,
  RecipesContent,
  RecipeIngredients,
  RecipesCategoryContent,
  DynamicRecipeContent,
  StoreLocatorContent,
  ProductFacets,
  CompareMetafields,
  ArticleContent,
  AcademyPageContent,
  Articles,
  ArticlesCount,
  PaginatedArticles,
  ContentFacets,
  RaysClubContent,
  RaysClubRecipesContent,
  EtchATeqContent,
  EtchATeqProducts,
  AllRecipes,
  AllRaysClubRecipes,
  ApiRecipes
}
