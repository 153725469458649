'use client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Select, LoadingIndicator, Button, ProductCard } from '@/components'
import { useInifiniteScroll, useShopifySearch } from '@/hooks'
import { usePathname, useRouter } from 'next/navigation'
import { useGtm } from '@/context'

interface Props {
  productQuery?: DataQuery[]
  productQueryCondition?: string
  title?: string
  description?: string
  productOption?: string
  manualSelection?: ManualProductSelection[]
  resultsPerPage: number
  products: Product[]
  sortKey?: string
  reverse?: boolean
}

export const CategorySection = ({
  productQuery,
  productQueryCondition,

  title,
  description,
  productOption,
  manualSelection,
  // resultsPerPage,
  sortKey,
  reverse,
  products
}: Props) => {
  const router = useRouter()
  const pathName = usePathname()
  const [lastCardId, setLastCardId] = useState<string[]>([])
  const [hasFiredViewCategoryList, setHasFiredViewCategoryList] = useState(false)
  const { data, getNextPage } = useShopifySearch(
    {
      productQuery,
      productQueryCondition,
      productOption,
      manualSelection,
      resultsPerPage: 250,
      sortKey,
      reverse
    },
    false,
    products
  )

  const shouldDisplayTotalCookingSpace = useMemo(() => {
    const present = products.some((product) => {
      return product.total_cooking_space && product.total_cooking_space.value
    })
    return present
  }, [products])
  const shouldDisplayMinTemp = useMemo(() => {
    return products.some((product) => product.min_temp && product.min_temp.value)
  }, [products])
  const shouldDisplayMaxTemp = useMemo(() => {
    return products.some((product) => product.max_temp && product.max_temp.value)
  }, [products])
  const shouldDisplayHopperCapacity = useMemo(() => {
    return products.some((product) => product.hopper_capacity && product.hopper_capacity.value)
  }, [products])

  const paginationCallback = useCallback(() => getNextPage(), [getNextPage])

  const { observerTarget } = useInifiniteScroll(() => {
    data.hasNextPage && !data.loading && paginationCallback()
  }, 500)
  const { fireEvent } = useGtm()

  useEffect(() => {
    if (data.products && data.products.length) {
      setLastCardId((prev) => [...prev, data.products[data.products.length - 1].variants[0].id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey])

  useEffect(() => {
    if (lastCardId.length > 1) {
      const lastViewdCard = document.getElementById(
        `details-label-${lastCardId[lastCardId.length - 2]}`
      )

      lastViewdCard?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      lastViewdCard?.focus()
    }
  }, [lastCardId])

  useEffect(() => {
    if (!hasFiredViewCategoryList && data.products.length > 0) {
      fireEvent('viewItemListCategory', { impressions: data.products })
      setHasFiredViewCategoryList(true)
    }
    if (typeof window !== 'undefined' && window?.affirm && window?.affirm?.ui) {
      try {
        window?.affirm?.ui?.refresh()
      } catch (error) {
        console.error(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.products])

  return (
    <>
      <section className="module-spacing relative" data-test="category-listing-page">
        <div className="container">
          <div className="mb-4 mt-2 flex flex-col-reverse flex-wrap py-3 md:mt-0 md:flex-row md:flex-nowrap md:items-center md:justify-between md:pt-0">
            <div className="p mt-4 flex w-full flex-wrap items-center gap-2 text-xs text-secondary md:mt-0 md:w-auto md:gap-6 md:text-sm">
              {shouldDisplayTotalCookingSpace && (
                <div className="flex items-center gap-2">
                  <span
                    aria-hidden="true"
                    className="material-symbols-outlined icon size-3 lg:size-5"
                  >
                    square_foot
                  </span>
                  : Interior Cooking Space
                </div>
              )}
              {shouldDisplayMinTemp && shouldDisplayMaxTemp && (
                <div className="flex items-center gap-2">
                  <span
                    aria-hidden="true"
                    className="material-symbols-outlined icon size-3 lg:size-5"
                  >
                    thermostat
                  </span>
                  : Temp Range
                </div>
              )}
              {shouldDisplayHopperCapacity && (
                <div className="flex items-center gap-2">
                  <span
                    aria-hidden="true"
                    className="material-symbols-outlined icon size-3 lg:size-5"
                  >
                    orders
                  </span>{' '}
                  : Hopper Capacity
                </div>
              )}
            </div>
            <div className="-z-1 relative w-full lg:w-[300px]">
              <Select
                placeholder="Sort By"
                error={false}
                onChange={(props) => {
                  !props.value && router.push(`${pathName}`)
                  props.value && router.push(`${pathName}?sortKey=${props.value}`)
                }}
                options={[
                  {
                    label: 'Default',
                    value: ''
                  },
                  {
                    label: 'PRICE: LOWEST TO HIGHEST',
                    value: 'PRICE'
                  },
                  {
                    label: 'PRICE: HIGHEST TO LOWEST',
                    value: 'PRICE&reverse=true'
                  },
                  {
                    label: 'NAME: A-Z',
                    value: 'TITLE'
                  },
                  {
                    label: 'NAME: Z-A',
                    value: 'TITLE&reverse=true'
                  }
                ]}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:mt-8 lg:grid-cols-3 lg:pt-10 xl:grid-cols-4">
            <div className="col-span-1 mb-8 self-center md:mb-4">
              <h2 data-test={`product-title`} className="h1 mb-4 text-center text-secondary">
                {title}
              </h2>
              <p className="p text-center text-secondary">{description}</p>
            </div>
            {data &&
              data.products.map((product, idx) => {
                return (
                  <>
                    <div>
                      {idx < data.products.length - 4 && <div ref={observerTarget} />}
                      <ProductCard key={product.id} as="categoryCard" product={product} />
                    </div>
                  </>
                )
              })}
            {data && data.products.length === 0 && (
              <div className="col-span-3 flex items-center justify-center">No results found.</div>
            )}
          </div>
          {data && data.products.length > 0 && (
            <div className="flex justify-center">
              <Button
                disabled={!data.hasNextPage}
                color="primary-outline"
                size="large"
                onClick={() => {
                  setLastCardId((prev) => [
                    ...prev,
                    data.products[data.products.length - 1].variants[0].id
                  ])

                  getNextPage()
                }}
                className={`mx-auto`}
              >
                {data.loading ? (
                  <LoadingIndicator iconSize={18} isLoading={data.loading} />
                ) : (
                  'Load more'
                )}
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
