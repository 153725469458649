import ModuleHandler from '../module-handler'

enum Alignment {
  start = 'justfiy-start',
  center = 'justify-center',
  end = ' justify-end',
  evenly = ' justify-evenly',
  between = ' justify-between'
}

interface GridProps {
  items: any
  alignment?: keyof typeof Alignment
  componentGlobalConfig?: Sanity.ComponentConfig
}

export const Grid = ({ items = [], alignment = 'center', componentGlobalConfig }: GridProps) => {
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <section
        data-test="grid-component"
        className={`module-spacing container flex flex-row flex-wrap gap-y-8 md:gap-6 ${Alignment[alignment]}`}
      >
        {items.map((item: any, index: number) => {
          let gridClass = 'w-full md:w-[22rem]'
          switch (item?.props?._type) {
            case 'heroCard':
              gridClass = 'w-full lg:w-[calc(50%-1.5rem)]'
              break
          }
          return (
            <div key={index} className={`w-full ${gridClass}`}>
              {item}
            </div>
          )
        })}
      </section>
    </ModuleHandler>
  )
}
