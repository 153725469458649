'use client'
import Image from 'next/image'
import { useSearchParams, useRouter } from 'next/navigation'
import {
  AddToCart,
  ProductPrice,
  ProductTitle,
  ProductBundles,
  AddToCartModal,
  ProductSwatches,
  ProductPolicies,
  Badge,
  StarRating,
  Button,
  KlaviyoOosForm
} from '@/components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useProductPageContext, useGtm } from '@/context'
import { getLowestVariantPriceInCents, isAvailableForSale } from '@/utils'
import { BUNDLE_PRODUCT_TYPE } from '@/constants'
import { getVariantById } from '@/app/(store)/lib'

interface Props {
  etchAteq: boolean
  darkMode?: boolean
  bottomline: any
  customer?: Customer
}

const Details = ({ etchAteq, darkMode, customer }: Props) => {
  const {
    bundle,
    isPending,
    parsedBundleIds,
    currentVariant,
    setSelectedBundleId,
    selectedBundleId,
    setQuery,
    product,
    selectedPlan
  } = useProductPageContext()
  const containerRef = useRef<HTMLElement | null>(null)
  const { fireEvent } = useGtm()
  // Product info
  const { model_number, options, variants } = product || {}
  const router = useRouter()
  const searchParams = useSearchParams()
  // Product info
  const isDisconted =
    parseFloat(currentVariant?.compareAtPrice?.amount as string) <
    parseFloat(currentVariant?.price?.amount as string)
  const isNew = product.tags.includes('pfs:label-new')
  const lowStock = product.tags.includes('low-stock')
  const isDiscontinued = product.tags.includes('Discontinued')
  const [expandedDetail, setExpanedDetail] = useState<boolean>(false)
  const [amountInCents, setAmountInCents] = useState('0')

  // Alternative Etch a teq data
  const [loadingAlternativeData, setLoadingAlternativeData] = useState(true)
  const [alternativeVariant, setAlternativeVariant] = useState<ProductVariant>()

  const hasAlternativeVariant = useMemo(
    () => currentVariant?.metafields?.find((metafield) => metafield?.key === 'swap_product'),
    [currentVariant]
  )

  const isAlternativeProductAvailable =
    alternativeVariant &&
    isAvailableForSale(alternativeVariant?.product, undefined, alternativeVariant)

  const handleCustomizationClick = useCallback(async () => {
    let variant

    if (alternativeVariant) {
      variant = btoa(alternativeVariant.id)
    } else {
      const variantId = searchParams.get('variantId')
      variant = variantId
    }

    router.push(`/etch-a-teq?${variant ? 'variant=' + variant : 'product=' + btoa(product.id)}`, {
      scroll: false
    })
  }, [alternativeVariant, product, router, searchParams])

  useEffect(() => {
    if (hasAlternativeVariant) {
      const getData = async () => {
        const { variant } = (await getVariantById({ id: hasAlternativeVariant?.value })) || {}

        variant && setAlternativeVariant(variant)
        setLoadingAlternativeData(false)
      }

      getData()
    } else {
      setLoadingAlternativeData(false)
    }
  }, [hasAlternativeVariant])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.affirm && window.affirm.ui) {
      try {
        window.affirm.ui.refresh()
      } catch (error) {
        console.error(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountInCents])

  useEffect(() => {
    let amount = getLowestVariantPriceInCents(product)
    setAmountInCents(amount)
    if (typeof window !== 'undefined' && window.affirm && window.affirm.ui) {
      window.affirm.ui.ready(function () {
        try {
          window.affirm.ui.refresh()
        } catch (error) {
          console.error(error)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    if (product) {
      fireEvent('viewItem', [product, currentVariant])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className="col-span-2 md:col-span-1 md:mt-12 lg:mt-0 lg:px-4 xl:col-span-4 xl:pl-[80px] dark:text-white"
      data-test="product-details"
    >
      <article className="col-span-1" ref={containerRef}>
        <section className="relative text-center lg:pr-10 lg:text-left">
          <div className="mb-2 flex w-full justify-center gap-2 lg:mb-0 lg:justify-start">
            {!bundle && model_number && (
              <span className="_h -mb-2 block text-2xl font-normal uppercase text-[#757575]">
                {model_number.value}
              </span>
            )}
            {isDisconted && (
              <Badge className="px-2" color="primary">
                Sale
              </Badge>
            )}
            {isNew && (
              <Badge className="px-2" color="secondary">
                New
              </Badge>
            )}
            {lowStock && (
              <Badge className="px-2" color="secondary">
                Low Stock
              </Badge>
            )}
          </div>
          <ProductTitle bundle={bundle} product={product} />
          {variants?.length > 1 && (
            <span className="p hidden xl:block">{currentVariant?.title}</span>
          )}
          <div
            className="my-4 flex justify-center lg:absolute lg:-top-4 lg:right-0 lg:my-0 lg:block"
            data-test="reviews-container"
          >
            <StarRating product={product} />
          </div>
          <div className="flex justify-center lg:block">
            <ProductPrice currentVariant={currentVariant} bundle={bundle} size="h3" />
          </div>
          {parseInt(amountInCents) >= 10000 && (
            <p
              className={`affirm-as-low-as mt-2 text-sm text-black`}
              data-page-type="product"
              data-amount={amountInCents}
              {...(darkMode ? {} : {})}
            ></p>
          )}
        </section>

        {product?.description && (
          <div className="relative">
            <p
              data-test={`product-description-${bundle?.id || currentVariant?.id || product?.id}`}
              className={`_p my-6 pr-4 ${expandedDetail || product?.description.length < 400 ? '' : 'line-clamp-3'}`}
            >
              {product.description}
            </p>
            <button
              className={`absolute bottom-1 right-0 z-10 h-5 bg-neutral-100 text-neutral-600 dark:bg-secondary dark:text-white ${product?.description.length >= 400 ? 'block' : 'hidden'} `}
              aria-label={`toggle description`}
              onClick={() => setExpanedDetail(!expandedDetail)}
            >
              <span
                className={`material-symbols-outlined icon-size-normal transition-all duration-300 ease-in-out ${expandedDetail ? 'rotate-180' : ''}`}
              >
                keyboard_arrow_down
              </span>
            </button>
          </div>
        )}

        {parsedBundleIds && parsedBundleIds?.length > 0 && currentVariant && (
          <ProductBundles
            parent={product}
            productIds={[...parsedBundleIds]}
            onSelection={(res) => {
              if (res?.productType === BUNDLE_PRODUCT_TYPE) {
                setSelectedBundleId(res.id)
              } else {
                setSelectedBundleId(res.variants[0].id)
              }
            }}
            currentVariant={currentVariant}
            selectedBundleId={selectedBundleId}
          />
        )}

        <ProductSwatches
          currentVariant={currentVariant}
          variants={variants}
          options={options}
          setQuery={setQuery}
        />
        {hasAlternativeVariant && loadingAlternativeData && (
          <div className="mb-4 h-[94px] w-full animate-pulse bg-gray-300"></div>
        )}
        {((isAlternativeProductAvailable && !loadingAlternativeData) ||
          (!hasAlternativeVariant && etchAteq)) && (
          <div className="mb-4 flex flex-wrap items-center justify-between border-b border-t border-gray py-4 lg:flex-nowrap lg:gap-2 lg:px-4">
            <div className="relative flex h-[60px] w-[160px] justify-center lg:block">
              <Image src="/images/etchateq-logo--dark.png" alt="" fill className="object-contain" />
            </div>
            <div className="w-2/3 px-1 text-center lg:w-7/12 lg:px-0">
              <h5 className="h6">personalize your {product.title} with etch-a-teq!</h5>
            </div>
            <div className="mt-4 w-full lg:mt-0 lg:w-2/12">
              <Button
                type="submit"
                className="mb-0 block w-full"
                color="primary-outline"
                size="minimal"
                aria-labelledby={`add-to-cart-label-customize`}
                id={`add-to-cart-label-customize`}
                data-test="product-add-customize"
                onClick={() => handleCustomizationClick()}
              >
                customize
              </Button>
            </div>
          </div>
        )}
        <AddToCart
          product={product}
          selectedVariant={currentVariant}
          bundle={bundle || null}
          isPending={isPending}
          isDiscontinued={isDiscontinued}
          selectedPlan={selectedPlan}
        />
        <KlaviyoOosForm
          product={product}
          selectedVariant={currentVariant}
          bundle={bundle || null}
          isPending={isPending}
          isDiscontinued={isDiscontinued}
          customer={customer}
        />
        <ProductPolicies />
      </article>
      <AddToCartModal
        variants={variants}
        currentVariant={currentVariant}
        bundle={bundle}
        product={product}
        containerRef={containerRef.current}
        isPending={isPending}
        isDiscontinued={isDiscontinued}
      />
    </div>
  )
}
export default Details
