'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useHeaderContext } from '../../context'
import { useEffect, useState } from 'react'

interface Props {
  homeElement?: React.ReactNode
  listClasses?: string
  theme?: string
  className?: string
}

const Breadcrumbs = ({
  listClasses = 'hover:text-primary text-gray-tertiary duration-200',
  theme = 'light',
  className
}: Props) => {
  const { isTransparent } = useHeaderContext()
  const [_, setDelayedBg] = useState(isTransparent)
  const paths = usePathname()
  const pathNames = paths?.split('/').filter((path) => path)

  const dark =
    'text-white hover:text-gray-300 peer-[.isTransparent]:group-[.main]:text-white dark:text-white'
  const light =
    'text-secondary hover:text-primary text-secondary peer-[.isTransparent]:group-[.main]:text-white dark:text-white'

  useEffect(() => {
    if (!isTransparent) {
      setTimeout(() => {
        setDelayedBg(isTransparent)
      }, 300)
    } else {
      setDelayedBg(isTransparent)
    }
  }, [isTransparent])

  try {
    return (
      <div className="peer-[.isTransparent]:group-[.main]:bg-black" data-component="breadcrumbs">
        <nav className={`container ${className ? className : 'py-6'}`} data-test="pdp-breadcrumbs">
          <ul className="relative z-10 flex flex-nowrap items-center gap-2 overflow-hidden text-ellipsis print:hidden ">
            {pathNames.length > 1 && (
              <li className={`p-alt text-lg uppercase ${listClasses}`}>
                <Link data-test="breadcrumbs-home-link" href={`/${pathNames[0]}`}>
                  {pathNames[0] || 'home'}
                </Link>
                <span
                  aria-hidden="true"
                  className="material-symbols-outlined ml-2 text-lg text-gray-secondary"
                >
                  arrow_forward_ios
                </span>
              </li>
            )}

            {pathNames.map((link, index) => {
              if (index === 0) return <></>
              let href = `/${pathNames.slice(0, index + 1).join('/')}`

              return (
                <li
                  className={`p-alt flex items-center text-lg uppercase ${index === pathNames.length - 1 ? (theme == 'dark' ? dark : light) : 'text-[#757575]'}`}
                  key={`${link}-${index}`}
                >
                  <Link
                    data-test="breadcrumbs-link flex items-center"
                    href={href}
                    key={`link:${link}-${index}`}
                  >
                    <span className="line-clamp-1">{link.replaceAll('-', ' ')}</span>
                    {pathNames.length === 1 ? (
                      <>
                        <span
                          aria-hidden="true"
                          className="material-symbols-outlined mx-2 text-lg leading-3 text-gray-secondary"
                          key={`arrow:${link}-${index}`}
                        >
                          arrow_forward_ios
                        </span>
                        <span>All</span>
                      </>
                    ) : (
                      ''
                    )}
                  </Link>
                  {pathNames.length !== index + 1 && (
                    <span
                      aria-hidden="true"
                      className="material-symbols-outlined ml-2 text-lg leading-3 text-gray-secondary"
                      key={`arrow:${link}-${index}`}
                    >
                      arrow_forward_ios
                    </span>
                  )}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    )
  } catch (error) {}
}

export default Breadcrumbs
