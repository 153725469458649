'use client'
import { useEffect, useState } from 'react'
import { BundleOptions } from '@/components'
import { getProductsById } from '@/lib/shopify'
interface Props {
  productIds?: string[]
  currentVariant: ProductVariant
  parent: Product
  selectedBundleId: string | null
  onSelection: (selectedProduct: Product, selectedVariant?: ProductVariant) => void
}

const ProductBundles: React.FC<Props> = ({
  parent,
  currentVariant,
  productIds,
  onSelection,
  selectedBundleId
}) => {
  const [options, setOptions] = useState<Product[]>()

  useEffect(() => {
    if (productIds && productIds?.length > 0) {
      const getBundles = async () => {
        const { products } = await getProductsById({ ids: productIds })

        setOptions(products)
      }

      getBundles()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div data-test={`product-bundle-container`} className="col-span-1">
        <section className={`border-t border-gray py-4 dark:border-secondary`}>
          <h3 className="h4 uppercase">Bundle and save</h3>
        </section>
        <section className="w-full">
          {!!options && (
            <BundleOptions
              parent={parent}
              selectedBundleId={selectedBundleId}
              options={[parent, ...options]}
              currentVariant={currentVariant}
              onSelection={onSelection}
            />
          )}
        </section>
      </div>
    </>
  )
}

export default ProductBundles
