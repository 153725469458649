'use client'
import { useFormStatus } from 'react-dom'
import { Button } from '@/components'

const SubmitButton = ({
  children,
  testAttr,
  onClick
}: {
  children: React.ReactNode
  testAttr?: string
  onClick?: () => void
}) => {
  const { pending } = useFormStatus()
  return (
    <Button
      type="submit"
      color="primary"
      size="large"
      data-test={testAttr}
      disabled={pending}
      onClick={onClick}
    >
      {pending ? (
        <span
          aria-hidden="true"
          style={{ fontSize: '20px' }}
          className="material-symbols-outlined animate-spin"
        >
          progress_activity
        </span>
      ) : (
        <>{children}</>
      )}
    </Button>
  )
}

export default SubmitButton
