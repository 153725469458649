'use client'
import { useEffect } from 'react'
import { useWindowPosition, useInViewport } from '@/hooks'
import { Transition } from '@headlessui/react'
import { AddToCart, ProductPrice, ProductTitle } from '@/components'

interface Props {
  variants: ProductVariant[]
  currentVariant: ProductVariant
  bundle?: Product | null
  product: Product
  containerRef?: HTMLElement | null
  isPending: boolean
  isDiscontinued?: boolean
}

const AddToCartModal = ({
  variants,
  currentVariant,
  bundle,
  product,
  containerRef,
  isPending,
  isDiscontinued
}: Props) => {
  const { position } = useWindowPosition()

  const { isInViewport, ref } = useInViewport({
    root: null,
    rootMargin: '-100px',
    threshold: 0.1
  })

  useEffect(() => {
    if (containerRef && position > containerRef?.offsetTop) {
      ref(containerRef)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position])

  return (
    <>
      {containerRef && (
        <Transition
          show={!isInViewport && position > 100 && position > containerRef?.offsetTop}
          as={'div'}
          appear
          enter="transition-all ease-in duration-300"
          enterFrom="translate-y-full max-h-0"
          enterTo="translate-y-0 opacity-100 h-auto"
          leave="transition-all ease-out duration-300"
          leaveFrom=""
          leaveTo="translate-y-full"
          className="fixed bottom-0 left-0 z-[15] w-full bg-secondary"
        >
          <div className="container mx-auto p-2 lg:grid lg:grid-cols-5 lg:p-4">
            <div className="flex items-center justify-between pt-2 lg:col-span-4 lg:mr-8">
              <div>
                {variants?.length > 1 && (
                  <span className="block max-w-[95svw] truncate text-sm text-gray">
                    {currentVariant?.title}
                  </span>
                )}
                <ProductTitle
                  size="_p text-lg text-white lg:h4"
                  bundle={bundle}
                  product={product}
                />
              </div>
              <ProductPrice
                currentVariant={currentVariant}
                bundle={bundle}
                size="h6 text-white lg:text-3xl"
              />
            </div>
            <div className="col-span-1">
              <AddToCart
                product={product}
                selectedVariant={currentVariant}
                bundle={bundle}
                isPending={isPending}
                isDiscontinued={isDiscontinued}
              />
            </div>
          </div>
        </Transition>
      )}
    </>
  )
}

export default AddToCartModal
