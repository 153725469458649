import { shopifyFetch } from '..'

import { getVariantQuery } from '@/lib/shopify/queries'

// Variants
export async function getVariantById({ id }: { id: string }): Promise<{ variant: ProductVariant }> {
  const res = await shopifyFetch<ShopifyVariantByIdOperation>({
    query: getVariantQuery,
    cache: 'no-cache',
    variables: { id }
  })

  return {
    variant: res.body.data.node
  }
}

export async function getVariantsByIds(ids: string[]): Promise<ProductVariant[]> {
  const results = await Promise.all(
    ids.map(async (id) => {
      return await getVariantById({ id })
    })
  )
  const variants = results.map((result) => result.variant)
  return variants
}
