import { Carousel, ModuleHandler } from '@/components'
interface Testimonial {
  review: string
  author: string
  isActive?: boolean
}
interface Props {
  testimonials: Testimonial[]
  componentGlobalConfig?: Sanity.ComponentConfig
}

export const Testimonials = ({ testimonials, componentGlobalConfig }: Props) => {
  return (
    <ModuleHandler config={componentGlobalConfig}>
      <div className="mt-6 overflow-hidden lg:mt-0">
        <Carousel
          items={testimonials?.map((testimonial, idx) => (
            <Testimonial key={idx} review={testimonial.review} author={testimonial.author} />
          ))}
          title="Testimonials"
          testimonials={true}
          showNavigation={true}
          showPagination={true}
          paginationStyles="light"
          delay={false}
          slidesPerView={1}
          mobileSlidesPerView={1}
          loop={true}
          wrapperStyles="max-w-full"
        />
      </div>
    </ModuleHandler>
  )
}

const Testimonial = ({ review, author, isActive = false }: Testimonial) => {
  return (
    <div className="mx-4 flex h-full flex-col items-center justify-center">
      <div
        className={`${isActive ? 'sm:opacity-100' : 'sm:opacity-0'} opacity-100 transition-opacity duration-500`}
      >
        {fiveStarSVG}
      </div>
      <p
        className={`text-center font-oswald text-lg font-black uppercase leading-normal lg:text-[1.5rem] ${isActive ? 'sm:text-secondary' : 'sm:text-[#d9d9d9] sm:opacity-60'} text-secondary transition-colors duration-500`}
      >
        {review}
      </p>
      <span
        className={`mt-2 font-oswald text-2xl font-bold uppercase text-primary lg:text-[2rem] ${isActive ? 'sm:opacity-100' : 'sm:opacity-0'} opacity-100 transition-opacity duration-500`}
      >
        {author}
      </span>
    </div>
  )
}

const fiveStarSVG = (
  <div className="mb-4 flex justify-center">
    {[...Array(5)].map((_, idx) => (
      <svg
        key={idx}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-1 text-[#EA0029]"
      >
        <path d="M9 0L11.751 5.96793L18 6.87558L13.452 11.4714L14.562 18L9 14.8717L3.43725 18L4.548 11.4714L0 6.87558L6.249 5.96793L9 0Z"></path>
      </svg>
    ))}
  </div>
)
