'use client'
import React, { useRef, useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import { LoadingIndicator, ModuleHandler } from '@/components'
import 'mapbox-gl/dist/mapbox-gl.css'

interface SimpleMapProps {
  title: string
  address: string
  componentGlobalConfig?: Sanity.ComponentConfig
}

export const SimpleMap = ({ title, address, componentGlobalConfig }: SimpleMapProps) => {
  const accessToken = process.env.NEXT_PUBLIC_MAPBOX_KEY
  if (!accessToken) {
    throw new Error('Mapbox access token is not defined')
  }
  mapboxgl.accessToken = accessToken
  const geocodingClient = mbxGeocoding({ accessToken })

  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const zoom = 15
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!address || address.trim() === '') {
      setError('No address provided')
      setLoading(false)
      return
    }

    geocodingClient
      .forwardGeocode({
        query: address,
        limit: 1
      })
      .send()
      .then((response) => {
        const match = response.body.features[0]
        if (match && match.geometry) {
          const [longitude, latitude] = match.geometry.coordinates
          setLoading(false)

          const placeText = match.text || ''
          const address = match.address || ''
          const city = match.context?.find((c) => c.id.includes('place'))?.text || ''
          const state = match.context?.find((c) => c.id.includes('region'))?.text || ''
          const country = match.context?.find((c) => c.id.includes('country'))?.text || ''

          const popupContent = `
          <div class="popupContent">
            <h3>recteq</h3>
            <p>${address} ${placeText}</p>
            <p>${city}, ${state}, ${country}</p>
          </div>
          `

          if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: 'mapbox://styles/mapbox/streets-v11',
              center: [longitude, latitude],
              zoom: zoom
            })
            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent)
            const marker = new mapboxgl.Marker()
              .setLngLat([longitude, latitude])
              .setPopup(popup)
              .addTo(map.current)

            marker.togglePopup()
          } else if (map.current) {
            map.current.flyTo({ center: [longitude, latitude], zoom: zoom })
          }
        } else {
          setError('Address not found')
          setLoading(false)
        }
      })
      .catch((err) => {
        console.error(err)
        setError('Error fetching geocoding data')
        setLoading(false)
      })
    return () => {
      if (map.current) {
        map.current.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <div className="flex flex-col items-center">
        <h3 className="p-alt-large font-bold">{title}</h3>
        {error && <p>{error}</p>}
        <div className="relative w-full max-w-md">
          <div
            ref={mapContainer}
            className="relative h-64 w-full border border-gray-300 sm:h-80 md:h-96"
          />
          {loading && (
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-75">
              <LoadingIndicator isLoading={loading} />
            </div>
          )}
        </div>
      </div>
    </ModuleHandler>
  )
}
