'use client'
import { useProductPrice } from '@/hooks'
import { parseMoneyFormat } from '@/utils'

interface Props {
  product?: Product
  showRange?: boolean
  currentVariant: ProductVariant
  bundle?: Product | null
  size?: string
  discountAllocations?: DiscountAllocation[]
  discountedItemsCount?: number
  upsell?: string
  cost?: string
}
const ProductPrice = ({
  currentVariant,
  bundle,
  size = 'h5',
  discountAllocations,
  discountedItemsCount,
  product,
  upsell,
  showRange,
  cost
}: Props) => {
  const discountedAmounts =
    discountAllocations?.map(({ discountedAmount }) =>
      parseFloat(discountedAmount?.amount as string)
    ) || []
  const discountAllocationTags = discountAllocations?.map(({ title }) => title)
  const { compareAtPrice, price, isDiscounted } = useProductPrice({
    currentVariant,
    discountAllocations,
    discountedItemsCount,
    bundle: bundle || undefined,
    upsell,
    cost:
      cost && discountedItemsCount
        ? (parseFloat(cost as string) / discountedItemsCount).toString()
        : undefined
  })

  const calculatedSavings = isDiscounted ? compareAtPrice.amount - price.amount : 0
  const displayRange =
    showRange &&
    product &&
    product.priceRange?.maxVariantPrice?.amount > product.priceRange?.minVariantPrice?.amount
  return (
    <>
      {displayRange && product && (
        <div className={`flex gap-2`}>
          <p className={`${size}`}>
            {parseMoneyFormat(product.priceRange?.minVariantPrice?.amount)}
            {product.priceRange?.maxVariantPrice?.amount >
              product.priceRange?.minVariantPrice?.amount && (
              <>
                <span className={`${size} mx-2`}>-</span>
                <span className={`${size}`}>
                  {parseMoneyFormat(product.priceRange?.maxVariantPrice?.amount)}
                </span>
              </>
            )}
          </p>
        </div>
      )}
      {!displayRange && (
        <section className={`flex flex-nowrap`}>
          <div
            className={`relative flex items-center text-secondary lg:w-auto dark:text-gray-tertiary`}
            data-test="product-price-container"
            data-component="product-price"
          >
            {price.amount > 0 && (
              <span
                data-test="product-compare-price"
                className={`${size ? size : 'p'} mr-2 block font-semibold ${compareAtPrice.amount > 0 && compareAtPrice.amount != price.amount ? 'text-primary' : 'text-secondary dark:text-white'} `}
              >
                {parseMoneyFormat(price.amount)}
              </span>
            )}

            {compareAtPrice.amount > 0 && compareAtPrice.amount != price.amount && !upsell && (
              <span
                data-test={`product-price`}
                className={`${size ? size : 'p'} text-gray-teritary block font-normal text-secondary line-through  dark:text-white`}
              >
                {parseMoneyFormat(compareAtPrice.amount)}
              </span>
            )}
            {upsell && currentVariant && (
              <span
                data-test={`product-price`}
                className={`${size ? size : 'p'} text-gray-teritary block font-normal text-secondary line-through  dark:text-white`}
              >
                {parseMoneyFormat(currentVariant.price.amount)}
              </span>
            )}
            {discountedAmounts &&
              discountedAmounts?.length > 0 &&
              discountedAmounts?.map((e) => {
                if (e <= 0 || !e) return <></>
                return (
                  <div
                    key={e}
                    className="ml-2 flex gap-1 uppercase text-white"
                    data-component="product-savings"
                  >
                    {e && (
                      <div className="p-alt flex items-center gap-1 rounded bg-success p-1 text-xxs">
                        Save
                        <span className="">
                          {parseMoneyFormat(e / (discountedItemsCount || 1))}
                        </span>
                      </div>
                    )}
                  </div>
                )
              })}
            {discountedAmounts?.length <= 0 && isDiscounted && calculatedSavings > 0 ? (
              <div
                key={calculatedSavings}
                className="ml-2 flex gap-1 uppercase text-white"
                data-component="product-savings"
              >
                {calculatedSavings && (
                  <div className="p-alt flex items-center gap-1 rounded bg-success p-1 text-xxs">
                    Save
                    <span className="">{parseMoneyFormat(calculatedSavings)}</span>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
      )}
      {discountAllocationTags &&
        discountAllocationTags.length > 0 &&
        discountAllocationTags.map((title) => {
          if (!title) return <></>
          return (
            <div key={title} className="flex items-center gap-1 uppercase text-gray-tertiary">
              <div className="flex items-center align-middle ">
                <span
                  aria-hidden="true"
                  className="material-symbols-outlined"
                  style={{ fontSize: '16px' }}
                >
                  shoppingmode
                </span>
              </div>
              <div className="p-alt flex gap-1">
                <span className="text-sm">{title}</span>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default ProductPrice
