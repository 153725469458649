'use client'
import { Address, Button, Drawer, Input } from '@/components'
import { SetStateAction, useRef, useState } from 'react'
import {
  createAddress,
  deleteAddress,
  updateAddress,
  updateDefaultAddress
} from '@/actions/account/account.actions'
import { handleShopifyErrors } from '@/lib/shopify/utils'
import {
  accessibleErrorToast,
  accessibleSuccessToast,
  cleanPhoneNumberFormat,
  formatPhoneNumberView
} from '@/utils'

const ManageAddresses = ({ customer }: { customer: Customer }) => {
  const [defaultAddress, setDefaultAddress] = useState(customer?.defaultAddress)
  const [addresses, setAddresses] = useState(
    customer?.addresses?.nodes.length > 0
      ? customer?.addresses?.nodes.filter((address) => address.id !== defaultAddress.id)
      : []
  )

  const removeAddressAction = async (id?: string) => {
    if (id === defaultAddress.id) {
      accessibleErrorToast(
        'A default address is required. \n Please add a new address and make it default in order to delete this address.'
      )

      return
    }

    const formData = new FormData()
    formData.append('id', id || '')
    const { deletedCustomerAddressId, customerUserErrors } = await deleteAddress(null, formData)

    if (customerUserErrors.length === 0) {
      setAddresses(addresses.filter((address) => address.id !== deletedCustomerAddressId))
      accessibleSuccessToast('Address removed successfully!')
    }

    handleShopifyErrors(customerUserErrors)
  }
  const updateAddressAction = async (addressId: string) => {
    const formData = new FormData()
    formData.append('addressId', addressId)

    const { customer, customerUserErrors } = await updateDefaultAddress(null, formData)

    if (customerUserErrors.length === 0) {
      setDefaultAddress(customer?.defaultAddress)
      setAddresses(
        customer.addresses.nodes.filter((address) => address.id !== customer?.defaultAddress?.id)
      )
      accessibleSuccessToast('Default address was updated!')
    }

    handleShopifyErrors(customerUserErrors)
  }

  return (
    <section className="col-span-10 lg:col-span-8">
      <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
        {defaultAddress && (
          <>
            <div className="bg-secondary">
              <div className="flex flex-col gap-6 px-4 pt-3 text-white">
                <div className="flex justify-between text-sm font-bold ">
                  <label className="inline-flex items-center gap-2">
                    <div className="flex h-[15px] w-[15px] items-center justify-center">
                      <Input
                        name="defaultAddress"
                        type="checkbox"
                        checked={true}
                        className="h-[15px] w-[15px]"
                      />
                    </div>
                    Default
                  </label>
                  <div className="flex items-center gap-2">
                    <Drawer icon="edit" iconSize={22} className="max-w-[525px]">
                      {(_, handleClose) => (
                        <AddressUpdateForm
                          isDefault={true}
                          address={defaultAddress}
                          defaultAddress={defaultAddress}
                          handleClose={handleClose}
                          setAddresses={setAddresses}
                          setDefaultAddress={setDefaultAddress}
                        />
                      )}
                    </Drawer>
                    <button
                      className="material-symbols-outlined"
                      onClick={() => {
                        removeAddressAction(defaultAddress?.id || '')
                      }}
                    >
                      delete
                    </button>
                  </div>
                </div>
                <div>
                  <p className="h5 text-white">
                    {defaultAddress.address1}, {defaultAddress.address2}
                  </p>
                </div>
              </div>
              <Address bgColor="secondary" {...defaultAddress} />
            </div>
          </>
        )}
        {addresses?.map((address) => {
          return (
            <div key={address.id} className="bg-gray">
              <div className="flex flex-col gap-6 px-4 pt-3 ">
                <div className="flex justify-between text-sm font-bold">
                  <label className="inline-flex items-center gap-2">
                    <div className="flex h-[15px] w-[15px] items-center justify-center">
                      <Input
                        name="defaultAddress"
                        type="checkbox"
                        onChange={() => updateAddressAction(address.id || '')}
                        className="h-full w-full"
                      />
                    </div>
                    Make default
                  </label>
                  <div className="flex items-center gap-2">
                    <Drawer icon="edit" iconSize={22} className="max-w-[525px]">
                      {(_, handleClose) => (
                        <AddressUpdateForm
                          isDefault={false}
                          address={address}
                          defaultAddress={defaultAddress}
                          handleClose={handleClose}
                          setAddresses={setAddresses}
                          setDefaultAddress={setDefaultAddress}
                        />
                      )}
                    </Drawer>
                    <button
                      className="material-symbols-outlined"
                      onClick={() => {
                        removeAddressAction(address.id)
                      }}
                    >
                      delete
                    </button>
                  </div>
                </div>
                <div>
                  <p className="h5 ">
                    {address?.address1}, {address?.address2}
                  </p>
                </div>
              </div>
              <Address bgColor="gray" {...address} />
            </div>
          )
        })}
        <div className="flex items-center justify-center">
          <Drawer
            icon=""
            className="max-w-[525px]"
            renderButton={(handleOpen) => (
              <button
                onClick={handleOpen}
                className="my-10 flex flex-col items-center justify-center gap-2 text-sm uppercase duration-100 hover:scale-105"
              >
                <span className="material-symbols-outlined inline-flex aspect-1 items-center justify-center rounded-full bg-gray p-9">
                  add
                </span>
                <p className="p">add an address</p>
              </button>
            )}
          >
            {(_, handleClose) => {
              return (
                <AddAddressForm
                  defaultAddress={defaultAddress}
                  setDefaultAddress={setDefaultAddress}
                  handleClose={handleClose}
                  addresses={addresses}
                  setAddresses={setAddresses}
                />
              )
            }}
          </Drawer>
        </div>
      </div>
    </section>
  )
}
const AddressUpdateForm = ({
  isDefault,
  address,
  handleClose,
  setAddresses,
  setDefaultAddress
}: {
  isDefault: boolean
  address: Address
  handleClose: () => void
  defaultAddress: Address
  setAddresses: React.Dispatch<SetStateAction<Address[]>>
  setDefaultAddress: React.Dispatch<SetStateAction<Address>>
}) => {
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    address1: false,
    city: false,
    zip: false
  })
  const [values, setValues] = useState({
    ...address,
    phone: address.phone ? formatPhoneNumberView(address.phone) : undefined
  })

  const editAddressAction = async (e: FormData): Promise<boolean> => {
    const makeDefault = e.get('default')

    const { address: addressResponse, customerUserErrors } = await updateAddress(
      null,
      e,
      address?.id || ''
    )

    if (makeDefault) {
      const form = new FormData()
      form.set('addressId', address?.id || '')

      const { customer } = await updateDefaultAddress(null, form)

      setDefaultAddress(customer.defaultAddress)
      setAddresses(
        customer.addresses.nodes.filter((address) => address.id !== customer?.defaultAddress?.id)
      )
    } else {
      if (!isDefault) {
        if (addressResponse) {
          setAddresses((prev) =>
            prev.map((adr) => {
              if (address?.id === adr?.id) {
                return addressResponse
              }
              return adr
            })
          )

          accessibleSuccessToast('Address updated successfully!')
          handleClose()
        }
      } else {
        setDefaultAddress(addressResponse)
      }
    }

    // Handle errors and display them to the user
    handleShopifyErrors(customerUserErrors)

    return false
  }

  return (
    <form
      action={async (e) => {
        const successs = await editAddressAction(e)
        successs && handleClose()
      }}
      className="p max-h-[90svh] overflow-y-auto p-8"
    >
      <div className="mb-8">
        <h4 className="h5">Edit your address</h4>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <label className="col-span-1 flex flex-col gap-1 " autoFocus={true}>
          First Name *
          <Input
            type="text"
            name="firstName"
            required={touched.firstName}
            value={values.firstName}
            onBlur={() => setTouched({ ...touched, firstName: true })}
            onChange={(e) => setValues({ ...values, firstName: e.target.value })}
          />
        </label>
        <label className="col-span-1 flex flex-col gap-1">
          Last Name *
          <Input
            type="text"
            name="lastName"
            required={touched.lastName}
            value={values.lastName}
            onBlur={() => setTouched({ ...touched, lastName: true })}
            onChange={(e) => setValues({ ...values, lastName: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Company
          <Input
            type="text"
            name="company"
            value={values.company}
            onChange={(e) => setValues({ ...values, company: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Address *
          <Input
            type="text"
            name="address1"
            required={touched.address1}
            value={values.address1}
            onBlur={() => setTouched({ ...touched, address1: true })}
            onChange={(e) => setValues({ ...values, address1: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Apartment, suite, etc
          <Input
            type="text"
            name="address2"
            value={values.address2}
            onChange={(e) => setValues({ ...values, address2: e.target.value })}
          />
        </label>
        <label className="col-span-1 flex flex-col gap-1">
          City *
          <Input
            type="text"
            name="city"
            required={touched.city}
            value={values.city}
            onBlur={() => setTouched({ ...touched, city: true })}
            onChange={(e) => setValues({ ...values, city: e.target.value })}
          />
        </label>
        <label className="col-span-1 flex flex-col gap-1">
          Postal code *
          <Input
            type="text"
            name="zip"
            required={touched.zip}
            value={values.zip}
            onBlur={() => setTouched({ ...touched, zip: true })}
            onChange={(e) => setValues({ ...values, zip: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Phone
          <Input
            type="text"
            name="phone"
            value={values.phone}
            placeholder="(xxx) xxx-xxxx"
            onChange={(e) => {
              if (cleanPhoneNumberFormat(e.target.value).length > 10) {
                return
              }
              setValues({ ...values, phone: formatPhoneNumberView(e.target.value) })
            }}
          />
        </label>
      </div>

      <div className="pt-5">
        <label className="p inline-flex items-center gap-2">
          <div className="flex h-[15px] w-[15px] items-center">
            <Input name="default" type="checkbox" className="h-[15px] w-[15px]" />
          </div>
          Make default
        </label>
      </div>

      <div className="mt-10">
        <Button
          type="submit"
          className="w-full"
          disabled={Object.entries(values).some(([key, value]) => {
            if (Object.keys(touched).includes(key) && !value) {
              return true
            }

            return false
          })}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}
const AddAddressForm = ({
  defaultAddress,
  setDefaultAddress,
  handleClose,
  addresses,
  setAddresses
}: {
  defaultAddress: Address
  addresses: Address[]
  setDefaultAddress: React.Dispatch<SetStateAction<Address>>
  setAddresses: React.Dispatch<SetStateAction<Address[]>>
  handleClose: () => void
}) => {
  const formRef = useRef<HTMLFormElement>(null)

  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    address1: false,
    city: false,
    zip: false
  })
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    phone: ''
  })

  const addAddressAction = async (e: FormData): Promise<boolean> => {
    const makeDefault = e.get('default')

    const { address, customerUserErrors } = await createAddress(null, e)

    if (customerUserErrors.length === 0) {
      if (addresses.length === 0 && !defaultAddress) {
        setDefaultAddress(address)
      } else {
        if (makeDefault) {
          const form = new FormData()
          form.set('addressId', address?.id || '')

          const res = await updateDefaultAddress(null, form)

          setDefaultAddress(res.customer.defaultAddress)
          setAddresses((prev) => [...prev, defaultAddress])
        } else {
          setAddresses((prev) => [...prev, address])
        }
      }
      formRef?.current?.reset()

      accessibleSuccessToast('Address added successfully!')

      return true
    }

    // Handle errors and display them to the user
    handleShopifyErrors(customerUserErrors)

    return false
  }

  return (
    <form
      ref={formRef}
      action={async (e) => {
        const successs = await addAddressAction(e)
        successs && handleClose()
      }}
      className="p max-h-[90svh] overflow-y-auto p-8"
    >
      <div className="mb-8">
        <h4 className="h5">Add an address</h4>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <label className="col-span-1 flex flex-col gap-1 " autoFocus={true}>
          First Name *
          <Input
            type="text"
            name="firstName"
            required={touched.firstName}
            value={values.firstName}
            onBlur={() => setTouched({ ...touched, firstName: true })}
            onChange={(e) => setValues({ ...values, firstName: e.target.value })}
          />
        </label>
        <label className="col-span-1 flex flex-col gap-1">
          Last Name *
          <Input
            type="text"
            name="lastName"
            required={touched.lastName}
            value={values.lastName}
            onBlur={() => setTouched({ ...touched, lastName: true })}
            onChange={(e) => setValues({ ...values, lastName: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Company
          <Input
            type="text"
            name="company"
            value={values.company}
            onChange={(e) => setValues({ ...values, company: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Address *
          <Input
            type="text"
            name="address1"
            required={touched.address1}
            value={values.address1}
            onBlur={() => setTouched({ ...touched, address1: true })}
            onChange={(e) => setValues({ ...values, address1: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Apartment, suite, etc
          <Input
            type="text"
            name="address2"
            value={values.address2}
            onChange={(e) => setValues({ ...values, address2: e.target.value })}
          />
        </label>
        <label className="col-span-1 flex flex-col gap-1">
          City *
          <Input
            type="text"
            name="city"
            required={touched.city}
            value={values.city}
            onBlur={() => setTouched({ ...touched, city: true })}
            onChange={(e) => setValues({ ...values, city: e.target.value })}
          />
        </label>
        <label className="col-span-1 flex flex-col gap-1">
          Postal code *
          <Input
            type="text"
            name="zip"
            required={touched.zip}
            value={values.zip}
            onBlur={() => setTouched({ ...touched, zip: true })}
            onChange={(e) => setValues({ ...values, zip: e.target.value })}
          />
        </label>
        <label className="col-span-2 flex flex-col gap-1">
          Phone
          <Input
            type="text"
            name="phone"
            value={values.phone}
            placeholder="(xxx) xxx-xxxx"
            onChange={(e) => {
              setValues({ ...values, phone: formatPhoneNumberView(e.target.value) })
            }}
          />
        </label>
      </div>

      <div className="pt-5">
        <label className="p inline-flex items-center gap-2">
          <div className="flex h-[15px] w-[15px] items-center">
            <Input name="default" type="checkbox" className="h-[15px] w-[15px]" />
          </div>
          Make default
        </label>
      </div>
      <div className="mt-10">
        <Button
          type="submit"
          className="w-full"
          disabled={Object.entries(values).some(([key, value]) => {
            if (Object.keys(touched).includes(key) && !value) {
              return true
            }

            return false
          })}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}

export default ManageAddresses
