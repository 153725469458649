'use client'
import { addCustomerTags } from '@/actions/account/account.actions'
import { useCustomerContext } from '@/context'
import { RAYSCLUB_TAG } from '@/constants'
import { accessibleErrorToast, accessibleSuccessToast } from '@/utils'
import { Button } from '../button'
import { Input } from '../input'
import { useRouter } from 'next/navigation'
import toast from 'react-hot-toast'
import ModuleHandler from '../module-handler'

export interface Props {
  title: string
  subTitle: string
  buttonText: string
  componentGlobalConfig?: Sanity.ComponentConfig
}

const RaysClubForm = ({
  title = 'Want access to exclusive products, recipes and more?',
  subTitle = "Join Ray's Club to gain access, for FREE",
  buttonText = 'Join now',
  componentGlobalConfig
}: Props) => {
  const router = useRouter()
  const { customer } = useCustomerContext()

  const actionHandler = async () => {
    if (customer) {
      const res = await addCustomerTags(null, {
        id: customer?.id || '',
        tags: [RAYSCLUB_TAG]
      })

      if (res.userErrors && res.userErrors.length > 0) {
        res.userErrors.forEach((error) => accessibleErrorToast(error.message))
      }
      accessibleSuccessToast("Congrats! You've joined Ray's Club!")
    } else {
      toast.error('Please create an account to access Rays Club')
      router.push('/account/sign-up?raysclub=true')
    }
  }

  return (
    <ModuleHandler config={componentGlobalConfig}>
      <form action={actionHandler}>
        <div className="my-10 mb-[32px] w-full border-y border-gray py-4 text-secondary lg:mb-[64px]">
          <div className="container flex flex-wrap">
            <div className="w-full lg:w-5/6">
              <h3 className="h4">{title}</h3>
              <p className="p">{subTitle}</p>
            </div>
            <div className="mt-4 w-full lg:mt-0 lg:w-1/6">
              <Button type="submit" color="primary" size="large" className="w-full">
                {buttonText}
              </Button>
            </div>
            <div className="mt-4 w-full lg:mt-0 lg:text-right">
              <label className="p flex gap-2 text-sm lg:justify-end">
                <div className="h-[16px] w-[16px]">
                  <Input type="checkbox" value="agree" name="rays-club-opt-in" required />
                </div>
                <span>
                  By signing up for Ray&rsquo;s Club, you consent to receiving emails from recteq.
                </span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </ModuleHandler>
  )
}

export default RaysClubForm
