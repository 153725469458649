'use client'
import FastInfo from './fast-info'
import OrdersTable from './orders-table'

const AccountOrders = ({
  customer,
  orders,
  subscriptions
}: {
  orders?: Order[]
  customer: Customer
  subscriptions: { data: Subscription[] }
}) => {
  return (
    <div className="col-span-10 flex w-full flex-col gap-10 lg:col-span-8">
      <FastInfo
        customer={customer}
        subscriptionsCount={subscriptions?.data?.length}
        orders={orders}
      />
      <div className="mb-6 flex flex-col gap-10">
        <h3 className="h4 hidden lg:inline">order history</h3>
        <OrdersTable orders={orders} />
      </div>
    </div>
  )
}

export default AccountOrders
