'use client'
import { useEffect, useState } from 'react'
import { DESKTOP_BREAKPOINT } from '../constants'

interface WindowSize {
  width: number
  height: number
}

export const useIsDesktop = (): boolean => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false)

  const getWindowSize = (): WindowSize => ({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = (): void => {
      const { width } = getWindowSize()
      setIsDesktop(width >= DESKTOP_BREAKPOINT)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isDesktop
}
