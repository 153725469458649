'use client'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import { oswald, lato } from '../../assets/fonts'
import { useGtm, useHeaderContext } from '@/context'
interface Props {
  children:
    | JSX.Element[]
    | JSX.Element
    | ((handleOpen: () => void, handleClose: () => void) => JSX.Element[] | JSX.Element)
  icon: string
  iconSize?: number
  testAttr?: string
  className?: string
  openOnChange?: boolean
  cart?: Cart
  title?: string
  type?: string
  renderButton?: (handleOpen: () => void, testAttr: string) => React.ReactNode
}
const Drawer = ({
  children,
  icon,
  iconSize = 24,
  className,
  testAttr = '',
  cart,
  type,
  title,
  renderButton
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const pathname = usePathname()
  const ref = useRef(cart?.totalQuantity)
  const { fireEvent } = useGtm()
  const { setIsOpen: openHeader } = useHeaderContext()

  const handleOpen = () => {
    document.body.classList.add('locked')
    if (type == 'cart') {
      fireEvent('miniCartOpened')
      fireEvent('viewCart', {})
    }
    if (type == 'search') {
      fireEvent('searchOpened')
    }
    setIsOpen(true)
  }
  const handleClose = () => {
    document.body.classList.remove('locked')
    if (type == 'cart') {
      fireEvent('miniCartClosed')
    }
    if (type == 'search') {
      fireEvent('searchClosed')
    }
    setIsOpen(false)
    openHeader(false)
  }

  useEffect(() => {
    // on route change, close the drawer
    document.body.classList.remove('locked')
    setIsOpen(false)
    openHeader(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (cart?.totalQuantity !== ref.current) {
      ref.current = cart?.totalQuantity

      if (!isOpen && !pathname.includes('cart')) {
        setIsOpen(true)
        document.body.classList.toggle('locked')
      }
      ref.current = cart?.totalQuantity
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.totalQuantity])
  const { lines, totalQuantity } = cart || {}
  const bundleExist = lines
    ?.filter((line) => line?.merchandise?.product?.productType === 'Qualifier')
    .reduce((acc, curr) => {
      return curr.quantity + acc
    }, 0)
  return (
    <>
      {renderButton && renderButton(handleOpen, `drawer-button ${testAttr}`)}
      {!renderButton && (
        <button
          data-test={`drawer-button ${testAttr}`}
          onClick={handleOpen}
          aria-label={testAttr}
          className="relative inline-flex items-center"
        >
          {title ? <span className="p">{title}</span> : ''}
          <span
            aria-hidden="true"
            className="material-symbols-outlined"
            style={{ fontSize: iconSize }}
          >
            {icon}
          </span>
          {cart && cart?.totalQuantity > 0 ? (
            <div className="absolute -right-3 -top-3 h-full w-full">
              <span className="p flex aspect-1 max-h-[24px] max-w-[24px] items-center justify-center rounded-full bg-primary text-[11px] leading-[24px] text-white">
                {totalQuantity && totalQuantity - (bundleExist || 0)}
              </span>
            </div>
          ) : null}
        </button>
      )}

      <Transition as={Fragment} show={isOpen}>
        <Dialog onClose={() => {}} className={`relative`} role="alertdialog">
          <div
            onClick={handleClose}
            className={`fixed left-0 top-0 z-[20] h-full w-full cursor-pointer bg-black/20`}
          />

          <TransitionChild
            enter="transition-all ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <DialogPanel
              data-test="cart-panel"
              className={`fixed right-0 top-0 z-[20] flex min-h-[100dvh] w-full flex-col bg-white text-secondary ${oswald.variable} ${lato.variable} ${className}`}
            >
              <div className="mt-4 flex w-full justify-end pr-4">
                <button
                  data-test="cart-close-button"
                  type="button"
                  onClick={handleClose}
                  className="material-symbols-outlined"
                >
                  close
                </button>
              </div>
              <div className="h-[calc(100svh-40px)] overflow-y-auto">
                {typeof children !== 'function' ? children : children(handleOpen, handleClose)}
              </div>
            </DialogPanel>
          </TransitionChild>
        </Dialog>
      </Transition>
    </>
  )
}

export default Drawer
