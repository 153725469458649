'use client'
import Image from 'next/image'
import { ProductTitle } from '../products'
import { useState, useEffect } from 'react'
import { Button } from '../button'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useGtm, useCompareContext } from '@/context'
import toast from 'react-hot-toast'

const CompareWidget = () => {
  const [open, setOpen] = useState(false)
  const { removeProductToCompare, previews } = useCompareContext()
  const { fireEvent } = useGtm()
  const pathname = usePathname()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <>
      <div
        aria-expanded={open}
        className={`ease fixed bottom-0 left-0 z-[16] w-full bg-gray transition-all duration-500 print:hidden ${open ? 'max-h-[60svh] border-t border-gray-300 lg:max-h-[40svh]' : 'max-h-0'}`}
        data-component="compare-widget"
      >
        <div className="mx-auto max-w-[2400px]">
          <div className="relative flex w-full flex-col justify-between gap-7 py-4 lg:flex-row">
            {(previews.length > 0 || open) && (
              <button
                aria-live="polite"
                className={`h6 absolute bottom-full right-0 flex origin-bottom items-center gap-6 border border-b-0 px-6 py-3 text-secondary transition-all duration-500 ${open ? 'border-gray-300 bg-gray' : 'border-gray bg-secondary text-white'}`}
                onClick={() => {
                  setOpen(!open)
                  if (!open) {
                    fireEvent('compareDrawerExpanded')
                  } else {
                    fireEvent('compareDrawerCollapsed')
                  }
                }}
              >
                Compare
                <span
                  aria-hidden="true"
                  className={`material-symbols-outlined duration-150 ${open ? '' : 'rotate-180'}`}
                >
                  keyboard_arrow_down
                </span>
              </button>
            )}
            <div className="container flex flex-col gap-4 lg:flex-row">
              <div
                aria-live="polite"
                className="flex max-h-[35svh] flex-col gap-7 overflow-y-auto md:flex-row lg:w-4/5 lg:flex-nowrap"
              >
                {previews.length > 0 &&
                  previews?.map((product) => {
                    return (
                      <article
                        key={product.id}
                        data-test="compare-products-article-container"
                        className="relative inline-flex min-w-[300px] flex-col items-center justify-center gap-2 border border-l-gray-secondary bg-white pb-2 lg:w-1/4"
                      >
                        <button
                          id="remove-button"
                          type="button"
                          onClick={() => {
                            removeProductToCompare(product)
                            toast.success('Product removed from the compare list')

                            fireEvent('compareProductRemoved', product)
                          }}
                          aria-labelledby={`remove-button ${product.id}`}
                          aria-label="remove"
                          className="material-symbols-outlined absolute right-1 top-1 text-gray-tertiary"
                          style={{ fontSize: '20px' }}
                        >
                          close
                        </button>
                        <div className="relative aspect-1 h-[110px] w-[110px]">
                          <Image
                            src={product?.featuredImage?.url}
                            alt={product?.featuredImage?.altText || ''}
                            fill
                            className="object-contain"
                          />
                        </div>

                        <Link href={`/products/${product.handle}`} className="px-2 ">
                          <ProductTitle product={product} size="h6 line-clamp-2 text-center" />
                        </Link>
                      </article>
                    )
                  })}
                {previews.length === 0 && (
                  <p className="h6 text-secondary">There are no products to compare yet.</p>
                )}
              </div>

              <div className="flex flex-col items-end justify-center gap-6 lg:w-1/4">
                <h6 className="h5 text-right text-secondary">select up to 4 items to compare</h6>
                <div>
                  <Button
                    color="primary"
                    size="normal"
                    renderButton={({ color, size }) => {
                      return (
                        <Link
                          href="/compare"
                          className={`${size} ${previews.length > 0 ? color : 'pointer-events-none bg-gray-secondary text-white'} inline-block`}
                        >
                          compare now
                        </Link>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompareWidget
