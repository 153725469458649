import { parseMoneyFormat } from '@/utils'
interface Props {
  price: Money
  compareAtPrice: Money
  size: string
  showRange?: boolean
  minMax?: {
    min: number
    max: number
  }
  discountAllocations?: DiscountAllocation[]
  numberOfDiscountedItems?: number
}
const CompareAtPricing: React.FC<Props> = ({
  price,
  compareAtPrice,
  size,
  showRange,
  minMax = { min: 0, max: 0 },
  discountAllocations,
  numberOfDiscountedItems = 1
}) => {
  const compareAtPriceAmount = compareAtPrice?.amount || 0
  const priceAmount = price?.amount || 0

  const discountedAmounts =
    discountAllocations?.map(({ discountedAmount }) =>
      parseFloat(discountedAmount?.amount as string)
    ) || []
  const discountAllocationTags = discountAllocations?.map(({ title }) => title)

  //
  const totalDiscounts =
    discountedAmounts?.reduce((acc, curr) => {
      return acc + curr
    }, 0) || 0

  const discountedPrice =
    parseFloat(priceAmount as string) - totalDiscounts / numberOfDiscountedItems

  const isDiscounted = compareAtPriceAmount || totalDiscounts

  return (
    <>
      {!showRange && (
        <>
          {parseFloat(compareAtPriceAmount as string) < parseFloat(priceAmount as string) && (
            <div
              className="relative flex text-secondary dark:text-gray-tertiary"
              data-test="product-price-container"
            >
              {isDiscounted ? (
                <span
                  data-test={`product-compare-price`}
                  className={`_p mr-2 ${size} block text-primary`}
                >
                  {totalDiscounts > 0
                    ? parseMoneyFormat(discountedPrice)
                    : parseMoneyFormat(compareAtPriceAmount)}
                </span>
              ) : (
                <></>
              )}

              <span
                data-test={`product-price`}
                className={`_p ${size} text-gray-teritary block font-normal text-secondary ${isDiscounted ? 'line-through' : ''}  dark:text-white`}
              >
                {parseMoneyFormat(priceAmount)}
              </span>
            </div>
          )}
          {parseFloat(compareAtPriceAmount as string) > parseFloat(priceAmount as string) && (
            <div
              className="relative flex text-secondary dark:text-gray-tertiary"
              data-test="product-price-container"
            >
              <span
                data-test={`product-compare-price`}
                className={`_p mr-2 ${size} block text-primary`}
              >
                {parseMoneyFormat(priceAmount)}
              </span>

              <span
                data-test={`product-price`}
                className={`_p ${size} text-gray-teritary block font-normal text-secondary ${isDiscounted ? 'line-through' : ''}  dark:text-white`}
              >
                {parseMoneyFormat(compareAtPriceAmount)}
              </span>
            </div>
          )}
          {discountAllocationTags &&
            discountAllocationTags.length > 0 &&
            discountAllocationTags.map((title) => {
              if (!title) return <></>
              return (
                <div
                  key={title}
                  className="ml-4 flex items-center gap-1 uppercase text-gray-tertiary"
                >
                  <div className=" flex items-center align-middle ">
                    <span
                      aria-hidden="true"
                      className="material-symbols-outlined"
                      style={{ fontSize: '18px' }}
                    >
                      sell
                    </span>
                  </div>
                  <div className="p-alt flex gap-1">
                    <span className="">{title}</span>
                  </div>
                </div>
              )
            })}
        </>
      )}

      {showRange && (
        <span
          className={`_p ${size} relative block text-secondary dark:text-gray-tertiary`}
          data-test="product-price-container"
        >
          {parseMoneyFormat(minMax?.min)} - {parseMoneyFormat(minMax?.max)}
        </span>
      )}

      {discountedAmounts &&
        discountedAmounts?.length > 0 &&
        discountedAmounts?.map((e) => {
          if (e <= 0 || !e) return <></>
          return (
            <div key={e} className="flex gap-1 uppercase text-success">
              <div className=" flex items-center align-middle ">
                <span
                  aria-hidden="true"
                  className="material-symbols-outlined"
                  style={{ fontSize: '14px' }}
                >
                  sell
                </span>
              </div>
              {e && (
                <div className="p-alt flex gap-1 text-xs">
                  Save
                  <span className="">${e}</span>
                </div>
              )}
            </div>
          )
        })}
    </>
  )
}

export default CompareAtPricing
