'use client'
import { getDataBySanityQuery } from '@/app/(store)/utils/query'
import { ContentCard, ProductCard, SanityCarouselSkelletonLoader } from '@/components'
import dynamic from 'next/dynamic'
import { ReactElement, useEffect, useState } from 'react'
import { directory } from '../../modules'
import { useCustomerContext } from '@/context'

const Carousel = dynamic(() => import('@/components').then((mod) => mod.Carousel), { ssr: false })

type Props = {
  params?: { slug?: string }
  metafieldProducts?: ProductVariant[]
  queries: DataByQuery[]
}

const AddOns = ({ metafieldProducts, queries }: Props) => {
  const { isRaysClub } = useCustomerContext()
  const [items, setItems] = useState<ReactElement[] | undefined>()

  useEffect(() => {
    const getData = async () => {
      if (metafieldProducts && metafieldProducts.length > 0) {
        const cards = metafieldProducts.map((variant: any) => {
          const reshapedVariants: ProductVariant[] = variant?.product?.variants?.nodes?.map(
            (variant: ProductVariant) => variant
          )
          const currentVariant = reshapedVariants.find(
            (reshapedVariant) => variant.id === reshapedVariant.id
          )
          const product = {
            ...variant.product,
            variants: reshapedVariants
          }

          return (
            <ProductCard
              key={variant.id}
              product={product as Product}
              currentVariant={currentVariant}
            />
          )
        })
        setItems(cards)
      } else {
        queries.forEach(async (query) => {
          const { source, manualSelections, querySelection } = query

          if (source === 'manual') {
            if (manualSelections?.reference?.manualSelection?.length > 0) {
              const sanityProducts = manualSelections.reference?.manualSelection.filter(
                (selection) => selection._type === 'productCard'
              )
              const data = await getDataBySanityQuery({
                source: 'manual',
                type: 'products',
                manualSelection: sanityProducts,
                isRaysClub
              })

              const cards = manualSelections?.reference?.manualSelection
                ?.map((selection) => {
                  if (selection._type === 'productCard') {
                    const product = data.find(
                      (item: Product) => item.id === selection.product.reference.gid
                    )
                    return directory[selection._type as keyof typeof directory]({
                      product: product,
                      buttonType: selection.buttonAction,
                      buttonText:
                        selection.buttonAction === 'subscribe' ? 'Subscribe' : 'Add To Cart',
                      planId: selection?.subscriptionId
                        ? `gid://shopify/SellingPlan/${selection?.subscriptionId}`
                        : undefined
                    })
                  }
                  return directory[selection._type as keyof typeof directory]({
                    ...selection
                  })
                })
                ?.flat()

              setItems((prev) => [...(prev || []), ...cards])
            }
          }

          if (source === 'query') {
            if (querySelection?.reference?.type === 'products') {
              const data = await getDataBySanityQuery({
                source: 'query',
                type: querySelection?.reference?.type,
                query: querySelection?.reference?.query,
                isRaysClub
              })

              const cards = data.map((product: Product) => (
                <ProductCard key={product.id} product={product} />
              ))
              setItems((prev) => [...(prev || []), ...cards])
            }

            if (querySelection?.reference?.type === 'content') {
              const data = await getDataBySanityQuery({
                source: 'query',
                type: querySelection?.reference?.type,
                query: querySelection.reference.contentquery,
                isRaysClub
              })
              const cards = data.map((hit: AlgoliaContent) => (
                <ContentCard key={hit._id} content={hit} />
              ))
              data && setItems((prev) => [...(prev || []), ...cards])
            }
          }
        })
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {items && items?.length > 0 && (
        <Carousel
          showNavigation={true}
          showPagination={true}
          slidesPerView={5}
          mobileSlidesPerView={1.5}
          delay={false}
          items={items}
          pagination={{
            el: `.swiper-custom-pagination-`,
            clickable: true,
            type: 'progressbar'
          }}
        />
      )}
      {(!items || items?.length === 0) && <SanityCarouselSkelletonLoader slidesPerView={5} />}
    </>
  )
}

export default AddOns
