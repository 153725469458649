export const algoliaToSanityArticleAdapter = (algolia: AlgoliaArticle): Sanity.Article => {
  const { _id, objectID, publishDate, _type, image, title, handle, slug, legacyImage } =
    algolia || {}

  return {
    _id: _id || objectID || '',
    title,
    _type,
    publishDate,
    slug,
    legacyImage,
    metadata: {
      _type: 'seo',
      title,
      ogimage: undefined,
      description: '',

      slug: { current: handle }
    },
    seo: undefined,
    content: [],
    media: {
      _type: 'image',
      asset: {
        _id: _id,
        url: image,
        altText: ''
      }
    },
    _createdAt: '',
    _updatedAt: '',
    relatedArticles: undefined,
    fallbackRelated: undefined
  }
}
