import { SITE_URL } from '../../constants'
import { parseShopifyProductId } from './parseShopifyProductId'

export async function fetchYotpoQuestionsPage({
  productId,
  page = 1,
  perPage = 6
}: {
  productId: string
  page?: number
  perPage?: number
}) {
  const parsedId = parseShopifyProductId(productId)
  const queryParams = new URLSearchParams()
  queryParams.set('page', page.toString())
  queryParams.set('per_page', perPage.toString())

  const url = `${SITE_URL}/api/yotpo/getQuestions/${parsedId}?${queryParams}`

  const res = await fetch(url, { cache: 'no-store' })
  if (!res.ok) {
    return { error: `Failed to fetch Yotpo questions. Status: ${res.status}` }
  }

  const data = await res.json()
  // data might have a structure like data.response.pagination for Q&A
  return data?.response ?? {}
}
