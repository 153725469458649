'use client'
import { useState, useEffect, useMemo, useCallback } from 'react'
import * as qs from 'qs'
import { ShopifyPricingAndInventory } from '../utils'
import { ProductFacets, fetchSanity } from '../lib'
import { useCustomerContext } from '../context'
import { HITS_PER_PAGE } from '../constants'

export const useAlgoliaProducts = ({
  search,
  sorting,
  preventFetching,
  searchParams
}: {
  search: string
  searchParams: Record<string, string | string[]>
  sorting?: string
  preventFetching?: boolean
}) => {
  const { isRaysClub } = useCustomerContext()
  const [metafields, setMetafields] = useState([])
  const [products, setProducts] = useState<AlgoliaResponse<ExtendedProduct>>({
    page: 0,
    hits: [],
    loading: true,
    nbHits: 0,
    nbPages: 0,
    facets: {},
    initialFacets: {},
    queryID: ''
  })

  /*
   * Removing the Search term from query params
   * - productqueries prop inside AlgoliaProductSearch requieres to receive only facets
   */
  const facetsOnly = useMemo(
    () => Object.entries(searchParams).filter((item) => item[0] != 'q'),
    [searchParams]
  )

  const getMetafields = useCallback(async () => {
    const { productsFacets } = (await fetchSanity(ProductFacets)) || []

    const metafields = productsFacets?.map(({ metafield, type, unit, icon }: any) => ({
      type,
      unit,
      icon,
      name: metafield?.data?.field_name,
      field: metafield?.data
    }))

    setMetafields(metafields)
    return metafields
  }, [])

  /*
   * Fetch Algolia results for content and products
   */
  const getProductResults = useCallback(
    async (page?: number) => {
      const localMetafields = metafields?.length > 0 ? metafields : await getMetafields()

      setProducts((prev: any) => ({ ...prev, loading: true, sanityConfig: localMetafields }))

      const recteqQueries = facetsOnly
        ?.filter(([key]) => key.includes('rq_'))
        ?.map(([key, value]) => [key.replace('rq_', ''), value])

      const filteredFacets = facetsOnly?.filter(([key]) =>
        localMetafields.map((metafield: any) => metafield?.field?.field_name).includes(key)
      )
      const productQueries = [...recteqQueries, ...filteredFacets]?.map(([key, input]) => {
        if (typeof input === 'object') {
          return input.map((value) => `${key}:${value}`)
        }

        try {
          const json = JSON.parse(input)

          return Object.keys(json).length > 0 ? JSON.parse(input) : `${key}: ${input}`
        } catch (e) {
          return `${key}:${input}`
        }
      })

      const ranges = productQueries
        .filter((value) => value?.type === 'range')
        .map((range) => `${range?.name}:${range?.value}`)
        .filter((item) => item)

      const decodedQueries = productQueries
        .map((item) => (typeof item === 'string' ? decodeURIComponent(item) : item))
        .filter((item) => !item?.type)

      const productQuery = qs.stringify({
        query: search,
        indexName: sorting || process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS,
        params: {
          facetFilters: decodedQueries,
          hitsPerPage: HITS_PER_PAGE,
          page: page || products.page,
          filters: `${!isRaysClub ? `NOT tags:raysclub AND ` : ''}NOT tags:"Hide From Search" AND NOT tags:"Discontinued"${ranges.length > 0 ? ' AND ' + ranges.join(' AND ') : ''}`,
          facets: localMetafields?.map(({ field }: any) => field?.field_name),
          attributesToRetrieve: [
            'title',
            'handle',
            'sku',
            'inventory_available',
            'price',
            'price_range',
            'compare_at_price',
            'product_image',
            'variants_count',
            'tags',
            '_tags'
          ]
        }
      })

      const res = await fetch(`/api/algolia-query/byIndex?${productQuery}`, {})
      const data = await res.json()
      const { hits, facets, allFacets } = data

      let prods
      prods = await ShopifyPricingAndInventory(hits)

      setProducts((prev: any) => {
        let newObject: Record<string, any> = {}

        allFacets &&
          Object.entries(allFacets).forEach(([key, value]) => {
            let attributes: Record<string, any> = {}

            Object.entries(value as Record<string, string>).forEach(([attrKey]) => {
              if (!facets[key][attrKey]) {
                attributes = {
                  ...attributes,
                  [attrKey]: 0
                }
              } else {
                attributes = {
                  ...attributes,
                  [attrKey]: facets[key][attrKey]
                }
              }
            })
            newObject[key] = {
              ...(value as any),
              ...attributes
            }
          })

        const newInitial = {
          price: { 1: 1, 4000: 1 },
          ...newObject
        }

        return {
          ...data,
          page: page || products.page,
          initialFacets: newInitial,
          hits: [...prev.hits, ...prods],
          sanityConfig: localMetafields,
          loading: false
        }
      })
    },
    [search, products, facetsOnly, sorting, metafields, getMetafields, isRaysClub]
  )

  useEffect(() => {
    if (!preventFetching) {
      setProducts((prev: any) => ({ ...prev, page: 0, hits: [], loading: true }))
      getProductResults()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sorting, searchParams])

  const getNextProductPage = () => {
    if (products.page + 1 < products.nbPages) {
      getProductResults(products.page + 1)
    }
  }

  return {
    products,
    metafields,
    getNextProductPage
  }
}
