'use client'

import Card from './card'
import { ProductCardContextProvider } from './productCardContext'

export enum CardStyles {
  default = 'border border-gray dark:border-none',
  minimal = 'minimal',
  'image-background' = 'transparent',
  'box-shadow' = 'shadow-blur',
  none = ''
}

export enum TagPosition {
  'top-left' = 'top-0 justify-start',
  'top-center' = 'top-0 justify-center',
  'top-right' = 'top-0 justify-end',
  'center-left' = 'top-1/2 -translate-y-1/2 justify-start',
  'center' = 'top-1/2 -translate-y-1/2 justify-center',
  'center-right' = 'top-1/2 -translate-y-1/2 justify-end',
  'bottom-left' = 'bottom-0 justify-start',
  'bottom-center' = 'top-full justify-center',
  'bottom-right' = 'top-full justify-end'
}

export interface Common {
  reviews?: React.ReactNode

  buttonText?: string
  buttonStyle?: 'primary' | 'secondary' | 'primary-outline' | 'secondary-outline'
  buttonType?: 'addToCart' | 'subscribe' | 'cta'
  planId?: string
  cardStyle?: keyof typeof CardStyles
  tagPosition?: keyof typeof TagPosition
  className?: string
  inFeed?: boolean
}

export interface ProductCardProps extends Common {
  product: Product
  as?: 'productCard' | 'categoryCard'
  queryId?: string
  position?: number
  currentVariant?: ProductVariant
  variantsCount?: number
}

export const ProductCard = (props: ProductCardProps) => {
  const {
    as = 'productCard',
    product,
    planId,
    cardStyle = 'default',

    buttonType = 'addToCart',
    buttonStyle = 'primary',
    buttonText = 'Add to cart',

    tagPosition = 'bottom-left',
    className = '',
    queryId,
    position,
    currentVariant,
    inFeed = false
  } = props

  if (!product) {
    console.error('Product is required at ProductCard component.')
    return <></>
  }

  // Determine if this card is variant-level: only true if currentVariant is explicitly provided.
  const isVariantLevel = typeof currentVariant !== 'undefined'

  const styleCard = inFeed ? cardStyle : 'none'
  return (
    <ProductCardContextProvider
      product={product}
      currentVariant={currentVariant}
      isVariantLevel={isVariantLevel}
      layout={{
        as,
        cardStyle: styleCard,
        tagPosition,
        buttonType,
        buttonText,
        buttonStyle
      }}
      general={{
        className,
        planId,
        queryId,
        position
      }}
    >
      <Card />
    </ProductCardContextProvider>
  )
}
